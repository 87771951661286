import { Directive, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core';

export enum KEY_CODE {
  ENTER = 39,
}

@Directive({
  selector: '[appEntercell]'
})
export class EntercellDirective implements OnDestroy {


  constructor(private el: ElementRef, renderer: Renderer2) {

  }

  @HostListener('window:keydown.enter', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    let element = this.el.nativeElement;
    let datatablebodycell = element.closest('datatable-body-cell');
    if (datatablebodycell) {
      datatablebodycell.focus()//.click();//classList.add('active');
    }
    element.blur();
    event.stopPropagation();
  }

  public ngOnDestroy() {
  }

}
