import { Component, ElementRef, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridService } from "../ag-grid/ag-grid.service";
@Component({
  selector: "app-checkbox-renderer",
  templateUrl: "./checkbox-renderer.component.html",
  styleUrls: ["./checkbox-renderer.component.scss"],
})
export class CheckboxRendererComponent
  implements ICellRendererAngularComp, OnDestroy, OnInit {
  checked = false;
  private value: boolean;
  disabledCheckBox: boolean = false;
  constructor(public agGridService: AgGridService,private elementRef: ElementRef) { }
  // refresh(params: ICellRendererParams<any, any>): boolean {
  //   return true;
  // }
  ngOnInit(): void {
  }
  public params: any;
  agInit(params: any): void {
    this.value = params.value;
    this.params = params;
    if (typeof (this.params.colDef.editable) == "boolean" && this.params.colDef.editable == false) {
      this.disabledCheckBox = true;
    }
    else if (typeof this.params.colDef.editable == "function") {
      let value = this.params.colDef.editable(this.params);
      if (!value) {
        this.disabledCheckBox = true;
      }
    }
  }

  onClick(event: Event): void {
    event.stopPropagation();
    // Handle any custom logic if needed
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  onFocus(): void {
    this.params.api.startEditingCell({
      rowIndex: this.params.node.rowIndex,
      colKey: this.params.column.getColId(),
    });
  }

  getValue(): any {
    return this.value; // Return the original value
  }

  checkedHandler(event) {
    // condition for transaction check
    if (this.params.colDef.cellRendererParams == true) {
      if (typeof (this.params.colDef.editable) == "boolean" && this.params.colDef.editable == false) {
        this.disabledCheckBox = true;
        return false;
      }
      else if (typeof this.params.colDef.editable == "function") {
        let value = this.params.colDef.editable(this.params);
        if (!value) {
          this.disabledCheckBox = true;
          return false;
        }
      }
    }
    // condition for transaction check
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);

    // if (this.params.colDef.headerComponentParams.gridName == StorageName.PERMISSION_FUNDGROUP_GRID
    //   || this.params.colDef.headerComponentParams.gridName == StorageName.PERMISSION_FORM_GRID) {
    //   if (colId == 'viewPermission' && !checked) {
    //     this.params.node.setDataValue('modifyPermission', checked);
    //     this.params.node.setDataValue('deletePermission', checked);
    //   }
    //   else if ((colId == 'modifyPermission' || colId == 'deletePermission') && checked) {
    //     this.params.node.setDataValue('viewPermission', checked);
    //   }
    // }
  }

  onchange() {
  }

  ngOnDestroy(): void { }

}
