import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginUserDataModel } from 'src/app/pages/login/login.model';
import { LoginService } from 'src/app/pages/login/login.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';
  currentUser: LoginUserDataModel;
  currentUserSubscription: Subscription;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) {
    this.currentUserSubscription = this.loginService.currentUser.subscribe(user => {
      if(user != null)
      this.currentUser = user;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }
}
