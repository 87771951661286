import { Component, OnInit } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community'
@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltip implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public data!: any;
  public formula : string;
  public color!: string;

  agInit(params: { color: string , displayName : string} & ITooltipParams): void {
    this.params = params;
    this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;

    switch(params.displayName)
    {
      case "Tier Calc" :
        this.formula = 
        "<span>Allocating : </span> "+params.data.allocPercent +" %</p>"+
        "<p><span>Tier Amount : </span>"+params.data.tierAmt +"</p>"+
        "<p><span>Comp. Tier Amount : </span>"+params.data.comparisionAmt +"</p>"+
        "<p><span>Tier Calc : "+params.data.tierCalc +"</span>"
        break;
      case "Balance":
        this.formula =  this.data.balanceFormula;
        break;
      case "Proportional %":
        this.formula =  this.data.allocPercentFormula;
        break;
    }
    this.color = this.params.color || 'white';
  }
}