import { Component, HostListener, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { UDFModel } from './pages/transactions/tier-transactions/tier-transactions.model';
import { TierTransactionService } from './pages/transactions/tier-transactions/tier-transactions.service';
import { CommonTabService } from './shared/services/common-tab.service';
import { StorageService } from './shared/services/storage.service';
import { StorageName } from './shared/common/enum';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './pages/login/login.service';
import { startWith } from 'rxjs/operators';

// //const counter = timer(0, 1000);
// const counter = interval(2000).pipe(
//       startWith(0)
//  );
 
@Component
  (
    {
      selector: 'app-root',
      templateUrl: './app.component.html',
      styleUrls: ['./app.component.scss']
    }
  )

export class AppComponent implements OnDestroy {

  public settings: Settings;
  UDFList: UDFModel[];
  public isRefresh = false;
  private _getExtraColumnSubscription!: Subscription;

  constructor(
    public appSettings: AppSettings,
    private tierTransactionsService: TierTransactionService,
    private commonTabService: CommonTabService,
    public storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService:LoginService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    console.log("app component loaded");

    // counter.subscribe(() => {
    //   this.time();
    // });

    

    this._getExtraColumnSubscription = this.tierTransactionsService.getExtraColumn().subscribe(data => {
      this.storageService.store("UDFLIST", data.response);
    })
  }

  time() {
    // let date = new Date();

    // if (this.storageService.retrieve(StorageName.TIMER) != undefined) {
    //   if ((date.getTime() - new Date(this.storageService.retrieve(StorageName.TIMER)).getTime()) / 1600 > 10) {        
    //     this.loginService.logout();
    //   }
    // }
        
    // this.storageService.store(StorageName.TIMER, date);
  }

  ngOnDestroy(): void {
    if (this._getExtraColumnSubscription) {
      this._getExtraColumnSubscription.unsubscribe();
    }
  }
}

