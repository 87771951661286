import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {
  private readonly _title = new BehaviorSubject<string>("");
  readonly _title$ = this._title.asObservable();
  get title(): string {
    return this._title.getValue();
  }
  set title(title: string) {
    this._title.next(title);
  }
  // title: string = '';
  constructor() { }
}
