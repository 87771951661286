import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DATE_FORMAT } from '../common/constant';

@Pipe({
  name: 'mydate'
})
export class MydatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe,
    @Inject(LOCALE_ID) public locale: string) {
      this.locale = 'en-US'
  }

  transform(value: Date | number | string, ...options: string[]): string {
    return this.datePipe.transform(value, DATE_FORMAT);
  }

  getUsersLocale(): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return this.locale;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : this.locale;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }
}
