import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { LoginService } from 'src/app/pages/login/login.service';
import { TransactionFilterCriteriaModel } from 'src/app/pages/transactions/tier-transactions/tier-transactions.model';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageName } from '../common/enum';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonTabService {
  onSaveBeforeClose = new EventEmitter();
  private redirectUrlAfterSave: BehaviorSubject<string>;

  constructor(private service: DataService
    , private storageService: StorageService
    , public loginService: LoginService
    , private router: Router) {
      this.redirectUrlAfterSave = new BehaviorSubject<string>(null);
     }

  updateDropdown(dropdownName: string, storageName: string) {
    let roleid = this.getRoleid();
    //let url = '/Dropdown/GetfilterDropdown/' + dropdownName;
    let url = '/Dropdown/GetfilterDropdown?formname='+ dropdownName + '&roleid='+ roleid;
    return this.service.get(url).pipe<Result>(tap((res: any) => {
      console.log(res)
      this.storageService.store(storageName, res.response);
    }))
  }

  public post(controllerName: string, data: any): Observable<Result> {
    let url = '/' + controllerName;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public get(formName: string, data: any): Observable<Result> {
    let url = '/' + formName;
    return this.service.post(url, data).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getById(formName: string, id: number, showDeleted: boolean): Observable<Result> {
    let roleid = this.getRoleid();
    let url = '/' + formName + '?Id=' + id + '&showDeleted=' + showDeleted+'&roleId='+roleid;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getfilterDropdown(formName: string): Observable<Result> {
    let roleid = this.getRoleid();
    //let url = '/Dropdown/GetfilterDropdown/' + formName;
    let url = '/Dropdown/GetfilterDropdown?formname='+ formName + '&roleid='+ roleid;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getCalcFilterDropdownByFundId(fundId: string): Observable<Result> {
    let roleid = this.getRoleid();
    //let url = '/Dropdown/GetfilterDropdown/' + formName;
    let url = '/Dropdown/getCalcFilterDropdownByFundId?fundId='+ fundId;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getPermissionByGroupAndDomain(fundGroupId,domainId): Observable<Result> {
    if(fundGroupId != undefined && domainId != undefined)
    {
      let roleid = this.getRoleid();
      let url = '/permission/getPermissionByGroupAndDomain?roleid='+ roleid+'&fundGroupId='+ fundGroupId+'&domainId='+ domainId;
      return this.service.get(url).pipe<Result>(tap((response: any) => {
        return response;
      }))
    }
    else
      return null
  }

  public getAllDropdowns(formname: string): Observable<Result> {
    let roleid = this.getRoleid();
    //let url = '/Dropdown/getAllDropdowns/' + formname;
    let url = '/Dropdown/getAllDropdowns?formname=' + formname + '&roleid='+ roleid;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      var fundModel = response.response;
      switch (formname) {
        case "Funds":
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.FUND_TYPE_DROPDOWN, fundModel.fundTypeDropdown);
          this.storageService.store(StorageName.CURRENCY_DROPDOWN, fundModel.currencyDropdown);
          this.storageService.store(StorageName.FUND_GROUP_DROPDOWN, fundModel.fundGroupDropdown);
          this.storageService.store(StorageName.DOMAIN_DROPDOWN, fundModel.domainDropdown);
          this.storageService.store(StorageName.INVESTOR_GROUP_DROPDOWN, fundModel.investorGroupDropdown);
          this.storageService.store(StorageName.INVESTOR_DROPDOWN, fundModel.investorDropdown);
          this.storageService.store(StorageName.INVESTMENT_DROPDOWN, fundModel.investmentDropdown);
          this.storageService.store(StorageName.VEHICLE_INVESTOR_DROPDOWN, fundModel.vehicleInvestorDropdown);
          this.storageService.store(StorageName.POSITION_GRID_DROPDOWN, fundModel.positionDropdown);
          this.storageService.store(StorageName.CARRY_PARTNER_DESIGNATION_LEVEL_GRID_DROPDOWN, fundModel.carryPtrDesignationDropdown);
          this.storageService.store(StorageName.CALCULATION_LEVEL_GRID_DROPDOWN, fundModel.allocCalcLevelDropdown);
          this.storageService.store(StorageName.TRANSFER_TYPE_GRID_DROPDOWN, fundModel.transferTypeDropdown);
          this.storageService.store(StorageName.STATUS_GRID_DROPDOWN, fundModel.statusDropdown);
          this.storageService.store(StorageName.FILTERFUND_DROPDOWN, fundModel.filterFundDropdown);
          break;
        case "Investors":  
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.INVESTOR_DROPDOWN, fundModel.investorDropdown);
          this.storageService.store(StorageName.INVESTOR_GROUP_DROPDOWN, fundModel.investorGroupDropdown);
          this.storageService.store(StorageName.INVESTOR_CLASSIFICATION_DROPDOWN, fundModel.investorClassificationDropdown);
          break;
        case "Investments":
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.INVESTMENT_DROPDOWN, fundModel.investmentDropdown);
          this.storageService.store(StorageName.POSITION_GRID_DROPDOWN, fundModel.positionDropdown);
          this.storageService.store(StorageName.CURRENCY_DROPDOWN, fundModel.currencyDropdown);
          this.storageService.store(StorageName.INVESTMENT_GROUP_DROPDOWN, fundModel.investmentGroupDropdown);
          this.storageService.store(StorageName.INVESTMENT_CLASSIFICATION_DROPDOWN, fundModel.investmentClassificationDropdown);
          break;
        case "Positions":
          this.storageService.store(StorageName.POSITION_DROPDOWN, fundModel.positionFormDropdown);
          break;

        case "Transactions":
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.FILTERFUND_DROPDOWN, fundModel.filterFundDropdown);
          this.storageService.store(StorageName.INVESTOR_DROPDOWN, fundModel.investorDropdown);
          this.storageService.store(StorageName.INVESTMENT_DROPDOWN, fundModel.investmentDropdown);
          this.storageService.store(StorageName.POSITION_GRID_DROPDOWN, fundModel.positionDropdown);
          this.storageService.store(StorageName.TIER_DROPDOWN, fundModel.tierDropdown);
          this.storageService.store(StorageName.INVESTOR_GROUP_DROPDOWN, fundModel.investorGroupDropdown);
          this.storageService.store(StorageName.TRANSACTION_FILTER_DROPDOWN, fundModel.transactionFilterDropdown);
          this.storageService.store(StorageName.STATUS_GRID_DROPDOWN, fundModel.statusDropdown);
          break;
        case "Calculations":
          this.storageService.store(StorageName.CALCULATION_DROPDOWN, fundModel.calculationDropdown);
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.INVESTMENT_DROPDOWN, fundModel.investmentDropdown);
          this.storageService.store(StorageName.CALCULATIONRULE_DROPDOWN, fundModel.calculationRuleDropdown);
          this.storageService.store(StorageName.POSITION_GRID_DROPDOWN, fundModel.positionDropdown);
          this.storageService.store(StorageName.CALCULATION_STATE_DROPDOWN, fundModel.calculationStateDropdown);
          this.storageService.store(StorageName.STATUS_GRID_DROPDOWN, fundModel.statusDropdown);
          this.storageService.store(StorageName.CALCULATION_TYPE_DROPDOWN, fundModel.calcTypeDropdown);
          this.storageService.store(StorageName.SUB_ACT_DROPDOWN, fundModel.subActDropdown);
          this.storageService.store(StorageName.LOCK_UNLOCK_STATUS_DROPDOWN, fundModel.lockUnlockStatusDropdown);
          this.storageService.store(StorageName.TIER_DROPDOWN, fundModel.tierDropdown);
          this.storageService.store(StorageName.OPERATOR_DROPDOWN, fundModel.operatorDropdown);
          this.storageService.store(StorageName.CALCULATION_LEVEL_DROPDOWN, fundModel.calculationLevelDropdown);
          this.storageService.store(StorageName.ALLOCATE_DROPDOWN, fundModel.allocateDropdown);
          this.storageService.store(StorageName.INVESTOR_GROUP_DROPDOWN, fundModel.investorGroupDropdown);
          break;
        case "Reports":
          this.storageService.store(StorageName.CALCULATIONRULE_DROPDOWN, fundModel.calculationRuleDropdown);
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.REPORT_DROPDOWN, fundModel.reportDropdown);
          fundModel.reportTypeDropdown.forEach(element => {
            element.id = parseInt(element.id);
          });
          this.storageService.store(StorageName.REPORT_TYPE_DROPDOWN, fundModel.reportTypeDropdown);
          break;
        case "CalculationRules":
          this.storageService.store(StorageName.CALCULATIONRULE_DROPDOWN, fundModel.calculationRuleDropdown);
          this.storageService.store(StorageName.CALCULATION_GROUP_DROPDOWN, fundModel.calculationGroupDropdown);
          this.storageService.store(StorageName.PERFORMANCERULE_DROPDOWN, fundModel.prefRetRuleDropdown);
          this.storageService.store(StorageName.TIER_DROPDOWN, fundModel.tierDropdown);
          var systemTierNames = fundModel.tierDropdown.filter(a=>a.color != '' && a.color != null).map(a=>a.name).join(',');
          this.storageService.store(StorageName.NON_STSTEM_TIER_DROPDOWN, fundModel.nonSystemTierDropdown);
          this.storageService.store(StorageName.SYSTEM_TIERS, systemTierNames);
          this.storageService.store(StorageName.SUB_ACT_DROPDOWN, fundModel.subActDropdown);
          this.storageService.store(StorageName.HURDLE_DROPDOWN, fundModel.hurdleDropdown);
          this.storageService.store(StorageName.ROLLUP_LEVEL_DROPDOWN , fundModel.rollupLevelDropdown);
          this.storageService.store(StorageName.NUMERATOR_DENOMINATOR_DROPDOWN , fundModel.numeratorDenominatorDropdown);
          this.storageService.store(StorageName.FILTER_FIELD_DROPDOWN, fundModel.filterFieldDropdown);
          this.storageService.store(StorageName.FILTER_CRITERIA_DROPDOWN, fundModel.filterCriteriaDropdown);
          this.storageService.store(StorageName.OPERATOR_DROPDOWN, fundModel.operatorDropdown);
          this.storageService.store(StorageName.ALLOCATE_DROPDOWN, fundModel.allocateDropdown);
          this.storageService.store(StorageName.APPLY_FOR_DROPDOWN, fundModel.applyForDropdown);
          this.storageService.store(StorageName.INCLUDE_IN_BALANCE_DROPDOWN, fundModel.includeInBalanceDropdown);
          
          break;
        case "Tiers":
          this.storageService.store(StorageName.TIER_DROPDOWN, fundModel.tierDropdown);
          this.storageService.store(StorageName.FUND_GROUP_DROPDOWN, fundModel.fundGroupDropdown);
          this.storageService.store(StorageName.SUB_ACT_DROPDOWN, fundModel.subActDropdown);
          break;
        case "PerformanceRules":
          this.storageService.store(StorageName.PERFORMANCERULE_DROPDOWN, fundModel.prefRetRuleDropdown);
          this.storageService.store(StorageName.TIER_DROPDOWN, fundModel.tierDropdown);
          this.storageService.store(StorageName.CATCHUP_TYPE_DROPDOWN, fundModel.catchupMethodDropdown);
          this.storageService.store(StorageName.DAY_BASIS_SETTING_DROPDOWN, fundModel.dayBasisSettingDropdown);
          this.storageService.store(StorageName.CALCULATION_TYPE_DROPDOWN, fundModel.calcTypeDropdown);
          this.storageService.store(StorageName.NUMERATOR_DENOMINATOR_DROPDOWN, fundModel.numeratorDenominatorDropdown);
          break;
        case "Permissions":
          this.storageService.store(StorageName.PERMISSION_DROPDOWN, fundModel.permissionRoleDropdown);
          break;
        case "Roles":
          this.storageService.store(StorageName.ROLE_DROPDOWN, fundModel.roleDropdown);
          break;
        case "Users":
          this.storageService.store(StorageName.ROLE_DROPDOWN, fundModel.roleDropdown);
          this.storageService.store(StorageName.USER_GRID_DROPDOWN, fundModel.userDropdown);
          this.storageService.store(StorageName.USER_DROPDOWN, fundModel.userFormDropdown);
          break;
        case "User Defined Fields":
          break;
        case "Lookups":
          this.storageService.store(StorageName.LOOKUP_DROPDOWN, fundModel.lookupDropdown);
          break;
        case "Dashboard":
          this.storageService.store(StorageName.IMPORT_TYPE_DROPDOWN, fundModel.importTypeDropdown);
          this.storageService.store(StorageName.CALCULATION_DROPDOWN, fundModel.calculationDropdown);
          this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
          this.storageService.store(StorageName.STATUS_GRID_DROPDOWN, fundModel.statusDropdown);
          // default:

          // this.storageService.store(StorageName.PREF_RET_RULE_DROPDOWN,                         fundModel.prefRetRuleDropdown               );
          // this.storageService.store(StorageName.ROLLUP_LEVEL_DROPDOWN,                          fundModel.rollupLevelDropdown               );
          // this.storageService.store(StorageName.PERMISSION_ROLE_DROPDOWN,                       fundModel.permissionRoleDropdown            );

          break;
      }
    }))
  }

  public getFundfilterDropdown(transactionFilterCriteria: TransactionFilterCriteriaModel): Observable<Result> {
    //let roleid = this.getRoleid();
    //let url = '/Dropdown/getAllDropdowns/' + formname;
    let url = '/Dropdown/GetFundfilterDropdown';
    return this.service.post(url, transactionFilterCriteria, true).pipe<Result>(tap((response: any) => {
      var fundModel = response.response;
      //this.storageService.store(StorageName.FUND_DROPDOWN, fundModel.fundDropdown);
      //this.storageService.store(StorageName.FILTERFUND_DROPDOWN, fundModel.filterFundDropdown);
      this.storageService.store(StorageName.FUNDFILTER_INVESTOR_DROPDOWN, fundModel.fundFilterInvestorDropdown);
      this.storageService.store(StorageName.FUNDFILTER_INVESTMENT_DROPDOWN, fundModel.fundFilterInvestmentDropdown);
      this.storageService.store(StorageName.FUNDFILTER_POSITION_GRID_DROPDOWN, fundModel.fundFilterPositionDropdown);
      this.storageService.store(StorageName.FUNDFILTER_INVESTOR_GROUP_DROPDOWN, fundModel.fundFilterInvestorGroupDropdown);
      //this.storageService.store(StorageName.FUNDFILTER_TIER_DROPDOWN, fundModel.fundFilterTierDropdown);
      //this.storageService.store(StorageName.TRANSACTION_FILTER_DROPDOWN, fundModel.transactionFilterDropdown);
      //this.storageService.store(StorageName.STATUS_GRID_DROPDOWN, fundModel.statusDropdown);
    }))
  }

  public delete(controllerName: string, type: number, data: number[]): Observable<Result> {
    var json = { list: data, deleteType: type, formname: controllerName }
    let url = '/deleteRecord';
    return this.service.post(url, json).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public getTabData(controllerName: string, id: number, showDeleted: any): Observable<Result> {
    let roleid = this.getRoleid();
    var json = { formName: controllerName, id: id, showDeleted: showDeleted, roleid : roleid }
    let url = '/RelationalRecord/';
    return this.service.post(url, json).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public lockUnlockRecords(locked: boolean, data: number[]): Observable<Result> {
    var json = { list: data, isLocked: locked }
    let url = '/Transaction/LockTransactions';
    return this.service.post(url, json).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  saveBeforeClose() {
    this.onSaveBeforeClose.emit();
  }

  getRoleid() {
    let roleid = 0;
    const currentUser = this.loginService.currentUserValue;
    if (currentUser && currentUser.permissions) {
      roleid = parseInt(currentUser.permissions.role.id);
    }
    return roleid;
  }


  getUserid() {
    var userId ="";
    const currentUser = this.loginService.currentUserValue;
     if (currentUser) {
       userId =currentUser.userName;
     }
    return userId;
  }




  // public get getredirectUrlAfterSave(): string {
  //   return this.redirectUrlAfterSave.value;
  // }

  public setredirectUrlAfterSave(value: string) {
    this.redirectUrlAfterSave.next(value);
  }

  checkRedirectUrlAfterSave() {
    // redirect after save on save button click of confirmation popup
    const redirectUrl = this.redirectUrlAfterSave.value;
    if (redirectUrl) {
      this.router.navigate([redirectUrl], { state: { confirmationSaveClicked: true } });
      this.setredirectUrlAfterSave(null);
    }
  }
  
}