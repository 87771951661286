//#region Imports
import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { ComponentCanDeactivate } from "src/app/shared/guard/direty-check.guard";
import { CommonService } from "src/app/shared/services/common.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { PageHeaderModel } from "src/app/shared/component/page-header/page-header.model";
import { CommonTabService } from "src/app/shared/services/common-tab.service";
import { PageHeaderComponent } from "src/app/shared/component/page-header/page-header.component";
import { DropdownListModel } from "../../fund-information/fund-information.model";
import { agGridDataModelUser, agGridDataModelUserRole, UserModel } from "./users.model";
//#endregion
//#region Component start
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
  providers: [AppComponent],
})
export class UsersComponent extends CommonService implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;
  // @ViewChild(TableHeaderButtonsComponent) tableHeaderComponent:TableHeaderButtonsComponent;
  //#region variable declarations
  // top model which contains filter, save, undo, delete and filter name
  pageHeaderModel: PageHeaderModel;
  // // top model for the panel over grid which contains add row, export, reset filter, reset grid, undo , soft delete and permanent delete and view/show hidden records
  // tableHeaderModel              : TableHeaderModel;

  //current/selected tab
  tabID: number;
  // grid apis which sets on grid ready event from ag-grid
  userGridApi: any;
  userRoleGridApi: any;


  //for changed form data
  formModel = new UserModel();
  //for original form data - will be used to reset/clear the form
  loadedFormModel = new UserModel();
  // for loading all the dropdowns - form and grid every dropdown
  dropdownListModel = new DropdownListModel();

  // tab group list
  tabGroupList: any[] = [
    { dataModel: agGridDataModelUser, label: 'User', tabIndex: 0 },
    { dataModel: agGridDataModelUserRole, label: 'User Role', tabIndex: 1 }
  ];

  private _onSaveBeforeCloseSubscription!: Subscription;

  //#endregion variable declarations
  //#region ctor
  constructor(
    public renderer: Renderer2,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService
  ) { super(renderer); }

  //#endregion
  //#region Save Confirmation
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
   var user =  this.storageService.retrieve(agGridDataModelUser.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
   var userRole =  this.storageService.retrieve(agGridDataModelUserRole.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    return (
      user?.length == 0 &&
      userRole?.length == 0);
  }
  //#endregion
  //#region Init
  ngOnInit(): void {
    this.fillPageHeader();

    //this.setTableHeader();
    this._onSaveBeforeCloseSubscription = this.commonTabService.onSaveBeforeClose.subscribe((data) => this.onSave());
  }
  fillPageHeader() {
    this.pageHeaderModel = new PageHeaderModel();
    this.pageHeaderModel.placeHolder = "";
    this.pageHeaderModel.controllerName = StorageName.USERS_CONTROLLER;
    this.pageHeaderModel.IdStorageName = StorageName.USERID;
    this.pageHeaderModel.filterOptionStorageName = StorageName.USER_DROPDOWN;
    this.pageHeaderModel.showDeleted = false;
    this.pageHeaderModel.setVisibilityForForm("User");
  }
  
  fillTopDropdown() {
    this.dropdownListModel.fundDropdown = this.storageService.retrieve(StorageName.USER_DROPDOWN);
  }
  //#endregion
  //#region Clear Complete Form
  clearFields(): void {
    this.formModel = new UserModel();
    this.pageHeaderModel.id = 0;
    this.pageHeaderModel.name = "";

    //set enpty rows to all grid apis
    this.userGridApi.api.setRowData([]);
    this.userRoleGridApi.api.setRowData([]);

    //fill the form with blank records
    this.fillForm(this.formModel);
  }
  //#endregion Clear Complete Form
  //#region page header events
  onAddNewForm() {
    //clear form fields
    this.clearFields();
    //load first tab by default - 0
    this.loadTabData(0);
  }
  //calling post api to save/update records
  onSave(): any {
    //this.pageHeaderModel.showDeleted  =   !this.tableHeaderComponent.tableHeaderModel.isShowDeletedButtonVisible;
    // get filter dropdown value from child component and assign
    this.formModel.user.userName = this.pageHeaderModel.name;
    this.formModel.user.id = this.pageHeaderModel.id;
    //create new mode for form to use for posting updated entries
    var postData = new UserModel();
    var user =  this.storageService.retrieve(agGridDataModelUser.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    var userRole =  this.storageService.retrieve(agGridDataModelUserRole.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    postData.user = this.formModel.user;
    postData.users = user;// this.userGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.userRoles = userRole;// this.userRoleGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    var deletedRecordsInUsers = postData.users?.filter((row) => row.isEdited == true && row.isDeleted == true);
    var deletedRecordsInUserRoles = postData.userRoles?.filter((row) => row.isEdited == true && row.isDeleted == true);
    this.pageHeaderComponent.post(postData, deletedRecordsInUsers.length != 0 || deletedRecordsInUserRoles.length != 0, true);
  }
  //#endregion
  //#region Tab Events
  //
  onChangeTab(event) {
    // this.pageHeaderModel.showDeleted  =   !this.tableHeaderComponent.tableHeaderModel.isShowDeletedButtonVisible;
    this.tabID = event.index
    this.loadTabData(this.tabID);
  }
  //load the tab records on click of tab
    loadTabData(index = 0) {
    this.tabID = index;
    // this.tableHeaderModel.setVisibility({
    //   isAddButtonVisible                          : true,
    //   isSoftDeleteButtonVisible                   : true,
    //   isShowDeletedButtonVisible                  : !this.pageHeaderModel.showDeleted,
    //   isHideDeletedButtonVisible                  : this.pageHeaderModel.showDeleted,
    //   isPermanentDeleteButtonVisible              : true,
    // });
    switch (this.tabID) {
      case 0: {
        agGridDataModelUser.gridApi = this.userGridApi;
        this.formModel.users = this.agGridService.performFilter(agGridDataModelUser);
        this.pageHeaderModel.agGridI = agGridDataModelUser;
        this.pageHeaderModel.gridApi = this.userGridApi;
        break;
      }
      case 1: {
        agGridDataModelUserRole.gridApi = this.userRoleGridApi;
        this.formModel.userRoles = this.agGridService.performFilter(agGridDataModelUserRole);
        this.pageHeaderModel.agGridI = agGridDataModelUserRole;
        this.pageHeaderModel.gridApi = this.userRoleGridApi;
        break;
      }
    }
    // this.tableHeaderComponent.getTabData();
  }
  ongridReady(item, gridApi) {
    switch (item.tabIndex) {
      case 0: this.userGridApi = gridApi; break;
      case 1: this.userRoleGridApi = gridApi; break;
    }
  }
  onPageSizeUpdated(pageSize){
    switch (this.tabID) {
      case 0: this.storageService.store(agGridDataModelUser.paginationStore,pageSize);      break;
      case 1: this.storageService.store(agGridDataModelUserRole.paginationStore,pageSize);  break;
    }
    this.loadTabData(this.tabID);
  }
  //#endregion tab events
  //#region get and fill form records
  getById(res: any) {
    this.storageService.store(StorageName.USERID, parseInt(res.response.user.id));
    this.setForm(res.response);
  }
  setForm(formModel: UserModel) {
    this.fillForm(formModel);
    this.loadTabData(this.tabID);
  }
  fillForm(formModel: UserModel) {
    //#region Top Data
    this.formModel.user = formModel.user;
    this.loadedFormModel.user = { ...this.formModel.user };
    this.pageHeaderModel.id = formModel.user.id;
    this.pageHeaderModel.name = formModel.user.userName;
  
    //#endregion Top Data
    //#region Set Grid Storage
    this.storageService.store(StorageName.USERS_INITIALROWS, formModel.users);
    this.storageService.store(StorageName.USER_ROLES_INITIALROWS, formModel.userRoles);
    //#endregion
  }
  //#endregion get and fill form records

  ngOnDestroy(): void {
    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }
}
//#endregion Component End