//#region Imports
import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { FundModel, FundCalculationSettings, agGridDataModelCalculationSetting, agGridDataModelInvestor, agGridDataModelInvestment, agGridDataModelCalculations, agGridDataModelPtrTransfer } from "./funds.model";
import { AppComponent } from "src/app/app.component";
import { ComponentCanDeactivate } from "src/app/shared/guard/direty-check.guard";
import { CommonService } from "src/app/shared/services/common.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { DropdownListModel } from "../fund-information.model";
import { PageHeaderModel } from "src/app/shared/component/page-header/page-header.model";
import { TableHeaderModel } from "src/app/shared/component/table-header-buttons/table-header-buttons.model";
import { CommonTabService } from "src/app/shared/services/common-tab.service";
import { PageHeaderComponent } from "src/app/shared/component/page-header/page-header.component";
import { TableHeaderButtonsComponent } from "src/app/shared/component/table-header-buttons/table-header-buttons.component";
//#endregion
//#region Component start
@Component({
  selector: "app-funds",
  templateUrl: "./funds.component.html",
  styleUrls: ["./funds.component.scss"],
  providers: [AppComponent],
})
export class FundsComponent extends CommonService implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;
  @ViewChild(TableHeaderButtonsComponent) tableHeaderComponent: TableHeaderButtonsComponent;

  //#region variable declarations
  // top model which contains filter, save, undo, delete and filter name
  pageHeaderModel: PageHeaderModel;
  // top model for the panel over grid which contains add row, export, reset filter, reset grid, undo , soft delete and permanent delete and view/show hidden records
  tableHeaderModel: TableHeaderModel;

  //current/selected tab
  tabID: number;
  // grid apis which sets on grid ready event from ag-grid
  investorGridApi: any;
  investmentGridApi: any;
  calculationSettingsGridApi: any;
  calculationsGridApi: any;
  ptrTransfersGridApi: any;
  //for changed form data
  formModel = new FundModel();
  //for original form data - will be used to reset/clear the form
  loadedFormModel = new FundModel();
  // for loading all the dropdowns - form and grid every dropdown
  dropdownListModel = new DropdownListModel();

  // tab group list
  tabGroupList: any[] = [
    { dataModel: agGridDataModelInvestor, label: 'Investors', tabIndex: 0 },
    { dataModel: agGridDataModelInvestment, label: 'Investment', tabIndex: 1 },
    { dataModel: agGridDataModelCalculationSetting, label: 'Journal Entry Selections', tabIndex: 2 },
    { dataModel: agGridDataModelCalculations, label: 'Calculations', tabIndex: 3 },
    //{ dataModel: agGridDataModelPtrTransfer, label: 'Ptr Transfer', tabIndex: 4 }
  ];
  //#endregion variable declarations

  private _onSaveBeforeCloseSubscription!: Subscription;
  
  //#region ctor
  constructor(
    public renderer: Renderer2,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService
  ) { super(renderer); }

  ngOnDestroy(): void {
    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }

  

  //#endregion
  //#region Save Confirmation
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    //check if user don't have modify rihgts then no need to open open confirm dialog
    if (formPermission && formPermission.modifyPermission ) {
    var investors =  this.storageService.retrieve(agGridDataModelInvestor.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    var investment =  this.storageService.retrieve(agGridDataModelInvestment.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    var ptrTransfer =  this.storageService.retrieve(agGridDataModelPtrTransfer.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    return (
      JSON.stringify(this.formModel.fund) === JSON.stringify(this.loadedFormModel.fund) &&
      investors?.length == 0 &&
      investment?.length == 0 &&
      ptrTransfer?.length == 0 &&
      JSON.stringify(this.calculationSettingsGridApi.api.getModel().rowsToDisplay.map(a => a.data)) === JSON.stringify(this.calculationSettingsGridApi.api.getModel().rowsToDisplay.map(a => a.data))
    ); }
    else return true
  }


  onDomainAndFundGroupDropdownChange(fundGroupId, domainId)
  {
    this.formModel.fund.fundGroupId=fundGroupId;
    this.formModel.fund.domainId=domainId;
    this.pageHeaderComponent.getPermissionByGroupAndDomain(fundGroupId,domainId);


    // var domainDropdown = this.storageService.retrieve("DOMAIN_DROPDOWN");
    // var filteredFund = domainDropdown.filter(a=>a.id == domainId)[0];
    // var canModify = filteredFund.color;
    // var canDelete = filteredFund.hoverColor;
    // this.pageHeaderModel.canModify =  canModify == 'true' ? true : false;
    // this.pageHeaderModel.canDelete =  canDelete == 'true' ? true : false;
  }
  //#endregion
  //#region Init
  ngOnInit(): void {
    this.fillPageHeader();
    this.setTableHeader();
    this._onSaveBeforeCloseSubscription = this.commonTabService.onSaveBeforeClose.subscribe((data) => this.onSave());
  }
  fillPageHeader() {
    this.pageHeaderModel = new PageHeaderModel();
    this.pageHeaderModel.placeHolder = "Enter New Fund Name";
    this.pageHeaderModel.controllerName = StorageName.FUND_HEADER_CONTROLLER;
    this.pageHeaderModel.IdStorageName = StorageName.FUNDID;
    this.pageHeaderModel.filterOptionStorageName = StorageName.FUND_DROPDOWN;
    this.pageHeaderModel.showDeleted = true;
    // var showDeleted = this.storageService.store(this.tableHeaderModel.agGridI.showDeleteStatusStorageName);
    this.pageHeaderModel.setVisibilityForForm("Fund Information");
  }
  setTableHeader() {
    this.tableHeaderModel = new TableHeaderModel();
    this.tableHeaderModel.setVisibility({
      isAddButtonVisible: false,
      isExportButtonVisible: true,
      isUndoButtonVisible: true,
      isResetFilterButtonVisible: true,
      isSoftDeleteButtonVisible: true,
      isPermanentDeleteButtonVisible: true,
      isExpandButtonVisible: true,
    });
  }
  fillTopDropdown() {
    this.dropdownListModel.fundDropdown = this.storageService.retrieve(StorageName.FUND_DROPDOWN);
    this.dropdownListModel.currencyDropdown = this.storageService.retrieve(StorageName.CURRENCY_DROPDOWN);
    this.dropdownListModel.fundTypeDropdown = this.storageService.retrieve(StorageName.FUND_TYPE_DROPDOWN);
    this.dropdownListModel.fundGroupDropdown = this.storageService.retrieve(StorageName.FUND_GROUP_DROPDOWN);
    this.dropdownListModel.domainDropdown = this.storageService.retrieve(StorageName.DOMAIN_DROPDOWN);
  }
  //#endregion
  //#region Clear Complete Form
  clearFields(): void {
    this.formModel = new FundModel();
    this.pageHeaderModel.id = 0;
    this.pageHeaderModel.name = "";
    //set default 1 row added when entering a new record and set to edited true becauuse we need to send it to api
    if (this.formModel.fundCalculationSettings.length == 0) {
      var fundCalculationSetting = new FundCalculationSettings();
      fundCalculationSetting.isEdited = true;
      fundCalculationSetting.isDeleted = false;
      this.formModel.fundCalculationSettings.push(fundCalculationSetting);
      this.calculationSettingsGridApi.api.setRowData([fundCalculationSetting]);
      this.storageService.store(StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_INITIALROWS, this.formModel.fundCalculationSettings);
    }
    //set enpty rows to all grid apis
    this.investorGridApi.api.setRowData([]);
    this.investmentGridApi.api.setRowData([]);
    this.calculationsGridApi.api.setRowData([]);
    this.ptrTransfersGridApi.api.setRowData([]);
    //fill the form with blank records
    this.fillForm(this.formModel);
  }
  //#endregion Clear Complete Form
  //#region page header events
  onAddNewForm() {
    
    //clear form fields
    this.clearFields();
    //focus on textbox to add new form name
    document.getElementById("AddNewForm").focus();

    this.tableHeaderModel.isAddNew = true;

    //load first tab by default - 0
    this.loadTabData(0);
  }
  //calling post api to save/update records
  onSave(): any {
    // get filter dropdown value from child component and assign
    this.formModel.fund.fundName = this.pageHeaderModel.name;
    this.formModel.fund.id = this.pageHeaderModel.id;
    //create new mode for form to use for posting updated entries
    var postData = new FundModel();
    postData.fund = this.formModel.fund;

     var fundInvestors =  this.storageService.retrieve(agGridDataModelInvestor.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    var fundInvestments =  this.storageService.retrieve(agGridDataModelInvestment.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    var fundCalculationSettings =  this.storageService.retrieve(agGridDataModelCalculationSetting.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
     var ptrTransfers =  this.storageService.retrieve(agGridDataModelPtrTransfer.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
     var fundCalculations =  this.storageService.retrieve(agGridDataModelCalculations.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    postData.fundInvestors = fundInvestors;// this.investorGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.fundInvestments = fundInvestments;//this.investmentGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.fundCalculationSettings =fundCalculationSettings;// this.calculationSettingsGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.ptrTransfers = ptrTransfers;//this.ptrTransfersGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.fundCalculations = fundCalculations;//this.calculationsGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    var deletedRecordsInFundInvestors = postData.fundInvestors?.filter((row) => row.isEdited == true && row.isDeleted == true);
    var deletedRecordsInFundInvestments = postData.fundInvestments?.filter((row) => row.isEdited == true && row.isDeleted == true);
    var deletedRecordsInPtrTransfers = postData.ptrTransfers?.filter((row) => row.isEdited == true && row.isDeleted == true);
    this.pageHeaderComponent.post(postData, deletedRecordsInFundInvestors.length != 0 || deletedRecordsInFundInvestments.length != 0 || deletedRecordsInPtrTransfers.length != 0);
  }
  //#endregion
  //#region Tab Events
  //
  onChangeTab(event) {
    this.tabID = event.index;
    this.loadTabData(this.tabID);
  }
  //load the tab records on click of tab
  loadTabData(index = 0) {

    this.tabID = index;
    this.tableHeaderModel.setVisibility({
      isAddButtonVisible: true,
      isSoftDeleteButtonVisible: true,
      isShowDeletedButtonVisible: !this.pageHeaderModel.showDeleted,
      isHideDeletedButtonVisible: this.pageHeaderModel.showDeleted,
      isResetDeletedButtonVisible: this.pageHeaderModel.showDeleted,
      isPermanentDeleteButtonVisible: true,
    });
    switch (this.tabID) {
      case 0: {
        agGridDataModelInvestor.masterIdStorageName = this.pageHeaderModel.IdStorageName;
        agGridDataModelInvestor.gridApi = this.investorGridApi;
        this.formModel.fundInvestors = this.agGridService.performFilter(agGridDataModelInvestor);
        this.tableHeaderModel.agGridI = agGridDataModelInvestor;
        this.tableHeaderModel.gridApi = this.investorGridApi;
        break;
      }
      case 1: {
        agGridDataModelInvestment.masterIdStorageName = this.pageHeaderModel.IdStorageName;
        agGridDataModelInvestment.gridApi = this.investmentGridApi;
        this.formModel.fundInvestments = this.agGridService.performFilter(agGridDataModelInvestment);
        this.tableHeaderModel.agGridI = agGridDataModelInvestment;
        this.tableHeaderModel.gridApi = this.investmentGridApi;
        break;
      }
      case 2: {
        agGridDataModelCalculationSetting.masterIdStorageName = this.pageHeaderModel.IdStorageName;
        agGridDataModelCalculationSetting.gridApi = this.calculationSettingsGridApi;
        this.formModel.fundCalculationSettings = this.agGridService.performFilter(agGridDataModelCalculationSetting);
        this.tableHeaderModel.agGridI = agGridDataModelCalculationSetting;
        this.tableHeaderModel.gridApi = this.calculationSettingsGridApi;
        this.tableHeaderModel.setVisibility({
          isAddButtonVisible: false,
          isSoftDeleteButtonVisible: false,
          isShowDeletedButtonVisible: false,
          isPermanentDeleteButtonVisible: false
        });
        break;
      }
      case 3: {
        agGridDataModelCalculations.masterIdStorageName = this.pageHeaderModel.IdStorageName;
        agGridDataModelCalculations.gridApi = this.calculationsGridApi;
        this.formModel.fundCalculations = this.agGridService.performFilter(agGridDataModelCalculations);
        this.tableHeaderModel.agGridI = agGridDataModelCalculations;
        this.tableHeaderModel.setVisibility({
          isAddButtonVisible: false,
          isSoftDeleteButtonVisible: false,
          isShowDeletedButtonVisible: false,
          isPermanentDeleteButtonVisible: false
        });
        break;
      }
      case 4: {
        agGridDataModelPtrTransfer.masterIdStorageName = this.pageHeaderModel.IdStorageName;
        agGridDataModelPtrTransfer.gridApi = this.ptrTransfersGridApi;
        this.tableHeaderModel.agGridI = agGridDataModelPtrTransfer;
        this.tableHeaderModel.gridApi = this.ptrTransfersGridApi;
        this.formModel.ptrTransfers = this.agGridService.performFilter(agGridDataModelPtrTransfer);
        break;
      }
    }
    //if(this.pageHeaderModel.isAddNew ==false)
    this.tableHeaderComponent.getTabData(this.tableHeaderModel.gridApi);
    if (this.formModel.fundCalculationSettings.length == 0) {
      var fundCalculationSetting = new FundCalculationSettings();
      fundCalculationSetting.isEdited = true;
      fundCalculationSetting.isDeleted = false;
      this.formModel.fundCalculationSettings.push(fundCalculationSetting);
      this.calculationSettingsGridApi.api.setRowData([fundCalculationSetting]);
      this.storageService.store(StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_INITIALROWS, this.formModel.fundCalculationSettings);
    }
  }
  ongridReady(item, gridApi) {
    switch (item.tabIndex) {
      case 0: this.investorGridApi = gridApi; break;
      case 1: this.investmentGridApi = gridApi; break;
      case 2: this.calculationSettingsGridApi = gridApi; break;
      case 3: this.calculationsGridApi = gridApi; break;
      case 4: this.ptrTransfersGridApi = gridApi; break;
    }
  }

  onPageSizeUpdated(pageSize){
    switch (this.tabID) {
      case 0: this.storageService.store(agGridDataModelInvestor.paginationStore,pageSize);            break;
      case 1: this.storageService.store(agGridDataModelInvestment.paginationStore,pageSize);          break;
      case 2: this.storageService.store(agGridDataModelCalculationSetting.paginationStore,pageSize);  break;
      case 3: this.storageService.store(agGridDataModelCalculations.paginationStore,pageSize);        break;
      case 4: this.storageService.store(agGridDataModelPtrTransfer.paginationStore,pageSize);         break;
    }
    this.loadTabData(this.tabID);
  }

  //#endregion tab events
  //#region get and fill form records
  getById(res: any) {
    this.storageService.store(StorageName.FUNDID, parseInt(res.response.fund.id));
    this.setForm(res.response);
  }
  setForm(formModel: FundModel) {
    this.fillForm(formModel);
    this.loadTabData(this.tabID);
  }
  fillForm(formModel: FundModel) {
    //#region Top Data
    this.formModel.fund = formModel.fund;
    this.formModel.fundCalculationSettings = formModel.fundCalculationSettings;
    this.loadedFormModel.fund = { ...this.formModel.fund };
    this.loadedFormModel.fundCalculationSettings = [...this.formModel.fundCalculationSettings];
    this.pageHeaderModel.id = formModel.fund.id;
    this.pageHeaderModel.name = formModel.fund.fundName;
    this.tableHeaderModel.isAddNew = false;
    //this.tableHeaderModel.masterDataColumnName = "calcRuleName";
    this.tableHeaderModel.masterDataValueName = formModel.fund.fundName;
    //#endregion Top Data
    //#region Set Grid Storage
    this.storageService.store(StorageName.FUNDADMIN_FUNDS_INVESTORS_INITIALROWS, formModel.fundInvestors);
    this.storageService.store(StorageName.FUNDADMIN_FUNDS_INVESTMENT_INITIALROWS, formModel.fundInvestments);
    this.storageService.store(StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_INITIALROWS, formModel.fundCalculationSettings);
    this.storageService.store(StorageName.FUNDADMIN_FUNDS_CALCULATIONS_INITIALROWS, formModel.fundCalculations);
    this.storageService.store(StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_INITIALROWS, formModel.ptrTransfers);
    //#endregion
  }
  //#endregion get and fill form records
}
//#endregion Component End