import { AfterViewInit, Component,OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { StorageName } from "../../common/enum";
import { StorageService } from "../../services/storage.service";
@Component({
  selector: "app-cell-editor",
  templateUrl: "./cell-editor.component.html",
  styleUrls: ["./cell-editor.component.scss"],
})
export class CellEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild("container", { read: ViewContainerRef }) public container;
  constructor( public storageService : StorageService ) {}
  values = [];
  params;
  value;
  oldValue;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }
  agInit(params: ICellEditorParams<any, any>): void {
    this.setDropdownDatasource(params.colDef.field,params);
    this.oldValue =params.data[this.params.colDef.headerComponentParams.filterIdColumnName];
  }
  getValue() {
    return this.value;
  }
  isPopup?(): boolean {
    return false;
  }
  getPopupPosition?(): string {
    return 'top'
  }
  isCancelBeforeStart?(): boolean {
    return false;
  }
  isCancelAfterEnd?(): boolean {
    return false
  }
  focusIn?(): void {
    throw new Error("Method not implemented.");
  }
  focusOut?(): void {
    throw new Error("Method not implemented.");
  }
  afterGuiAttached?(): void {
    this.getValue()
  }
  ngOnInit(): void {
  }
  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // right
      event.stopPropagation();
    }
  }

  cellEditingStopped(event)
  {
  }

  cellValueChanged()
  {
  }
  onClick(happy: boolean) {
    this.params.api.stopEditing();
  }
  selected(item){
    var selectedItem =  this.values.filter(a=>a.name==item)[0];
    this.value = selectedItem.name;
    if(this.oldValue != this.value)
    {
      var idColumn = this.params.colDef.headerComponentParams.filterIdColumnName;
      var valueColumn = this.params.colDef.headerComponentParams.filterValueColumnName;
      this.params.node.data.isEdited = true;
      this.params.node.data[idColumn] = selectedItem.id;
      this.params.node.data[valueColumn] = selectedItem.name;
      this.storageService.store(this.params.colDef.headerComponentParams.initialRows,this.params.api.getModel().rowsToDisplay.map(a => a.data));
    }
  }
  public setDropdownDatasource(fieldName: string, params : any)
  {
    var dropdownData : any[] =[];
    switch(fieldName)
    {
      
        case "vehicleInvestorName": dropdownData =this.storageService.retrieve(StorageName.VEHICLE_INVESTOR_DROPDOWN);                      break;
        //case "leAssociation":       dropdownData =this.storageService.retrieve(StorageName.LE_ASSOCIATION_DROPDOWN);                      break;
        case "investorName":        dropdownData =this.storageService.retrieve(StorageName.INVESTOR_DROPDOWN);                              break;
        case "investmentName":      dropdownData =this.storageService.retrieve(StorageName.INVESTMENT_DROPDOWN);                            break;
        case "positionName":        dropdownData =this.storageService.retrieve(StorageName.POSITION_GRID_DROPDOWN);                         break;
        case "carryPtrDesignation": dropdownData =this.storageService.retrieve(StorageName.CARRY_PARTNER_DESIGNATION_LEVEL_GRID_DROPDOWN);  break;
        case "allocCalcLevel":      dropdownData =this.storageService.retrieve(StorageName.CALCULATION_LEVEL_GRID_DROPDOWN);                break;
        case "transferType":        dropdownData =this.storageService.retrieve(StorageName.TRANSFER_TYPE_GRID_DROPDOWN);                    break;
        case "status":   
              if(params.colDef.headerComponentParams.gridName == "FUNDADMIN_FUNDS_PTR_TRANSFER_GRID")           
                dropdownData =this.storageService.retrieve(StorageName.STATUS_GRID_DROPDOWN);   
              else
                dropdownData =this.storageService.retrieve(StorageName.STATUS_GRID_DROPDOWN);             
              break;
        case "leAssociationName":   dropdownData =this.storageService.retrieve(StorageName.FILTERFUND_DROPDOWN);                            break;
        case "allocate":            dropdownData =this.storageService.retrieve(StorageName.ALLOCATE_DROPDOWN);                              break;
        case "fundName":            dropdownData =this.storageService.retrieve(StorageName.FUND_DROPDOWN);                                  break;
        case "tierName":
        case "comparisonTierName":
        case "expAllocationTierName":
        case "tier":                    dropdownData =this.storageService.retrieve(StorageName.TIER_DROPDOWN);                                  break;
        case "transSetForUploadName":                    dropdownData =this.storageService.retrieve(StorageName.NON_STSTEM_TIER_DROPDOWN);                                  break;
        case "rollupLevelName":         dropdownData =this.storageService.retrieve(StorageName.ROLLUP_LEVEL_DROPDOWN);                          break;
        case "includeinBalanceName":    dropdownData =this.storageService.retrieve(StorageName.INCLUDE_IN_BALANCE_DROPDOWN);                    break;
        case "numDenom":                dropdownData =this.storageService.retrieve(StorageName.NUMERATOR_DENOMINATOR_DROPDOWN);                 break;
        case "roleName":                dropdownData =this.storageService.retrieve(StorageName.ROLE_DROPDOWN);                                  break;
        case "userName":                dropdownData =this.storageService.retrieve(StorageName.USER_GRID_DROPDOWN);                             break;
        case "hurdleRetLocation":       dropdownData =this.storageService.retrieve(StorageName.HURDLE_DROPDOWN);                                break;
        case "lpaSubActName":           dropdownData =this.storageService.retrieve(StorageName.SUB_ACT_DROPDOWN);                               break;
        case "filterField":             dropdownData =this.storageService.retrieve(StorageName.FILTER_FIELD_DROPDOWN);                          break;
        case "filterCriteria":          dropdownData =this.storageService.retrieve(StorageName.FILTER_CRITERIA_DROPDOWN);                       break;
        case "udfFieldName":            dropdownData =this.storageService.retrieve(StorageName.UDF_DROPDOWN);                                   break;
        case "subActName":              dropdownData =this.storageService.retrieve(StorageName.SUB_ACT_DROPDOWN);                               break;
        case "prefReturnRuleName":      dropdownData =this.storageService.retrieve(StorageName.PERFORMANCERULE_DROPDOWN);                       break;
        case "applyForName":            dropdownData =this.storageService.retrieve(StorageName.APPLY_FOR_DROPDOWN);                             break;
    }
    
    // var blankval = {id: null , name: ''};
    // //adds one or more elements to the beginning of an array
    // dropdownData.unshift(blankval);
    this.values = dropdownData;
    this.params = params;
    this.value = params.value;
    //return dropdownData;
  }
}
