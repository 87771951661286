//#region Imports
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { ComponentCanDeactivate } from "src/app/shared/guard/direty-check.guard";
import { CommonService } from "src/app/shared/services/common.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { DropdownListModel } from "../fund-information.model";
import { PageHeaderModel } from "src/app/shared/component/page-header/page-header.model";
import { TableHeaderModel } from "src/app/shared/component/table-header-buttons/table-header-buttons.model";
import { agGridDataModelFundInvestor, InvestorModel } from "./investors.model";
import { CommonTabService } from "src/app/shared/services/common-tab.service";
import { PageHeaderComponent } from "src/app/shared/component/page-header/page-header.component";
import { TableHeaderButtonsComponent } from "src/app/shared/component/table-header-buttons/table-header-buttons.component";
//#endregion
//#region Component start
@Component({
  selector: "app-investors",
  templateUrl: "./investors.component.html",
  styleUrls: ["./investors.component.scss"],
  providers: [AppComponent],
})
export class InvestorComponent extends CommonService implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;
  @ViewChild(TableHeaderButtonsComponent) tableHeaderComponent: TableHeaderButtonsComponent;
  //#region variable declarations

  // top model which contains filter, save, undo, delete and filter name
  pageHeaderModel: PageHeaderModel;
  id: number;
  // top model for the panel over grid which contains add row, export, reset filter, reset grid, undo , soft delete and permanent delete and view/show hidden records
  tableHeaderModel: TableHeaderModel;

  //current/selected tab
  tabID: number;
  // grid apis which sets on grid ready event from ag-grid
  gridApi: any;
  //for changed form data
  formModel = new InvestorModel();
  //for original form data - will be used to reset/clear the form
  loadedFormModel = new InvestorModel();
  // for loading all the dropdowns - form and grid every dropdown
  dropdownListModel = new DropdownListModel();
  // tab group list
  tabGroupList: any[] = [
    { dataModel: agGridDataModelFundInvestor, label: 'Fund Commitments', tabIndex: 0 },
  ];
  //#endregion variable declarations

  private _onSaveBeforeCloseSubscription!: Subscription;

  //#region ctor
  constructor(
    public renderer: Renderer2,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService
  ) { super(renderer); }

  ngOnDestroy(): void {
    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }

  //#endregion
  //#region Save Confirmation
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {

    // var data = this.gridApi.api.getModel().rowsToDisplay.map(a => a.data);
    // var data2 = this.formModel.fundInvestors;
    // var data3 =  this.storageService.retrieve(StorageName.FUNDADMIN_INVESTORS_INITIALROWS);
    var fundInvestors =  this.storageService.retrieve(agGridDataModelFundInvestor.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    return (
      JSON.stringify(this.formModel.investor) === JSON.stringify(this.loadedFormModel.investor) &&
      fundInvestors?.length == 0
    );
  }
  //#endregion
  //#region Init
  ngOnInit(): void {
    //this.fillTopDropdown();
    this.fillPageHeader();
    this.setTableHeader();
    this._onSaveBeforeCloseSubscription = this.commonTabService.onSaveBeforeClose.subscribe((data) => this.onSave());
  }
  fillPageHeader() {
    this.pageHeaderModel = new PageHeaderModel();
    this.pageHeaderModel.setVisibilityForForm("Fund Information");
    //this.pageHeaderModel.filteredOptions  = this.dropdownListModel.investorDropdown;
    this.pageHeaderModel.placeHolder = "Enter New Investor Name";
    this.pageHeaderModel.controllerName = StorageName.INVESTOR_HEADER_CONTROLLER;
    this.pageHeaderModel.IdStorageName = StorageName.INVESTORID;
    this.pageHeaderModel.showDeleted = true;
  }
  setTableHeader() {
    this.tableHeaderModel = new TableHeaderModel();
    this.tableHeaderModel.setVisibility({
      isAddButtonVisible: false,
      isExportButtonVisible: true,
      isUndoButtonVisible: true,
      isResetFilterButtonVisible: true,
      isSoftDeleteButtonVisible: true,
      isPermanentDeleteButtonVisible: true,
      isExpandButtonVisible: true,
    });
  }
  fillTopDropdown() {
    this.dropdownListModel.investorDropdown = this.storageService.retrieve(StorageName.INVESTOR_DROPDOWN);
    this.dropdownListModel.investorGroupDropdown = this.storageService.retrieve(StorageName.INVESTOR_GROUP_DROPDOWN);
    this.dropdownListModel.investorClassificationDropdown = this.storageService.retrieve(StorageName.INVESTOR_CLASSIFICATION_DROPDOWN);
  }
  //#endregion
  //#region Clear Complete Form
  clearFields(): void {
    this.formModel = new InvestorModel();
    this.pageHeaderModel.id = 0;
    this.pageHeaderModel.name = "";
    //set enpty rows to all grid apis
    this.gridApi.api.setRowData([]);
    //fill the form with blank records
    this.fillForm(this.formModel);
  }
  //#endregion Clear Complete Form
  //#region page header events
  onAddNewForm() {
    //clear form fields
    this.clearFields();
    //focus on textbox to add new form name
    document.getElementById("AddNewForm").focus();
    
    this.tableHeaderModel.isAddNew = true;

    //load first tab by default - 0
    this.loadTabData(0);
  }
  //calling post api to save/update records
  onSave() {
    // get filter dropdown value from child component and assign
    this.formModel.investor.investorName = this.pageHeaderModel.name;
    this.formModel.investor.id = this.pageHeaderModel.id;
    //create new mode for form to use for posting updated entries
    var postData = new InvestorModel();
    postData.investor = this.formModel.investor;
    var fundInvestors =  this.storageService.retrieve(agGridDataModelFundInvestor.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    postData.fundInvestors = fundInvestors;//this.gridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);

    var deletedRecordsFundInvestors = postData.fundInvestors?.filter((row) => row.isEdited == true && row.isDeleted == true);
    this.pageHeaderComponent.post(postData, deletedRecordsFundInvestors.length != 0);

  }

  //#endregion
  //#region Tab Events
  //

  onChangeTab(event) {
    this.tabID = event.index;
    this.loadTabData(this.tabID);
  }

  //load the tab records on click of tab
    loadTabData(index = 0) {
    this.tabID = index;
    this.tableHeaderModel.setVisibility({
      isAddButtonVisible: true,
      isSoftDeleteButtonVisible: true,
      isShowDeletedButtonVisible: !this.pageHeaderModel.showDeleted,
      isHideDeletedButtonVisible: this.pageHeaderModel.showDeleted,
      isPermanentDeleteButtonVisible: true,
    });

    switch (this.tabID) {
      case 0:
        agGridDataModelFundInvestor.masterIdStorageName = this.pageHeaderModel.IdStorageName,
          agGridDataModelFundInvestor.gridApi = this.gridApi;
        this.formModel.fundInvestors = this.agGridService.performFilter(agGridDataModelFundInvestor);
        this.tableHeaderModel.agGridI = agGridDataModelFundInvestor;
        this.tableHeaderModel.gridApi = this.gridApi;
        break;
    }
    
    this.tableHeaderComponent.getTabData(this.tableHeaderModel.gridApi);
  }

  ongridReady(item, gridApi) {
    switch (item.tabIndex) { case 0: this.gridApi = gridApi; break; }
  }
  onPageSizeUpdated(pageSize){
    switch (this.tabID) {
      case 0: this.storageService.store(agGridDataModelFundInvestor.paginationStore,pageSize);            break;
    }
    this.loadTabData(this.tabID);
  }
  //#endregion tab events
  //#region get and fill form records
  getById(res: any) {
    this.storageService.store(StorageName.INVESTORID, parseInt(res.response.investor.id));
    this.setForm(res.response);
  }

  setForm(formModel: InvestorModel) {
    this.fillForm(formModel);
    this.loadTabData(this.tabID);
  }
  fillForm(formModel: InvestorModel) {
    //#region Top Data
    this.formModel.investor = formModel.investor;
    this.loadedFormModel.investor = { ...this.formModel.investor };
    this.pageHeaderModel.id = formModel.investor.id;
    this.pageHeaderModel.name = formModel.investor.investorName;
    this.tableHeaderModel.isAddNew = false;
    //this.tableHeaderModel.masterDataColumnName = "calcRuleName";
    this.tableHeaderModel.masterDataValueName = formModel.investor.investorName;
    //#region Set Grid Storage
    this.storageService.store(StorageName.FUNDADMIN_INVESTORS_INITIALROWS, formModel.fundInvestors);
    //#endregion
  }
  //#endregion get and fill form records
}
//#endregion Component End