import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

   constructor(private notificationService: NotificationService,
      private globalservice: GlobalService) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
         catchError((error) => {
            this.notificationService.errorpopup(error.message, error.error?.title);
            this.globalservice.deactivateLoader();
            return throwError(error.message);
         })
      );
   }
}
