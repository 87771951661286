//#region Imports
import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { ComponentCanDeactivate } from "src/app/shared/guard/direty-check.guard";
import { CommonService } from "src/app/shared/services/common.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { PageHeaderModel } from "src/app/shared/component/page-header/page-header.model";
import { TableHeaderModel } from "src/app/shared/component/table-header-buttons/table-header-buttons.model";
import { CommonTabService } from "src/app/shared/services/common-tab.service";
import { PageHeaderComponent } from "src/app/shared/component/page-header/page-header.component";
import { TableHeaderButtonsComponent } from "src/app/shared/component/table-header-buttons/table-header-buttons.component";
import { agGridDataModelRoles, RolesModel } from "../roles.model";
//#endregion
//#region Component start
@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
  providers: [AppComponent],
})
export class RolesComponent extends CommonService implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;
  // @ViewChild(TableHeaderButtonsComponent) tableHeaderComponent:TableHeaderButtonsComponent;
  //#region variable declarations
  // top model which contains filter, save, undo, delete and filter name
  pageHeaderModel: PageHeaderModel;

  //current/selected tab
  tabID: number;
  // grid apis which sets on grid ready event from ag-grid
  roleGridApi: any;

  //for changed form data
  formModel = new RolesModel();

  //for original form data - will be used to reset/clear the form
  loadedFormModel = new RolesModel();

  // tab group list
  tabGroupList: any[] = [
    { dataModel: agGridDataModelRoles, label: 'User', tabIndex: 0 },
  ];
  //#endregion variable declarations

  private _onSaveBeforeCloseSubscription!: Subscription;

  //#region ctor
  constructor(
    public renderer: Renderer2,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService
  ) { super(renderer); }

  ngOnDestroy(): void {
    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }

  //#endregion
  //#region Save Confirmation
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
   var roles =  this.storageService.retrieve(agGridDataModelRoles.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    return (
      roles?.length == 0)
  }
  //#endregion
  //#region Init
  ngOnInit(): void {
    this.fillPageHeader();
    //this.setTableHeader();
    this._onSaveBeforeCloseSubscription = this.commonTabService.onSaveBeforeClose.subscribe((data) => this.onSave());
  }

  fillPageHeader() {
    this.pageHeaderModel = new PageHeaderModel();
    this.pageHeaderModel.placeHolder = "";
    this.pageHeaderModel.controllerName = StorageName.ROLES_CONTROLLER;
    this.pageHeaderModel.showDeleted = false;
    this.pageHeaderModel.setVisibilityForForm("Role");
    this.pageHeaderModel.filterOptionStorageName = StorageName.ROLE_DROPDOWN;
  }

  // setTableHeader()
  // {
  //   this.tableHeaderModel             = new TableHeaderModel();
  //   this.tableHeaderModel.setVisibility({
  //     isAddButtonVisible              :   false,
  //     isExportButtonVisible           :   true,
  //     isUndoButtonVisible             :   true,
  //     isResetFilterButtonVisible      :   true,
  //     isSoftDeleteButtonVisible       :   true,
  //     isShowDeletedButtonVisible      :   !this.pageHeaderModel.showDeleted,
  //     isHideDeletedButtonVisible      :   this.pageHeaderModel.showDeleted,
  //     isPermanentDeleteButtonVisible  :   true,
  //     isExpandButtonVisible           :   true,
  //   });
  // }
  //#endregion
  //#region Clear Complete Form
  clearFields(): void {
    this.formModel = new RolesModel();

    //set enpty rows to all grid apis
    this.roleGridApi.api.setRowData([]);

    //fill the form with blank records
    this.fillForm(this.formModel);
  }
  //#endregion Clear Complete Form
  //#region page header events

  //calling post api to save/update records
  onSave(): any {
    //this.pageHeaderModel.showDeleted  =   !this.tableHeaderComponent.tableHeaderModel.isShowDeletedButtonVisible;
    //create new mode for form to use for posting updated entries
    var postData = new RolesModel();
    var roles =  this.storageService.retrieve(agGridDataModelRoles.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    postData.roles = roles;// this.roleGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    var deletedRecordsInRoles = postData.roles?.filter((row) => row.isEdited == true && row.isDeleted == true);
    this.pageHeaderComponent.post(postData, deletedRecordsInRoles.length != 0, true);
  }
  //#endregion
  //#region Tab Events
  //
  onChangeTab(event) {
    // this.pageHeaderModel.showDeleted  =   !this.tableHeaderComponent.tableHeaderModel.isShowDeletedButtonVisible;
    this.tabID = event.index
    this.loadTabData(this.tabID);
  }

  //load the tab records on click of tab
  loadTabData(index = 0) {
    this.tabID = index;
    // this.tableHeaderModel.setVisibility({
    //   isAddButtonVisible                          : true,
    //   isSoftDeleteButtonVisible                   : true,
    //   isShowDeletedButtonVisible                  : !this.pageHeaderModel.showDeleted,
    //   isHideDeletedButtonVisible                  : this.pageHeaderModel.showDeleted,
    //   isPermanentDeleteButtonVisible              : true,
    // });
    switch (this.tabID) {

      case 0: {
        agGridDataModelRoles.gridApi = this.roleGridApi;
        this.formModel.roles = this.agGridService.performFilter(agGridDataModelRoles);
        this.pageHeaderModel.agGridI = agGridDataModelRoles;
        this.pageHeaderModel.gridApi = this.roleGridApi;
        break;
      }
    }
    // this.tableHeaderComponent.getTabData();
  }
  ongridReady(item, gridApi) {  this.roleGridApi = gridApi; }
  onPageSizeUpdated(pageSize){  this.storageService.store(agGridDataModelRoles.paginationStore,pageSize);     this.loadTabData(this.tabID);}
  //#endregion tab events
  //#region get and fill form records
  getById(res: any) {
    this.setForm(res.response);
  }
  setForm(formModel: RolesModel) {
    this.fillForm(formModel);
    this.loadTabData(this.tabID);
  }
  fillForm(formModel: RolesModel) {
    //#region Set Grid Storage
    this.storageService.store(StorageName.ROLES_INITIALROWS, formModel.roles);
    //#endregion
  }
  //#endregion get and fill form records
}
//#endregion Component End