import { EventEmitter, Injectable } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
@Injectable({
  providedIn: 'root'
})
export class ResetFilterCalcRuleService {
  isResetFilter = new EventEmitter();
  
  constructor(private service: DataService) { }
}
