export class Pagination {
   page: number;
   count: number;
   pageSize: number;
   totalPages: number;
   fromRecordNumber: number;
   toRecordNumber: number;

   constructor(
      page?: number,
      count?: number,
      pageSize?: number,
      totalPages?: number
   ) {
      this.page = page;
      this.count = count;
      this.pageSize = pageSize;
      this.totalPages = totalPages;
   }
}

export const defaultPageLimit = 25;

export class PaginationModelCommon {
   page: number = 1;
   pageSize: number = defaultPageLimit;
   totalPages: number ;
   count: number ;
   getAll: boolean = false;
   id : string = "0"
}

export const pageLimitOptions = [
   { value: 25 },
   { value: 50 },
   { value: 75 },
];