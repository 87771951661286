import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { StorageService } from "../../services/storage.service";
import { StorageName } from "../../common/enum";
@Component({
  selector: "app-custom-input-control",
  templateUrl: "./custom-input-control.component.html",
  styleUrls: ["./custom-input-control.component.scss"],
})
export class CustomInputControlComponent implements OnInit {
  @ViewChild('resizedDiv') myDiv: ElementRef;
  params;
  @Input()  type : string;
  @Input()  model : string;
  @Input()  name : string;
  @Input()  title : string;
  @Input()  isRequired : boolean = false;
  @Input()  dropdownModelList : string;
  @Input()  inputWidthPer : string ;
  @Input()  labelWidthPer : string ;
  @Input()  checkboxRelatedLabel : string;
  @Input()  disabled : boolean;
  @Input()  isPopupField :  boolean = false;
  @Input()  showSuffix :  boolean = false;
  @Input()  selectedItems : string;
  @Input()  disableInput : boolean = false;
  @Input()  labelFontClass : string = "";
  @Input()  inputHeightClass : string = "";
  @Input()  widthClass : string = "w-100";
  inputWidth : number;
  labelWidth : number;
  dropdownSettings: IDropdownSettings = {};

  @Output() onInputChanged: EventEmitter<any> = new EventEmitter();
  constructor(   private storageService: StorageService) {}
  ngOnInit(): void {
   
    switch(this.type){
      case 'dropdown':
      case 'date':
      case 'input':
      case 'percentInput':
      case 'label':
        this.inputWidth = 70;
        break;
      case 'textarea':
        this.inputWidth = 85;
        break;
      case 'checkbox': 
        this.inputWidth = 10;
        break;
      case 'multiselectdropdown':
         this.inputWidth = 70;
        break;
    }
    this.labelWidth = 100 - this.inputWidth;
    if(this.inputWidthPer == undefined)
      this.inputWidthPer = this.inputWidth.toString();
    if(this.labelWidthPer == undefined)
      this.labelWidthPer = this.labelWidth.toString();
      
      if(this.widthClass == undefined)
        this.widthClass = "w-100"

  }

  ngAfterViewInit() {
    // var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    // const inputElements = this.myDiv.nativeElement.querySelectorAll('input');
    // inputElements.forEach(input => {
    //   input.disabled = !formPermission.modifyPermission;
    // });

    // const selectElements = this.myDiv.nativeElement.querySelectorAll('select');
    // selectElements.forEach(input => {
    //   input.disabled = !formPermission.modifyPermission;
    // });

    // const textareaElements = this.myDiv.nativeElement.querySelectorAll('textarea');
    // textareaElements.forEach(input => {
    //   input.disabled = !formPermission.modifyPermission;
    // });

    // const checkboxElements = this.myDiv.nativeElement.querySelectorAll('checkbox');
    // checkboxElements.forEach(input => {
    //   input.disabled = !formPermission.modifyPermission;
    // });
    
  }
  
  inputChanged(selectedItems){
    if(this.type=="dropdown")
      this.onInputChanged.emit((this.model == "null" ? null : this.model));
    else if(this.type == "date"){
      this.onInputChanged.emit(this.model);
    }
    else if(this.type!="multiselectdropdown")
      this.onInputChanged.emit(this.model);
    else 
      this.onInputChanged.emit(selectedItems);
  }
}