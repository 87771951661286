import { SharedModule } from 'src/app/shared/shared.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LockUnlockRecordsUpdateRoutingModule } from './lock-unlock-records-update-routing.module';
import { LockUnlockRecordsUpdateComponent } from './lock-unlock-records-update.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    LockUnlockRecordsUpdateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,     
    SharedModule,
    MatFormFieldModule,
    LockUnlockRecordsUpdateRoutingModule
  ]
})
export class LockUnlockRecordsUpdateModule { }
