import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor() { }

  export(data: any) {

   // this.saveFile(data[0].headerData)
    //  var blob;
      var link = document.createElement("a");
      if (link.download != 'undefined') {
      
      //header file
     
    //  blob = window.atob(data.headerData);
      link.href = 'data:text/xlsm;charset=utf-8;base64,' + data[0].headerData;
      link.target = '_blank';
      link.download = data[0].fileName+'.xlsm';
      link.click();
   
   }
    
  }

  saveFile(data) {
    saveAs(data, `filename.xlsx`)
}

  exportFile(url: any) {
    var blob;
    var link = document.createElement("a");
    if (link.download != 'undefined') {
    
    //header file

    link.href = url;
    link.target = '_blank';
    link.download = 'test'+'.xlsx';
    link.click();
  
  }
  
}
  exportMultiple(data: any) {
    data.forEach(async object => {
      var blob;
      var link = document.createElement("a");
      if (link.download != 'undefined') {
      
      //header file
     
      blob = window.atob(object.headerData);
      link.href = 'data:text/xlsx;charset=utf-8;base64,' + object.headerData;
      link.target = '_blank';
      link.download = object.fileName+'.xlsx';
      link.click();
    
    }
    });
  }
}