import { StorageName } from "src/app/shared/common/enum";
import { AgGridI, colDefsPermissionColumn, colDefsPermissionForm, colDefsPermissionFundGroup } from "src/app/shared/component/ag-grid/ag-grid.interface";
import { DropdownModel } from "src/app/shared/model/common.model";

export class PermissionsModel
{
    role                                    : DropdownModel = new DropdownModel();
    fundGroupPermissions                    : FundGroupPermission[]=[];
    formPermissions                         : FormPermission[]=[];
    columnPermissions                       : ColumnPermission[]=[];
}

export class ModificationColumns
{
  isDeleted               : boolean = false;
  isAdded                 : boolean = false;
  isEdited                : boolean = false;
}

export class FundGroupPermission extends ModificationColumns {
    roleId: number;
    fundGroupId: number;
    viewPermission: boolean;
    modifyPermission: boolean;
    deletePermission: boolean;
}

export class FormPermission extends ModificationColumns {
    roleId: number;
    moduleId: number;
    formId: number;
    roleName: string;
    moduleName: string;
    formName: string;
    viewPermission: boolean;
    viewPermissionId: number;
    modifyPermission: boolean;
    modifyPermissionId: number;
    deletePermission: boolean;
    deletePermissionId: number;
}

export class ColumnPermission extends ModificationColumns {
    roleId: number;
    moduleId: number;
    columnName: string;
    formName: string;
    permissions: boolean;
}

export const agGridDataModelPermissionFundGroup: AgGridI = {
    colDefs: colDefsPermissionFundGroup,
    rowSelection: "multiple",
    gridName: StorageName.PERMISSION_FUNDGROUP_GRID,
    controllerName: StorageName.PERMISSION_FUNDGROUP_CONTROLLER,
    storageName: StorageName.PERMISSION_FUNDGROUP_COLUMNCHANGED,
    paginationStore: StorageName.PERMISSION_FUNDGROUP_PAGINATION,
    initialRows: StorageName.PERMISSION_FUNDGROUP_INITIALROWS,
    filterStorageName: StorageName.PERMISSION_FUNDGROUP_FILTER,
    showDeleteStatusStorageName : StorageName.PERMISSION_SHOW_DELETED,
    tabIndex: 0
};
export const agGridDataModelPermissionForm: AgGridI = {
    colDefs: colDefsPermissionForm,
    rowSelection: "multiple",
    gridName: StorageName.PERMISSION_FORM_GRID,
    controllerName: StorageName.PERMISSION_FORM_CONTROLLER,
    storageName: StorageName.PERMISSION_FORM_COLUMNCHANGED,
    paginationStore: StorageName.PERMISSION_FORM_PAGINATION,
    initialRows: StorageName.PERMISSION_FORM_INITIALROWS,
    filterStorageName: StorageName.PERMISSION_FORM_FILTER,
    showDeleteStatusStorageName : StorageName.PERMISSION_SHOW_DELETED,
    tabIndex: 1
};
export const agGridDataModelPermissionColumn: AgGridI = {
    colDefs: colDefsPermissionColumn,
    rowSelection: "multiple",
    gridName: StorageName.PERMISSION_COLUMN_GRID,
    controllerName: StorageName.PERMISSION_COLUMN_CONTROLLER,
    storageName: StorageName.PERMISSION_COLUMN_COLUMNCHANGED,
    paginationStore: StorageName.PERMISSION_COLUMN_PAGINATION,
    initialRows: StorageName.PERMISSION_COLUMN_INITIALROWS,
    filterStorageName: StorageName.PERMISSION_COLUMN_FILTER,
    showDeleteStatusStorageName : StorageName.PERMISSION_SHOW_DELETED,
    tabIndex: 2
};
  