export class CommonFilterModel {
  columnName: string;
  ids: string;
}

export class CommonFilter {
  type: string;
  textFilterValue : string = "";
  tableFilterValues: CommonFilterModel[];
}

export class FilterModel {
  name: string;
  id: string;
}

export class MainFilterModel {
  columnName: string;
}

export enum AllColumnName {
  InvestorGroup = 'Investor Group',
  Investor = 'Investor',
  Investment = 'Investment',
  Position = 'Position',
  Status = 'Status',
  CalcRule = 'Calc Rule',
  TransferType = 'Transfer Type',
  TransferDate = 'Transfer Date',
  Fund = 'Fund',
  FundName = 'Fund Name'
}



export interface Result {
  data: any;
  new(): Result;
  isSuccess: boolean;
  message: string;
  response: any;
  totalRecords: number;
  errors: string[];
  statusCode: number;

}

