import { CellClassParams } from "ag-grid-community";
import { AppInjector } from "src/app/app.module";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridI,colDefsAllocation,colDefsAllocationsSummary, colDefsJournalEntries, colDefsPrefRetCalc, colDefsPrefRetCalcSummary, colDefsTierCalc, colDefsTierCalcSummary } from "src/app/shared/component/ag-grid/ag-grid.interface";
import { DropdownModel } from "src/app/shared/model/common.model";
import { StorageService } from "src/app/shared/services/storage.service";

 
export class CalculationModel
{
    calculation                         : Calculation = new Calculation();
    prefRetCalcs                        : PrefRetCalc[]=[];
    prefRetCalcSummary                  : PrefRetCalc[]=[];
    tierCalcs                           : TierCalc[]=[];
    tierCalcSummary                     : TierCalc[]=[];
    allocations                         : TierCalc[]=[];
    allocationsSummary                  : TierCalc[]=[];
    journalEntries                      : JournalEntries[]=[];

    calcTypeDropdown : DropdownModel[] = [];
    statusDropdown : DropdownModel[] = [];
    calculationStateDropdown : DropdownModel[] = [];

    
    
    investmentDropdown : DropdownModel[] = [];
    positionDropdown : DropdownModel[] = [];
    investorGroupDropdown: DropdownModel[] = [];
    isDraftCalc: boolean = false;
    isExportedExcel: boolean = false;
    includeFormulasInExport: boolean = false;
    fundId: number;    
    calcId: number;
    importUserId : string ;
    columnKeysPrefRetCalc : ColumnNamesWithOrder[]
    columnKeystierCalc : ColumnNamesWithOrder[]
    columnKeysallocations : ColumnNamesWithOrder[]
    columnKeysjournalEntries : ColumnNamesWithOrder[]

    
}

export class ColumnNamesWithOrder
{
  ColumnName: string;
  ColumnOrder: number;
}


export class Calculation {


  id : number;
  calcName: string;
  PLReallocSubActId: string;
  allocationPercent: string;
  calcDesc: string;
  calcDataSet: string;
  calcDate: Date;
  runDate: Date;
  distIncomeNum: string;
  operation: string;
  positionId: string;
  calcStateId: string;

  fundName : string;

  //ForeignKey Changes
  fundId: string;
  fundInvestmentId: string;

  investmentId: string;
  
  calcRuleId: string;
  calcTypeId: string;
  calculationLevelId: string;
  calcStatusId: string;
  
  investmentJEId: string;
  positionJEId: string;
  incomeSubActId: string;
  incomeSubActOffsetId: string;
  distributionSubActId: string;
  distributionSubActOffsetId: string;
  unrealSubActId: string;
  unrealSubActOffsetId: string;
  calcExpeAllocPerc: string;
  isCalcLocked: boolean;
  calcDateString: string;

  scenarioMode: boolean = false;
  isScenarioModeApplied: boolean = false;
  isHypothetical: boolean;

  lockedFrom: string;
  lockedTo: string;
  reference: string;
  statusId : number;
  proceeds? : string;
  investorGroupId : string;
  runInLoop? : boolean;
}

export class ScenarioMode 
{
  id:number;
  tierId:number;
  tierName:string;
  percentage:number;
}
export class ChartDropdownModel {
  name: string;
  label: string;
}
export class Allocations {
  fund: string;
  calculationName: string;
  investor: string;
  investorGroup: string;
  calcType: string;

  amt: number;
  allocPercent: number;
  allocAmt: number;
  carryPromoteAmt : number;
  maxLTDCarry: number;
  catchUp: number;
  ptrTrans: number;
  recCarry : boolean;
  finalRow : boolean;
}
export class AllocationsSummary {
  fund: string;
  calculationName: string;
  amt: number;
  allocAmt: number;
  calcType: string;
  maxLTDCarry: number;
}
export class JournalEntries {
  calculationName: string;
  calcDataSet: string;
  category: string;
  fund: string;
  transactionSet: string;
  investment: string;
  position: string;
  investorGroup: string;
  investor: string;
  subAct: string;
  jeid: string;
  transID: string;
  jEComments: string;
  date: Date;
  idDebit: number;
  amount: number;
  iso: string;
}
export class PrefRetCalc {
  fund: string;
  calculationName: string;
  calcDate: string;
  prRuleName: string;
  calculationMethod: string;
  investor: string;
  investorGroup: string;
  tier: string;
  prRate: number;
  prefRetAmt: number;
  runDate: string;
  ptrTrans: boolean;
  termValueRow: boolean;
  cashFlowAmt: number;
  cashFlowDate: string;
  coumpoundInterestPeriod: number;
  daysCapital: number;
  frequency: number;
  daysCalc: number;
}
export class PrefRetCalcSummary {
  fund: string;
  calculationName: string;
  calcDate: Date;
  prRuleName: string;
  calculationMethod: string;
  tier: string;
  prRate: number;
  prefRetAmt: number;
  runDate: Date;
  investor: string;
  investorGroup: string;
  cashFlowAmt: number;
  cashFlowDate: Date;
  coumpoundInterestPeriod: number;
  daysCapital: number;
  frequency: number;
  daysCalc: number;
  ptrTrans: boolean;
  termValueRow: boolean;
}
export class TierCalc {
  Fund: string;
  calculationName: string;
  investor: string;
  investorGroup: string;
  investment: string;
  position: string;
  tier: string;
  tierOrder: number;
  comparisonTier: string;
  tierAmt: number;
  comparisionAmt: number;
  allocPercent: number;
  tierCalc: number;
  balance: number;
  calculationRule: string;
  prefRetTier: boolean;
  terminationTier: boolean;
  calculationType: string;
  ptrTrans: boolean;
  applyTierCarryPer: boolean;
  tierCarryPer: number;
  isSumRow : boolean;
  hurdleRate : number;
  IncludeinBalance : boolean;
  systemTier : boolean;

}
export class TierCalcSummary {
  fund: string;
  calculationName: string;
  investment: string;
  tier: string;
  tierOrder: number;
  comparisonTier: string;
  tierAmt: number;
  comparisionAmt: number;
  tierCalc: number;
  balance: number;
}
export class SearchFilterModel{
  calcType: number;
  status: number;
  state: number;
  fundId: number;
  calcId: number;
  statusFilterName: string ="";
  stateFilterName: string ="";
  calcTypeFilterName: string ="";
}

export class CalcGraphModel{
    selectedGrid: string ="";
    selectedColumnNames: string ="";
    graphJson: string ="";
    fundId: string;
    calcId: number;
}



//#region  Grid Model Declaration
  // for Investor Col Defination
  export const  agGridDataModelPrefRetCalc: AgGridI = {
    colDefs: colDefsPrefRetCalc,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_PREF_RET_CALC_GRID,
    controllerName: StorageName.CALCULATIONS_PREF_RET_CALC_CONTROLLER,
    storageName: StorageName.CALCULATIONS_PREF_RET_CALC_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_PREF_RET_CALC_PAGINATION,
    initialRows: StorageName.CALCULATIONS_PREF_RET_CALC_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_FILTER,
    //showDeleteStatusStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_SHOW_DELETED,
    tabIndex: 0,
    showDeleted : false
  };
  // for Investment Col Defination

  
  export const  agGridDataModelPrefRetCalcSummary: AgGridI = {
    colDefs: colDefsPrefRetCalcSummary,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_GRID,
    controllerName: StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_CONTROLLER,
    storageName: StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_PAGINATION,
    initialRows: StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_FILTER,
    //showDeleteStatusStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_SHOW_DELETED,
    tabIndex: 0,
    showDeleted : false
  };
  /* End Of Pref Summary */

  /* Start of Tier Calculation */

  export const  agGridDataModelTierCalc: AgGridI = {
    colDefs: colDefsTierCalc,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_TIER_CALC_GRID,
    controllerName: StorageName.CALCULATIONS_TIER_CALC_CONTROLLER,
    storageName: StorageName.CALCULATIONS_TIER_CALC_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_TIER_CALC_PAGINATION,
    initialRows: StorageName.CALCULATIONS_TIER_CALC_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_TIER_CALC_FILTER,
    //showDeleteStatusStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_SHOW_DELETED,
    tabIndex: 0,
    showDeleted : false,
    columnTypes: {
      editableColumn: {
        cellStyle: (params: CellClassParams<any>) => {
          if(params.colDef.field == "tier")
          {
            const storageService = AppInjector.get(StorageService);
            var systemTiers =  storageService.retrieve(StorageName.SYSTEM_TIERS)// "Pref Ret Calculation,Proceeds Amt,Remaining Balance from Rrior Tier, Tier Calc from Prior Tier / Participant %, Tier Calc from Prior Tier,Remaining Balance from Prior Tier"
            if(params.data.tier != undefined && params.data.tier.indexOf("Pref Ret Calculation") != -1)
              params.data.tier = "Pref Ret Calculation"
            if (systemTiers!= undefined && ( (systemTiers.indexOf(params.data.tier) != -1))) 
              return { color: 'blue' };
            else
              return { color: 'black' };
          }
          if(params.colDef.field == "comparisonTier")
          {
            const storageService = AppInjector.get(StorageService);
            var systemTiers =  storageService.retrieve(StorageName.SYSTEM_TIERS)// "Pref Ret Calculation,Proceeds Amt,Remaining Balance from Rrior Tier, Tier Calc from Prior Tier / Participant %, Tier Calc from Prior Tier,Remaining Balance from Prior Tier"
            if(params.data.comparisonTier != undefined && params.data.comparisonTier.indexOf("Pref Ret Calculation") != -1)
              params.data.comparisonTier = "Pref Ret Calculation"
            if (systemTiers!= undefined && ( (systemTiers.indexOf(params.data.comparisonTier) != -1))) 
              return { color: 'blue' };
            else
              return { color: 'black' };
          }
        }
      }
    },
  };
  /* End of Tier Calculation */

  /* Start of Tier Calculation Summary*/
  export const  agGridDataModelTierCalcSummary: AgGridI = {
    colDefs: colDefsTierCalcSummary,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_TIER_CALC_SUMMARY_GRID,
    controllerName: StorageName.CALCULATIONS_TIER_CALC_SUMMARY_CONTROLLER,
    storageName: StorageName.CALCULATIONS_TIER_CALC_SUMMARY_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_TIER_CALC_SUMMARY_PAGINATION,
    initialRows: StorageName.CALCULATIONS_TIER_CALC_SUMMARY_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_TIER_CALC_SUMMARY_FILTER,
    //showDeleteStatusStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_SHOW_DELETED,
    tabIndex: 0,
    showDeleted : false
  };
  /* End of Tier Calculation Summary */

  /* Start of App Allocation */

  export const  agGridDataModelAllocation: AgGridI = {
    colDefs: colDefsAllocation,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_ALLOCATIONS_GRID,
    controllerName: StorageName.CALCULATIONS_ALLOCATIONS_CONTROLLER,
    storageName: StorageName.CALCULATIONS_ALLOCATIONS_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_ALLOCATIONS_PAGINATION,
    initialRows: StorageName.CALCULATIONS_ALLOCATIONS_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_ALLOCATIONS_FILTER,
    //showDeleteStatusStorageName: StorageName.CALCULATIONS_PREF_RET_CALC_SHOW_DELETED,
    tabIndex: 0,
    showDeleted : false,
  };
  /* End of App Allocation*/

  /* Start of App Allocation Summary */

  export const  agGridDataModelAllocationSummary: AgGridI = {
    colDefs: colDefsAllocationsSummary,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_GRID,
    controllerName: StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_CONTROLLER,
    storageName: StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_PAGINATION,
    initialRows: StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_FILTER,
    tabIndex: 5,
  };
  /* End of App Allocation Summary */

  /* Start of App Journal Entries*/


  export const agGridDataModelJournalEntries: AgGridI = {
    colDefs: colDefsJournalEntries,
    rowSelection: "multiple",
    gridName: StorageName.CALCULATIONS_JOURNAL_ENTRIES_GRID,
    controllerName: StorageName.CALCULATIONS_JOURNAL_ENTRIES_CONTROLLER,
    storageName: StorageName.CALCULATIONS_JOURNAL_ENTRIES_COLUMNCHANGED,
    paginationStore: StorageName.CALCULATIONS_JOURNAL_ENTRIES_PAGINATION,
    initialRows: StorageName.CALCULATIONS_JOURNAL_ENTRIES_INITIALROWS,
    filterStorageName: StorageName.CALCULATIONS_JOURNAL_ENTRIES_FILTER,
    tabIndex: 6,
  };
  /* End of App Journal Entries*/
  
  
//#endregion Grid Model Declaration
