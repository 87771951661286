import { LoginService } from './../../pages/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Inject, Injectable, Injector }                   from '@angular/core';
import { HttpClient, 
         HttpHeaders }                  from '@angular/common/http';
import { Subject }                      from 'rxjs';
import { StorageService }               from './storage.service';
import { StorageName } from '../common/enum';
@Injectable({
    providedIn: 'root'
  })
export class SecurityService 
{
    private headers                  : HttpHeaders;
    private storage                  : StorageService;
    private authenticationSource     = new Subject<boolean>();
    authenticationChallenge$         = this.authenticationSource.asObservable();
    private authorityUrl             = '';
    private sessionTimeout: number = 30 * 60 * 1000; // 30sec in milliseconds
    public loginService;
    constructor(
                private _http: HttpClient, 
                private _router: Router, 
                private route: ActivatedRoute, 
                private _storageService: StorageService,
                private Injector:Injector
               ) 
               {
                this.headers = new HttpHeaders();
                this.headers.append('Content-Type', 'application/json');
                this.headers.append('Accept', 'application/json');
                this.storage = _storageService;
                if (this.storage.retrieve('IsAuthorized') !== '') 
                {
                    this.IsAuthorized = this.storage.retrieve('IsAuthorized');
                    this.authenticationSource.next(true);
                    this.UserData = this.storage.retrieve('userData');
                }
               }
    public IsAuthorized: boolean;
    public GetToken(): any 
    {
        return this.storage.retrieve('authorizationDataIdToken');
    }
    public ResetAuthorizationData() 
    {
        this.storage.store('authorizationDataIdToken', '');
        this.IsAuthorized = false;
        this.storage.store('IsAuthorized', false);
    }
    public UserData: any;
    public SetAuthorizationData(token: any, id_token: any) 
    {
        if (this.storage.retrieve('authorizationDataIdToken') !== '') {
            this.storage.store('authorizationDataIdToken', '');
        }
        this.storage.store('authorizationDataIdToken', id_token);
        this.IsAuthorized = true;
        this.storage.store('IsAuthorized', true);
    }
   
    
    public Logoff() 
    {
        let authorizationUrl = this.authorityUrl + '/connect/endsession';
        let id_token_hint = this.storage.retrieve('authorizationDataIdToken');
        let post_logout_redirect_uri = location.origin + '/';
        let url =
            authorizationUrl + '?' +
            'id_token_hint=' + encodeURI(id_token_hint) + '&' +
            'post_logout_redirect_uri=' + encodeURI(post_logout_redirect_uri);
        this.ResetAuthorizationData();
        // emit observable
        this.authenticationSource.next(false);
        window.location.href = url;
    }
    public HandleError(error: any) 
    {
        if (error.status == 403) 
        {
            this._router.navigate(['/Forbidden']);
        }
        else if (error.status == 401) 
        {
            // this.ResetAuthorizationData();
            this._router.navigate(['/Unauthorized']);
        }
    }
    private urlBase64Decode(str: string) 
    {
        let output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw 'Illegal base64url string!';
        }
        return window.atob(output);
    }
    private getDataFromToken(token: any) 
    {
        let data = {};
        if (typeof token !== 'undefined') {
            let encoded = token.split('.')[1];
            
            data = JSON.parse(this.urlBase64Decode(encoded));
        }
        return data;
    }
   
    public setHeaders(needId:boolean): any 
    {
        this.checkSessionTimeout(); // for session Timeout check
        const httpOptions = {
            headers: new HttpHeaders()
        };

        httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
        return httpOptions;
    }

    checkSessionTimeout() {
       var data =  this._router.url;
       if(data == "/" || data == "/login")
       {
        return;
       }
 
       var IsAuthenticated =   this._storageService.retrieve(StorageName.Authenticated);
       if(!IsAuthenticated)
       {
        this.loginService = this.Injector.get<LoginService>(LoginService);
        if (this.loginService != undefined) 
        
            this.loginService.logout();
            this._storageService.store(StorageName.Authenticated,false);
            this._router.navigate(['/login']);
            return;
       }
    
       
        // const currentTime = Date.now();
        let lastActivityTime  = this._storageService.retrieve(StorageName.LASTACTIVITYTIME)??0;
        if (lastActivityTime === 0) {
            // First-time initialization, set lastActivityTime to the current time.
                this._storageService.store(StorageName.LASTACTIVITYTIME,Date.now());
                lastActivityTime =  Date.now();
        }
        
        if ((Date.now() - lastActivityTime > this.sessionTimeout)) {
            // The session has timed out; perform logout and redirection.
            this.loginService = this.Injector.get<LoginService>(LoginService);
            if (this.loginService != undefined) {
                this.loginService.logout();
                this._storageService.store(StorageName.Authenticated,false);
                this._router.navigate(['/login']);
                return;
            } else {
                // If loginService is not available, just reset lastActivityTime.
                // this.lastActivityTime = currentTime;
                    this._storageService.store(StorageName.LASTACTIVITYTIME,Date.now());
                    this._storageService.store(StorageName.Authenticated,true);
                
            }
        } else {
            // Update lastActivityTime to the current time to extend the session.
            // this.lastActivityTime = currentTime;
            this._storageService.store(StorageName.LASTACTIVITYTIME,Date.now());
            this._storageService.store(StorageName.Authenticated,true);
        }
    
   
    }
    
}