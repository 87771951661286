import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { MOMENT_DATE_FORMATE } from '../../common/my-date-formats';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements ICellRendererAngularComp, OnInit {
  params;
  firstCellParam = [];
  secondCellParam = [];
  date: string;
  constructor(public agGridService: AgGridService) { }
  ngOnInit(): void { }
  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    if (params.value == 'Invalid date') {
      params.value = "";
    }
    const formattedDate = this.agGridService.dateConvertorByRegion(params.value);
    this.date = formattedDate;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    const formattedDate = this.agGridService.dateConvertorByRegion(params.value);
    this.date = formattedDate;
    return true;
  }
}