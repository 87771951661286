import { Component, OnInit } from '@angular/core';
import { IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community'
@Component({
  selector: 'app-custome-filter',
  templateUrl: './custome-filter.component.html',
  styleUrls: ['./custome-filter.component.scss']
})
export class CustomeFilterComponent implements OnInit,IFilterComp {

  ngOnInit(): void {
  }
  filterParams!: IFilterParams;
  filterText: string | null = null;
  eFilterText: any;
  params!: IFilterParams;
  gui: any;
  onFilterChanged!: () => void;
  init(params: IFilterParams) {
    this.filterParams = params;
    this.filterText = null;
    this.params = params;
    this.setupGui();
  }
  // not called by AG Grid, just for us to help setup
  setupGui() {
    this.gui = document.createElement('div');
    this.gui.innerHTML =`
    <div style="padding: 4px;"> 
    <div style="font-weight: bold;">Greater than: </div>
    <div><input style="margin: 4px 0px 4px 0px;" type="number" id="filterText" placeholder="Number of medals..."/></div>
    </div>
    `
     ;
    this.onFilterChanged = () => {
      this.extractFilterText();
      this.params.filterChangedCallback();
    };
    this.eFilterText = this.gui.querySelector('#filterText');
    this.eFilterText.addEventListener('input', this.onFilterChanged);
  }
  extractFilterText() {
    this.filterText = this.eFilterText.value;
  }
  getGui() {
    return alert("Custoe Filter.")
  
    return this.gui;
  }
  doesFilterPass(params: IDoesFilterPassParams) {
    if (!this.isFilterActive()) {
      return false;
    }
    const { api, colDef, column, columnApi, context } = this.filterParams;
    const { node } = params;
    const value = this.filterParams.valueGetter({
      api,
      colDef,
      column,
      columnApi,
      context,
      data: node.data,
      getValue: (field) => node.data[field],
      node,
    });
    const filterValue = this.filterText;
    if (!value) return false;
    return Number(value) > Number(filterValue);
  }
  isFilterActive() {
    return (
      this.filterText !== null &&
      this.filterText !== undefined &&
      this.filterText !== '' &&
      isNumeric(this.filterText)
    );
  }
  getModel() {
    return this.isFilterActive() ? Number(this.eFilterText.value) : null;
  }
  setModel(model: any) {
    this.eFilterText.value = model;
    this.extractFilterText();
  }
  destroy() {
    this.eFilterText.removeEventListener('input', this.onFilterChanged);
  }
  getModelAsString() {
    return this.isFilterActive() ? '>' + this.filterText : '';
  }
}
const isNumeric = (n: string) =>
  !isNaN(parseFloat(n)) && isFinite(parseFloat(n));