import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { StorageName } from '../../common/enum';
import { CommonFilterModel } from '../../model/response.model';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { FilterComponent } from '../filter/filter/filter.component';
import { ICustomHeaderParams } from './custome-header.interface';
import { CustomeHeaderService } from './custome-header.service';
import { StorageService } from '../../services/storage.service';
@Component({
  selector: 'app-custome-header',
  templateUrl: './custome-header.component.html',
  styleUrls: ['./custome-header.component.scss'],
})
export class CustomeHeaderComponent implements OnInit, IHeaderAngularComp {
  @ViewChild(FilterComponent) filterComponent:FilterComponent;
  @ViewChildren(MatCheckbox) components:QueryList<MatCheckbox>;
  public params!: IHeaderParams & ICustomHeaderParams;
  @ViewChild('myTestDiv') myTestDiv: MatSelectChange;
  privatUserCheckboxText : string = "privatUserCheckbox";
  rows: any[];
  filterData: CommonFilterModel[];
  private _allRowsSubscription!: Subscription;
  private _filterDataSubscription!: Subscription;
  filterSelect: string = "";
  selectedElement:any= ""; 
  inputValue: any;
  isFilterIconColor: boolean = false;
  showCheckbox : boolean = false;
  isChecked : boolean = false;
  tabName: string;
  checkBoxId : string;
  @Output() onHeaderChecboxChecked: EventEmitter<any> = new EventEmitter();
  constructor(public agGridService: AgGridService, public customeHeaderService:CustomeHeaderService, public storageService : StorageService) {
    
  }
  ngOnInit(): void {
    this._filterDataSubscription = this.agGridService.filterData$.subscribe(
      (data) => {
        this.filterData = data;
      }
    )
  }
  resetValue(gridName){
    this.selectedElement = '';
  }
  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    this.checkBoxId = this.params.columnName;
    // this.params.columnApi.getAllDisplayedColumns().forEach(element => {
    //   var colDefinternal = element.getColDef();
    //   if(colDefinternal.headerName !== this.params.column.getColDef().headerName)
    //     element.getColDef().headerComponentParams.isSelected = false;
    // });
    var coldef = this.params.column.getColDef();
    var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    if((coldef.field == "viewPermission" || coldef.field == "modifyPermission" || coldef.field == "deletePermission" )  && formPermission.modifyPermission== true)
      this.showCheckbox = true;
   
    if((this.params.gridName == StorageName.CALCULATIONS_ALLOCATIONS_GRID 
      && (coldef.field == "investor" ||
          coldef.field == "investorGroup" ||
          coldef.field == "amt" ||
          coldef.field == "allocPercent" ||
          coldef.field == "allocAmt")) ||
          (this.params.gridName == StorageName.CALCULATIONS_TIER_CALC_GRID)
      && (coldef.field != "fund" && 
          coldef.field != "calculationName" &&  
          coldef.field != "tierOrder" && 
          coldef.field != "calculationRule" && 
          coldef.field != "prefRetTier" &&  
          coldef.field != "terminationTier" &&
          coldef.field != "calculationType" &&
          coldef.field != "ptrTrans"
          ))
          
    {
      this.showCheckbox = true;
    }
  }
  refresh(params: IHeaderParams & ICustomHeaderParams) {
    this.params = params;    
    return true;
  }
  
  openFilterComponent(name: string, event) {
    this.params.eventOccurs.emit({ eventName: 'onfilter', payload:{name:name, data: event}});
  }
  onClearFilter(name: string, event)
  {
    this.inputValue = "";
    event.target.value = "";
    this.params.eventOccurs.emit({ eventName: 'onfilter', payload:{name:name, data: event}});
  }
  onFloatFilter(name,event){
    this.params.eventOccurs.emit({eventName: 'floatFilter', payload:{name:name, data: event}})
  }

  ngOnDestroy(): void {
    if (this._allRowsSubscription) {
      this._allRowsSubscription.unsubscribe();
    }
    if (this._filterDataSubscription) {
      this._filterDataSubscription.unsubscribe();
    }
  }

    checkedHandler(event) {
      event = event.currentTarget;
        if(this.params.gridName == StorageName.CALCULATIONS_ALLOCATIONS_GRID){
        this.params.columnApi.getAllDisplayedColumns().forEach(element => {
          var colDefinternal = element.getColDef();
          if(colDefinternal.headerName == this.params.column.getColDef().headerName)
            element.getColDef().headerComponentParams.isSelected = event.checked;
        });
    
        if(this.params.column.getColDef().headerName=="Amt" && event.checked==true)
        {
            this.params.columnApi.getColumn("allocPercent").getColDef().headerComponentParams.isSelected = false;
            this.params.columnApi.getColumn("allocAmt").getColDef().headerComponentParams.isSelected = false;
            var allocPercentCheckbox =(<HTMLInputElement>document.getElementById('allocPercent'));
            allocPercentCheckbox.checked = false;
            var allocAmtCheckbox =(<HTMLInputElement>document.getElementById('allocAmt'));
            allocAmtCheckbox.checked = false;
        }
        if(this.params.column.getColDef().headerName=="Proportional %" && event.checked==true)
        {
            this.params.columnApi.getColumn("amt").getColDef().headerComponentParams.isSelected = false;
            this.params.columnApi.getColumn("allocAmt").getColDef().headerComponentParams.isSelected = false;
            var amtCheckbox =(<HTMLInputElement>document.getElementById('amt'));
            amtCheckbox.checked = false;
            var allocAmtCheckbox =(<HTMLInputElement>document.getElementById('allocAmt'));
            allocAmtCheckbox.checked = false;
        }
        if(this.params.column.getColDef().headerName=="Alloc. Amt" && event.checked==true)
        {
            this.params.columnApi.getColumn("allocPercent").getColDef().headerComponentParams.isSelected = false;
            this.params.columnApi.getColumn("amt").getColDef().headerComponentParams.isSelected = false;
            var allocPercentCheckbox =(<HTMLInputElement>document.getElementById('allocPercent'));
            allocPercentCheckbox.checked = false;
            var amtCheckbox =(<HTMLInputElement>document.getElementById('amt'));
            amtCheckbox.checked = false;
        }
      this.customeHeaderService.columnCheckboxChecked.emit(true)
    }
else if(this.params.gridName == StorageName.CALCULATIONS_TIER_CALC_GRID)
{
  this.params.columnApi.getAllDisplayedColumns().forEach(element => {
    var colDefinternal = element.getColDef();
    if(colDefinternal.headerName == this.params.column.getColDef().headerName)
      element.getColDef().headerComponentParams.isSelected = event.checked;
  });

  if(this.params.column.getColDef().field=="tierAmt" && event.checked==true)
  {
      this.params.columnApi.getColumn("allocPercent").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("comparisionAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierCalc").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("balance").getColDef().headerComponentParams.isSelected = false;
      
      var allocPercentCheckbox =(<HTMLInputElement>document.getElementById('allocPercent'));
      allocPercentCheckbox.checked = false;
      var comparisionAmtCheckbox =(<HTMLInputElement>document.getElementById('comparisionAmt'));
      comparisionAmtCheckbox.checked = false;
      var tierCalcCheckbox =(<HTMLInputElement>document.getElementById('tierCalc'));
      tierCalcCheckbox.checked = false;
      var balanceCheckbox =(<HTMLInputElement>document.getElementById('balance'));
      balanceCheckbox.checked = false;
  }
  if(this.params.column.getColDef().field=="comparisionAmt" && event.checked==true)
  {
      this.params.columnApi.getColumn("allocPercent").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierCalc").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("balance").getColDef().headerComponentParams.isSelected = false;
      
      var allocPercentCheckbox =(<HTMLInputElement>document.getElementById('allocPercent'));
      allocPercentCheckbox.checked = false;
      var tierAmtCheckbox =(<HTMLInputElement>document.getElementById('tierAmt'));
      tierAmtCheckbox.checked = false;
      var tierCalcCheckbox =(<HTMLInputElement>document.getElementById('tierCalc'));
      tierCalcCheckbox.checked = false;
      var balanceCheckbox =(<HTMLInputElement>document.getElementById('balance'));
      balanceCheckbox.checked = false;
  }
  if(this.params.column.getColDef().field=="allocPercent" && event.checked==true)
  {
      this.params.columnApi.getColumn("tierAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("comparisionAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierCalc").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("balance").getColDef().headerComponentParams.isSelected = false;
      
      var tierAmtCheckbox =(<HTMLInputElement>document.getElementById('tierAmt'));
      tierAmtCheckbox.checked = false;
      var comparisionAmtCheckbox =(<HTMLInputElement>document.getElementById('comparisionAmt'));
      comparisionAmtCheckbox.checked = false;
      var tierCalcCheckbox =(<HTMLInputElement>document.getElementById('tierCalc'));
      tierCalcCheckbox.checked = false;
      var balanceCheckbox =(<HTMLInputElement>document.getElementById('balance'));
      balanceCheckbox.checked = false;
  }
  if(this.params.column.getColDef().field=="tierCalc" && event.checked==true)
  {
      this.params.columnApi.getColumn("allocPercent").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("comparisionAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("balance").getColDef().headerComponentParams.isSelected = false;
      
      var allocPercentCheckbox =(<HTMLInputElement>document.getElementById('allocPercent'));
      allocPercentCheckbox.checked = false;
      var tierAmtCheckbox =(<HTMLInputElement>document.getElementById('tierAmt'));
      tierAmtCheckbox.checked = false;
      var comparisionAmtCheckbox =(<HTMLInputElement>document.getElementById('comparisionAmt'));
      comparisionAmtCheckbox.checked = false;
      var balanceCheckbox =(<HTMLInputElement>document.getElementById('balance'));
      balanceCheckbox.checked = false;
  }
  if(this.params.column.getColDef().field=="balance" && event.checked==true)
  {
      this.params.columnApi.getColumn("allocPercent").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("comparisionAmt").getColDef().headerComponentParams.isSelected = false;
      this.params.columnApi.getColumn("tierCalc").getColDef().headerComponentParams.isSelected = false;
      
      var allocPercentCheckbox =(<HTMLInputElement>document.getElementById('allocPercent'));
      allocPercentCheckbox.checked = false;
      var tierAmtCheckbox =(<HTMLInputElement>document.getElementById('tierAmt'));
      tierAmtCheckbox.checked = false;
      var comparisionAmtCheckbox =(<HTMLInputElement>document.getElementById('comparisionAmt'));
      comparisionAmtCheckbox.checked = false;
      var tierCalcCheckbox =(<HTMLInputElement>document.getElementById('tierCalc'));
      tierCalcCheckbox.checked = false;
  }
  
  this.customeHeaderService.columnCheckboxChecked.emit(true)
} 
else
{
    var colIdSelected = this.params.column.getColId();
    var coldef = this.params.api.selectAll();
    var getSelectedRows = this.params.api.getSelectedRows();
    var arrayOfSelected = getSelectedRows?.filter((row) => row[colIdSelected] == !event.checked);
    
    if (this.params.column.getColDef().headerComponentParams.gridName == StorageName.PERMISSION_FORM_GRID) {
      arrayOfSelected = arrayOfSelected?.filter((row) => row[colIdSelected + 'Id'] > 0);
    }
    arrayOfSelected.forEach(element => {
      element[colIdSelected] = event.checked;
      element['isEdited'] = true;
    });
    this.params.api.deselectAll();
  }
    // condition for transaction check
    // if (this.params.api.cellRendererParams == true) {
    //   if (typeof (this.params.colDef.editable) == "boolean" && this.params.colDef.editable == false) {
    //   }
    // }
    // condition for transaction check
    //let checked = event.checked;
    // let colId = this.params.column.getColId();
    //this.params.node.setDataValue(colId, checked);
  }
}
