export enum StorageName {
   LASTACTIVITYTIME ="LAST_ACTIVITY_TIME",
   Authenticated ="Authenticated",
   CURRENTUSER = "currentUser",
   CURRENT_FORM = "Default",
   CURRENT_FORM_PERMISSION = "CURRENT_FORM_PERMISSION",
   FUNDID = "FUNDID",
   INVESTORID = "INVESTORID",
   LOOKUPID = "LOOKUPID",
   INVESTMENTID = "INVESTMENTID",
   CALCULATION_RULE_ID = "CALCULATION_RULE_ID",
   PERFORMANCE_RULE_ID = "PERFORMANCE_RULE_ID",
   POSITIONID = "POSITIONID",
   Tier_ID = "tier_id",
   ROLEID = "roleId",
   TRANSACTION_FUNDID = "TRANSACTION_FUNDID",
   TRANSACTION_FILTERID = "TRANSACTION_FILTERID",
   TRANSACTION_FILTER_CRITERIA = "TransactionFilterCriteria",
   PERMISSION_ROLEID = "PERMISSION_ROLEID",
   TRANSACTION_APPLIED_POST_TRANSACTION_DATA = "TRANSACTION_APPLIED_POST_TRANSACTION_DATA",
   CALCULATION_ID = "CALCULATION_ID",
   IS_EXPORT_CALC_FROM_HOMEPAGE = "IS_EXPORT_CALC_FROM_HOMEPAGE",
   IS_EXPORT_WITH_FORMULA_CALC_FROM_HOMEPAGE = "IS_EXPORT_WITH_FORMULA_CALC_FROM_HOMEPAGE",
   CALCULATION_FUNDID = "CALCULATION_FUNDID",
   FUND_HEADER_CONTROLLER = "Fund",
   INVESTOR_HEADER_CONTROLLER = "Investor",
   INVESTMENT_HEADER_CONTROLLER = "Investment",
   POSITION_HEADER_CONTROLLER = "Position",
   TRANSACTION_CONTROLLER = "Transaction",
   CALCULATION_RULE_HEADER_CONTROLLER = "CalculationRule",
   TIER_HEADER_CONTROLLER = "Tier",
   PERFORMANCE_RULE_HEADER_CONTROLLER = "PerformanceRule",
   PERMISSION_CONTROLLER = "Permission",
   FUND_INVESTORCOLUMNCHANGED = "fund_investorColumnChanged",
   FUND_INVESTMENTCOLUMNCHANGED = "fund_investmentColumnChanged",
   FUND_CALCULATIONSETTINGCOLUMNCHANGED = "fund_calculationsettingColumnChanged",
   FUND_CALCULATIONSCOLUMNCHANGED = "fund_calculationsColumnChanged",
   FUND_PTRTRANSACTIONCOLUMNCHANGED = "fund_ptrTransactionColumnChanged",
   USERS_USERCOLUMNCHANGED = "users_userColumnChanged",
   USERS_USERROLECOLUMNCHANGED = "users_userroleColumnChanged",
   ROLES_ROLESCOLUMNCHANGED = "roles_rolesColumnChanged",
   INVESTORCOLUMNCHANGED = "investorColumnChanged",
   INVESTMENTCOLUMNCHANGED = "investmentColumnChanged",
   POSITIONCOLUMNCHANGED = "positionColumnChanged",
   IMPORTFUNDINVESTORCOLUMNCHANGED = "import_fund_investorColumnChanged",
   IMPORTFUNDINVESTMENTCOLUMNCHANGED = "import_fund_investmentColumnChanged",
   CALCULATIONSFUNDID = 'fundsId',
   TRANSACTION_TRANSACTIONLEVELCOLUMNCHANGED = "transaction_transactionlevelColumnChanged",
   TRANSACTION_FUNDLEVELCOLUMNCHANGED = "transaction_fundlevelColumnChanged",
   TRANSACTION_TIER_FUNDID = "transaction_tier_fundId",
   TRANSACTION_TIER_FILTERID = "transaction_tier_filterId",
   IMPORTTRANSACTIONCOLUMNCHANGED = "import_transactionColumnChanged",
   FUND_INVESTOR_PAGINATION = 'fund_investor_pagination',
   FUND_INVESTMENT_PAGINATION = 'fund_investment_pagination',
   FUND_CLACULATION_PAGINATION = 'fund_calculation_pagination',
   FUND_CALCULATION_SETTINGS_PAGINATION = 'fund_calculation_settings_pagination',
   FUND_PTR_TRANSFER_PAGINATION = 'fund_ptr_transfer_pagination',
   USERS_USER_PAGINATION = 'users_user_pagination',
   USERS_USERROLE_PAGINATION = 'users_userrole_pagination',
   ROLES_ROLES_PAGINATION = 'roles_roles_pagination',
   Import_Investor_PAGINATION = 'import_investor_pagination',
   Import_Investment_PAGINATION = 'import_investment_pagination',
   CALCULATION_CONTROLLER = "Calculation",
   // For Grids

   // For Grids
   // For Filter of Investor
   // INVESTOR_INITIALROWS = "investor_initalrows",
   // INVESTOR_PAGINATION = "investor_pagination",
   // FILTER_INVESTOR_SELECTED = "filter_investor_selected",
   // For Investement Filter
   // INVESTEMNT_INITIALROWS = "investment_initalrows",
   // INVESTMENT_PAGINTIOAN = 'investment_pagination',
   // FILTER_INVESTMENT_SELECTED = 'filter_investment_selected',
   // For Position Filter
   // POSITION_INITIALROWS = 'position_initialrows',
   // POSITION_PAGINATION = 'position_pagination',
   // FILTER_POSITION_SELECTED = 'filter_position_selected',
   // For Import Filter
   // IMPORT_INVESTOR_INITALROWS = "import_investor_initalrows",
   // FILTER_IMPORT_INVESTOR_SELECTED = 'filter_investor_import_selected',
   // GRID_IMPORT_INVESTOR = 'grid_import_investor',
   // IMPORT_INVESTMENT_INITALROWS = 'import_investment_initalrows',
   // FILTER_IMPORT_INVESTMENT_SELECTED = 'filter_import_investment_selected',
   // GRID_IMPORT_INVESTMENT = 'grid_import_investment',

   // FOR TIER FILTER
   TIER_INITIALROWS = 'tier_initialrows',
   FILTER_TIER_SELECTED = 'filter_tier_selected',
   TIER_PAGINATION = 'tier_pagination',
   // FOR PERFORMANCE FILTER
   TRANSACTION_SET_INITIALROWS = 'transaction_set_initialrows',
   FILTER_TRANSACTION_SET_SELECTED = 'filter_transaction_set_selected',
   TRANSACTION_SET_PAGINATION = 'transaction_set_pagination',
   GRID_TRANSACTION_SET = 'grid_transaction_set',
   TRANSACTION_FILTER_INITIALROWS = 'transaction_filter_initialrows',
   FILTER_TRANSACTION_FILTER_SELECTED = 'filter_transaction_filter_selected',
   TRANSACTION_FILTER_PAGINATION = 'transaction_filter_pagination',
   GRID_TRANSACTION_FILTER = 'grid_transaction_filter',
   // FOR CALCULATION RULE
   RULE_TIER_INITIALROWS = 'rule_tier_initialrows',
   RULE_TIER_PAGINATION = 'rule_tier_pagination',
   FILTER_RULE_TIER_SELECTED = 'filter_rule_tier_selected',
   GRID_RULE_TIER = 'grid_rule_tier',
   RULE_TIER_FILTER_INITIALROWS = 'rule_tier_filterinitialrows',
   RULE_TIER_FILTER_PAGINATION = 'rule_tier_filter_pagination',
   FILTER_RULE_TIER_FILTER_SELECTED = 'filter_rule_tier_filter_selected',
   GRID_RULE_TIER_FILTER = 'grid_rule_tier_filter',

   // FOR CALCULATION FILTER
   RET_CALC_INITALROWS = 'ret_calc_initialrows',
   RET_CALC_PAGINATION = 'ret_calc_pagination',
   FILTER_RET_CALC_SELECTED = 'filter_ret_calc_selected',
   GIRD_RET_CALC = 'grid_ret_calc',
   RET_CALC_SUMMARY_INITALROWS = 'ret_calc_summary_initialrows',
   RET_CALC_SUMMARY_PAGINATION = 'ret_calc_summary_pagination',
   FILTER_RET_CALC_SUMMARY_SELECTED = 'filter_ret_calc_summary_selected',
   GIRD_RET_CALC_SUMMARY = 'grid_ret_calc_summary',
   TIER_CALC_INITALROWS = 'tier_calc_initialrows',
   TIER_CALC_PAGINATION = 'tier_calc_pagination',
   FILTER_TIER_CALC_SELECTED = 'filter_tier_calc_selected',
   GIRD_TIER_CALC = 'grid_tier_calc',
   TIER_CALC_SUMMARY_INITALROWS = 'tier_calc_summary_initialrows',
   TIER_CALC_SUMMARY_PAGINATION = 'tier_calc_summary_pagination',
   FILTER_TIER_CALC_SUMMARY_SELECTED = 'filter_tier_calc_summary_selected',
   GIRD_TIER_CALC_SUMMARY = 'grid_tier_calc_summary',
   ALLOCATION_INITALROWS = 'allocation_initialrows',
   ALLOCATION_PAGINATION = 'allocation_pagination',
   FILTER_ALLOCATION_SELECTED = 'filter_allocation_selected',
   GIRD_ALLOACTION = 'grid_allocation',
   JOURNAL_INITALROWS = 'journal_initialrows',
   JOURNAL_PAGINATION = 'journal_pagination',
   FILTER_JOURNAL_SELECTED = 'filter_journal_selected',
   GIRD_JOURNAL = 'grid_journal',
   ALLOCATION_SUMMARY_INITALROWS = 'allocation_summary_initialrows',
   ALLOCATION_SUMMARY_PAGINATION = 'allocation_summary_pagination',
   FILTER_ALLOCATION_SUMMARY_SELECTED = 'filter_allocation_summary_selected',
   GIRD_ALLOACTION_SUMMARY = 'grid_allocation_summary',

   // For Transactions Rows 
   TRANSACTIONS_TIER_TRANSACTIONS_TRANSACTION_LEVEL_INITIALROWS = "transactions_tier_transactions_transaction_level_initialrows",
   FILTER_TRANSACTIONS_TIER_TRANSACTIONS_TRANSACTION_LEVEL_SELECTED = "filter_transactions_tier_transactions_transaction_level_selected",
   TRANSACTIONS_TIER_TRANSACTIONS_FUND_LEVEL_INITIALROWS = "transactions_tier_transactions_fund_level_initialrows",
   FILTER_TRANSACTIONS_TIER_TRANSACTIONS_FUND_LEVEL_SELECTED = "filter_transactions_tier_transactions_fund_level_selected",
   TRANSACTIONS_IMPORT_TRANSACTIONS_INITIALROWS = "transactions_import_transactions_initialrows",
   FILTER_TRANSACTIONS_IMPORT_TRANSACTIONS_SELECTED = "filter_transactions_import_transactions_selected",
   // For Transactions Rows 
   //Reporting
   REPORTID = "reportId",
   REPORT_HEADER_CONTROLLER = "Report",
   REPORTING_FUNDINVESTOR_CONTROLLER = "Report FundInvestor",
   REPORTING_FUNDINVESTOR_FILTER = "reporting_fundinvestor_filter",
   REPORTING_FUNDINVESTOR_INITIALROWS = "reporting_fundinvestor_initialrows",
   REPORTING_FUNDINVESTORCOLUMNCHANGED = "reporting_fundinvestorcolumnchanged",
   GRID_REPORTING_FUNDINVESTOR = "grid_reporting_fundInvestor",

   REPORTING_FUNDINVESTMENT_CONTROLLER = "Report FundInvestment",
   REPORTING_FUNDINVESTMENT_FILTER = "reporting_fundinvestment_filter",
   REPORTING_FUNDINVESTMENT_INITIALROWS = "reporting_fundinvestment_initialrows",
   REPORTING_FUNDINVESTMENTCOLUMNCHANGED = "reporting_fundinvestmentcolumnchanged",
   GRID_REPORTING_FUNDINVESTMENT = "grid_reporting_fundinvestment",

   REPORTING_CALCRULE_CONTROLLER = "Report CalcRule",
   REPORTING_CALCRULE_FILTER = "reporting_calcrule_filter",
   REPORTING_CALCRULE_INITIALROWS = "reporting_calcrule_initialrows",
   REPORTING_CALCRULECOLUMNCHANGED = "reporting_calcrulecolumnchanged",
   GRID_REPORTING_CALCRULE = "grid_reporting_calcrule",

   REPORTING_CALCTIER_CONTROLLER = "Report CalcTier",
   REPORTING_CALCTIER_FILTER = "reporting_calctier_filter",
   REPORTING_CALCTIER_INITIALROWS = "reporting_calctier_initialrows",
   REPORTING_CALCTIERCOLUMNCHANGED = "reporting_calctiercolumnchanged",
   GRID_REPORTING_CALCTIER = "grid_reporting_calctier",

   REPORTING_CALCALLOC_CONTROLLER = "Report Calcalloc",
   REPORTING_CALCALLOC_FILTER = "reporting_calcalloc_filter",
   REPORTING_CALCALLOC_INITIALROWS = "reporting_calcalloc_initialrows",
   REPORTING_CALCALLOCCOLUMNCHANGED = "reporting_calcalloccolumnchanged",
   GRID_REPORTING_CALCALLOC = "grid_reporting_calcalloc",

   REPORTING_CALCJES_CONTROLLER = "Report Calcjes",
   REPORTING_CALCJES_FILTER = "reporting_calcjes_filter",
   REPORTING_CALCJES_INITIALROWS = "reporting_calcjes_initialrows",
   REPORTING_CALCJESCOLUMNCHANGED = "reporting_calcjescolumnchanged",
   GRID_REPORTING_CALCJES = "grid_reporting_calcjes",

   REPORTING_TRANSACTION_CONTROLLER = "Report transaction",
   REPORTING_TRANSACTION_FILTER = "reporting_transaction_filter",
   REPORTING_TRANSACTION_INITIALROWS = "reporting_transaction_initialrows",
   REPORTING_CALCTRANSACTIONCOLUMNCHANGED = "reporting_calctransactioncolumnchanged",
   GRID_REPORTING_TRANSACTIONS = "grid_reporting_transactions",
   //Reporting

   USERID = "userId",

   INVESTMENT_DROPDOWN = "INVESTMENT_DROPDOWN",
   CARRY_PARTNER_DESIGNATION_LEVEL_GRID_DROPDOWN = "carry_partner_designation_level_grid_dropdown",
   CALCULATION_LEVEL_GRID_DROPDOWN = "calculation_level_grid_dropdown",
   TRANSFER_TYPE_GRID_DROPDOWN = "TRANSFER_TYPE_GRID_DROPDOWN",
   STATUS_GRID_DROPDOWN = "STATUS_GRID_DROPDOWN",
   FUND_GRID_DROPDOWN = "fund_grid_dropdown",
   POSITION_GRID_DROPDOWN = "position_grid_dropdown",
   POSITION_DROPDOWN = "POSITION_DROPDOWN",
   POSITION_JE_DROPDOWN = "POSITION_JE_DROPDOWN",
   FUND_TYPE_DROPDOWN = "FUND_TYPE_DROPDOWN",
   CURRENCY_DROPDOWN = "CURRENCY_DROPDOWN",
   FUND_GROUP_DROPDOWN = "FUND_GROUP_DROPDOWN",
   DOMAIN_DROPDOWN = "DOMAIN_DROPDOWN",
   FUND_DROPDOWN = "FUND_DROPDOWN",
   ALLOCATE_DROPDOWN = "ALLOCATE_DROPDOWN",
   APPLY_FOR_DROPDOWN = "APPLY_FOR_DROPDOWN",
   FILTERFUND_DROPDOWN = "FILTERFUND_DROPDOWN",
   TRANSACTION_FILTER_DROPDOWN = "TRANSACTION_FILTER_DROPDOWN",
   INVESTOR_DROPDOWN = "INVESTOR_DROPDOWN",
   INVESTMENT_GROUP_DROPDOWN = "INVESTMENT_GROUP_DROPDOWN",
   INVESTMENT_CLASSIFICATION_DROPDOWN = "INVESTMENT_CLASSIFICATION_DROPDOWN",
   INVESTOR_GROUP_DROPDOWN = "INVESTOR_GROUP_DROPDOWN",
   VEHICLE_INVESTOR_DROPDOWN = "VEHICLE_INVESTOR_DROPDOWN",
   //LE_ASSOCIATION_DROPDOWN = "LE_ASSOCIATION_DROPDOWN",
   INVESTOR_CLASSIFICATION_DROPDOWN = "INVESTOR_CLASSIFICATION_DROPDOWN",
   FUNDFILTER_INVESTOR_DROPDOWN = "FUNDFILTER_INVESTOR_DROPDOWN",
   FUNDFILTER_INVESTOR_GROUP_DROPDOWN = "FUNDFILTER_INVESTOR_GROUP_DROPDOWN",
   FUNDFILTER_INVESTMENT_DROPDOWN = "FUNDFILTER_INVESTMENT_DROPDOWN",
   FUNDFILTER_POSITION_GRID_DROPDOWN = "FUNDFILTER_POSITION_GRID_DROPDOWN",
   FUNDFILTER_TIER_DROPDOWN = "FUNDFILTER_TIER_DROPDOWN",

   // For UserDefinedField
   GRID_ADMINISTRATION_UDFS_UDF = "grid_administration_udfs_udf",
   UDFS_UDFCOLUMNCHANGED = "udfs_udfcolumnchanged",
   UDFS_UDF_PAGINATION = 'udfs_udf_pagination',
   ADMINISTRATION_UDFS_UDF_INITIALROWS = "administration_udfs_udf_initialrows",
   FILTER_ADMINISTRATION_UDFS_UDF_SELECTED = "filter_administration_udfs_udf_selected",
   // For UserDefinedField
   //#region FUNDADMIN
   FUNDADMIN_FUNDS_INVESTORS_GRID = "FUNDADMIN_FUNDS_INVESTORS_GRID",
   FUNDADMIN_FUNDS_INVESTORS_CONTROLLER = "Investors",
   FUNDADMIN_FUNDS_INVESTORS_COLUMNCHANGED = "FUNDADMIN_FUNDS_INVESTORS_COLUMNCHANGED",
   FUNDADMIN_FUNDS_INVESTORS_PAGINATION = "FUNDADMIN_FUNDS_INVESTORS_PAGINATION",
   FUNDADMIN_FUNDS_INVESTORS_INITIALROWS = "FUNDADMIN_FUNDS_INVESTORS_INITIALROWS",
   FUNDADMIN_FUNDS_INVESTORS_FILTER = "FUNDADMIN_FUNDS_INVESTORS_FILTER",

   FUNDADMIN_FUNDS_INVESTMENT_GRID = "FUNDADMIN_FUNDS_INVESTMENT_GRID",
   FUNDADMIN_FUNDS_INVESTMENT_CONTROLLER = "Investments",
   FUNDADMIN_FUNDS_INVESTMENT_COLUMNCHANGED = "FUNDADMIN_FUNDS_INVESTMENT_COLUMNCHANGED",
   FUNDADMIN_FUNDS_INVESTMENT_PAGINATION = "FUNDADMIN_FUNDS_INVESTMENT_PAGINATION",
   FUNDADMIN_FUNDS_INVESTMENT_INITIALROWS = "FUNDADMIN_FUNDS_INVESTMENT_INITIALROWS",
   FUNDADMIN_FUNDS_INVESTMENT_FILTER = "FUNDADMIN_FUNDS_INVESTMENT_FILTER",
   FUNDADMIN_FUNDS_CALCULATIONS_GRID = "FUNDADMIN_FUNDS_CALCULATIONS_GRID",
   FUNDADMIN_FUNDS_CALCULATIONS_CONTROLLER = "Calculations",
   FUNDADMIN_FUNDS_CALCULATIONS_COLUMNCHANGED = "FUNDADMIN_FUNDS_CALCULATIONS_COLUMNCHANGED",
   FUNDADMIN_FUNDS_CALCULATIONS_PAGINATION = "FUNDADMIN_FUNDS_CALCULATIONS_PAGINATION",
   FUNDADMIN_FUNDS_CALCULATIONS_INITIALROWS = "FUNDADMIN_FUNDS_CALCULATIONS_INITIALROWS",
   FUNDADMIN_FUNDS_CALCULATIONS_FILTER = "FUNDADMIN_FUNDS_CALCULATIONS_FILTER",
   FUNDADMIN_FUNDS_CALCULATION_SETTINGS_GRID = "FUNDADMIN_FUNDS_CALCULATION_SETTINGS_GRID",
   FUNDADMIN_FUNDS_CALCULATION_SETTINGS_CONTROLLER = "Calculation Settings",
   FUNDADMIN_FUNDS_CALCULATION_SETTINGS_COLUMNCHANGED = "FUNDADMIN_FUNDS_CALCULATION_SETTINGS_COLUMNCHANGED",
   FUNDADMIN_FUNDS_CALCULATION_SETTINGS_PAGINATION = "FUNDADMIN_FUNDS_CALCULATION_SETTINGS_PAGINATION",
   FUNDADMIN_FUNDS_CALCULATION_SETTINGS_INITIALROWS = "FUNDADMIN_FUNDS_CALCULATION_SETTINGS_INITIALROWS",
   FUNDADMIN_FUNDS_CALCULATION_SETTINGS_FILTER = "FUNDADMIN_FUNDS_CALCULATION_SETTINGS_FILTER",
   FUNDADMIN_FUNDS_PTR_TRANSFER_GRID = "FUNDADMIN_FUNDS_PTR_TRANSFER_GRID",
   FUNDADMIN_FUNDS_PTR_TRANSFER_CONTROLLER = "Ptr Transfer",
   FUNDADMIN_FUNDS_PTR_TRANSFER_COLUMNCHANGED = "FUNDADMIN_FUNDS_PTR_TRANSFER_COLUMNCHANGED",
   FUNDADMIN_FUNDS_PTR_TRANSFER_PAGINATION = "FUNDADMIN_FUNDS_PTR_TRANSFER_PAGINATION",
   FUNDADMIN_FUNDS_PTR_TRANSFER_INITIALROWS = "FUNDADMIN_FUNDS_PTR_TRANSFER_INITIALROWS",
   FUNDADMIN_FUNDS_PTR_TRANSFER_FILTER = "FUNDADMIN_FUNDS_PTR_TRANSFER_FILTER",

   FUNDADMIN_FUNDS_SHOW_DELETED = "FUNDADMIN_FUNDS_SHOW_DELETED",
   //#endregion
   //#region INVESTMENTS
   FUNDADMIN_INVESTMENTS_GRID = "FUNDADMIN_INVESTMENTS_GRID",
   FUNDADMIN_INVESTMENTS_CONTROLLER = "Fund Investments",
   FUNDADMIN_INVESTMENTS_PAGINATION = "FUNDADMIN_INVESTMENTS_PAGINATION",
   FUNDADMIN_INVESTMENTS_COLUMNCHANGED = "FUNDADMIN_INVESTMENTS_COLUMNCHANGED",
   FUNDADMIN_INVESTMENTS_INITIALROWS = "FUNDADMIN_INVESTMENTS_INITIALROWS",
   FUNDADMIN_INVESTMENTS_FILTER = "FUNDADMIN_INVESTMENTS_FILTER",
   FUNDADMIN_INVESTMENTS_SHOW_DELETED = "FUNDADMIN_INVESTMENTS_SHOW_DELETED",
   //#endregion
   //#region INVESTORS
   FUNDADMIN_INVESTORS_GRID = "FUNDADMIN_INVESTORS_GRID",
   FUNDADMIN_INVESTORS_CONTROLLER = "Fund Commitments",
   FUNDADMIN_INVESTORS_COLUMNCHANGED = "FUNDADMIN_INVESTORS_COLUMNCHANGED",
   FUNDADMIN_INVESTORS_PAGINATION = "FUNDADMIN_INVESTORS_PAGINATION",
   FUNDADMIN_INVESTORS_INITIALROWS = "FUNDADMIN_INVESTORS_INITIALROWS",
   FUNDADMIN_INVESTORS_FILTER = "FUNDADMINS_INVESTOR_FILTER",
   FUNDADMIN_INVESTORS_SHOW_DELETED = "FUNDADMIN_INVESTORS_SHOW_DELETED",
   //#endregion
   //#region POSITIONS
   FUNDADMIN_POSITIONS_GRID = "FUNDADMIN_POSITIONS_GRID",
   FUNDADMIN_POSITIONS_CONTROLLER = "Positions",
   FUNDADMIN_POSITIONS_COLUMNCHANGED = "FUNDADMIN_POSITIONS_COLUMNCHANGED",
   FUNDADMIN_POSITIONS_PAGINATION = "FUNDADMIN_POSITIONS_PAGINATION",
   FUNDADMIN_POSITIONS_INITIALROWS = "FUNDADMIN_POSITIONS_INITIALROWS",
   FUNDADMIN_POSITIONS_FILTER = "FUNDADMINS_POSITIONS_FILTER",
   FUNDADMIN_POSITIONS_SHOW_DELETED = "FUNDADMIN_POSITIONS_SHOW_DELETED",
   //#endregion
   //#region FUND IMPORTS
   INVESTOR_IMPORT_GRID = "INVESTOR_IMPORT_GRID",
   INVESTOR_IMPORT_CONTROLLER = "FundInvestorImport",
   INVESTOR_IMPORT_COLUMNCHANGED = "INVESTOR_IMPORT_COLUMNCHANGED",
   INVESTOR_IMPORT_PAGINATION = "INVESTOR_IMPORT_PAGINATION",
   INVESTOR_IMPORT_INITIALROWS = "INVESTOR_IMPORT_INITIALROWS",
   INVESTOR_IMPORT_FILTER = "INVESTOR_IMPORT_FILTER",
   INVESTMENT_IMPORT_GRID = "INVESTMENT_IMPORT_GRID",
   INVESTMENT_IMPORT_CONTROLLER = "FundInvestmentImport",
   INVESTMENT_IMPORT_COLUMNCHANGED = "INVESTMENT_IMPORT_COLUMNCHANGED",
   INVESTMENT_IMPORT_PAGINATION = "INVESTMENT_IMPORT_PAGINATION",
   INVESTMENT_IMPORT_INITIALROWS = "INVESTMENT_IMPORT_INITIALROWS",
   INVESTMENT_IMPORT_FILTER = "INVESTMENT_IMPORT_FILTER",
   //#endregion
   //#region CALCULATION RULES LIBRARY
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_GRID = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_GRID",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_CONTROLLER = "Tiers",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_COLUMNCHANGED = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_COLUMNCHANGED",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_PAGINATION = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_PAGINATION",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_INITIALROWS = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_INITIALROWS",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_GRID = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_GRID",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_CONTROLLER = "Tier Filters",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_COLUMNCHANGED = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_COLUMNCHANGED",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_PAGINATION = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_PAGINATION",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_INITIALROWS = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_INITIALROWS",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_FILTER = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_FILTER_FILTER",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_GRID = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_GRID",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_CONTROLLER = "Pref Ret Structures",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_COLUMNCHANGED = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_COLUMNCHANGED",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_PAGINATION = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_PAGINATION",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_INITIALROWS = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_INITIALROWS",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_FILTER = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_PREFRET_STRUCTURE_FILTER",
   CALCULATION_RULES_LIBRARY_CALCULATION_RULES_SHOW_DELETED = "CALCULATION_RULES_LIBRARY_CALCULATION_RULES_TIER_SHOW_DELETED",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_GRID = "CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_GRID",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_CONTROLLER = "Tier Components",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_COLUMNCHANGED = "CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_COLUMNCHANGED",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_PAGINATION = "CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_PAGINATION",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_INITIALROWS = "CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_INITIALROWS",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_FILTER = "CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_FILTER",
   CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_SHOW_DELETED = "CALCULATION_RULES_LIBRARY_TIERS_TIER_COMPONENTS_SHOW_DELETED",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_GRID = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_GRID",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_CONTROLLER = "Transaction Set",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_COLUMNCHANGED = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_COLUMNCHANGED",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_PAGINATION = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_PAGINATION",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_INITIALROWS = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_INITIALROWS",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_GRID = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_GRID",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_CONTROLLER = "Transaction Set Filter",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_COLUMNCHANGED = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_COLUMNCHANGED",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_PAGINATION = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_PAGINATION",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_INITIALROWS = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_INITIALROWS",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_FILTER = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_TRANSACTION_SET_FILTER_FILTER",
   CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_SHOW_DELETED = "CALCULATION_RULES_LIBRARY_PERFORMANCE_RULES_SHOW_DELETED",

  
   //#endregion
   //#region LOOKUPS
   LOOKUP_HEADER_CONTROLLER = "Lookup",
   LOOKUP_DAYBASISSETTING_GRID = "LOOKUP_DAYBASISSETTING_GRID",
   LOOKUP_DAYBASISSETTING_CONTROLLER = "Lookup Day Basis Setting",
   LOOKUP_DAYBASISSETTING_COLUMNCHANGED = "LOOKUP_DAYBASISSETTING_COLUMNCHANGED",
   LOOKUP_DAYBASISSETTING_PAGINATION = "LOOKUP_DAYBASISSETTING_PAGINATION",
   LOOKUP_DAYBASISSETTING_INITIALROWS = "LOOKUP_DAYBASISSETTING_INITIALROWS",
   LOOKUP_DAYBASISSETTING_FILTER = "LOOKUP_DAYBASISSETTING_FILTER",
   LOOKUP_FUNDCURRENCY_GRID = "LOOKUP_FUNDCURRENCY_GRID",
   LOOKUP_FUNDCURRENCY_CONTROLLER = "Lookup Fund Currency",
   LOOKUP_FUNDCURRENCY_COLUMNCHANGED = "LOOKUP_FUNDCURRENCY_COLUMNCHANGED",
   LOOKUP_FUNDCURRENCY_PAGINATION = "LOOKUP_FUNDCURRENCY_PAGINATION",
   LOOKUP_FUNDCURRENCY_INITIALROWS = "LOOKUP_FUNDCURRENCY_INITIALROWS",
   LOOKUP_FUNDCURRENCY_FILTER = "LOOKUP_FUNDCURRENCY_FILTER",
   LOOKUP_FUNDGROUP_GRID = "LOOKUP_FUNDGROUP_GRID",
   LOOKUP_FUNDGROUP_CONTROLLER = "Lookup Fund Group",
   LOOKUP_FUNDGROUP_COLUMNCHANGED = "LOOKUP_FUNDGROUP_COLUMNCHANGED",
   LOOKUP_FUNDGROUP_PAGINATION = "LOOKUP_FUNDGROUP_PAGINATION",
   LOOKUP_FUNDGROUP_INITIALROWS = "LOOKUP_FUNDGROUP_INITIALROWS",
   LOOKUP_FUNDGROUP_FILTER = "LOOKUP_FUNDGROUP_FILTER",
   LOOKUP_FUNDTYPE_GRID = "LOOKUP_FUNDTYPE_GRID",
   LOOKUP_FUNDTYPE_CONTROLLER = "Lookup Fund Type",
   LOOKUP_FUNDTYPE_COLUMNCHANGED = "LOOKUP_FUNDTYPE_COLUMNCHANGED",
   LOOKUP_FUNDTYPE_PAGINATION = "LOOKUP_FUNDTYPE_PAGINATION",
   LOOKUP_FUNDTYPE_INITIALROWS = "LOOKUP_FUNDTYPE_INITIALROWS",
   LOOKUP_FUNDTYPE_FILTER = "LOOKUP_FUNDTYPE_FILTER",
   LOOKUP_INVESTMENTCLASSIFICATION_GRID = "LOOKUP_INVESTMENTCLASSIFICATION_GRID",
   LOOKUP_INVESTMENTCLASSIFICATION_CONTROLLER = "Lookup Investment Classification",
   LOOKUP_INVESTMENTCLASSIFICATION_COLUMNCHANGED = "LOOKUP_INVESTMENTCLASSIFICATION_COLUMNCHANGED",
   LOOKUP_INVESTMENTCLASSIFICATION_PAGINATION = "LOOKUP_INVESTMENTCLASSIFICATION_PAGINATION",
   LOOKUP_INVESTMENTCLASSIFICATION_INITIALROWS = "LOOKUP_INVESTMENTCLASSIFICATION_INITIALROWS",
   LOOKUP_INVESTMENTCLASSIFICATION_FILTER = "LOOKUP_INVESTMENTCLASSIFICATION_FILTER",
   LOOKUP_INVESTMENTGROUP_GRID = "LOOKUP_INVESTMENTGROUP_GRID",
   LOOKUP_INVESTMENTGROUP_CONTROLLER = "Lookup Investment Group",
   LOOKUP_INVESTMENTGROUP_COLUMNCHANGED = "LOOKUP_INVESTMENTGROUP_COLUMNCHANGED",
   LOOKUP_INVESTMENTGROUP_PAGINATION = "LOOKUP_INVESTMENTGROUP_PAGINATION",
   LOOKUP_INVESTMENTGROUP_INITIALROWS = "LOOKUP_INVESTMENTGROUP_INITIALROWS",
   LOOKUP_INVESTMENTGROUP_FILTER = "LOOKUP_INVESTMENTGROUP_FILTER",
   LOOKUP_INVESTORCLASSIFICATION_GRID = "LOOKUP_INVESTORCLASSIFICATION_GRID",
   LOOKUP_INVESTORCLASSIFICATION_CONTROLLER = "Lookup Investor Classification",
   LOOKUP_INVESTORCLASSIFICATION_COLUMNCHANGED = "LOOKUP_INVESTORCLASSIFICATION_COLUMNCHANGED",
   LOOKUP_INVESTORCLASSIFICATION_PAGINATION = "LOOKUP_INVESTORCLASSIFICATION_PAGINATION",
   LOOKUP_INVESTORCLASSIFICATION_INITIALROWS = "LOOKUP_INVESTORCLASSIFICATION_INITIALROWS",
   LOOKUP_INVESTORCLASSIFICATION_FILTER = "LOOKUP_INVESTORCLASSIFICATION_FILTER",
   LOOKUP_INVESTORGROUP_GRID = "LOOKUP_INVESTORGROUP_GRID",
   LOOKUP_INVESTORGROUP_CONTROLLER = "Lookup Investor Group",
   LOOKUP_INVESTORGROUP_COLUMNCHANGED = "LOOKUP_INVESTORGROUP_COLUMNCHANGED",
   LOOKUP_INVESTORGROUP_PAGINATION = "LOOKUP_INVESTORGROUP_PAGINATION",
   LOOKUP_INVESTORGROUP_INITIALROWS = "LOOKUP_INVESTORGROUP_INITIALROWS",
   LOOKUP_INVESTORGROUP_FILTER = "LOOKUP_INVESTORGROUP_FILTER",
   LOOKUP_INVESTORVEHICLE_GRID = "LOOKUP_INVESTORVEHICLE_GRID",
   LOOKUP_INVESTORVEHICLE_CONTROLLER = "Lookup Investor Vehicle",
   LOOKUP_INVESTORVEHICLE_COLUMNCHANGED = "LOOKUP_INVESTORVEHICLE_COLUMNCHANGED",
   LOOKUP_INVESTORVEHICLE_PAGINATION = "LOOKUP_INVESTORVEHICLE_PAGINATION",
   LOOKUP_INVESTORVEHICLE_INITIALROWS = "LOOKUP_INVESTORVEHICLE_INITIALROWS",
   LOOKUP_INVESTORVEHICLE_FILTER = "LOOKUP_INVESTORVEHICLE_FILTER",
   LOOKUP_SUBACCOUNT_GRID = "LOOKUP_SUBACCOUNT_GRID",
   LOOKUP_SUBACCOUNT_CONTROLLER = "Lookup Subaccount",
   LOOKUP_SUBACCOUNT_COLUMNCHANGED = "LOOKUP_SUBACCOUNT_COLUMNCHANGED",
   LOOKUP_SUBACCOUNT_PAGINATION = "LOOKUP_SUBACCOUNT_PAGINATION",
   LOOKUP_SUBACCOUNT_INITIALROWS = "LOOKUP_SUBACCOUNT_INITIALROWS",
   LOOKUP_SUBACCOUNT_FILTER = "LOOKUP_SUBACCOUNT_FILTER",
   LOOKUP_TIERCOMPONENT_GRID = "LOOKUP_TIERCOMPONENT_GRID",
   LOOKUP_TIERCOMPONENT_CONTROLLER = "Lookup Tiercomponent",
   LOOKUP_TIERCOMPONENT_COLUMNCHANGED = "LOOKUP_TIERCOMPONENT_COLUMNCHANGED",
   LOOKUP_TIERCOMPONENT_PAGINATION = "LOOKUP_TIERCOMPONENT_PAGINATION",
   LOOKUP_TIERCOMPONENT_INITIALROWS = "LOOKUP_TIERCOMPONENT_INITIALROWS",
   LOOKUP_TIERCOMPONENT_FILTER = "LOOKUP_TIERCOMPONENT_FILTER",

   LOOKUP_DOMAIN_GRID = "LOOKUP_DOMAIN_GRID",
   LOOKUP_DOMAIN_CONTROLLER = "Lookup Domain",
   LOOKUP_DOMAIN_COLUMNCHANGED = "LOOKUP_DOMAIN_COLUMNCHANGED",
   LOOKUP_DOMAIN_PAGINATION = "LOOKUP_DOMAIN_PAGINATION",
   LOOKUP_DOMAIN_INITIALROWS = "LOOKUP_DOMAIN_INITIALROWS",
   LOOKUP_DOMAIN_FILTER = "LOOKUP_DOMAIN_FILTER",
   //#endregion
   //#region TRANSACTION
   TRANSACTION_LEVEL_GRID = "TRANSACTION_LEVEL_GRID",
   TRANSACTION_LEVEL_CONTROLLER = "Transaction Level",
   TRANSACTION_LEVEL_COLUMNCHANGED = "TRANSACTION_LEVEL_COLUMNCHANGED",
   TRANSACTION_LEVEL_PAGINATION = "TRANSACTION_LEVEL_PAGINATION",
   TRANSACTION_LEVEL_INITIALROWS = "TRANSACTION_LEVEL_INITIALROWS",
   TRANSACTION_LEVEL_FILTER = "TRANSACTION_LEVEL_FILTER",
   TRANSACTION_LEVEL_APPLIED_FILTER = "TRANSACTION_LEVEL_APPLIED_FILTER",
   TRANSACTION_LEVEL_POPUP_FILTER = "TRANSACTION_LEVEL_POPUP_FILTER",
   
   //TRANSACTION Checkboxes
   TRANSACTION_LEVEL_ACTIVE_CHECKBOX_FILTER = "TRANSACTION_LEVEL_ACTIVE_CHECKBOX_FILTER",
   TRANSACTION_LEVEL_LOCKED_CHECKBOX_FILTER = "TRANSACTION_LEVEL_LOCKED_CHECKBOX_FILTER",
   TRANSACTION_LEVEL_DELETED_CHECKBOX_FILTER = "TRANSACTION_LEVEL_DELETED_CHECKBOX_FILTER",
   TRANSACTION_LEVEL_DRAFT_CHECKBOX_FILTER = "TRANSACTION_LEVEL_DRAFT_CHECKBOX_FILTER",
   

   FUND_LEVEL_GRID = "FUND_LEVEL_GRID",
   FUND_LEVEL_CONTROLLER = "Fund Level",
   FUND_LEVEL_COLUMNCHANGED = "FUND_LEVEL_COLUMNCHANGED",
   FUND_LEVEL_PAGINATION = "FUND_LEVEL_PAGINATION",
   FUND_LEVEL_INITIALROWS = "FUND_LEVEL_INITIALROWS",
   FUND_LEVEL_FILTER = "FUND_LEVEL_FILTER",
   FUND_LEVEL_APPLIED_FILTER = "FUND_LEVEL_APPLIED_FILTER",
   FUND_LEVEL_POPUP_FILTER = "FUND_LEVEL_POPUP_FILTER",
   //#endregion
   //#region FUND IMPORTS
   TRANSACTION_IMPORT_GRID = "TRANSACTION_IMPORT_GRID",
   TRANSACTION_IMPORT_CONTROLLER = "TransactionImport",
   TRANSACTION_IMPORT_COLUMNCHANGED = "TRANSACTION_IMPORT_COLUMNCHANGED",
   TRANSACTION_IMPORT_PAGINATION = "TRANSACTION_IMPORT_PAGINATION",
   TRANSACTION_IMPORT_INITIALROWS = "TRANSACTION_IMPORT_INITIALROWS",
   TRANSACTION_IMPORT_FILTER = "TRANSACTION_IMPORT_FILTER",
   //#endregion
   //#region Administration
   //#region Roles
   ROLES_GRID = "ROLES_GRID",
   ROLES_CONTROLLER = "Role",
   FROLES_PAGINATION = "FROLES_PAGINATION",
   ROLES_COLUMNCHANGED = "ROLES_COLUMNCHANGED",
   ROLES_INITIALROWS = "ROLES_INITIALROWS",
   ROLES_FILTER = "ROLES_FILTER",
   ROLES_SHOW_DELETED = "ROLES_SHOW_DELETED",
   //#endregion
   //#region users
   USERS_GRID = "USERS_GRID",
   USERS_CONTROLLER = "User",
   USERS_PAGINATION = "USERS_PAGINATION",
   USERS_COLUMNCHANGED = "USERS_COLUMNCHANGED",
   USERS_INITIALROWS = "USERS_INITIALROWS",
   USERS_FILTER = "USERS_FILTER",
   USERS_SHOW_DELETED = "USERS_SHOW_DELETED",
   //#endregion
   //#region users
   USER_ROLES_GRID = "USER_ROLES_GRID",
   USER_ROLES_CONTROLLER = "User Roles",
   USER_ROLES_COLUMNCHANGED = "USER_ROLES_COLUMNCHANGED",
   USER_ROLES_PAGINATION = "USER_ROLES_PAGINATION",
   USER_ROLES_INITIALROWS = "USER_ROLES_INITIALROWS",
   USER_ROLES_FILTER = "USER_ROLES_FILTER",
   USER_SHOW_DELETED = "USER_SHOW_DELETED",
   //#endregio
   //#endregion
   //#region DROPDOWNS
   CALCULATION_DROPDOWN = "CALCULATION_DROPDOWN",
   CALCULATIONRULE_DROPDOWN = "CALCULATIONRULE_DROPDOWN",
   PERFORMANCERULE_DROPDOWN = "PERFORMANCERULE_DROPDOWN",
   CALCULATION_GROUP_DROPDOWN = "CALCULATION_GROUP_DROPDOWN",
   CALCULATION_TYPE_DROPDOWN = "CALCULATION_TYPE_DROPDOWN",
   CALCULATION_STATE_DROPDOWN = "CALCULATION_STATE_DROPDOWN",
   CATCHUP_TYPE_DROPDOWN = "CATCHUP_TYPE_DROPDOWN",
   DAY_BASIS_SETTING_DROPDOWN = "DAY_BASIS_SETTING_DROPDOWN",
   SUB_ACT_DROPDOWN = "SUB_ACT_DROPDOWN",
   LOCK_UNLOCK_STATUS_DROPDOWN = "LOCK_UNLOCK_STATUS_DROPDOWN",
   TIER_DROPDOWN = "TIER_DROPDOWN",
   NON_STSTEM_TIER_DROPDOWN = "NON_STSTEM_TIER_DROPDOWN",
   SYSTEM_TIERS = "SYSTEM_TIERS",
   LOOKUP_DROPDOWN = "LOOKUP_DROPDOWN",
   REPORT_DROPDOWN = "REPORT_DROPDOWN",
   REPORT_TYPE_DROPDOWN = "REPORT_TYPE_DROPDOWN",
   ROLLUP_LEVEL_DROPDOWN = "ROLLUP_LEVEL_DROPDOWN",
   INCLUDE_IN_BALANCE_DROPDOWN = "INCLUDE_IN_BALANCE_DROPDOWN",
   
   NUMERATOR_DENOMINATOR_DROPDOWN = "NUMERATOR_DENOMINATOR_DROPDOWN",
   USER_DROPDOWN = "USER_DROPDOWN",
   USER_GRID_DROPDOWN = "USER_GRID_DROPDOWN",
   ROLE_DROPDOWN = "ROLE_DROPDOWN",
   FILTER_FIELD_DROPDOWN = "FILTER_FIELD_DROPDOWN",
   HURDLE_DROPDOWN = "HURDLE_DROPDOWN",
   UDF_DROPDOWN = "UDF_DROPDOWN",
   FILTER_CRITERIA_DROPDOWN = "FILTER_CRITERIA_DROPDOWN",
   OPERATOR_DROPDOWN = "OPERATOR_DROPDOWN",
   CALCULATION_LEVEL_DROPDOWN = "CALCULATION_LEVEL_DROPDOWN",
   IMPORT_TYPE_DROPDOWN = "IMPORT_TYPE_DROPDOWN",
   PERMISSION_DROPDOWN = "PERMISSION_DROPDOWN",
   //#endregion
   //#region Permission
   PERMISSION_FUNDGROUP_GRID = "PERMISSION_FUNDGROUP_GRID",
   PERMISSION_FUNDGROUP_CONTROLLER = "PERMISSION_FUNDGROUP_CONTROLLER",
   PERMISSION_FUNDGROUP_COLUMNCHANGED = "PERMISSION_FUNDGROUP_COLUMNCHANGED",
   PERMISSION_FUNDGROUP_PAGINATION = "PERMISSION_FUNDGROUP_PAGINATION",
   PERMISSION_FUNDGROUP_INITIALROWS = "PERMISSION_FUNDGROUP_INITIALROWS",
   PERMISSION_FUNDGROUP_FILTER = "PERMISSION_FUNDGROUP_FILTER",
   PERMISSION_FORM_GRID = "PERMISSION_FORM_GRID",
   PERMISSION_FORM_CONTROLLER = "PERMISSION_FORM_CONTROLLER",
   PERMISSION_FORM_COLUMNCHANGED = "PERMISSION_FORM_COLUMNCHANGED",
   PERMISSION_FORM_PAGINATION = "PERMISSION_FORM_PAGINATION",
   PERMISSION_FORM_INITIALROWS = "PERMISSION_FORM_INITIALROWS",
   PERMISSION_FORM_FILTER = "PERMISSION_FORM_FILTER",

   PERMISSION_COLUMN_GRID = "PERMISSION_COLUMN_GRID",
   PERMISSION_COLUMN_CONTROLLER = "PERMISSION_COLUMN_CONTROLLER",
   PERMISSION_COLUMN_COLUMNCHANGED = "PERMISSION_COLUMN_COLUMNCHANGED",
   PERMISSION_COLUMN_PAGINATION = "PERMISSION_COLUMN_PAGINATION",
   PERMISSION_COLUMN_INITIALROWS = "PERMISSION_COLUMN_INITIALROWS",
   PERMISSION_COLUMN_FILTER = "PERMISSION_COLUMN_FILTER",
   PERMISSION_SHOW_DELETED = "PERMISSION_SHOW_DELETED",

   //#endregion


   //#region Calculations
      //#region Pref Ret Cals
      CALCULATIONS_PREF_RET_CALC_GRID = "CALCULATIONS_PREF_RET_CALC_GRID",
      CALCULATIONS_PREF_RET_CALC_CONTROLLER = "Pref Ret Calc",
      CALCULATIONS_PREF_RET_CALC_COLUMNCHANGED = "CALCULATIONS_PREF_RET_CALC_COLUMNCHANGED",
      CALCULATIONS_PREF_RET_CALC_PAGINATION = "CALCULATIONS_PREF_RET_CALC_PAGINATION",
      CALCULATIONS_PREF_RET_CALC_INITIALROWS = "CALCULATIONS_PREF_RET_CALC_INITIALROWS",
      CALCULATIONS_PREF_RET_CALC_FILTER = "CALCULATIONS_PREF_RET_CALC_FILTER",
      //CALCULATIONS_PREF_RET_CALC_SHOW_DELETED = "FUNDADMIN_POSITIONS_SHOW_DELETED",
      //#endregion

      //#region Pref Ret Cals Summary
      CALCULATIONS_PREF_RET_CALC_SUMMARY_GRID = "CALCULATIONS_PREF_RET_CALC_SUMMARY_GRID",
      CALCULATIONS_PREF_RET_CALC_SUMMARY_CONTROLLER = "Pref Ret Calc Summary",
      CALCULATIONS_PREF_RET_CALC_SUMMARY_COLUMNCHANGED = "CALCULATIONS_PREF_RET_CALC_SUMMARY_COLUMNCHANGED",
      CALCULATIONS_PREF_RET_CALC_SUMMARY_PAGINATION = "CALCULATIONS_PREF_RET_CALC_SUMMARY_PAGINATION",
      CALCULATIONS_PREF_RET_CALC_SUMMARY_INITIALROWS = "CALCULATIONS_PREF_RET_CALC_SUMMARY_INITIALROWS",
      CALCULATIONS_PREF_RET_CALC_SUMMARY_FILTER = "CALCULATIONS_PREF_RET_CALC_SUMMARY_FILTER",
      //#endregion

      //#region Tier Cals
      CALCULATIONS_TIER_CALC_GRID = "CALCULATIONS_TIER_CALC_GRID",
      CALCULATIONS_TIER_CALC_CONTROLLER = "Tier Calc",
      CALCULATIONS_TIER_CALC_COLUMNCHANGED = "CALCULATIONS_TIER_CALC_COLUMNCHANGED",
      CALCULATIONS_TIER_CALC_PAGINATION = "CALCULATIONS_TIER_CALC_PAGINATION",
      CALCULATIONS_TIER_CALC_INITIALROWS = "CALCULATIONS_TIER_CALC_INITIALROWS",
      CALCULATIONS_TIER_CALC_FILTER = "CALCULATIONS_TIER_CALC_FILTER",
      //#endregion

      //#region Tier Cals
      CALCULATIONS_TIER_CALC_SUMMARY_GRID = "CALCULATIONS_TIER_CALC_SUMMARY_GRID",
      CALCULATIONS_TIER_CALC_SUMMARY_CONTROLLER = "Tier Calc Summary",
      CALCULATIONS_TIER_CALC_SUMMARY_COLUMNCHANGED = "CALCULATIONS_TIER_CALC_SUMMARY_COLUMNCHANGED",
      CALCULATIONS_TIER_CALC_SUMMARY_PAGINATION = "CALCULATIONS_TIER_CALC_SUMMARY_PAGINATION",
      CALCULATIONS_TIER_CALC_SUMMARY_INITIALROWS = "CALCULATIONS_TIER_CALC_SUMMARY_INITIALROWS",
      CALCULATIONS_TIER_CALC_SUMMARY_FILTER = "CALCULATIONS_TIER_CALC_SUMMARY_FILTER",
      //#endregion

      //#region Tier Cals
      CALCULATIONS_ALLOCATIONS_GRID = "CALCULATIONS_ALLOCATIONS_GRID",
      CALCULATIONS_ALLOCATIONS_CONTROLLER = "Allocations",
      CALCULATIONS_ALLOCATIONS_COLUMNCHANGED = "CALCULATIONS_ALLOCATIONS_COLUMNCHANGED",
      CALCULATIONS_ALLOCATIONS_PAGINATION = "CALCULATIONS_ALLOCATIONS_PAGINATION",
      CALCULATIONS_ALLOCATIONS_INITIALROWS = "CALCULATIONS_ALLOCATIONS_INITIALROWS",
      CALCULATIONS_ALLOCATIONS_FILTER = "CALCULATIONS_ALLOCATIONS_FILTER",
      //#endregion

      //#region Tier Cals
      CALCULATIONS_ALLOCATIONS_SUMMARY_GRID = "CALCULATIONS_ALLOCATIONS_SUMMARY_GRID",
      CALCULATIONS_ALLOCATIONS_SUMMARY_CONTROLLER = "Allocations Summary",
      CALCULATIONS_ALLOCATIONS_SUMMARY_COLUMNCHANGED = "CALCULATIONS_ALLOCATIONS_SUMMARY_COLUMNCHANGED",
      CALCULATIONS_ALLOCATIONS_SUMMARY_PAGINATION = "CALCULATIONS_ALLOCATIONS_SUMMARY_PAGINATION",
      CALCULATIONS_ALLOCATIONS_SUMMARY_INITIALROWS = "CALCULATIONS_ALLOCATIONS_SUMMARY_INITIALROWS",
      CALCULATIONS_ALLOCATIONS_SUMMARY_FILTER = "CALCULATIONS_ALLOCATIONS_SUMMARY_FILTER",
      //#endregion

      //#region Tier Cals
      CALCULATIONS_JOURNAL_ENTRIES_GRID = "CALCULATIONS_JOURNAL_ENTRIES_GRID",
      CALCULATIONS_JOURNAL_ENTRIES_CONTROLLER = "Journal Entries",
      CALCULATIONS_JOURNAL_ENTRIES_COLUMNCHANGED = "CALCULATIONS_JOURNAL_ENTRIES_COLUMNCHANGED",
      CALCULATIONS_JOURNAL_ENTRIES_PAGINATION = "CALCULATIONS_JOURNAL_ENTRIES_PAGINATION",
      CALCULATIONS_JOURNAL_ENTRIES_INITIALROWS = "CALCULATIONS_JOURNAL_ENTRIES_INITIALROWS",
      CALCULATIONS_JOURNAL_ENTRIES_FILTER = "CALCULATIONS_JOURNAL_ENTRIES_FILTER",
      //#endregion

      //#region timer
         TIMER = "TIMER",
      //#endregion
   //#endregion

}