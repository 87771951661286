import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { Result } from "src/app/shared/model/response.model";
import { DataService } from "src/app/shared/services/data.service";
import { CalcGraphModel, Calculation, CalculationModel, ScenarioMode } from "./calculation.model";
import { HttpClient } from "@angular/common/http";
import { CommonTabService } from "src/app/shared/services/common-tab.service";

@Injectable({
  providedIn: "root",
})
export class CalculationService {
  inCalculation = new BehaviorSubject<boolean>(false);
  constructor(private service: DataService,private http: HttpClient,private commonTabService: CommonTabService) {}
  GetCalculationLists(fundId): Observable<Result> {
    let url = "/Calculation/getAllCalcList/" + fundId;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getCalcListFromSearch(data: CalculationModel): Observable<Result> {
    let url = "/Dropdown/getCalcListFromSearch";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  GetJEPositions(data: CalculationModel): Observable<Result> {
    let url = "/Calculation/getJEPositions";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
  GetScenarioMode(): Observable<Result> {
    let url = "/ScenarioMode/GetAll";
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  DeleteScenarioMode(id:number): Observable<Result> {
    let url = "/ScenarioMode/Delete/" + id;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  AddScenarioMode(data:ScenarioMode):Observable<Result> {
    let url = "/ScenarioMode/post";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
  UpdateScenarioMode(id:number):Observable<Result> {
    let url = "/ScenarioMode/post";
    let myJson = JSON.stringify(id);
    return this.service.post(url, id).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  RunModal(data:CalculationModel):Observable<Result> {
    let url = "/Calculation/Run";
    let userid = this.commonTabService.getUserid();
    data.importUserId = userid;
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  ExportAfterRun(data:CalculationModel):Observable<Result> {
    let url = "/Calculation/ExportAfterRun";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


  postData

  DoPrefRetCalc(data:Calculation):Observable<Result> {
    let url = "/Calculation/DoPrefRetCalc";
    
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  ProcessCalculation(data:Calculation):Observable<Result> {
    let url = "/Calculation/ProcessCalculation";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }
  CreateCalculationJE(data:Calculation):Observable<Result> {
    let url = "/Calculation/CreateCalculationJE";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetCalcAllocPerByFundIdCalcID(data:Calculation):Observable<Result> {
    let url = "/Calculation/GetCalcAllocPerByFundIdCalcID";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  postCalLockedUnlockedData(data:Calculation):Observable<Result> {
    let url = "/Calculation/PostCalculationLockEntry";
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  SaveRecords(data:CalculationModel):Observable<Result> {
    let url = "/Calculation/AddUpdate";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getInvestmentDropDownDataByFundID(fundId,InvestmentId){
    InvestmentId = InvestmentId == undefined ? 0 : InvestmentId;
    let url = "/Calculation/GetAllInvestment_ByFundID" + `?fundId=${fundId}` + `&InvestmentId=${InvestmentId}`;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getPositionDropDownDataByFundID(fundId,InvestmentId,PositionId){
    InvestmentId = InvestmentId == undefined ? 0 : InvestmentId;
    PositionId = PositionId == undefined ? 0 : PositionId;
    let url = "/Calculation/GetAllPosition_By_FundID_InvestmentID" + `?FundID=${fundId}` + `&InvestmentID=${InvestmentId}` + `&PositionID=${PositionId}`;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getInvestorGroupByFundId(fundId){
    let url = "/Calculation/getInvestorGroupByFundId" + `?FundID=${fundId}`;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  

  getCalcByFundIdCalcId(fundId,calcId,isDraftCalc){
    fundId = fundId == undefined ? 0 : fundId;
    calcId = calcId == undefined ? 0 : calcId;
    isDraftCalc = isDraftCalc == undefined ? 0 : isDraftCalc;
    let url = "/Calculation/GetByFundIdCalcId" + "?FundID="+fundId+"&CalcId="+calcId+"&IsDraftCalc="+isDraftCalc;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }


  GetChartData(data:CalcGraphModel):Observable<Result> {
    let url = "/Calculation/GetChartData";
    let myJson = JSON.stringify(data);
    return this.service.post(url, data).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    );
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}
