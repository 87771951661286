import { Injectable }                       from '@angular/core';
import { Observable,                
         tap }                              from 'rxjs';
import { Result }                           from 'src/app/shared/model/response.model';
import { DataService }                      from 'src/app/shared/services/data.service';
import { FundModel } from './funds.model';

@Injectable({
  providedIn: 'root'
})
export class FundsService {
  rows                            : any;
  constructor(private service: DataService) { }
// Post API 
public runPtrTransfer(transferId):Observable<Result> {
  let url='/Fund/runPtrTransfer';
  return this.service.post(url,transferId,true).pipe<Result>(tap((response:any) => {
    return response;
  }));
}
public revertPtrTransfer(transferId):Observable<Result> {
  let url='/Fund/revertPtrTransfer';
  return this.service.post(url,transferId,true).pipe<Result>(tap((response:any) => {
    return response;
  }));
}
}

