import { Menu } from './../menu.model';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  Router,
  NavigationEnd
} from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { MenuService } from "../menu.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { FundInformationService } from "src/app/pages/fund-information/fund-information.service";
import { FundsComponent } from "src/app/pages/fund-information/funds/funds.component";
import { AppComponent } from "src/app/app.component";
import { NotificationService } from "src/app/shared/services/notification.service";
import { LoginService } from 'src/app/pages/login/login.service';

@Component({
  selector: "app-horizontal-menu",
  templateUrl: "./horizontal-menu.component.html",
  styleUrls: ["./horizontal-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService, FundsComponent, AppComponent],
})
export class HorizontalMenuComponent implements OnInit {
  @Input("menuParentId") menuParentId;
  @Output() id = new EventEmitter<number>();
  @Output() onClickMenuItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public menuItems: Array<Menu>;
  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    public fundInformationService: FundInformationService,
    public router: Router,
    public NotificationService: NotificationService,
    public loginService: LoginService,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.HorizontalMenu();
    
    //filter menu based on permission
    const currentUser = this.loginService.currentUserValue;
    var menuItemspermission = [];
    if (currentUser && currentUser.permissions) {
      //this.menuItems = this.menuItems.map(function (element) { 
      for (let element of this.menuItems) {
        var vmenu = this.menuService.getVerticalMenuById(element.id);
        if(vmenu.menuItems.length > 0) {
          element.routerLink = vmenu.menuItems[0].routerLink;
        }
        
        element['allowed'] = element.title == 'Home' || element.title == 'Dashboard'||
                currentUser.permissions.formPermissions.findIndex(x => 
                x.formName == element.title && x.viewPermission == true) >= 0;

        //return element;
        menuItemspermission.push(element);
      }
      this.menuItems = menuItemspermission.filter(x => x['allowed'] == true);
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById("main-content");
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName("mat-drawer-content")[0].scrollTop = 0;
        }
      }
    });
  }

  onClick(menu: Menu) {
    document.getElementById("Fund Information").classList.remove("ActiveMenu");
    document.getElementById("Transactions").classList.remove("ActiveMenu")
    document.getElementById("Calculation Rule Library").classList.remove("ActiveMenu");
    document.getElementById("Administration").classList.remove("ActiveMenu")
    this.onClickMenuItem.emit(menu);
  }
}
