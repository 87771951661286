import { StorageName } from "src/app/shared/common/enum";
import { AgGridI, ColDefsFundCommitments } from "src/app/shared/component/ag-grid/ag-grid.interface";
import { FundInvestors } from "../funds/funds.model";
export class InvestorModel
{
    investor                                : Investor = new Investor();
    fundInvestors                            : FundInvestors[]=[];
}
export class Investor {
    id                      : number;
    createdAt               : string;
    updatedAt               : string;
    isDeleted               : boolean;
    deletedAt               : string;
    investorName            : string;
    investorFamily          : string;
    taxID                   : string;
    investorGroupId         : number;
    investorClassificationId: number;
    investorExternalId      : string;
    active                  : boolean;
    investorComments        : string;
}


  export const agGridDataModelFundInvestor: AgGridI = {
    colDefs: ColDefsFundCommitments,
    rowSelection: "multiple",
    gridName: StorageName.FUNDADMIN_INVESTORS_GRID,
    controllerName: StorageName.FUNDADMIN_INVESTORS_CONTROLLER,
    storageName: StorageName.FUNDADMIN_INVESTORS_COLUMNCHANGED,
    paginationStore: StorageName.FUNDADMIN_INVESTORS_PAGINATION,
    initialRows: StorageName.FUNDADMIN_INVESTORS_INITIALROWS,
    filterStorageName: StorageName.FUNDADMIN_INVESTORS_FILTER,
    showDeleteStatusStorageName : StorageName.FUNDADMIN_INVESTORS_SHOW_DELETED,
    tabIndex: 1
  };
