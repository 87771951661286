import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../shared.module';
import { IconsComponent } from './icons.component';
@NgModule({
  imports: [
    MatTooltipModule,
    CommonModule,
    FormsModule,
  ],
  declarations: [
    IconsComponent,
  ],
  providers: []
})
export class IconModule { }