import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  DatatableComponent,
  SelectionType
} from '@swimlane/ngx-datatable';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { CommonFilter, CommonFilterModel, FilterModel } from 'src/app/shared/model/response.model';
import { ResetFilterCalcRuleService } from '../reset-filter-calc-rule.service'
import { StorageService } from 'src/app/shared/services/storage.service';
import { ColDef, GridApi } from 'ag-grid-community';
import { ICustomHeaderParams } from 'src/app/shared/component/custome-header/custome-header.interface';
import { CustomeHeaderComponent } from 'src/app/shared/component/custome-header/custome-header.component';
import { FilterService } from 'src/app/shared/component/filter/filter.service';

@Component({
  selector: 'app-reset-filter-calc-rule',
  templateUrl: './reset-filter-calc-rule.component.html',
  styleUrls: ['./reset-filter-calc-rule.component.scss']
})
export class ResetFilterCalcRuleComponent implements OnInit {
  components: {
    [p: string]: any;
  } = {
      agColumnHeader: CustomeHeaderComponent,
    };
  public columnDefs: ColDef[] = [
    {
      field: '',
      width:40,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'name',
      width:385,
      headerComponentParams:{
        displayName:this.rowsDataSource.colName,
        popUpfloatingFilter:true,
      }as ICustomHeaderParams
   },
  ];
  public rowSelection = 'multiple';
  public rowsData:FilterModel[];
  selected = [];
  gridApi:GridApi;
  onFilterCompleted = new EventEmitter();
  // new Changes
  @Input() column: string = "";
  @Input() filteredData: CommonFilterModel[] = [];
  @Output() onFilter = new EventEmitter<CommonFilter>();
  // new Changes
  @ViewChild(DatatableComponent) table: DatatableComponent;
  filterTitle: string;
  // columnHeader : string;
  isvalue: boolean;
  columnData: string = "";
  isFilterIconColor: boolean;
  constructor(
    public appSettings: AppSettings,
    public storageservice: StorageService,
    public ResetFilterCalcRuleService: ResetFilterCalcRuleService,
    public filterService: FilterService,
    public dialogRef: MatDialogRef<ResetFilterCalcRuleComponent>,
    @Inject(MAT_DIALOG_DATA)
    public rowsDataSource: any,
  ) { 
  }
  rows: any[] = [];
  // filteredData      : CommonFilterModel[]=[]
  rowsModel = new FilterModel();
  selectedDate:any;
  showDatepicker: boolean = false;
  temp: FilterModel[] = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  isDateFilterShow: boolean = false;
  public settings: Settings;
  editing = {};
  
  SelectionType = SelectionType;

  ngOnInit(): void {
    this.filterService.emitHeaderInputValue.subscribe(res=>{
      this.columnData = res;
      this.filterByAll();
    })
    
    let nullEmptyObject = new FilterModel();
    nullEmptyObject.id = '-1';
    nullEmptyObject.name = 'Null/(empty)';
    this.rowsData = this.rowsDataSource.data.sort((a,b)=> 0 - (a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1));    
    this.rowsData.unshift(nullEmptyObject);
    this.filterTitle = this.rowsDataSource.colName + ' Filter';
    this.isDateFilterShow = this.rowsDataSource.isDateFilterShow;
  }
  onGridReady(params) {
    var getStorageData = this.storageservice.retrieve(this.rowsDataSource.filterStorageName)
    var getSelectedFilterData = getStorageData?.filter(col=>col.name == this.rowsDataSource.valueColumnName)
    this.gridApi = params.api;
    if(getSelectedFilterData != undefined)
    getSelectedFilterData[0]?.FilterData?.forEach((element)=>{
      let index = this.rowsData.findIndex(row=>row.id == element.id && row.name == element.name)
      this.gridApi.getRowNode(index.toString()).selectThisNode(true);
    })
  }
  onClearFilter() {
    this.gridApi.deselectAll();
    var getStorageData = this.storageservice.retrieve(this.rowsDataSource.filterStorageName);
    var index = getStorageData?.findIndex(col=>col.name == this.rowsDataSource.valueColumnName);
    getStorageData.splice(index,1);
    this.storageservice.store(this.rowsDataSource.filterStorageName,getStorageData);
    this.filter();
  }
  onResetDateFilter(){
    var getStorageData = this.storageservice.retrieve(this.rowsDataSource.filterStorageName);
    var index = getStorageData?.findIndex(col=>col.name == this.rowsDataSource.valueColumnName);
    getStorageData.splice(index,1);
    this.storageservice.store(this.rowsDataSource.filterStorageName,getStorageData);
    this.selectedDate = undefined;
    this.filter();
  }
  filter() {
    var filterdate ="";
    
    if(this.selectedDate != undefined){
        const date = this.selectedDate._i.date;
        const month = this.selectedDate._i.month + 1; // Add 1 since the month value is zero-based
        const year = this.selectedDate._i.year;
        console.log(this.selectedDate);
        filterdate = month.toString()+"/"+date.toString()+"/" + year.toString()
      }
        var commonfilter = new CommonFilter();
        commonfilter.textFilterValue =filterdate;
        this.selected = this.gridApi!=undefined? this.gridApi.getSelectedRows() : [];
        commonfilter.tableFilterValues =this.selected;
        if(this.selected.length == this.rowsData.length){          
          this.onClearFilter();
        }
        else{
          this.onFilter.emit(commonfilter);
        }

        this.dialogRef.close();        
  }
  filterByAll() {
    let rowsData: FilterModel[] = this.rowsDataSource.data;
    if (this.columnData?.length > 0) {
      var searchTextRegx = "^" + this.columnData.toLowerCase() + "$";
      var searchText = searchTextRegx.substring(0,searchTextRegx.length-1);
      rowsData = this.rowsDataSource.data.filter(a => a.name?.toLowerCase().match(searchText));
      this.rowsData = [];
      this.rowsData = rowsData;
    }
    else {
      this.rowsData = [];
      this.rowsData = rowsData;
    }
  }

  onSelect(event){


  }
}