import { Subscription } from 'rxjs';
import { Component, EventEmitter, Inject, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/shared/services/global.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CalculationModel } from '../calculation.model';
import { CalculationService } from '../calculation.service';
import { DropdownListModel } from '../../fund-information/fund-information.model';
import { StorageName } from 'src/app/shared/common/enum';

@Component({
  selector: 'app-serch-filtermodal',
  templateUrl: './serch-filtermodal.component.html',
  styleUrls: ['./serch-filtermodal.component.scss']
})
export class SerchFiltermodalComponent implements OnInit, OnDestroy {
  searchCalcList = new CalculationModel();
  dropdownListModel = new DropdownListModel();
  //filterData = new EventEmitter()
  @Output() filterData: EventEmitter<any> = new EventEmitter();

  private _getAllRecordsByIdSubscription!: Subscription;

  constructor(
    public calculationService: CalculationService,
    public notificationService: NotificationService,
    public globalService: GlobalService,
    public storageService: StorageService,
    public dialogRef: MatDialogRef<SerchFiltermodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getAlldropdownAPI();
     this.searchCalcList = this.storageService.retrieve('searchCalcList');
     if(this.searchCalcList == undefined)
      this.searchCalcList = new CalculationModel();
  }

  private getAlldropdownAPI() {
    this.dropdownListModel.calcTypeDropdown = this.storageService.retrieve(StorageName.CALCULATION_TYPE_DROPDOWN);
    this.dropdownListModel.statusDropdown = this.storageService.retrieve(StorageName.STATUS_GRID_DROPDOWN);
    this.dropdownListModel.calculationStateDropdown = this.storageService.retrieve(StorageName.CALCULATION_STATE_DROPDOWN);
  }
  
  OnReset() {
    this.searchCalcList = new CalculationModel();
    this.storageService.store('searchCalcList', this.searchCalcList);
  }

  OnFilter() {
    var searchFilterModeldata = this.storageService.retrieve('searchCalcList');
    if (JSON.stringify(this.searchCalcList) != JSON.stringify(searchFilterModeldata)) {
      this.storageService.store('searchCalcList', this.searchCalcList)
    }
    this._getAllRecordsByIdSubscription = this.calculationService.getCalcListFromSearch(this.searchCalcList).subscribe(result => {
      if (result.isSuccess)
        this.dialogRef.close(this.searchCalcList);
      this.globalService.deactivateLoader();
    })

  }

  ngOnDestroy(): void {
    if (this._getAllRecordsByIdSubscription) 
      this._getAllRecordsByIdSubscription.unsubscribe();
    
  }
}
