import { Select2Data } from "ng-select2-component";
import { PaginationModel } from "src/app/pages/transactions/transactions.model";
import { DropdownModel } from "../../model/common.model";
import { AgGridI } from "../ag-grid/ag-grid.interface";
export class PageHeaderImportFormModel {
    controllerName                  :   string;
    agGridI                         :   AgGridI;
    gridApi                         :   any;
    importTypes                     :   DropdownModel[];
    isNew                           :   boolean;
    isOverride                      :   boolean;
    isAppend                        :   boolean;
    showImportTypeForm              :   number      =   1;
    showIsOverride                  :   boolean     =   true;
    canModify                       :   boolean     =   false;
}
export class sheetNameList
{
    name : string
}
export class ImportFileModel 
{
    fileDataArray: string[];
    fileData: string;
    isOverride: boolean = false;
    isNew: boolean = false;
    isAppend: boolean = true;
    fileType: number;
    importMethod: number;
    delete : boolean = true;
    importUserID : string;
}
