import { LicenseManager } from 'ag-grid-enterprise';
import {
  Component,
  OnInit,
  Input,
  Renderer2,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import {
  ColDef,
  ColGroupDef,
  SideBarDef,
  ExcelStyle,
  CellClassParams,
  ValueFormatterFunc,
  SuppressKeyboardEventParams,
  ColumnApi,
  GridApi,
  EditableCallbackParams,
} from "ag-grid-enterprise";
import { Subscription, throwError } from "rxjs";
import "ag-grid-enterprise";
import { StorageService } from "../../services/storage.service";
import { CellEditorComponent } from "../cell-editor/cell-editor.component";
import { CustomeHeaderComponent } from "../custome-header/custome-header.component";
import { ICustomHeaderParams } from "../custome-header/custome-header.interface";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { IconsComponent } from "../actions/icons.component";
import { sort } from "../sort/sort.model";
import { SortService } from "../sort/sort.service";
import { AgColDefsI, AgGridI, defaultColDef } from "./ag-grid.interface";
import { AgGridService } from "./ag-grid.service";
import { CustomeHeaderService } from "../custome-header/custome-header.service";
import { CommonFilterModel } from "../../model/response.model";
import { DatepickerComponent } from "../datepicker/datepicker.component";
import { DatepickerEditorComponent } from "../datepicker-editor/datepicker-editor.component";
import { NotificationService } from "../../services/notification.service";
import { RunComponent } from "../run/run.component";
import { CheckboxRendererComponent } from "../checkbox-renderer/checkbox-renderer.component";
import { NumericValueEditorComponent } from "../numeric-value-editor/numeric-value-editor.component";
import { NumericeditorComponent } from "../numericeditor/numericeditor.component";
import { StorageName } from "../../common/enum";
import { defaultPageLimit, Pagination } from "../../model/pagination.model";
import { CellEditorTransactionComponent } from "../cell-editor-transaction/cell-editor-transaction.component";
import { FundsService } from "src/app/pages/fund-information/funds/funds.service";
import { Router } from "@angular/router";
import { FirstDataRenderedEvent, RowSelectedEvent } from 'ag-grid-community';
import { CustomTooltip } from '../custom-tooltip/custom-tooltip.component';
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-041068 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Sedna Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Northstar )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Northstar )_need_to_be_licensed___( Northstar )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 May 2024 )____[v2]_MTcxNTY0MTIwMDAwMA==0e95b5c4ab27ac8cc8adf26b0600a21c');
declare var window: any;
@Component({
  selector: "app-ag-grid",
  templateUrl: "./ag-grid.component.html",
  styleUrls: ["./ag-grid.component.scss"],
})
export class AgGridComponent implements OnInit, OnDestroy {
  @Input() dataModel!: AgGridI;
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() onAddrow: EventEmitter<any> = new EventEmitter();
  @Output() changedData: EventEmitter<any> = new EventEmitter();
  @Output() newAddedData: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() onPagination: EventEmitter<Pagination> = new EventEmitter();
  @Output() onPageSizeUpdated: EventEmitter<number> = new EventEmitter();
  // @Output() onChangeCellValue: EventEmitter<any> = new EventEmitter();
  eventOccurs: EventEmitter<{ eventName: string; payload?: string }> =
    new EventEmitter();

  public tooltipShowDelay = 0;
  public tooltipHideDelay = 10000;
  rows: any[] = [];
  agColDefs: (ColDef | ColGroupDef)[] = [];
  defaultColDef: ColDef;
  columnTypes: {
    [key: string]: ColDef;
  };
  rowSelection: "single" | "multiple" = "multiple";
  rowStyle = "redColor";
  components: {
    [p: string]: any;
  } = {
      agColumnHeader: CustomeHeaderComponent,
    };
  // cold component properties
  selected: any[] = [];
  copiedRow: any;
  isMultipleCopied: boolean = false;
  pastedRows: any[] = [];
  deletedRows: any[] = [];
  calculationFooter = false;
  doChange = true;
  pagnationUpdated = false;
  // For Pagination
  pagination = new Pagination();
  // pagination.pageSize: number = defaultPageLimit;
  goToPageNumber: number;
  isBtnDisabled: boolean;
  public readonly pageLimitOptions = [
    { value: 25 },
    { value: 50 },
    { value: 75 },
  ];
  // getInvestorFilterListModel: any;
  // columnName: any;
  // exportService: any;
  public excelStyles: ExcelStyle[] = [
    {
      id: "header",
      alignment: {
        vertical: "Center",
      },
      interior: {
        color: "#3b5998",
        pattern: "Solid",
        patternColor: "#dfe3ee",
      },
      font: {
        fontName: "Calibri",
        bold: true,
        color: "#FFFFFF",
        size : 9.5
      },
      // borders: {
      //   borderBottom: {
      //     color: "#f44336",
      //     lineStyle: "Continuous",
      //     weight: 1,
      //   },
      // },
    },
    {
      id: 'cell',
      alignment: {
        vertical: 'Center',
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    // {
    //   id: "cell",
    //   alignment: {
    //     vertical: "Center",
    //     horizontal: "Left"
    //   }
    // },
    {
      id: "skyBlueBackground",
      interior: {
        color: "#e8f3ff",
        pattern: "Solid",
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: "leftAlignment",
      alignment: {
        horizontal: "Left",
        vertical: "Bottom",
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: "rightAlignment",
      alignment: {
        horizontal: "Right",
        vertical: "Bottom",
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: 'negativeInBrackets',
      numberFormat: {
        format: '#,##0.00; (#,##0.00)',
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: 'negativeInBrackets6Digits',
      numberFormat: {
        format: '#,##0.000000; (#,##0.000000)',
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },

    {
      id: 'blueBackground',
      interior: {
        color: '#3b5998',
        pattern: 'Solid',
      },
      font: {
        fontName: "Calibri",
        bold: true,
        color: "#FFFFFF",
        size : 9.5
      },
    },
    {
      id: 'purpleRow',
      // interior: {
      //   color: 'purple',
      //   pattern: 'Solid',
      // },
      font: {
        fontName: "Calibri",
        bold: true,
        color: "#800080",
        size : 9.5
      },
    },



    // addPercentSymbol(params) {
    //   const formattedValue = params.value + '%';
    
    //   if (params.node.rowPinned || params.node.group) {
    //     return formattedValue;
    //   } else {
    //     // Format the value as a number with the percent symbol
    //     return { value: formattedValue, style: { numberFormat: '#,##0.00%' } };
    //   }
    // }

     {
      id: 'percentFormat',
      numberFormat: {
        format: '#,##0.00%',
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: 'percentFormatWith4Dec',
      numberFormat: {
        format: '#,##0.0000%',
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: 'currencyFormat',
      dataType : 'Number',
      numberFormat: {
        format: '#,##0.00',
      },
      alignment: {
        horizontal: "Right",
        vertical: "Bottom",
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },
    {
      id: 'ExcelDate',
      dataType : 'DateTime',
      numberFormat: { format: "yyyy-mm-dd" },
      alignment: {
        horizontal: "Right",
        vertical: "Bottom",
      },
      font: {
        fontName: "Calibri",
        color:"black",
        size : 9.5
      },
    },

    
    // {
    //   id: 'negativeInBrackets',
    //   numberFormat: {
    //     format: '$[blue] #,##0;$ [red](#,##0)',
    //   },
    // },
    // {
    //   id: 'booleanType',
    //   dataType: 'Boolean',
    // },
    // {
    //   id: 'stringType',
    //   dataType: 'String',
    // },
    // {
    //   id: 'dateType',
    //   dataType: 'DateTime',
    // },
    // {
    //   id: 'percentType',
    //   numberFormat: {
    //     format: '0%',
    //   },
    // },
  ];
  sortList: sort[] = [];

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          //suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          //suppressColumnExpandAll: true,
        },
      },
    ],
    defaultToolPanel: "columns",
  };

  frameworkComponents;
  totalPrefRate = 0;
  filterData: CommonFilterModel[] = [];
  tabsRowData: any = {};
  UDFList: any[];

  private _sortListSubscription!: Subscription;
  private _eventOccursSubscription!: Subscription;
  private _isEditedSunscription!: Subscription;
  private _allRowsSubscription!: Subscription;
  private _addRowsSubscription!: Subscription;
  private _resetFilterDataSubscription!: Subscription;

  constructor(
    renderer2: Renderer2,
    public agGridService: AgGridService,
    private sortService: SortService,
    public storageService: StorageService,
    public customeHeaderService: CustomeHeaderService,
    public fundsService: FundsService,
    public notificationService: NotificationService,
    private router: Router
  ) {
    this.UDFList = this.storageService.retrieve("UDFLIST");
    // this.UDFList = [{nStarUDFName:"transTxt1",nStarTable: "Transactions",udfFieldName:"Pranay"}]
    this._sortListSubscription = this.sortService.sortList$.subscribe(
      (data) => {
        this.sortList = data;
      }
    );

    this._eventOccursSubscription = this.eventOccurs.subscribe(($event) => {
      this.customEvent($event);
    });
    this.frameworkComponents = {
      dropdown: CellEditorComponent,
      dropdownRenderer: DropdownComponent,
      datePickerEditor: DatepickerEditorComponent,
      datePickerRenderer: DatepickerComponent,
      checkboxRenderer: CheckboxRendererComponent,
      numericRenderer: NumericValueEditorComponent,
    };
  }
  ngOnInit(): void {
    this._isEditedSunscription = this.agGridService.isEdited.subscribe((res) => {
      if (res == true) {
        let dmcolDefs: any = [...this.agColDefs];
        for (let i = 0; i < dmcolDefs.length; i++) {
          dmcolDefs[i].editable = true
        }
        this.agColDefs = dmcolDefs
      }
      if (res == false) {
        let dmcolDefs: any = [...this.agColDefs];
        for (let i = 0; i < dmcolDefs.length; i++) {
          dmcolDefs[i].editable = false
        }
        this.agColDefs = dmcolDefs
      }
    })

    this._allRowsSubscription = this.agGridService.allRows.subscribe((pagination) => {
      if (this.dataModel.serversidepagination) {
        this.pagination.page = this.pagination.page;
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.totalPages = pagination.totalPages;
        this.pagination.count = pagination.count;
        this.pagination.fromRecordNumber = ((this.pagination.page - 1) * this.pagination.pageSize) + 1;
        var lastRow = (this.pagination.fromRecordNumber - 1) + this.pagination.pageSize;
        this.pagination.toRecordNumber = (lastRow < this.pagination.count) ? (lastRow) : this.pagination.count;
        // this.pagination.fromRecordNumber = this.pagination.toRecordNumber ==0 ? 0 : this.pagination.fromRecordNumber;
        this.pagination.totalPages = this.pagination.totalPages == 0 ? this.pagination.page : this.pagination.totalPages;
      }
      // this.pagination.count = rows.rowData?.length;
      // if (!this.dataModel.serversidepagination)
      //   this.pagination.count = this.rows?.length;
      // else this.pagination.count = this.rows?.length > 0 ? this.rows[0].total_rows : 0;
      // this.pagnationUpdated = true;
    });
    this._addRowsSubscription = this.agGridService.addRows.subscribe((row) => {
      this.pagination.count = this.pagination.count + 1;
      this.pagnationUpdated = true;
     // this.addRow(row);
    });
    this._resetFilterDataSubscription = this.agGridService.resetFilterData.subscribe((rows) => {
      this.resetFilter();
      this.rows = rows;
      this.changedData.emit(rows);
      this.gridApi.setRowData(this.rows);
    });
    // fOR TAB ROWS DATA
    // this.agGridService.rowsChanged.subscribe((data) => {
    //   this.rows = data;
    //   if (!this.dataModel.serversidepagination)
    //     this.pagination.count = data?.length;
    //   else this.pagination.count = data?.length > 0 ? data[0].total_rows : 0;
    //   this.pagnationUpdated = true;
    // });

    // fOR TAB ROWS DATA
    // this.agGridService.sortChangeEmitter.subscribe((sortedrows) => {
    //   this.rows = sortedrows;
    // })
    // this.tierTransactionsService.getExtraColumn().subscribe((data) => {
    //   if (data.isSuccess && data.response) {
    //     this.UDFList = data.response as UDFModel[];
    //   }
    // });
    this.pagination.pageSize = this.pagination.pageSize == undefined ? defaultPageLimit : this.pagination.pageSize;
    this.bindColumnsToAgGrid();
  }
  bindColumnsToAgGrid(colDefss: AgColDefsI[] = []) {
    if (this.dataModel.colDefs) {
      const colDefs = [...this.dataModel.colDefs];
      this.agColDefs = [];
      colDefs.forEach((element) => {
        const coldef = this.assignValues(element);
        this.agColDefs.push(coldef);
      });
    }
    // this.defaultColDef.headerComponentParams.headerCellRendererParams = {
    //   style: { whiteSpace: 'normal' },
    // };
    if (this.dataModel.columnTypes) {
      this.columnTypes = this.dataModel.columnTypes;
    }
    if (this.dataModel.defaultColDef) {
      this.defaultColDef = this.dataModel.defaultColDef;
    }
    else {
      this.defaultColDef = new defaultColDef();
    }

    this.defaultColDef.wrapHeaderText =true,
    // this.defaultColDef.headerhe= true,
    // this.headerhe
    this.defaultColDef.cellClassRules = {
      skyBlueBackground: function (params: CellClassParams) {
        return (params.node.rowIndex || 0) % 2 !== 0;
      },
    };

    this.defaultColDef.tooltipComponent = CustomTooltip;

    this.defaultColDef.cellClass = params => {
      return params.data.isDeleted === true && params.colDef.field != '' && params.colDef.field !=  'actions' ? 'deletedRow' : '';
     }


     
 

     var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);

     this.defaultColDef.editable =  (params: EditableCallbackParams<any>) => {
      return formPermission.modifyPermission && (params.data.isDeleted === false && params.colDef.field != '' && params.colDef.field != 'actions')
    }

    

    this.defaultColDef.cellStyle =  (params: CellClassParams<any>) => {
      if (params.data.isDeleted === true) {
        return { color: 'deletedRow' };
      }
    }
        this.defaultColDef.editable = (params: EditableCallbackParams<any>) => {
          return  formPermission.modifyPermission && params.data.statusId !== 4  && params.data.isDeleted == false ;
        };
        this.defaultColDef.cellStyle= (params: CellClassParams<any>) => {
          if ((params.data.isDeleted === true)) {
            return { color: '#f98a9c' };
          }
        }
        this.defaultColDef.singleClickEdit = false;
    // this.defaultColDef.comparator = this.customComparator
    // this.defaultColDef.autoHeight = true;
  }

  customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  

   dateComparator(date1: string, date2: string) {
    if (date1 === undefined || date1 === null || date1.length !== 10) {
      return null;
    }
    var yearNumber = Number.parseInt(date1.substring(6, 10));
    var monthNumber = Number.parseInt(date1.substring(3, 5));
    var dayNumber = Number.parseInt(date1.substring(0, 2));
    var date1Number = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    //var date1Number = this.agGridService.monthToComparableNumber(date1);


    if (date2 === undefined || date2 === null || date2.length !== 10) {
      return null;
    }
    var yearNumber2 = Number.parseInt(date2.substring(6, 10));
    var monthNumber2 = Number.parseInt(date2.substring(3, 5));
    var dayNumber2 = Number.parseInt(date2.substring(0, 2));
    var date2Number = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
    //var date2Number = this.agGridService.monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
  assignValues(element) {
    const coldef: ColDef | ColGroupDef = {};
    coldef.suppressKeyboardEvent = this.suppressNavigation;
    coldef.field = element.field;
    coldef.width = element.width;
    coldef.minWidth = element.minWidth;
    coldef.filter = false;
    coldef.filter = false;
    coldef.cellClassRules = element.cellClassRules;
    coldef.aggFunc = element.aggFunc;
    coldef.cellClass = element.cellClass;
    if(element.cellRenderer=="datePickerRenderer")
    {
    coldef.comparator = this.dateComparator;
    }
    if(element.valueFormatter == "currencyFormat")
    {
      coldef.cellClass = "negativeInBrackets";
    }
    if(element.valueFormatter == "currencyFormatWith6Dec")
    {
      coldef.cellClass = "negativeInBrackets6Digits";
    }
      if(element.valueFormatter == "percentFormat")
    {
      coldef.cellClass = "percentFormat";
    }
    if(element.valueFormatter == "percentFormatWith4Dec")
    {
      coldef.cellClass = "percentFormatWith4Dec";
    }
    var displayName = element.displayName;
    if (element.isUDFField)
      displayName = this.UDFList?.filter(el => el.nStarUDFName.toLowerCase() == displayName.toLowerCase() && el.nStarTable == 'Transactions')[0]?.udfFieldName ?? element.displayName;
    coldef.headerName = displayName;
    coldef.suppressMenu = false;
    coldef.lockPosition = element.lockPosition;
    coldef.lockPinned = element.lockPinned;
    if (element.hide != undefined) coldef.hide = element.hide;
    if (element.isUDFField) {
      coldef.hide = !(this.UDFList?.filter(el => el.nStarUDFName.toLowerCase() == element.displayName.toLowerCase() && el.nStarTable == 'Transactions')[0]?.active ?? true);
    }
    var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    if (element.editable != undefined) coldef.editable = element.editable ;
    if(element.editable == true) coldef.editable  = formPermission.modifyPermission ;
    if (element.sortable != undefined) coldef.sortable = element.sortable;
    if (element.headerCheckboxSelection != undefined)
      coldef.headerCheckboxSelection = element.headerCheckboxSelection;
    if (element.checkboxSelection != undefined)
      coldef.checkboxSelection = element.checkboxSelection;
    if (element.type != undefined) coldef.type = element.type;
    if (element.cellType != undefined) {
      coldef.cellRenderer = this.getCellComponent(element.cellType);
      coldef.cellEditor = this.getCellEditor(element.cellRenderer);
      coldef.cellRendererParams = element?.cellEditor?.cellRendererParams;
      coldef.cellRendererParams = { eventOccurs: this.eventOccurs, gridName: this.dataModel.gridName };
      if (coldef.cellRenderer == CheckboxRendererComponent) {
        coldef.cellRendererParams = this.dataModel.serversidepagination; // for checkbox check in transaction
      }
    }
    if(element.tooltipComponent != undefined)
    {
      coldef.tooltipComponent = this.getTooltipComponent();
      coldef.tooltipField = element.tooltipField;
      coldef.tooltipComponentParams = element;
    }
    coldef.cellEditor = this.getCellEditor(element.cellRenderer);
    if (element.valueFormatter)
      coldef.valueFormatter = this.getValueFormatter(element);
    if (element.field.toLowerCase().indexOf("date") != -1)
        coldef.valueFormatter = this.getValueFormatter(element);
      
    

    if (element.cellStyle)
      coldef.cellStyle = this.getCellStyle(element.cellStyle);
    // if (element.cellType=="dropdown") {
    //   // coldef.cellEditor = element.cellEditor.cellEditor;
    //   coldef.cellEditorParams = {
    //     values: this.setDropdownDatasource(coldef.field)
    //   //  Ids: element.cellEditor.cellEditorId,
    //   };
    // }
    coldef.headerComponentParams = {
      displayName: coldef.headerName, // add displayName
      columnName: coldef.field,
      gridName: this.dataModel.gridName,
      isSort: coldef.field && coldef.field != "actions" ? true : false,
      eventOccurs: this.eventOccurs,
      isRequired: element.isRequired, // add isRequired
      isFilter: element.isfilter,
      floatingFilter: element.floatingFilter,
      floatingDropDown: element.floatingDropDown,
      filterValueColumnName: element.filterValueColumnName,
      filterIdColumnName: element.filterIdColumnName,
      initialRows: this.dataModel.initialRows,
      filterStorageName: this.dataModel.filterStorageName,
      cellDisplayType: element.cellDisplayFormat,
      isDateFilterShow: element.isDateFilterShow,
      isServerSidepagination: this.dataModel.serversidepagination,
      controllerName: this.dataModel.controllerName

    } as ICustomHeaderParams;
    return coldef;
  }

  public gridApi !: GridApi<any>;
  private gridColumnApi!: ColumnApi;
  onGridReady(params) {
    this.clearPaginationElements();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridReady.emit(params);
    this.closeToolPanel();
    // For Column Save State
    var colState = this.storageService.retrieve(this.dataModel.storageName);
    // if (colState != undefined) {
    //   colState.forEach(col => {
    //     col.colId = this.UDFList?.filter(el => el.nStarUDFName.toLowerCase() == col.colId.toLowerCase() && el.nStarTable == 'Transactions')[0]?.udfFieldName ?? col.colId;
    //   })
    // }
    if (!colState) {
      return;
    }
    this.gridColumnApi.applyColumnState({
      state: colState,
      applyOrder: true,
    });

    // var value = (document.getElementById('page-size') as HTMLInputElement)
    // .value;
    // For Column Save State
  }
  clearPaginationElements() {
    this.pagination.count = 0;
    this.pagination.totalPages = 1;
    this.pagination.page = 1;
    this.pagination.pageSize = defaultPageLimit;
    // this.pagnationUpdated = true;
  }
  customEvent($event) {
    let row = $event.payload;
    // this.agGridService.setgridApi(row.api);
    // this.agGridService.setColumnApi(row.columnApi);
    switch ($event.eventName) {
      // case "copy":
      //   this.copyRow(row.data, row.rowIndex);
      //   break;
      // case "paste":
      //   this.pasteRow(row.rowIndex, row);
      //   break;
      // case "export":
      //   this.exportItems(this.dataModel.gridName, row.rowIndex);
      //   break;
      case "delete":
        this.onDelete(row);
        break;
      case "undo":
        this.onUndo(row);
        break;
      case "sort":
        this.dataModel.gridName;
        this.agGridService.allRows.next(row.rowData)
        this.agGridService.allRows.emit(row);
        break;
      case "hidesort":
        this.hideColumn($event);
        break;
      case "onfilter":
        this.onFilter.emit($event);
        break;
      case "addingRow":
        this.onAddrow.emit($event);
        break;
      case "run":
        this.onRun($event);
        break;
      case "reset-transfer":
        this.resetTransfer($event);
        break;
    }
  }
  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // var start =  parseInt((selectedPage - 1) * resultsPerPage);
    // var end = parseInt(selectedPage * resultsPerPage);

    if (this.dataModel.storageName == StorageName.USERS_USERCOLUMNCHANGED
      || this.dataModel.storageName == StorageName.USERS_USERROLECOLUMNCHANGED) {
      params.columnApi.setColumnVisible('id', false);
    }
    if (this.dataModel.storageName == StorageName.ROLES_ROLESCOLUMNCHANGED) {
      params.columnApi.setColumnVisible('id', false);
    }
  }
  redrawAllRows() {
    this.gridApi.redrawRows();
  }
  onSelectionChanged() {
    this.gridApi.redrawRows();
  }

  onRowSelected(event: RowSelectedEvent) {

    this.storageService.store(StorageName.CALCULATION_FUNDID, event.data.fundId);
    this.storageService.store(StorageName.CALCULATION_ID, event.data.calcId);
    const url = '/#/calculation';
    if (this.dataModel.gridName == StorageName.FUNDADMIN_FUNDS_CALCULATIONS_GRID && event.node.isSelected()) {
      window.open(url, '_blank')
    }
  }
  closeToolPanel() {
    this.gridApi.closeToolPanel();
  }
  resetFilter() {
    this.customeHeaderService.isResetFilterIcon.emit(true);
  }
  

  onDelete(row: any) {
    this.pagination.count = this.pagination.count - 1;
    let selectedRows = this.gridApi.getSelectedRows();
    //  row.isDeleted = true;
    this.rows[row.rowIndex].isDeleted = true;
    this.rows[row.rowIndex].isEdited = true;
    this.rows[row.rowIndex].isAdded = false;
    this.redrawAllRows();
    this.changedData.emit(this.rows);
    this.pagnationUpdated = true;

  }
  onUndo(row: any) {
    this.pagination.count = this.pagination.count + 1;
    this.rows[row.rowIndex].isDeleted = false;
    this.rows[row.rowIndex].isEdited = false;
    // this.rows[row.rowIndex].Deleted = false;
    this.redrawAllRows();
    this.pagnationUpdated = true;
  }

  onRun(event) {
    let result = this.fundsService.runPtrTransfer(
      event.payload.data?.ptrTransNameId
    );
    result.subscribe((res) => {
      if (res.isSuccess) {
        this.notificationService.sucesspopup(res.message);
      } else {
        this.notificationService.errorpopup(res.message);
      }
    });
  }
  resetTransfer(event) {
    let result = this.fundsService.revertPtrTransfer(
      event.payload.data?.ptrTransNameId
    );
    result.subscribe((res) => {
      if (res.isSuccess) {
        this.notificationService.sucesspopup(res.message);
      } else {
        this.notificationService.errorpopup(res.message);
      }
    });
  }
  getRowClass = (row) => {
    if (row.data.statusId == 4 && row.node._objectid != 0) {
      return "lockedRow";
    }
    else if(row.data.statusId == 1)
    {
      return "draftRow";
    }
    else if(row.data.isDraft == true)
    {
      return "draftRow";
    }
    else if(row.data.isSumRow == true)
    {
      return "sumRow";
    }
    else if(row.data.finalRow == true)
    {
      return "finalRow";
    }
    
    // else if (row.data.isPermanentDelete) {
    //   return "deletedRow";
    // }
    else if (row.data?.isCopiedRow && !row.data?.isPasteRow && !row.data?.isDeleted) {
      return "copiedRow";
    } else if (row.data?.isPasteRow && !row.data?.isDeleted) {
      return "pasteRowColor";
    }
   else if (
      row.node.selected &&
      !row.data.isDeleted &&
      !row.data.isPasteRow
    ) {
      return "checkedRow";
    } else if (row.data?.isAdded && !row.data?.isDeleted) {
      return "AddedRowColor";
    } else {
      return "";
    }
  };

  private handleError(error: any) {
    // this.toaster.error(error.error.message);
    return throwError(error);
  }
  //export


  //#region PaginationisBtnDisabled
  updateServersidePaginationData(totalRows, totalPages, page,pageSize) {
    this.pagination.page = page;
    this.pagination.pageSize = pageSize;
    this.pagination.totalPages = totalPages;
    this.pagination.count = totalRows;
    this.pagination.fromRecordNumber = ((this.pagination.page - 1) * this.pagination.pageSize) + 1;
    var lastRow = (this.pagination.fromRecordNumber - 1) + this.pagination.pageSize;
    this.pagination.toRecordNumber = (lastRow < this.pagination.count) ? (lastRow) : this.pagination.count;
    this.pagination.fromRecordNumber = this.pagination.toRecordNumber == 0 ? 0 : this.pagination.fromRecordNumber;
    this.pagination.totalPages = this.pagination.totalPages == 0 ? this.pagination.page : this.pagination.totalPages;
  }
  setInputValue() {
    if (this.goToPageNumber == 0 || this.goToPageNumber > this.pagination.totalPages)
      this.isBtnDisabled = true;
    else
      this.isBtnDisabled = false;
  }

  // updatePageSize(pageSize)
  // {
  //   this.gridApi.paginationSetPageSize(this.pagination.pageSize)
  // }

  onPageSizeChanged(index) {
    if (this.dataModel.serversidepagination)
      this.onPagination.emit(this.pagination)
    else
    {
      this.gridApi.paginationSetPageSize(index);
      this.onPageSizeUpdated.emit(this.pagination.pageSize)
      //this.updatePageSize(this.pagination.pageSize)
    }
  }
  onBtFirst() {
    if (this.dataModel.serversidepagination) {
      this.pagination.page = 1;
      this.onPagination.emit(this.pagination)
    }
    else
      this.gridApi.paginationGoToFirstPage();
  }
  onBtLast() {
    if (this.dataModel.serversidepagination) {
      this.pagination.page = this.pagination.totalPages;
      this.onPagination.emit(this.pagination)
    }
    else
      this.gridApi.paginationGoToLastPage();
  }
  onBtNext() {
    if (this.dataModel.serversidepagination) {
      if (this.pagination.page < this.pagination.totalPages) {
        this.pagination.page++;
        this.onPagination.emit(this.pagination)
      }
      else
        this.notificationService.warningpopup("You reached last page");
    }
    else
      this.gridApi.paginationGoToNextPage();
  }
  onBtPrevious() {
    if (this.dataModel.serversidepagination) {
      if (this.pagination.page > 1) {
        this.pagination.page--;
        this.onPagination.emit(this.pagination)
      }
      else
        this.notificationService.warningpopup("You reached first page");
    }
    else
      this.gridApi.paginationGoToPreviousPage();
  }
  onPaginationChanged(event) {
    if (!this.dataModel.serversidepagination) {
      this.pagination.page = event.api.paginationGetCurrentPage() + 1;
      this.pagination.pageSize = event.api.paginationGetPageSize();
      this.pagination.totalPages = event.api.paginationGetTotalPages();
      this.pagination.count = event.api.paginationGetRowCount();
      this.pagination.fromRecordNumber = (event.api.paginationGetCurrentPage() * this.pagination.pageSize) + 1;
      var lastRow = (this.pagination.fromRecordNumber - 1) + this.pagination.pageSize;
      this.pagination.toRecordNumber = (lastRow < this.pagination.count) ? (lastRow) : this.pagination.count;
      this.pagination.fromRecordNumber = this.pagination.toRecordNumber == 0 ? 0 : this.pagination.fromRecordNumber;
      this.pagination.totalPages = this.pagination.totalPages == 0 ? this.pagination.page : this.pagination.totalPages;
    }
  }
  goToPage() {
    if (this.dataModel.serversidepagination) {
      if (this.goToPageNumber <= this.pagination.totalPages) {
        this.pagination.page = this.goToPageNumber;
        this.onPagination.emit(this.pagination)
      }
      else
        this.notificationService.warningpopup("Page number does not exist");
    }
    else
      this.gridApi.paginationGoToPage(this.goToPageNumber - 1);
  }
  //#endregion Pagination
  private getValueFormatter(element: AgColDefsI): ValueFormatterFunc {
      if (element?.valueFormatter == "currencyFormat") {
        return (params) =>
          this.agGridService?.currencyFormatter(params?.data[element?.field],"",2);
      }
      else if (element?.valueFormatter == "currencyFormatWith6Dec") {
        return (params) =>
          this.agGridService?.currencyFormatter(params?.data[element?.field],"",6);
          } else if (element?.valueFormatter == "percentFormat") {
            return (params) =>
              this.agGridService.percentColumnFormatter(
                params?.data[element?.field],
                "");}
            else if (element?.valueFormatter == "percentFormatWith4Dec") {
            return (params) =>
              this.agGridService.percentColumnFormatterWith4dec(
                params?.data[element?.field],
                "");}


                if (element.field.toLowerCase().indexOf("date") != -1) {
                  return (params) =>
                    this.agGridService.dateColumnFormatter(params);}
  }
  //  setLastButtonDisabled(disabled: boolean) {
  //     (document.querySelector('#btLast') as any).disabled = disabled;
  //   }
  //   setNextButtonDisabled(disabled: boolean) {
  //     (document.querySelector('#btNext') as any).disabled = disabled;
  //   }
  private getCellStyle(cellStyle) {
    switch (cellStyle) {
      case "text-align-center":
        return { "text-align": "center" };
      case "text-align-right":
        return { "text-align": "right" };
      default:
        return null;
    }
  }
  private getCellComponent(cellType) {
    switch (cellType) {
      case "icons":
        return IconsComponent;
      case "dropdown":
        return DropdownComponent;
      case "checkbox":
        return CheckboxRendererComponent;
      case "datePickerEditor":
        return DatepickerComponent;
      case "run":
        return RunComponent;
      case "number":
        return NumericeditorComponent;
      default:
        return null;
    }
  }
  private getCellEditor(cellType) {
    switch (cellType) {
      case "dropdownRenderer":
        return CellEditorComponent;
      case "transactionDropdownRenderer":
        return CellEditorTransactionComponent;
      case "datePickerRenderer":
        return DatepickerEditorComponent;
      case "checkboxRenderer":
        return CheckboxRendererComponent;
      case "numericRenderer":
        return NumericValueEditorComponent;
    }
  }
  private getTooltipComponent()
  {
    return CustomTooltip;
  }
  onColumnVisible(event) {
    window.colState = event.columnApi.getColumnState(); // For getting Column State
    this.storageService.store(this.dataModel.storageName, window.colState);
  }
  onColumnResized(event) {
    window.colState = event.columnApi.getColumnState(); // For getting Column State
    this.storageService.store(this.dataModel.storageName, window.colState);
  }
  columnMoved(event) {
    window.colState = event.columnApi.getColumnState(); // For getting Column State
    this.storageService.store(this.dataModel.storageName, window.colState);
  }


  suppressNavigation(params: SuppressKeyboardEventParams) {
    if (params.event.code == "Space") return true;
    if (params.event.code == "Tab") {
      //if (params.editing == true)
        params.api.tabToNextCell();
    }

    if (params.event.code == "ArrowRight")
    {
        var col = params.column;
        var id = col.getColId();
        var currentColIndex = params.columnApi.getAllDisplayedColumns().findIndex(col => col.getColId() === id);
        var rowIndex = params.node.rowIndex + 1;
        var allColumns = params.columnApi.getAllDisplayedColumns();
        var maxColIndex = allColumns.length -1;
        if(currentColIndex == maxColIndex)
        {
          var currentColumn = allColumns[0];
          var colId = currentColumn.getColId();
          params.api.setFocusedCell(rowIndex, colId);
        }
    }

    if (params.event.code == "ArrowLeft")
    {
        var col = params.column;
        var id = col.getColId();
        var currentColIndex = params.columnApi.getAllDisplayedColumns().findIndex(col => col.getColId() === id);
        var rowIndex = params.node.rowIndex -1;
        var allColumns = params.columnApi.getAllDisplayedColumns();
        var maxColIndex = allColumns.length -1;
        if(currentColIndex == 0)
        {
          var currentColumn = allColumns[maxColIndex];
          var colId = currentColumn.getColId();
          params.api.setFocusedCell(rowIndex, colId);
        }
    }

    // if (params.event.code == "ArrowRight" || params.event.code == "Tab" || params.event.code == "ArrowLeft") {
    
    //  // instanceId
    //  var col = params.column;
    // //  var colmn = this.getColumnIndexByColId( params.columnApi,);
    // //var  col : any = "";
    //  var id = col.getColId();
    //  var currentColIndex = params.columnApi.getAllDisplayedColumns().findIndex(col => col.getColId() === id);
    //  var maxColIndex = params.columnApi.getAllDisplayedColumns().length -1;
    //  var columntest = params.columnApi.getColumn(id);
    //  var coldef = columntest.getColDef();
    //  var headerParams = coldef.headerComponentParams;
    //  if (params.editing == true && headerParams.cellDisplayType == "textFilter")
    //  return false;
    //   //var currentColIndex = getColumnIndexByColId(params.columnApi,id);
    //   if(currentColIndex == maxColIndex)
    //     currentColIndex = 0;
    //   // sets focus into the first grid cell
    //   if (params.editing && params.colDef.cellEditor == DatepickerEditorComponent) 
    //     return true;
    //   else {
    //     var allColumns = params.columnApi.getAllDisplayedColumns();
    //    // var instanceId = params.column.getInstanceId();
    //     if (params.event.code == "ArrowLeft") {
    //       var rowIndex = params.node.rowIndex - 1;
    //       if(currentColIndex == 0)
    //         var currentColumn = allColumns[maxColIndex];
    //       else
    //         var currentColumn = allColumns[currentColIndex - 1];
    //     }
    //     else
    //     {
    //       var rowIndex = params.node.rowIndex + 1;
    //       if(currentColIndex == maxColIndex)
    //         var currentColumn = allColumns[0];
    //       else
    //         var currentColumn = allColumns[currentColIndex + 1];
    //     }
        
    //       var colId = currentColumn.getColId();
    //       params.api.setFocusedCell(rowIndex, colId);
    //   }
      
    //}
  }

  
  onCellValueChanged(event) {
    if(event.newValue != null)
    {
   var values = this.getCellDatasource(event.colDef.field,event.colDef.headerComponentParams.gridName);
   if( values != undefined && values.length != 0  && values != null && event.colDef.headerComponentParams.gridName != StorageName.TRANSACTION_LEVEL_GRID && event.colDef.headerComponentParams.gridName != StorageName.FUND_LEVEL_GRID)
   {
     var selectedItem =  values.filter(a=>a.name==event.newValue)[0];
    // this.value = selectedItem.name;
   
    if(event.oldValue != event.newValue)
    {
      var idColumn = event.colDef.headerComponentParams.filterIdColumnName;
      var valueColumn = event.colDef.headerComponentParams.filterValueColumnName;
      //event.data.isEdited = true;
      if (event.data.isAdded != true) {
        event.data.isEdited = true;
      } 
      if(selectedItem != undefined)
      {
      event.data[idColumn] = selectedItem.id;
      event.data[valueColumn] = selectedItem.name;
      }
     // this.storageService.store(event.colDef.headerComponentParams.initialRows,this.params.api.getModel().rowsToDisplay.map(a => a.data));
    }
  }
  else
  {
   
    event.data[event.colDef.field] =event.newValue;
   
      // this.storageService.store(event.colDef.headerComponentParams.initialRows,this.params.api.getModel().rowsToDisplay.map(a => a.data));
  
    if (event.data.isAdded != true) {
      event.data.isEdited = true;
    } 
  }
}
else
{
  event.data[event.colDef.field] = ((event.oldValue == true || event.oldValue == false) && event.newValue == null) ? event.oldValue : event.newValue;
}
this.storageService.store(event.colDef.headerComponentParams.initialRows,event.api.getModel().rowsToDisplay.map(a => a.data));

   // this.onAddrow.emit(event);
    // this.onChangeCellValue.emit(event);
  }

  public getCellDatasource(fieldName: string, gridName : any) : any[]
  {
    var dropdownData : any[] =[];
    switch(fieldName)
    {
      
        case "vehicleInvestorName": dropdownData =this.storageService.retrieve(StorageName.VEHICLE_INVESTOR_DROPDOWN);                      break;
        //case "leAssociation":       dropdownData =this.storageService.retrieve(StorageName.LE_ASSOCIATION_DROPDOWN);                      break;
        case "investorName":        dropdownData =this.storageService.retrieve(StorageName.INVESTOR_DROPDOWN);                              break;
        case "investmentName":      dropdownData =this.storageService.retrieve(StorageName.INVESTMENT_DROPDOWN);                            break;
        case "positionName":        dropdownData =this.storageService.retrieve(StorageName.POSITION_GRID_DROPDOWN);                         break;
        case "carryPtrDesignation": dropdownData =this.storageService.retrieve(StorageName.CARRY_PARTNER_DESIGNATION_LEVEL_GRID_DROPDOWN);  break;
        case "allocCalcLevel":      dropdownData =this.storageService.retrieve(StorageName.CALCULATION_LEVEL_GRID_DROPDOWN);                break;
        case "transferType":        dropdownData =this.storageService.retrieve(StorageName.TRANSFER_TYPE_GRID_DROPDOWN);                    break;
        case "status":   
              if(gridName == "FUNDADMIN_FUNDS_PTR_TRANSFER_GRID")           
                dropdownData =this.storageService.retrieve(StorageName.STATUS_GRID_DROPDOWN);   
              else
                dropdownData =this.storageService.retrieve(StorageName.STATUS_GRID_DROPDOWN);             
              break;
        case "leAssociationName":   dropdownData =this.storageService.retrieve(StorageName.FILTERFUND_DROPDOWN);                            break;
        case "allocate":            dropdownData =this.storageService.retrieve(StorageName.ALLOCATE_DROPDOWN);                              break;
        case "fundName":            dropdownData =this.storageService.retrieve(StorageName.FUND_DROPDOWN);                                  break;
        case "tierName":
        case "comparisonTierName":
        case "tier":                dropdownData =this.storageService.retrieve(StorageName.TIER_DROPDOWN);                                  break;
        case "TransSetForUploadName":dropdownData =this.storageService.retrieve(StorageName.NON_STSTEM_TIER_DROPDOWN);                       break;
        
        case "rollupLevelName":     dropdownData =this.storageService.retrieve(StorageName.ROLLUP_LEVEL_DROPDOWN);                          break;
        case "includeinBalanceName":dropdownData =this.storageService.retrieve(StorageName.INCLUDE_IN_BALANCE_DROPDOWN);                    break;
        case "roleName":            dropdownData =this.storageService.retrieve(StorageName.ROLE_DROPDOWN);                                  break;
        case "userName":            dropdownData =this.storageService.retrieve(StorageName.USER_GRID_DROPDOWN);                             break;
        case "hurdleRetLocation":   dropdownData =this.storageService.retrieve(StorageName.HURDLE_DROPDOWN);                                break;
        case "lpaSubActName":       dropdownData =this.storageService.retrieve(StorageName.SUB_ACT_DROPDOWN);                               break;
        case "filterField":         dropdownData =this.storageService.retrieve(StorageName.FILTER_FIELD_DROPDOWN);                          break;
        case "filterCriteria":      dropdownData =this.storageService.retrieve(StorageName.FILTER_CRITERIA_DROPDOWN);                       break;
        case "udfFieldName":        dropdownData =this.storageService.retrieve(StorageName.UDF_DROPDOWN);                                   break;
        case "subActName":          dropdownData =this.storageService.retrieve(StorageName.SUB_ACT_DROPDOWN);                               break;
        // case "operator":            dropdownData =this.storageService.retrieve(StorageName.OPERATOR_DROPDOWN);                              break;
        case "prefReturnRuleName":  dropdownData =this.storageService.retrieve(StorageName.PERFORMANCERULE_DROPDOWN);                       break;
    }
    
    // var blankval = {id: null , name: ''};
    // //adds one or more elements to the beginning of an array
    // dropdownData.unshift(blankval);
    
    return dropdownData;
    //return dropdownData;
  }

  setSelected(item){
   
  }

  hideColumn(event) {
    window.colState = event.payload.columnApi.setColumnsVisible(
      [event.payload.columnName],
      false
    );
    this.storageService.store(this.dataModel.storageName, window.colState);
  }
  onCellKeyPress(event) {
    if (event.event.code == "Space") {
      event.api.startEditingCell({
        rowIndex: event.rowIndex,
        colKey: event.column.getId(),
      });
    }
  }
  //#region SortEvents
  onSortChanged(e) {
  }
  onColumnPivotChanged(e) {
  }
  onColumnRowGroupChanged(e) {
  }
  onColumnValueChanged(e) {
  }
  onColumnMoved(e) {
  }
  onColumnPinned(e) {
  }
  //#endregion

  ngOnDestroy(): void {
    if (this._sortListSubscription) {
      this._sortListSubscription.unsubscribe();
    }
    if (this._eventOccursSubscription) {
      this._eventOccursSubscription.unsubscribe();
    }
    if (this._isEditedSunscription) {
      this._isEditedSunscription.unsubscribe();
    }
    if (this._allRowsSubscription) {
      this._allRowsSubscription.unsubscribe();
    }
    if (this._addRowsSubscription) {
      this._addRowsSubscription.unsubscribe();
    }
    if (this._resetFilterDataSubscription) {
      this._resetFilterDataSubscription.unsubscribe();
    }
  }
}

