import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectDropdownComponent } from './multiselect-dropdown.component';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { NgMultiselectDropdownModule } from './ng-multiselect-dropdown/ng-multiselect-dropdown.module';

@NgModule({
  declarations: [MultiselectDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // SharedModule,
    NgMultiselectDropdownModule
  ],
  exports: [MultiselectDropdownComponent]
})
export class MultiselectDropdownModule { }