import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  searchUserForm: FormGroup;
  
  userTypeFilters = [
    {
      key: 1, value: 'Value 1',
    },
    {
      key: 2, value: 'Value 2',
    },
    {
      key: 3, value: 'Value 3',
    },
    {
      key: 4, value: 'Value 4',
    }
  ];
  userType = [
    {
      key: 1, value: 'Value 1',
    },
    {
      key: 2, value: 'Value 2',
    },
    {
      key: 3, value: 'Value 3',
    },
    {
      key: 4, value: 'Value 4',
    }
  ];
  Datedata = [
    {
      key: 1, value: '<',
    },
    {
      key: 2, value: '<=',
    },
    {
      key: 3, value: '>',
    },
    {
      key: 4, value: '>=',
    },
    {
      key: 4, value: 'Equal',
    },
    {
      key: 4, value: 'Bettwen',
    }
  ];
  
  toppingList: string[] = [ 'Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  @ViewChild('allSelected') private allSelected: MatOption;
  constructor(private fb: FormBuilder){}
  
  toppings = new FormControl();
  ngOnInit() {
    this.searchUserForm = this.fb.group({
      userType: new FormControl('')
    });
  }

tosslePerOne(all){ 
   if (this.allSelected.selected) {  
    this.allSelected.deselect();
    return false;
}
  if(this.searchUserForm.controls.userType.value.length==this.userTypeFilters.length)
    this.allSelected.select();
}
  toggleAllSelection() {
    if (this.allSelected.selected)
     {
      this.searchUserForm.controls.userType
    .patchValue([...this.userTypeFilters.map(item => item.key), 0]);
    }
     else {
      this.searchUserForm.controls.userType.patchValue([]);
    }
  }

  checks: boolean=false;
  bulk(e){
    if(e.target.checked==true){
      this.checks == true;
    }
    else{
      this.checks == false;
    }
  }
}
