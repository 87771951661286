import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as moment from 'moment';
import { AgGridService } from '../ag-grid/ag-grid.service';
@Component({
  selector: 'app-datepicker-editor',
  templateUrl: './datepicker-editor.component.html',
  styleUrls: ['./datepicker-editor.component.scss']
})
export class DatepickerEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  params: any;
  public value!: string;
  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;
  constructor(public agGridService: AgGridService) { }
  agInit(params: ICellEditorParams,): void {
    this.params = params;
    const formattedDate = this.agGridService.dateConvertorByRegion(params.value);
    params.value = formattedDate;
    this.value = formattedDate;
    
  }
  getValue(): any {
    const formattedDate = this.agGridService.dateConvertorByRegion(this.value);         
    this.value= formattedDate;
    return this.value;
  }
  handleDateChanged(newDate: Date) {
    const formattedDate = this.agGridService.dateConvertorByRegion(newDate);      
    this.value = formattedDate;
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input?.element.nativeElement.focus();
    });
  }
}
