import { sort } from "src/app/shared/component/sort/sort.model";
import { DropdownModel } from "src/app/shared/model/common.model";
import { PaginationModelCommon } from "src/app/shared/model/pagination.model";
export class DropdownListModel
{
    fundDropdown                        : DropdownModel[];
    filterfundDropdown                  : DropdownModel[];
    fundTypeDropdown                    : DropdownModel[];
    currencyDropdown                    : DropdownModel[];
    investorGroupDropdown               : DropdownModel[];
    investorClassificationDropdown      : DropdownModel[];
    investmentGroupDropdown             : DropdownModel[];
    investmentClassificationDropdown    : DropdownModel[];
    fundGroupDropdown                   : DropdownModel[];
    domainDropdown                      : DropdownModel[];
    vehicleInvestorDropdown             : DropdownModel[];
    leAssociationDropdown               : DropdownModel[];
    investorDropdown                    : DropdownModel[];
    investmentDropdown                  : DropdownModel[];
    positionDropdown                    : DropdownModel[];
    carryPtrDesignationDropdown         : DropdownModel[];
    allocCalcLevelDropdown              : DropdownModel[];
    transferTypeDropdown                : DropdownModel[];
    statusDropdown                      : DropdownModel[];
    calculationRuleDropdown             : DropdownModel[];
    calculationGroupDropdown            : DropdownModel[];
    prefRetRuleDropdown                 : DropdownModel[];
    tierDropdown                        : DropdownModel[];
    ExpAllocationTierDropdown           : DropdownModel[];
    calcTypeDropdown                    : DropdownModel[];
    catchupMethodDropdown               : DropdownModel[];
    dayBasisSettingDropdown             : DropdownModel[];
    lookupDropdown                      : DropdownModel[];
    importTypeDropdown                  : DropdownModel[];
    permissionRoleDropdown              : DropdownModel[];
    transactionSetDropdown              : DropdownModel[];
    calculationStateDropdown            : DropdownModel[];
    subActDropdown                      : DropdownModel[];
    positionJEDropdown                  : DropdownModel[];
    LockUnlockStatusDropdown            : DropdownModel[];
    calculationDropdown                 : DropdownModel[];
    calculationLevelDropdown            : DropdownModel[];
    NumeratorDenominatorDropdown        : DropdownModel[];
}
export class PaginationModel extends PaginationModelCommon
{
  investorId: number;
  selectedIds: number[];
  showColumn : string[]=[];
  sortList: sort[] = [];
}
export class ExportModel
{
    sortColumn          : string;
    sortDirection       : string;
    page                : number=1;
    pageSize            : number=25;
    searchText          : string;
    getAll              : boolean = false;
    fundId              : number =0;
    selectedIds         : number[];
    showColumn          : string[]=[];
    sortList            : sort[] = []
}