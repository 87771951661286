import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ColumnState, IHeaderParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { ICustomHeaderParams } from '../custome-header/custome-header.interface';
import { FilterService } from '../filter/filter.service';
import { StorageSortModel } from './sort.model';
import { SortService } from './sort.service';
@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit, OnDestroy {
  @Input() gridApi: any;
  @Input() initialRows: string;
  @Input() filterStorageName: string;
  @Input() columnName: string;
  @Input() gridName: string;
  @Input() params: any;
  @Input() isFilterIconColor: any;
  @Input() filterData: any;
  @Input() rows: any[];
  @Input() isServerSidePagination: boolean;
  @Output() onSort = new EventEmitter<any[]>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  storageSortList = new StorageSortModel();
  storageSortListArray: StorageSortModel[] = [];
  sortList: ColumnState[] = [];
  subSort: ColumnState;
  sortData: any = this.sortService.sortData;
  private _sortListSubscription!: Subscription;

  constructor(private sortService: SortService, public agGridService: AgGridService, public storageService: StorageService, private filterService: FilterService) {
    // this._sortListSubscription = this.sortService.sortList$.subscribe((data) => {
    //   this.sortList = data;
    // })
  }

  ngOnInit(): void {
  }
  agInit(params: IHeaderParams & ICustomHeaderParams) {
    this.params = params;
  }

  saveSort() {
    var colState = this.params.columnApi.getColumnState();
    var sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return {
          colId: s.colId,
          sort: s.sort,
          sortIndex: s.sortIndex,
        };
      });
    this.storageService.store(this.gridName, sortState)
    console.log('saved sort', sortState);
  }

  createSortArray(col, sortId) {
    var maxSortIndex = 0;
    var listOfSortModel = this.storageService.retrieve(this.gridName);
    if (listOfSortModel == undefined)
      listOfSortModel = [];
    if (listOfSortModel.length > 0)
      maxSortIndex = Math.max(...listOfSortModel.map(o => o.sortIndex));
    var data = listOfSortModel.filter(a => a.colId == col);
    if (data.length != 0) {
      data[0].sort = sortId;
      data[0].sortIndex = data[0].sortIndex != maxSortIndex ? maxSortIndex + 1 : maxSortIndex;
    }
    else listOfSortModel.push({ colId: col, sort: sortId, sortIndex: maxSortIndex + 1 })
    this.storageService.store(this.gridName, listOfSortModel)
  }

  onSortClick(col, sortId) {
    if (this.rows?.length == 0)
      return;
    this.createSortArray(col, sortId);
    this.setSort(col);
  }

  setSort(col) {
    if (this.isServerSidePagination)
      this.sortService.emitOnSort.emit(this.gridName)
    else
      this.agGridService.performFilter(this.params);
    this.trigger.closeMenu();
    this.setRemoveSortVisibility(col);
  }
  menuOpened() {
    var thisColumn = this.storageService.retrieve(this.gridName).filter(b => b.colId == this.columnName);
    //var thisColumn = this.params.columnApi.getColumnState().filter(b=>b.colId==this.columnName);
    if (thisColumn != undefined && thisColumn.length != 0)
      this.sortData.find(x => x.id == 3).display = thisColumn[0].sort == null ? false : true;
    else
      this.sortData.find(x => x.id == 3).display = false;
  }
  isSubSort(name) {
    var storedSortList = this.storageService.retrieve(this.gridName);
    var thisColumn = storedSortList != undefined ? storedSortList.filter(b => b.colId == name) : [];
    if (thisColumn != undefined)
      this.subSort = thisColumn[0];
    return this.subSort;
  }

  onRemoveSortClick(col) {
    var listOfSortModel = this.storageService.retrieve(this.gridName).filter(s => s.sort !== null);
    var sortedCol = listOfSortModel.findIndex(s => s.colId == col);
    delete listOfSortModel[sortedCol];
    listOfSortModel = listOfSortModel.filter(a => a.sort != null);
    var initialSortIndex = 1;
    listOfSortModel.forEach(x1 => x1.sortIndex = initialSortIndex++);
    this.storageService.store(this.gridName, listOfSortModel)
    this.setSort(col);
  }

  onHideColumnClick(col) {
    this.params.columnApi.setColumnVisible(col, false)
  }

  setRemoveSortVisibility(columnName) {
    var storedSortList = this.storageService.retrieve(this.gridName);
    var listOfSortModel = storedSortList != undefined ? storedSortList.filter(s => s.sort !== null) : [];
    const sortedCol = listOfSortModel.findIndex(s => s.colId == columnName);
    this.sortData[2].display = sortedCol > -1
  }

  ngOnDestroy(): void {
    if (this._sortListSubscription) {
      this._sortListSubscription.unsubscribe();
    }
  }

}
