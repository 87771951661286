import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CalculationService } from 'src/app/pages/calculation/calculation.service';
import { StorageName } from '../../common/enum';
@Component({
  selector: 'app-numericeditor',
  templateUrl: './numericeditor.component.html',
  styleUrls: ['./numericeditor.component.scss']
})
export class NumericeditorComponent implements ICellRendererAngularComp, OnInit {
  params;
  formatedValue : string;
  className : string;
  number:number;
  negtiveValue:number;
  unformatedNum:number;
  calcNum:number;
  positiveNubmer:number;
  feildName:string;
  constructor(public calculationService:CalculationService) {}
  ngOnInit(): void {
  }
  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.formatedValue = params?.valueFormatted;
    this.unformatedNum = params?.value;
    this.feildName = params.colDef.field;
    this.number = parseFloat(this.formatedValue);
     var test =  (parseFloat(this.formatedValue) < 0) ;
      var finalAmount = (parseFloat(this.formatedValue) < 0) ? "(" + this.formatedValue.replace('-','')+ ")" : this.formatedValue;
      this.formatedValue = finalAmount == 'NaN' ? "" : finalAmount;
      this.className = (this.params.gridName != StorageName.TRANSACTION_LEVEL_GRID && this.params.gridName != StorageName.FUND_LEVEL_GRID ) ? ((finalAmount !=  null && finalAmount != undefined && finalAmount.toString().indexOf('(')!= -1) ? "negativeCell" : "positiveCell") : "defaultCurrencyCell";
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    this.formatedValue = params.value;
    return true;
  }
}
