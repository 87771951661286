import { StorageService } from './../../shared/services/storage.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginRequestModel } from './login.model';
import { LoginService } from './login.service';
import { StorageName } from 'src/app/shared/common/enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  submitted = false;
  returnUrl: string;

  public settings: Settings;

  private _postLoginSubscription!: Subscription;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public appSettings: AppSettings,
    public loginService: LoginService,
    public notificationService: NotificationService,
    public storageService:StorageService
  ) {
    this.settings = this.appSettings.settings;

    // redirect to home if already logged in
    if (this.loginService.currentUserValue && this.loginService.currentUserValue.isQueSet == true) {
      this.router.navigate(['/']);
    }
  }

  form = this.fb.group({
    userName: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnInit(): void {
    // this.settings.loadingSpinner = false;


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onclickForgetPassword() {
    this.router.navigate(['/login/forgetpassword']);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.valid) {
      //console.warn(this.form.value);

      var model = new LoginRequestModel();

    
      model.userName = this.form.value.userName;
      model.password = this.form.value.password;

      this._postLoginSubscription = this.loginService.postLogin(model).subscribe((res) => {
        //this.globalService.deactivateLoader();
        if (res.isSuccess) {
          //this.notificationService.warningpopup('login success');
          if (res.response.loginUserDataModel.isQueSet) {
            
            this.storageService.store(StorageName.CURRENTUSER,res.response.loginUserDataModel);
            this.router.navigate([this.returnUrl]);
          }
          else {
            if (this.returnUrl == '/') {
              this.router.navigate(['/login/question']);
            }
            else {
              this.router.navigate(['/login/question'], { queryParams: { returnUrl: this.returnUrl } });
            }
          }
        } else {
          this.notificationService.warningpopup(res.message);
        }
      });
    }

  }

  ngOnDestroy(): void {
    if (this._postLoginSubscription) {
      this._postLoginSubscription.unsubscribe();
    }
  }
}
