
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportService } from './import-popup.service';
import * as XLSX from 'xlsx';
import { GlobalService } from 'src/app/shared/services/global.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DropdownModel } from 'src/app/shared/model/common.model';
import { ImportFileModel, sheetNameList } from 'src/app/shared/component/page-header-import-form/page-header-import-form.model';
import { AgGridService } from 'src/app/shared/component/ag-grid/ag-grid.service';
import { StorageService } from 'src/app/shared/services/storage.service';
@Component
  (
    {
      selector: 'app-import-popup',
      templateUrl: './import-popup.component.html',
      styleUrls: ['./import-popup.component.scss']
    }
  )
export class ImportPopupComponent implements OnInit {
  @Output() ServiceValue = new EventEmitter<any>();
  constructor(
    public importService: ImportService,
    public agGridService : AgGridService,
    public globalService: GlobalService,
    public storageService : StorageService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<ImportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) { }
  importFileType: number;
  importTypes: DropdownModel[];
  sheetName: string[];
  sheetNameModel = new sheetNameList();
  sheetNameList: sheetNameList[] = [];
  xsmlfile: string;
  importModel = new ImportFileModel();
  selectedVal: number;
  importFormName: string;
  isLoading : boolean = false;
  isFileLoading : boolean = false;
  isTransactionForm : boolean = false;
  UDFList: any[];
  progressPercent : number;
   file : any;
  ngOnInit(): void {
    this.selectedVal = this.data.pageHeadeModel.showImportTypeForm;
    this.importFormName = this.data.pageHeadeModel.controllerName;
    this.importTypes = this.data.pageHeadeModel.importTypes;    
    if(this.importFormName == 'Transaction')
    this.isTransactionForm = true;
    else
    this.isTransactionForm = false;
  }
  onAppend(isAppend : boolean){
    this.importModel.isAppend = isAppend;
    this.selectedVal=1;
  }
  onChangeOfType() {
    this.importService.importType = this.importFileType
    this.importModel.fileType=this.importFileType;
  }
  openFile() {
    document.querySelector('input').click()
  }
  handle(e) {
  }
  handleUpload(event) 
  { 
    if(this.importFormName != "Transaction") {
      this.isFileLoading = true;
      this.globalService.activateLoader();
    }

    this.xsmlfile = this.xsmlfile.toString().split("fakepath\\")[1];
    const file = event.target.files[0];    
    this.file = file;
  //this.globalService.activateLoader();
  // this.isLoading = true;
if(this.importFormName != "Transaction")
{
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      /* create workbook */
      const trimtext = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      const binarystr: string = e.target.result.substring(trimtext.length);
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'base64' });
      /* selected the first sheet */
      this.sheetName = wb.SheetNames;
      this.isFileLoading = false;
      this.globalService.deactivateLoader();
      this.sheetNameModel.name = this.sheetName[0];
      this.sheetNameList.unshift(this.sheetNameModel)
        this.importModel.fileData = reader.result.toString();
        document.getElementById("titleForm").click();
    };
   
  }
  else
  {
  //   this.storageService.removeItem("transactionValidationSuccess");
  //   // this.xsmlfile = this.xsmlfile.toString().split("fakepath\\")[1];
  //   this.file = event.target.files[0];
  //   this.convertToJSON(this.file).then(data => {
  //      this.importModel.fileDataArray = data;
  //      this.globalService.deactivateLoader();
  //      this.isFileLoading = false;
  //      document.getElementById("titleForm").click();

  //  });
  }
  }

  // splitJson(jsonObj, chunkSize) {
  //   const keys = Object.keys(jsonObj);
  //   const numChunks = Math.ceil(keys.length / chunkSize);
  //   const result = [];
  
  //   for (let i = 0; i < numChunks; i++) {
  //     const start = i * chunkSize;
  //     const end = start + chunkSize;
  //     const chunkKeys = keys.slice(start, end);
  //     const chunk = {};
  //     for (const key of chunkKeys) {
  //       chunk[key] = jsonObj[key];
  //     }
  //     result.push(chunk);
  //   }
  
  //   return result;
  // }
  async import() {
    if(this.importFileType != undefined)
    {
    var controllerName = this.importTypes.filter(
      (row) => row.id == this.importFileType.toString()
    );
    this.importModel.fileType=parseInt(this.importFileType.toString());
    this.importFormName = controllerName[0].name.replace(" ", "");
    if (this.importFormName == "StructuredFormat" || this.importFormName == "FlatFormat")
      this.importFormName = "Transaction";
    }
   if(this.importFormName != "Transaction")
   {
    this.isLoading = true;
    this.globalService.activateLoader();
      var entireData =
      this.importService.Import(
        this.importModel,
        this.importFormName
      );
      entireData.subscribe((res) => {
        if (res.isSuccess == true) {
          this.dialogRef.close({ controllerName: this.importFormName, response: res.response, 
            type: parseInt( this.importFileType == undefined ? "0" : this.importFileType.toString()), isAppend : this.importModel.isAppend });
        } else {
          this.notificationService.errorpopup(res.message);
        }
        this.globalService.deactivateLoader();
       this.isLoading = false;
    });
  }
  else
  {    
    const formData: FormData = new FormData();
    formData.append('file', this.file, this.file.name);
    this.isFileLoading = true;
      var entireData =
      this.importService.ImportTransaction(
        formData,
        this.importFormName
      );
      entireData.subscribe((res) => {
        if (res.isSuccess == true) {
          this.dialogRef.close({ controllerName: this.importFormName, response: res.response, 
            type: parseInt( this.importFileType == undefined ? "0" : this.importFileType.toString()), isAppend : this.importModel.isAppend });
        } else {
          this.notificationService.errorpopup(res.message);
        }
        this.isFileLoading = false;
        this.globalService.deactivateLoader();
       this.isLoading = false;
    });
    // this.importLoop(0);
  }
  }

 importLoop(index) {
  if(index==0)
  this.importModel.delete = true;
  else
  this.importModel.delete = false;
  this.importModel.fileData =  this.importModel.fileDataArray[index];
    const entireData = this.importService.Import(this.importModel,  this.importFormName);
    
    entireData.subscribe((res) => {
      if (res.isSuccess == true) {
        this.progressPercent = ((index* 100) / this.importModel.fileDataArray.length) ;
        if(this.importModel.fileDataArray.length-1 == index)
        {
          this.isLoading = false;
          this.globalService.deactivateLoader();
          document.getElementById("titleForm").click();
          this.dialogRef.close({ 
          controllerName: this.importFormName, 
          response: res.response, 
          type: parseInt(this.importFileType == undefined ? "0" : this.importFileType.toString()), 
          isAppend:this.importModel.isAppend 
        });
        }
      //  alert(index.toString() + " out of "+ this.importModel.fileDataArray.length.toString())
      
      } else {
        this.notificationService.errorpopup(res.message);
      }
      
      
      if (index < this.importModel.fileDataArray.length - 1) {
        this.importLoop(index + 1); // call importLoop again with the next index
      }
    });
  }

  convertToJSON(file: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.sheetName = workbook.SheetNames;
      this.sheetNameModel.name = this.sheetName[0]
      this.sheetNameList.unshift(this.sheetNameModel)

        var result = [];
        var listOfJsons : string[]=[];
        var maxsize = 10000;

        if(this.importFormName == "Transaction")
        {
        this.UDFList = this.storageService.retrieve("UDFLIST");
        var headers : any  = jsonData[0] ;
        var countItems = 0;
        for (let i = 1; i < jsonData.length; i++) {
          
          const item = {};
          for (let j = 0; j < headers.length; j++) {
            const excelColumnName = headers[j];
            const agGridColumnName  = this.UDFList?.filter(el => el.udfFieldName.toLowerCase() == excelColumnName.toLowerCase() && el.nStarTable == 'Transactions')[0]?.nStarUDFName ?? excelColumnName;
            item[agGridColumnName.replace(" ","")] = jsonData[i][j];
          }
          if(countItems == maxsize)
          {
            listOfJsons.push(JSON.stringify(result));
            result = [];
            countItems = 0;
          }
          result.push(item);
          countItems++;
        }
      }
      listOfJsons.push(JSON.stringify(result));
      document.getElementById("titleForm").click();
     // this.isLoading = false;
      //this.importModel.fileDataArray = listOfJsons;
        resolve(listOfJsons);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
}
//commited for temporary demo purpose as we needed old code for import
// import {
//   Component,
//   EventEmitter,
//   Inject,
//   OnInit,
//   Output,
//   ViewChild
// } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ImportService } from './import-popup.service';
// import * as XLSX from 'xlsx';
// import { GlobalService } from 'src/app/shared/services/global.service';
// import { NotificationService } from 'src/app/shared/services/notification.service';
// import { DropdownModel } from 'src/app/shared/model/common.model';
// import { ImportFileModel, sheetNameList } from 'src/app/shared/component/page-header-import-form/page-header-import-form.model';
// import { AgGridService } from 'src/app/shared/component/ag-grid/ag-grid.service';
// import { StorageService } from 'src/app/shared/services/storage.service';
// import { HttpClient } from '@angular/common/http';
// @Component
//   (
//     {
//       selector: 'app-import-popup',
//       templateUrl: './import-popup.component.html',
//       styleUrls: ['./import-popup.component.scss']
//     }
//   )
// export class ImportPopupComponent implements OnInit {
//   @Output() ServiceValue = new EventEmitter<any>();
//   constructor(
//     private http: HttpClient,
//     public importService: ImportService,
//     public agGridService : AgGridService,
//     public globalService: GlobalService,
//     public storageService : StorageService,
//     public notificationService: NotificationService,
//     public dialogRef: MatDialogRef<ImportPopupComponent>,
//     @Inject(MAT_DIALOG_DATA)
//     public data: any,
//   ) { }
//   importFileType: number;
//   importTypes: DropdownModel[];
//   sheetName: string[];
//   sheetNameModel = new sheetNameList();
//   sheetNameList: sheetNameList[] = [];
//   xsmlfile: string;
//   importModel = new ImportFileModel();
//   selectedVal: number;
//   importFormName: string;
//   isLoading : boolean = false;
//   isFileLoading : boolean = false;
//   isTransactionForm : boolean = false;
//   UDFList: any[];
//   progressPercent : number;
//    file : any;
//   ngOnInit(): void {
//     this.selectedVal = this.data.pageHeadeModel.showImportTypeForm;
//     this.importFormName = this.data.pageHeadeModel.controllerName;
//     this.importTypes = this.data.pageHeadeModel.importTypes;
//     if(this.importFormName == 'Transaction')
//     this.isTransactionForm = true;
//     else
//     this.isTransactionForm = false;
//   }
//   onAppend(isAppend : boolean){
//     this.importModel.isAppend = isAppend;
//     this.selectedVal=1;
//   }
//   onChangeOfType() {
//     this.importService.importType = this.importFileType
//     this.importModel.fileType=this.importFileType;
//   }
//   openFile() {
//     document.querySelector('input').click()
//   }
//   handle(e) {
//   }
//   handleUpload(event) 
//   { 
//     // this.isFileLoading = true;
//     // this.globalService.activateLoader();
//     this.xsmlfile = this.xsmlfile.toString().split("fakepath\\")[1];
//     const file = event.target.files[0];
//     this.file = file;
   

//   // this.isLoading = true;
// if(this.importFormName != "Transaction")
// {
//   this.globalService.activateLoader();
//     const reader: FileReader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = (e: any) => {
//       /* create workbook */
//       const trimtext = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
//       const binarystr: string = e.target.result.substring(trimtext.length);
//       const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'base64' });
//       /* selected the first sheet */
//       this.sheetName = wb.SheetNames;
//       this.isFileLoading = false;
//       this.globalService.deactivateLoader();
//       this.sheetNameModel.name = this.sheetName[0];
//       this.sheetNameList.unshift(this.sheetNameModel)
//         this.importModel.fileData = reader.result.toString();
//         document.getElementById("titleForm").click();
//     };
//   }
//   }

//   async import() {
//     if(this.importFileType != undefined)
//     {
//     var controllerName = this.importTypes.filter(
//       (row) => row.id == this.importFileType.toString()
//     );
//     this.importModel.fileType=parseInt(this.importFileType.toString());
//     this.importFormName = controllerName[0].name.replace(" ", "");
//     if (this.importFormName == "StructuredFormat" || this.importFormName == "FlatFormat")
//       this.importFormName = "Transaction";
//     }
//    if(this.importFormName != "Transaction")
//    {
//     this.isLoading = true;
//     this.globalService.activateLoader();
//       var entireData =
//       this.importService.Import(
//         this.importModel,
//         this.importFormName
//       );
//       entireData.subscribe((res) => {
//         if (res.isSuccess == true) {
//           this.dialogRef.close({ controllerName: this.importFormName, response: res.response, 
//             type: parseInt( this.importFileType == undefined ? "0" : this.importFileType.toString()), isAppend : this.importModel.isAppend });
//         } else {
//           this.notificationService.errorpopup(res.message);
//         }
//         this.globalService.deactivateLoader();
//        this.isLoading = false;
//     });
//   }
//   else
//   {
//     const formData: FormData = new FormData();
//     formData.append('file', this.file, this.file.name);
//       var entireData =
//       this.importService.ImportTransaction(
//         formData,
//         this.importFormName
//       );
//       entireData.subscribe((res) => {
//         if (res.isSuccess == true) {
//           this.dialogRef.close({ controllerName: this.importFormName, response: res.response, 
//             type: parseInt( this.importFileType == undefined ? "0" : this.importFileType.toString()), isAppend : this.importModel.isAppend });
//         } else {
//           this.notificationService.errorpopup(res.message);
//         }
//         this.globalService.deactivateLoader();
//        this.isLoading = false;
//     });
//     // this.importLoop(0);
//   }

//   }

//  importLoop(index) {
//   if(index==0)
//   this.importModel.delete = true;
//   else
//   this.importModel.delete = false;
//   this.importModel.fileData =  this.importModel.fileDataArray[index];
//     const entireData = this.importService.Import(this.importModel,  this.importFormName);
    
//     entireData.subscribe((res) => {
//       if (res.isSuccess == true) {
//         this.progressPercent = ((index* 100) / this.importModel.fileDataArray.length) ;
//         if(this.importModel.fileDataArray.length-1 == index)
//         {
//           this.isLoading = false;
//           this.globalService.deactivateLoader();
//           document.getElementById("titleForm").click();
//           this.dialogRef.close({ 
//           controllerName: this.importFormName, 
//           response: res.response, 
//           type: parseInt(this.importFileType == undefined ? "0" : this.importFileType.toString()), 
//           isAppend:this.importModel.isAppend 
//         });
//         }
//       //  alert(index.toString() + " out of "+ this.importModel.fileDataArray.length.toString())
      
//       } else {
//         this.notificationService.errorpopup(res.message);
//       }
      
      
//       if (index < this.importModel.fileDataArray.length - 1) {
//         this.importLoop(index + 1); // call importLoop again with the next index
//       }
//     });
//   }

//   convertToJSON(file: File): Promise<any> {
//     return new Promise<any>((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = (e: any) => {
//         const data = new Uint8Array(e.target.result as ArrayBuffer);
//         const workbook = XLSX.read(data, { type: 'array' });
//         const sheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[sheetName];
//         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//         this.sheetName = workbook.SheetNames;
//       this.sheetNameModel.name = this.sheetName[0]
//       this.sheetNameList.unshift(this.sheetNameModel)

//         var result = [];
//         var listOfJsons : string[]=[];
//         var maxsize = 10000;

//         if(this.importFormName == "Transaction")
//         {
//         this.UDFList = this.storageService.retrieve("UDFLIST");
//         var headers : any  = jsonData[0] ;
//         var countItems = 0;
//         for (let i = 1; i < jsonData.length; i++) {
          
//           const item = {};
//           for (let j = 0; j < headers.length; j++) {
//             const excelColumnName = headers[j];
//             const agGridColumnName  = this.UDFList?.filter(el => el.udfFieldName.toLowerCase() == excelColumnName.toLowerCase() && el.nStarTable == 'Transactions')[0]?.nStarUDFName ?? excelColumnName;
//             item[agGridColumnName.replace(" ","")] = jsonData[i][j];
//           }
//           if(countItems == maxsize)
//           {
//             listOfJsons.push(JSON.stringify(result));
//             result = [];
//             countItems = 0;
//           }
//           result.push(item);
//           countItems++;
//         }
//       }
//       listOfJsons.push(JSON.stringify(result));
//       document.getElementById("titleForm").click();
//      // this.isLoading = false;
//       //this.importModel.fileDataArray = listOfJsons;
//         resolve(listOfJsons);
//       };
//       reader.onerror = reject;
//       reader.readAsArrayBuffer(file);
//     });
//   }
// }
