import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AgGridService } from "../ag-grid/ag-grid.service";
import { CustomeHeaderService } from "../custome-header/custome-header.service";
import { NotificationService } from "../../services/notification.service";
import { CommonTabService } from "../../services/common-tab.service";
import { StorageService } from "../../services/storage.service";
import { GlobalService } from "../../services/global.service";
import { ToastrService } from "ngx-toastr";
import { StorageName } from "../../common/enum";
import { ImportFileModel, PageHeaderImportFormModel, sheetNameList } from "./page-header-import-form.model";
import { MatDialog } from "@angular/material/dialog";
import { ImportPopupComponent } from "src/app/pages/import-popups/import-popup.component";
import { ImportService } from "src/app/pages/import-popups/import-popup.service";
@Component({
  selector: "app-page-header-import-form",
  templateUrl: "./page-header-import-form.component.html",
  styleUrls: ["./page-header-import-form.component.scss"]
})
export class PageHeaderImportFormComponent implements OnInit {

  params;
  transactionFilterId : string ="";
  sheetNameList : sheetNameList[]=[];
  sheetName : string[];
  sheetNameModel = new sheetNameList();
  importModel = new ImportFileModel();
  @Input() pageHeaderModel: PageHeaderImportFormModel;
  
   @Output() onSuccessImport: EventEmitter<any> = new EventEmitter();
  // @Output() onAddNewForm: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteImport: EventEmitter<any> = new EventEmitter();
  overlay: boolean = true;
  showOverrideCheckbox: boolean = true;
  xsmlfile : string;
  constructor(
    public agGridService        : AgGridService, 
    public notificationService  : NotificationService,
    public commonTabService     : CommonTabService,
    public storageService       : StorageService,
    public importService        : ImportService,
    public globalService        : GlobalService,
    public toasterService       : ToastrService,
    public dialog               : MatDialog,
    public customHeaderService  : CustomeHeaderService) {
      this.pageHeaderModel;
    }
  ngOnInit(): void {
    //set from permission
    var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    if (formPermission) {
      this.pageHeaderModel.canModify = formPermission.modifyPermission;
    }
    if(this.pageHeaderModel.showIsOverride == undefined || this.pageHeaderModel.showIsOverride == null)
      this.pageHeaderModel.showIsOverride = true;

  if(formPermission.moduleName == "Transactions")
  {
    this.showOverrideCheckbox = false;
    var oldValidateStatus =   this.storageService.retrieve("transactionValidationSuccess");
    if(oldValidateStatus != undefined && oldValidateStatus != false)
    {
       this.openImportResultDialog("Validate");
    }

    var oldCommitStatus =   this.storageService.retrieve("transactionCommitSuccess");
    if(oldCommitStatus != undefined && oldCommitStatus != false)
    {
      this.openImportResultDialog("Commit");
    }

    var oldImportStatus =   this.storageService.retrieve("transactionImportSuccess");
    if(oldImportStatus != undefined && oldImportStatus != false)
    {
      this.openImportResultDialog("Import");
    }
  }
  else
    this.showOverrideCheckbox = true;

  }


  openImportResultDialog(type : string, sendResponce : boolean = false , dataResult : any = undefined, isSuccess : boolean = true)
  {
    var successImport = "";
    switch(type)
    {
      case "Import":
        successImport = "Data Import Ready for Validation & Import.";
        break;
      case "Validate":
        successImport = isSuccess ? "Validation completed with no errors identified." : "Validation completed with errors identified." ;
        break;
      case "Commit":
        successImport = isSuccess ? "Data Import Finished" : "Commit Failed with error"+ dataResult.errors;
        break;
    }
    this.notificationService.openImportSuccessDialog(successImport).subscribe((result) => {
      if (result.isConfirmed) {
        switch(type)
        {
          case "Import":
            successImport = "Data Import Ready for Validation & Import.";
            this.storageService.store("transactionImportSuccess",false);
            if(sendResponce)
              this.onSuccessImport.emit({ controllerName :  dataResult.controllerName , response : dataResult.response , isAppend : dataResult.isAppend});
            break;
          case "Validate":
            successImport = "Validation Completed. No Errors Identified. Ready for Upload to NorthStar.";
            this.storageService.store("transactionValidationSuccess",false);
            if(sendResponce)
              this.onSuccessImport.emit({ controllerName :  this.pageHeaderModel.agGridI.controllerName.replace("Import","") , response : dataResult.response });
            break;
          case "Commit":
            successImport = "Data Added to NorthStar.";
            this.storageService.store("transactionCommitSuccess",false);
            if(sendResponce)
              this.onSuccessImport.emit({ controllerName :  this.pageHeaderModel.agGridI.controllerName.replace("Import","") , response : dataResult.response });

            break;
        }
      }
    })
  }

  openImportFileForm()
  {
    let dialogRef = this.dialog.open(ImportPopupComponent, {
      maxWidth: 350,
      minWidth: 350,
      data: { pageHeadeModel  : this.pageHeaderModel },
      backdropClass: 'my-backdrop-class',
      hasBackdrop : true,
      disableClose : true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result.response != undefined)
      {
        var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
        if (formPermission.moduleName == "Transactions") {
          this.storageService.store("transactionValidationSuccess",false);
          this.storageService.store("transactionCommitSuccess",false);
          this.storageService.store("transactionImportSuccess",true);
        
        }
        this.pageHeaderModel.isAppend = result.isAppend;
        this.openImportResultDialog("Import",true,result);
    }
    else
    {
      var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
      if (formPermission.moduleName == "Transactions") {
        this.storageService.store("transactionValidationSuccess",false);
        this.storageService.store("transactionCommitSuccess",false);
        this.storageService.store("transactionImportSuccess",false);
        this.pageHeaderModel.isAppend = result.isAppend;
      }
    }
    });
  }
  commit()
  {
    this.importService.commit(this.pageHeaderModel.agGridI.controllerName,this.pageHeaderModel.isNew,this.pageHeaderModel.isAppend,this.pageHeaderModel.isOverride).subscribe((res) => { 
      //hide loader
        this.globalService.deactivateLoader();
        if (res.isSuccess) {
          this.toasterService.success(res.message,"", {
            timeOut: 1000
          });
          var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
          if (formPermission.moduleName == "Transactions") {
          this.storageService.store("transactionValidationSuccess",false);
          this.storageService.store("transactionCommitSuccess",true);
          this.storageService.store("transactionImportSuccess",false);
          }
          if(this.pageHeaderModel.agGridI.controllerName.replace("Import","")!="Transaction")
          this.reloadDropdown(this.pageHeaderModel.agGridI.controllerName.replace("Import",""));
          this.openImportResultDialog("Commit",true,res);
        } 
        else 
        {
          this.notificationService.warningpopup(res.errors); // show error if success false
          this.openImportResultDialog("Commit",true,res,false);
        }
        //here
     
      });
  }
  validate(isCommit)
  {
    this.globalService.activateLoader();
    this.importService.validate(this.pageHeaderModel.agGridI.controllerName,this.pageHeaderModel.isNew,this.pageHeaderModel.isAppend,this.pageHeaderModel.isOverride).subscribe((res) => { 
      //hide loader
      if(isCommit==false)
        this.globalService.deactivateLoader();
        if (res.isSuccess) {
          var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
          if (formPermission.moduleName == "Transactions") {
            this.storageService.store("transactionValidationSuccess",true);
            this.storageService.store("transactionCommitSuccess",false);
            this.storageService.store("transactionImportSuccess",false);
          }

          if(isCommit==0)
          {
            this.openImportResultDialog("Validate",true,res);
          }
            else
            this.commit()
        } 
        else 
        {
          this.notificationService.warningpopup(res.errors); 
          this.openImportResultDialog("Validate",true,res,false);
          this.globalService.deactivateLoader();
        }
        
      });
  }

reloadDropdown(controllerName)
{
    var dropdownToResetName = "";
    var storageName = "";
      switch(controllerName)
      {
          case "FundInvestor" :
              //this.fillFundInvestorData(res.response as FundInvestorUpload[]);
              dropdownToResetName = "Investor";
              storageName = StorageName.INVESTOR_DROPDOWN;
              break;
          case "FundInvestment" :
              //this.fillFundInvestmentData(res.response as FundInvestmentUpload[]);
              dropdownToResetName = "Investment"
              storageName = StorageName.INVESTMENT_DROPDOWN;
              break;
      }
    console.log(dropdownToResetName)
    console.log(storageName)
    this.commonTabService.updateDropdown(dropdownToResetName,storageName).subscribe(res=>console.log(res));
    this.commonTabService.updateDropdown("Fund",StorageName.FUND_DROPDOWN).subscribe(res=>console.log(res));
  }
  //#endregion

  CloseBadge(badgeName){
    switch(badgeName){
      case 'Import':
        this.storageService.store("transactionImportSuccess",false);
        break;
      case 'Commit':
        this.storageService.store("transactionCommitSuccess",false);
        break;
    }
  }

  onDelete(){
    this.globalService.activateLoader();
    this.importService.delete(this.pageHeaderModel.agGridI.controllerName).subscribe((res) => { 
      this.globalService.deactivateLoader();
      if (res.isSuccess) {
        this.onSuccessImport.emit({ controllerName :  this.pageHeaderModel.agGridI.controllerName.replace("Import","") , response : res.message  });
      }
      else
      {
        this.notificationService.warningpopup(res.errors); 
      }
    });  
  }
}