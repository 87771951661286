import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExportService } from 'src/app/shared/services/export.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DropdownListModel } from '../../fund-information/fund-information.model';
import { CalculationModel } from '../calculation.model';
import { CalculationService } from '../calculation.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-run-model',
  templateUrl: './run-model.component.html',
  styleUrls: ['./run-model.component.scss']
})
export class RunModelComponent implements OnInit, OnDestroy {
  formType : string = "Run";
  exportToExcel;
  statusDropdown = []
  statusId;
  fromDashboard : boolean = false;
  formModel: CalculationModel;
  fundName: string;
  activeRadioBtn: string;
  isLoading: boolean;
  dropdownListModel = new DropdownListModel();
  private _RunModalSubscription!: Subscription;
  private _DoPrefRetCalcSubscription!: Subscription;
  private _ProcessCalculationSubscription!: Subscription;
  private _CreateCalculationJESubscription!: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public calculationService: CalculationService,
    public notificationService: NotificationService,
    public globalService: GlobalService,
    public toasterService: ToastrService,
    public exportService : ExportService,
    public storageService : StorageService,
    public dialogRef: MatDialogRef<RunModelComponent>
  ) { }

  ngOnInit(): void {
    this.fromDashboard = this.data.fromDashboard == undefined ? false : this.data.fromDashboard ;
    this.formModel = this.data.calculation == undefined ? new CalculationModel() : this.data.calculation;
    var retrive = this.storageService.retrieve("includeFormulasInExport");
    this.formModel.includeFormulasInExport=retrive;
    this.formType = this.data.formType == undefined ? "Run" : this.data.formType;
    if(this.formModel.calculation.id == undefined)
      this.formModel.calculation.id = 0;
    if(this.formModel.calculation.fundId == undefined)
      this.formModel.calculation.fundId = "0";
    if(this.formModel.calculation.statusId == undefined)
      this.formModel.calculation.statusId = 2;

    this.dropdownListModel = this.data.dropdownModel;
  }

  onIncludeFormulaChanged(inputFormule)
  {
    this.formModel.includeFormulasInExport=inputFormule;
    this.storageService.store("includeFormulasInExport",inputFormule);
  }

  onRun() {
    this.isLoading = true;
    
    this._RunModalSubscription = this.calculationService.RunModal(this.formModel).subscribe(result => {
        if (result.isSuccess) {
          this.toasterService.success(result.message, "", {
            timeOut: 2000
          });
          this.dialogRef.close({ data: this.formModel });
        } else  this.notificationService.errorpopup(result.errors)
      this.isLoading = false;
    })
  }

  onView() {
        this.dialogRef.close({ data: this.formModel });
  }
  radioChange(event) {
    console.log('the event is: ', event);
    this.activeRadioBtn = event.value;
  }

  runRadio() {
    this.isLoading = true;
    switch(this.activeRadioBtn)
    {
      case 'Preferred Return Calculation':
        this._DoPrefRetCalcSubscription = this.calculationService.DoPrefRetCalc(this.formModel.calculation).subscribe(result => {
          if (result.isSuccess) {
            this.toasterService.success(result.message, "", {
              timeOut: 2000
            });
            this.dialogRef.close({ data: this.formModel });
          } else this.notificationService.errorpopup('An Error Occurred')
          this.isLoading = false;
        })
        break;
      case 'Process Calculation':
        this._ProcessCalculationSubscription = this.calculationService.ProcessCalculation(this.formModel.calculation).subscribe(result => {
          if (result.isSuccess) {
            this.toasterService.success(result.message, "", {
              timeOut: 2000
            });
            this.dialogRef.close({ data: this.formModel });
          } else this.notificationService.errorpopup('An Error Occurred')
          this.isLoading = false;
        })
        break;
      case "Create Calculation JE's":
        this._CreateCalculationJESubscription = this.calculationService.CreateCalculationJE(this.formModel.calculation).subscribe(result => {
          if (result.isSuccess) {
            this.toasterService.success(result.message, "", {
              timeOut: 2000
            });
            this.dialogRef.close({ data: this.formModel });
          } else this.notificationService.errorpopup('An Error Occurred')
          this.isLoading = false;
        })
        break;
    }
  }

  getCalcFilterDropdown(fundId){
    this.formModel.calculation.fundId=fundId;
    if(this.fromDashboard == true){
      this.calculationService.getCalcListFromSearch(this.formModel ).subscribe((res) => {
        if (res.isSuccess) 
          this.dropdownListModel.calculationDropdown = res.response;
      });
    }
  }

  ngOnDestroy(): void {
    if (this._RunModalSubscription) {
      this._RunModalSubscription.unsubscribe();
    }
    if (this._DoPrefRetCalcSubscription) {
      this._DoPrefRetCalcSubscription.unsubscribe();
    }
    if (this._ProcessCalculationSubscription) {
      this._ProcessCalculationSubscription.unsubscribe();
    }
    if (this._CreateCalculationJESubscription) {
      this._CreateCalculationJESubscription.unsubscribe();
    }
  }
}
