import { Subscription } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, ViewChild, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HeaderTitleService } from 'src/app/shared/services/header-title.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { VerticalMenuComponent } from '../menu/vertical-menu/vertical-menu.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class SidenavComponent implements OnInit, OnDestroy {
  public userImage = '../assets/img/users/user.jpg';
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  @ViewChild(VerticalMenuComponent) childVerticalMenu: VerticalMenuComponent;
  // @Output() onClickMenuItem: EventEmitter<any> = new EventEmitter<any>();

  // public menuItems:Array<any>;
  public menuHeader: string = "";
  public settings: Settings;
  private headerTitleSubscribe!: Subscription;
  constructor(public appSettings: AppSettings, public menuService: MenuService, public headerTitleService: HeaderTitleService,) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.headerTitleSubscribe = this.headerTitleService._title$.subscribe((data) => {
      this.menuHeader = data;
    })
  }

  openVerticalMenu(menuId: number): void {
    return this.childVerticalMenu.changeSideNavContent(menuId);
  }

  public closeSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  public getMenuByRouteUrl(url) {
    return this.menuService.getMenuByRouteUrl(url);
  }

  ngOnDestroy(): void {
    if (this.headerTitleSubscribe) {
      this.headerTitleSubscribe.unsubscribe();
    }
  }

}
