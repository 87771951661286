import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridService } from "../ag-grid/ag-grid.service";
export interface MyCellParam {
  values?: any;
  Ids?: any;
  eventOccurs?: any;
}
export interface MyParams {
  // label?: string;
  eventOccurs?: any;
}
@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent implements ICellRendererAngularComp {
  params;
  firstCellParam = [];
  secondCellParam = [];
  name: string;
  constructor(public agGridService: AgGridService) {}
  ngOnInit(): void {}
  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.name = params.value;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    this.params = params;
    this.name = params.value;
    return true;
  }
  addRow(){
    this.params.eventOccurs.emit({ eventName: 'addingRow', 
    payload:this.params.data });
  }
}
