import { ISelectCellEditorParams } from "ag-grid-community/dist/lib/rendering/cellEditors/selectCellEditor";
import { StorageName } from "../../common/enum";
import { CustomTooltip } from "../custom-tooltip/custom-tooltip.component";
import { ITooltipParams } from "ag-grid-community";
export class AgGridI {
   // rows: [];
   masterIdStorageName?: string;
   colDefs: AgColDefsI[];
   columnTypes?: any;
   defaultColDef?: defaultColDef;
   rowSelection: "single" | "multiple";
   gridName?: string;
   controllerName?: string;
   storageName?: StorageName;
   paginationStore?: StorageName;
   tabIndex?: number;
   initialRows?: string;
   filterStorageName?: string;
   serversidepagination?: boolean = false;
   gridApi?: any;
   showDeleted? : false;
   showDeleteStatusStorageName? : string;
   isSelecte? : boolean;
   suppressClipboardPasteVariable? : boolean = false;
}

export class defaultColDef {
   constructor() {
      this.sortable = true;
      this.filter = false;
      this.floatingFilter = false;
      this.editable = true;
      this.resizable = true;
   }
   sortable?: boolean = true;
   filter?: boolean = false;
   floatingFilter?: boolean = false;
   editable?: boolean = true;
   resizable?: boolean = true;
   headerCellRendererParams : any;
}


export class AgColDefsI {
   isUDFField ?:boolean = false;
   field: string;
   lockPosition?: boolean;
   lockPinned?: boolean;
   displayName?: string;
   filterValueColumnName?: string;
   filterIdColumnName?: string;
   property?: string;
   isRequired?: boolean | false;
   isDateFilterShow?: boolean = false;
   width?: number;
   minWidth?: number;
   cellDisplayFormat?: string;
   cellType?: 'icons' | 'dropdown' | 'checkbox' | 'run' | 'datePickerEditor' | 'number';
   cellRenderer?: 'dropdownRenderer' | 'datePickerRenderer' | 'checkboxRenderer' | 'numericRenderer' | 'transactionDropdownRenderer';
   type?: string; //for editable function 
   // cellRenderer?: cellRendererI;
   editable?: any;
   sortable?: boolean | false;
   isfilter?: boolean | false; // to display filter icon in header
   // filter?: any;
   // filterParams?: any;
   // suppressMenu?: boolean = false;
   cellStyle?: 'text-align-center' | 'text-align-right'
   pinned?: boolean | 'left' | 'right' | null;
   cellEditor?: cellEditorI;
   cellEditorParams?: ISelectCellEditorParams;
   headerCheckboxSelection?: boolean | false;
   checkboxSelection?: boolean | false;
   valueFormatter?: "currencyFormat" | "percentFormat" | "currencyFormatWith6Dec"  | "percentFormatWith4Dec"| "dateFormat" | null;
   cellClass?: string;
   aggFunc?: string = 'sum';
   valueParser?: string = "Number(newValue)";
   floatingFilter?: boolean | false;
   floatingDropDown?: boolean | false;
   hide?: boolean;
   cellClassRules?:any;
   isSelecte? : boolean;
   tooltipComponent?: 'CustomTooltip';
   tooltipField?: string;
   tooltipComponentParams?: ITooltipParams;
}
export interface cellEditorI {
   cellEditor?: 'agSelectCellEditor' | 'dropdownRenderer' | 'datePickerEditor' | 'numericValueEditor' | null;
   cellEditorData?: any;
   cellEditorParams?: any;
   values?: any;
   cellEditorId?: any;
}
export interface cellRendererI {
   cellType?: 'icons' | 'dropdown' | 'checkbox';
   cellRendererParams?: any;
}
const cellClassRulesforSpecialAlloc = {
   "opacity_4": params => params.data.specialAlloc == false,
 };
 const cellClassRulesforSpecialPrefRetRate = {
   "opacity_4": params => params.data.specialPrefRetRate == false
 };
const SelectColumn : AgColDefsI ={
   field: "",
   width: 40,
   pinned: "left",
   headerCheckboxSelection: true,
   checkboxSelection: true,
   lockPosition: true,
 };
 const ActionColumn : AgColDefsI = {
   displayName: "Actions",
   field: "actions",
   width: 100,
   minWidth: 30,
   cellType: "icons",
   editable: false,
   sortable: false,
   lockPinned: true,
 }
 const FundNameColumn : AgColDefsI = {
   displayName: "Fund",
   field: "fundName",
   width: 150,
   minWidth: 30,
   cellClass: "leftAlignment",
   isRequired: true,
   //floatingFilter: true,
   filterValueColumnName: "fundName",
   filterIdColumnName: "fundId",
   cellDisplayFormat: "textFilter",
   editable: false,
   lockPinned: true,
   hide : true,
   isfilter: true,
   type: "editableColumn",
 }

 const FundNameColumnFundLevel : AgColDefsI = {
  displayName: "Fund",
  field: "fundName",
  width: 150,
  minWidth: 30,
  cellClass: "leftAlignment",
  isRequired: true,
  //floatingFilter: true,
  filterValueColumnName: "fundName",
  filterIdColumnName: "fundId",
  cellDisplayFormat: "textFilter",
  editable: false,
  lockPinned: true,
  hide : true,
  isfilter: true
}
 const DropdownFundNameColumn : AgColDefsI = {
  field: "fundName",
  displayName: "Fund",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  filterValueColumnName: "fundName",
  filterIdColumnName: "fundId",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellEditor: {
     //   cellEditorData: carryPtrDesignationFilter
     },
  isRequired: true,
  isfilter: true,
  editable: true,
  lockPinned: true,
}
const InvestmentFundNameColumn : AgColDefsI = {
  field: "fundName",
  displayName: "Fund",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  filterValueColumnName: "fundName",
  filterIdColumnName: "fundsId",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellEditor: {
     //   cellEditorData: carryPtrDesignationFilter
     },
  isRequired: true,
  isfilter: true,
  editable: true,
  lockPinned: true,
  
}
 const VehicleInvestorColumn : AgColDefsI = {
   field: "vehicleInvestorName",
   displayName: "Investor Group",
   width: 100,
   minWidth: 30,
   cellType: "dropdown",
   filterValueColumnName: "vehicleInvestorName",
   filterIdColumnName: "vehicleInvestorId",
   cellRenderer: "dropdownRenderer",
   cellClass: "leftAlignment",
   cellEditor: {
      //   cellEditorData: carryPtrDesignationFilter
      },
   isRequired: true,
   isfilter: true,
   type: "editableColumn",
   lockPinned: true,
 }
 const LEAssociationColumn : AgColDefsI = {
  field: "leAssociationName",
  displayName: "LE Association",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  filterValueColumnName: "leAssociationName",
  filterIdColumnName: "leAssociationId",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellEditor: {
     //   cellEditorData: carryPtrDesignationFilter
     },
  isRequired: false,
  isfilter: true,
  type: "editableColumn",
  lockPinned: true,
}
 const InvestorColumn : AgColDefsI ={
   field: "investorName",
   displayName: "Investor",
   width: 100,
   minWidth: 30,
   cellType: "dropdown",
   cellRenderer: "dropdownRenderer",
   cellEditor: {
      //   cellEditorData: carryPtrDesignationFilter
      },
   cellClass: "leftAlignment",
   filterValueColumnName: "investorName",
   filterIdColumnName: "investorId",
   isRequired: true,
   isfilter: true,
   type: "editableColumn",
   lockPinned: true,
 }
 const InvestorInvestorColumn : AgColDefsI = {
  displayName: "Investor",
  field: "investorName",
  width: 150,
  minWidth: 30,
  cellClass: "leftAlignment",
  isRequired: true,
  filterValueColumnName: "investorName",
  filterIdColumnName: "investorId",
  cellDisplayFormat: "textFilter",
  editable: false,
  lockPinned: true,
  hide : true,
  isfilter: true,
}
 const InvestorCommitmentColumn : AgColDefsI ={
   field: "investorCommitment",
   displayName: "Commitment",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "investorCommitment",
   cellDisplayFormat: "textFilter",
   valueFormatter: "currencyFormat",
   cellType: "number",
   cellRenderer: "numericRenderer",
   cellClass: "rightAlignment",
   cellStyle: "text-align-right",
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const CommitPerColumn : AgColDefsI ={
   field: "commitPer",
   displayName: "Commit %",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "commitPer",
   cellDisplayFormat: "textFilter",
   valueFormatter: "percentFormat",
   cellClass: "rightAlignment",
   cellStyle: "text-align-right",
   floatingFilter: true,
   editable: false,
   lockPinned: true,
   type: "editableColumn",
 }
 const RecCarryColumn : AgColDefsI ={
   field: "recCarry",
   displayName: "Receives Carry",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellClass: "leftAlignment",
   cellRenderer: "checkboxRenderer",
   cellStyle: "text-align-center",
   floatingDropDown: true,
   filterValueColumnName: "recCarry",
   cellDisplayFormat: "textFilter",
   lockPinned: true,
   type: "editableColumn",
 }
 const FeeEligibleColumn : AgColDefsI ={
   field: "feeEligible",
   displayName: "Fee Eligible",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellRenderer: "checkboxRenderer",
   cellStyle: "text-align-center",
   floatingDropDown: true,
   filterValueColumnName: "feeEligible",
   cellDisplayFormat: "textFilter",
   lockPinned: true,
   type: "editableColumn",
 }
 const SpecialAllocColumn : AgColDefsI ={
   field: "specialAlloc",
   displayName: "Special Alloc",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellRenderer: "checkboxRenderer",
   cellStyle: "text-align-center",
   filterValueColumnName: "specialAlloc",
   cellDisplayFormat: "textFilter",
   floatingDropDown: true,
   lockPinned: true,
 }
 const SideLetterColumn : AgColDefsI ={
  field: "sideLetter",
  displayName: "Side Letter Agreement",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellRenderer: "checkboxRenderer",
  cellStyle: "text-align-center",
  filterValueColumnName: "sideLetter",
  cellDisplayFormat: "textFilter",
  floatingDropDown: true,
  lockPinned: true,
  type: "editableColumn",
}
 const SpecialGPAllocPercentColumn : AgColDefsI ={
   field: "specialGPAllocPercent",
   displayName: "Special GP Alloc",
   width: 100,
   minWidth: 30,
   cellType: "number",
   cellRenderer: "numericRenderer",
   filterValueColumnName: "specialGPAllocPercent",
   cellDisplayFormat: "textFilter",
   valueFormatter: "percentFormat",
   cellStyle: "text-align-right",
   floatingFilter: true,
   editable: (params) => params.data.specialAlloc == true,
   lockPinned: true,
   cellClassRules: cellClassRulesforSpecialAlloc,
 }
 const SideLetterPromote1Column : AgColDefsI ={
  field: "sideLetterPromote1",
  displayName: "Side Letter Promote for 1st Hurdle",
  width: 100,
  minWidth: 30,
  cellType: "number",
  cellRenderer: "numericRenderer",
  filterValueColumnName: "sideLetterPromote1",
  cellDisplayFormat: "textFilter",
  valueFormatter: "percentFormatWith4Dec",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: (params) => params.data.sideLetter == true,
  lockPinned: true,
  cellClassRules: cellClassRulesforSpecialAlloc,
  type: "editableColumn",
}
const SideLetterPromote2Column : AgColDefsI ={
 field: "sideLetterPromote2",
 displayName: "Side Letter Promote for 2nd Hurdle",
 width: 100,
 minWidth: 30,
 cellType: "number",
 cellRenderer: "numericRenderer",
 filterValueColumnName: "sideLetterPromote2",
 cellDisplayFormat: "textFilter",
 valueFormatter: "percentFormatWith4Dec",
 cellStyle: "text-align-right",
 floatingFilter: true,
 editable: (params) => params.data.sideLetter == true,
 lockPinned: true,
 cellClassRules: cellClassRulesforSpecialAlloc,
 type: "editableColumn",
}
const SideLetterPromote3Column : AgColDefsI ={
 field: "sideLetterPromote3",
 displayName: "Side Letter Promote for 3rd Hurdle",
 width: 100,
 minWidth: 30,
 cellType: "number",
 cellRenderer: "numericRenderer",
 filterValueColumnName: "sideLetterPromote3",
 cellDisplayFormat: "textFilter",
 valueFormatter: "percentFormatWith4Dec",
 cellStyle: "text-align-right",
 floatingFilter: true,
 editable: (params) => params.data.sideLetter == true,
 lockPinned: true,
 cellClassRules: cellClassRulesforSpecialAlloc,
 type: "editableColumn",
}
 const SideLetterPref1Column : AgColDefsI ={
  field: "sideLetterPref1",
  displayName: "Side Letter Pref Ret for 1st Hurdle",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormatWith4Dec",
  filterValueColumnName: "sideLetterPref1",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: (params) => params.data.sideLetter == true,
  lockPinned: true,
  cellClassRules: cellClassRulesforSpecialPrefRetRate,
  type: "editableColumn",
}
const SideLetterPref2Column : AgColDefsI ={
  field: "sideLetterPref2",
  displayName: "Side Letter Pref Ret for 2nd Hurdle",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormatWith4Dec",
  filterValueColumnName: "sideLetterPref2",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: (params) => params.data.sideLetter == true,
  lockPinned: true,
  cellClassRules: cellClassRulesforSpecialPrefRetRate,
  type: "editableColumn",
}
const SideLetterPref3Column : AgColDefsI ={
  field: "sideLetterPref3",
  displayName: "Side Letter Pref Ret for 3rd Hurdle",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormatWith4Dec",
  filterValueColumnName: "sideLetterPref3",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: (params) => params.data.sideLetter == true,
  lockPinned: true,
  cellClassRules: cellClassRulesforSpecialPrefRetRate,
  type: "editableColumn",
}
 const ActiveColumn : AgColDefsI ={
   field: "active",
   displayName: "Active",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellRenderer: "checkboxRenderer",
   cellStyle: "text-align-center",
   filterValueColumnName: "active",
   cellDisplayFormat: "textFilter",
   floatingDropDown: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const FundInvestorExtIdColumn : AgColDefsI ={
   field: "fundInvestorExtId",
   displayName: "External ID",
   width: 100,
   filterValueColumnName: "fundInvestorExtId",
   cellDisplayFormat: "textFilter",
   minWidth: 30,
   cellStyle: "text-align-right",
   cellClass: "rightAlignment",
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const CommentsColumn : AgColDefsI ={
   field: "comments",
   cellClass: "leftAlignment",
   displayName: "Comments",
   filterValueColumnName: "comments",
   cellDisplayFormat: "textFilter",
   width: 100,
   minWidth: 30,
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const PositionColumn : AgColDefsI ={
   field: "positionName",
   displayName: "Position",
   width: 100,
   minWidth: 30,
   cellType: "dropdown",
   cellRenderer: "dropdownRenderer",
   cellEditor: {
      //   cellEditorData: carryPtrDesignationFilter
      },
   cellClass: "leftAlignment",
   filterValueColumnName: "positionName",
   filterIdColumnName: "positionId",
   isRequired: true,
   isfilter: true,
   type: "editableColumn",
   lockPinned: true,
 }
 const PositionColumnFundLevel : AgColDefsI ={
  field: "positionName",
  displayName: "Position",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellEditor: {
     //   cellEditorData: carryPtrDesignationFilter
     },
  cellClass: "leftAlignment",
  filterValueColumnName: "positionName",
  filterIdColumnName: "positionId",
  isRequired: true,
  isfilter: true,
  editable: false,
  lockPinned: true,
}

 const PositionFormColumn : AgColDefsI ={
  field: "positionName",
  cellClass: "leftAlignment",
  displayName: "Position",
  filterValueColumnName: "positionName",
  filterIdColumnName: "id",
  cellDisplayFormat: "textFilter",
  width: 100,
  minWidth: 30,
  //floatingFilter: true,
  lockPinned: true,
  isfilter:true
}

 const InvestmentColumn : AgColDefsI ={
   field: "investmentName",
   displayName: "Investment",
   width: 100,
   minWidth: 30,
   cellType: "dropdown",
   cellRenderer: "dropdownRenderer",
   cellEditor: {
      //   cellEditorData: carryPtrDesignationFilter
      },
   cellClass: "leftAlignment",
   filterValueColumnName: "investmentName",
   filterIdColumnName: "investmentId",
   isRequired: true,
   isfilter: true,
   type: "editableColumn",
   lockPinned: true,
 }

 const InvestmentInvestmentColumn : AgColDefsI ={
  field: "investmentName",
  displayName: "Investment",
  width: 100,
  minWidth: 30,
  filterValueColumnName: "investmentName",
  filterIdColumnName: "investmentId",
  cellDisplayFormat: "textFilter",
  // cellStyle: "text-align-left",
  cellClass: "leftAlignment",
 //floatingFilter: true,
  isfilter:true,
  lockPinned: true,
  hide : true,
  editable: false
}
 const InvestmentColumnFundLevel : AgColDefsI ={
  field: "investmentName",
  displayName: "Investment",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellEditor: {
     //   cellEditorData: carryPtrDesignationFilter
     },
  cellClass: "leftAlignment",
  filterValueColumnName: "investmentName",
  filterIdColumnName: "investmentId",
  isRequired: true,
  isfilter: true,
  editable: false,
  lockPinned: true,
}

 const FundInvestmentExtIdColumn : AgColDefsI ={
   field: "fundInvestmentExtId",
   displayName: "External ID",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "fundInvestmentExtId",
   cellDisplayFormat: "textFilter",
   cellStyle: "text-align-right",
   cellClass: "rightAlignment",
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }

 const PositionExternalIdColumn : AgColDefsI ={
  field: "positionExternalId",
  displayName: "External ID",
  width: 100,
  minWidth: 30,
  filterValueColumnName: "positionExternalId",
  cellDisplayFormat: "textFilter",
  cellStyle: "text-align-right",
  cellClass: "rightAlignment",
  floatingFilter: true,
  lockPinned: true,
}
 
 const FundInvestmentAmtColumn : AgColDefsI =
 {
   field: "fundInvestmentAmt",
   displayName: "Amount",
   width: 100,
   minWidth: 30,
   cellType: "number",
   filterValueColumnName: "fundInvestmentAmt",
   cellDisplayFormat: "textFilter",
   cellRenderer: "numericRenderer",
   valueFormatter: "currencyFormat",
   cellStyle: "text-align-right",
   cellClass: "rightAlignment",
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const InvestmentVintageDateColumn : AgColDefsI =
 {
   field: "strInvestmentVintageDate",
   displayName: "Vintage Date",
   width: 100,
   minWidth: 30,
   cellType: "datePickerEditor",
   filterValueColumnName: "strInvestmentVintageDate",
   cellDisplayFormat: "textFilter",
   cellClass : "datePickerRenderer",
   cellRenderer: "datePickerRenderer",
   type: "editableColumn",
   cellStyle: "text-align-center",
   floatingFilter: true,
   lockPinned: true,
   
 }
 const CalcColumn : AgColDefsI =
 {
   displayName: "Calc Name",
   field: "calcName",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "calcName",
   cellDisplayFormat: "textFilter",
   cellClass: "leftAlignment",
   isRequired: false,
   floatingFilter: true,
   editable: false,
   lockPinned: true,
   type: "editableColumn",
 }
 const CalcRuleColumn : AgColDefsI =
 {
   field: "calcRuleName",
   displayName: "Calc Rule",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "calcRuleName",
   filterIdColumnName: "calcRuleId",
   cellClass: "leftAlignment",
   isRequired: false,
   isfilter: true,
   editable: false,
   lockPinned: true,
   type: "editableColumn",
 }
 const CalcDateColumn : AgColDefsI =
 {
   field: "strCalcDate",
   displayName: "Date",
   width: 100,
   minWidth: 30,
   cellRenderer: "datePickerRenderer",
   filterValueColumnName: "strCalcDate",
   cellDisplayFormat: "textFilter",
   cellClass: "leftAlignment",
   floatingFilter: true,
   editable: false,
   lockPinned: true,
   valueFormatter : "dateFormat",
   type: "editableColumn",
 }
 const CalcDescColumn : AgColDefsI =
 {
   field: "calcDesc",
   displayName: "Description",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "calcDesc",
   cellDisplayFormat: "textFilter",
   cellClass: "leftAlignment",
   editable: false,
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const CalcDataSetColumn : AgColDefsI =
 {
   field: "calcDataSet",
   displayName: "Data Set",
   cellClass: "leftAlignment",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "calcDataSet",
   cellDisplayFormat: "textFilter",
   floatingFilter: true,
   editable: false,
   lockPinned: true,
   type: "editableColumn",
 }
 const DistIncomeNumColumn : AgColDefsI =
 {
   field: "distIncomeNum",
   cellClass: "leftAlignment",
   displayName: "Dist Income Num",
   width: 100,
   cellType: "number",
   minWidth: 30,
   filterValueColumnName: "distIncomeNum",
   cellDisplayFormat: "textFilter",
   editable: false,
   floatingFilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const CalcStatusColumn : AgColDefsI =
 {
   field: "calcStatus",
   cellClass: "leftAlignment",
   displayName: "Status",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "calcStatus",
   filterIdColumnName: "calcStatusId",
   editable: false,
   isfilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const DistReAllocEntriesColumn : AgColDefsI =
 {
   field: "distReAllocEntries",
   displayName: "Dist Reallocation Entries",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellStyle: "text-align-center",
   cellRenderer: "checkboxRenderer",
   filterValueColumnName: "distReAllocEntries",
   cellDisplayFormat: "textFilter",
   floatingDropDown: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const LpaEntriesColumn : AgColDefsI =
 {
   field: "lpaEntries",
   displayName: "LPA Entries",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellRenderer: "checkboxRenderer",
   cellStyle: "text-align-center",
   filterValueColumnName: "lpaEntries",
   cellDisplayFormat: "textFilter",
   floatingDropDown: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const IncomeReAllocEntriesColumn : AgColDefsI =
 {
   field: "incomeReAllocEntries",
   displayName: "Income Reallocation Entries",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellStyle: "text-align-center",
   cellRenderer: "checkboxRenderer",
   filterValueColumnName: "incomeReAllocEntries",
   cellDisplayFormat: "textFilter",
   floatingDropDown: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const UnrealizedEntriesColumn : AgColDefsI =
 {
   field: "unrealizedEntries",
   displayName: "Unrealized Entries",
   width: 100,
   minWidth: 30,
   cellType: "checkbox",
   cellRenderer: "checkboxRenderer",
   cellStyle: "text-align-center",
   filterValueColumnName: "unrealizedEntries",
   cellDisplayFormat: "textFilter",
   floatingDropDown: true,
   lockPinned: true,
   type: "editableColumn",
 }
 const RunColumn : AgColDefsI =
 {
   displayName: "Run",
   field: "run",
   width: 100,
   minWidth: 30,
   cellType: "run",
   cellStyle: "text-align-center",
   editable: false,
   sortable: false,
   lockPinned: true,
   type: "editableColumn",
 }
 const PtrTransNameIdColumn : AgColDefsI =
 {
   field: "ptrTransNameId",
   displayName: "Transfer ID",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "ptrTransNameId",
   cellDisplayFormat: "textFilter",
   isRequired: true,
   floatingFilter: true,
   editable: false,
   lockPinned: true,
   type: "editableColumn",
 }
 const PtrTransferNameColumn : AgColDefsI =
 {
   field: "ptrTransferName",
   displayName: "Transfer Name",
   width: 100,
   minWidth: 30,
   filterValueColumnName: "ptrTransferName",
   cellDisplayFormat: "textFilter",
   isRequired: true,
   floatingFilter: true,
   type: "editableColumn",
   lockPinned: true,
   
 }
 const TransferDateColumn : AgColDefsI =
 {
   field: "strTransferDate",
   displayName: "Transfer Date",
   width: 100,
   minWidth: 30,
   isRequired: true,
   cellType: "datePickerEditor",
   cellRenderer: "datePickerRenderer",
   filterValueColumnName: "strTransferDate",
   filterIdColumnName: "transferDate",
   isDateFilterShow: true,
   cellDisplayFormat: "dateFilter",
   isfilter: true,
   type: "editableColumn",
   lockPinned: true,
 }
 const TransferTypeColumn : AgColDefsI =
 {
   field: "transferType",
   displayName: "Transfer Type",
   width: 100,
   minWidth: 30,
   cellType: "dropdown",
   cellRenderer: "dropdownRenderer",
   cellEditor: {
      //   cellEditorData: carryPtrDesignationFilter
      },
   cellClass: "leftAlignment",
   filterValueColumnName: "transferType",
   filterIdColumnName: "transferTypeId",
   // cellEditor: {
   //   // cellEditor: "dropdownRenderer",
   //   cellEditorData: this.transferTypeNameFilter
   // },
   isRequired: true,
   isfilter: true,
   type: "editableColumn",
   lockPinned: true,
 }
 const TransferPerColumn : AgColDefsI =
 {
   field: "transferPer",
   displayName: "Transfer %",
   width: 100,
   minWidth: 30,
   cellType: "number",
   filterValueColumnName: "transferPer",
   cellDisplayFormat: "textFilter",
   cellRenderer: "numericRenderer",
   valueFormatter: "currencyFormat",
   isRequired: true,
   floatingFilter: true,
   type: "editableColumn",
   lockPinned: true,
 }
 const StatusColumn : AgColDefsI =
 {
   field: "status",
   displayName: "Status",
   width: 100,
   minWidth: 30,
   cellType: "dropdown",
   cellRenderer: "dropdownRenderer",
   cellClass: "leftAlignment",
   filterValueColumnName: "status",
   filterIdColumnName: "statusId",
   cellEditor: {
   
   },
   isRequired: true,
   isfilter: true,
   lockPinned: true,
   type: "editableColumn",
 }
export const ColDefsInvestor: AgColDefsI[] = [
   SelectColumn,
   ActionColumn,
   FundNameColumn,
   InvestorColumn,
   VehicleInvestorColumn,
   LEAssociationColumn,
   InvestorCommitmentColumn,
   CommitPerColumn,
   RecCarryColumn,
   FeeEligibleColumn,
   SideLetterColumn,
   SideLetterPromote1Column,
   SideLetterPromote2Column,
   SideLetterPromote3Column,
   SideLetterPref1Column,
   SideLetterPref2Column,
   SideLetterPref3Column,
   ActiveColumn,
   FundInvestorExtIdColumn,
   CommentsColumn
 ];
 export const ColDefsInvestment: AgColDefsI[] = [
   SelectColumn,
   ActionColumn,
   FundNameColumn,
   InvestmentColumn,
   PositionColumn,
   FundInvestmentExtIdColumn,
   FundInvestmentAmtColumn,
   ActiveColumn,
   InvestmentVintageDateColumn,
   CommentsColumn
 ];
 export const ColDefsInvestmentMaster: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  InvestmentFundNameColumn,
  InvestmentInvestmentColumn,
  PositionColumn,
  FundInvestmentExtIdColumn,
  FundInvestmentAmtColumn,
  ActiveColumn,
  InvestmentVintageDateColumn,
  CommentsColumn
];
 export const ColDefsPositions: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  PositionFormColumn,
  ActiveColumn,
  PositionExternalIdColumn,
  CommentsColumn
];
 export const ColDefsCalculations: AgColDefsI[] = [
   SelectColumn,
   ActionColumn,
   FundNameColumn,
   CalcColumn,
   CalcRuleColumn,
   CalcDateColumn,
   CalcDescColumn,
   CalcDataSetColumn,
   DistIncomeNumColumn,
   CalcStatusColumn
 ];
 export const ColDefsCalculationSetting: AgColDefsI[] = [
   SelectColumn,
   ActionColumn,
   FundNameColumn,
   DistReAllocEntriesColumn,
   LpaEntriesColumn,
   IncomeReAllocEntriesColumn,
   UnrealizedEntriesColumn,
   CommentsColumn
 ];
 export const ColDefsPtrTransfer: AgColDefsI[] = [
   SelectColumn,
   ActionColumn,
   FundNameColumn,
   RunColumn,
   PtrTransNameIdColumn,
   PtrTransferNameColumn,
   VehicleInvestorColumn,
   InvestorColumn,
   TransferDateColumn,
   TransferTypeColumn,
   TransferPerColumn,
   StatusColumn,
   CommentsColumn,
 ];
 export const ColDefsFundCommitments: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  DropdownFundNameColumn,
  InvestorInvestorColumn,
  VehicleInvestorColumn,
  LEAssociationColumn,
  InvestorCommitmentColumn,
  CommitPerColumn,
  RecCarryColumn,
  FeeEligibleColumn,
  SideLetterColumn,
  SideLetterPromote1Column,
  SideLetterPromote2Column,
  SideLetterPromote3Column,
  SideLetterPref1Column,
  SideLetterPref2Column,
  SideLetterPref3Column,
  ActiveColumn,
  FundInvestorExtIdColumn,
  CommentsColumn
];
//#region Calculation rules only
const TierOrderColumn : AgColDefsI =
{
  displayName: "Tier Order",
  field: "tierOrder",
  width: 100,
  minWidth: 30,
  // cellType: "number",
  filterValueColumnName:"tierOrder",
  cellDisplayFormat:"textFilter",
  cellRenderer: "numericRenderer",
  cellClass: "rightAlignment",
  cellStyle: "text-align-right",
  floatingFilter: true,
  lockPinned: true,
}
const TierColumn : AgColDefsI =
{
  displayName: "Tier",
  field: "tierName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"tierId",
  filterValueColumnName:"tierName",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
  type: "editableColumn"
}

const TransSetForUploadColumn : AgColDefsI =
{
  displayName: "Trans Set For Upload",
  field: "transSetForUploadName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"transSetForUploadId",
  filterValueColumnName:"transSetForUploadName",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
  type: "editableColumn"
}

const PrefReturnRuleColumn : AgColDefsI =
{
  displayName: "Pref Return Rule",
  field: "prefReturnRuleName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"prefReturnRuleId",
  filterValueColumnName:"prefReturnRuleName",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
}
const ParticipantAlloc : AgColDefsI ={
  field: "participantAlloc",
  displayName: "ParticipantAlloc",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormat",
  filterValueColumnName: "participantAlloc",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: true,
  lockPinned: true
};
const CarriedInterest : AgColDefsI ={
  field: "carriedInterest",
  displayName: "CarriedInterest",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormat",
  filterValueColumnName: "carriedInterest",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: true,
  lockPinned: true
};
const AllocateColumn : AgColDefsI =
{
  displayName: "Allocate",
  field: "allocate",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"allocate",
  filterValueColumnName:"allocate",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
}

const TransactionSetColumn : AgColDefsI =
{
  displayName: "Transaction Set",
  field: "tierName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"transactionSet",
  filterValueColumnName:"tierName",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
  type: "editableColumn",
}
const TransactionSetColumnFundLevel : AgColDefsI =
{
  displayName: "Transaction Set",
  field: "tierName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"tierId",
  filterValueColumnName:"tierName",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
  type: "editableColumn"
}
const ComparisonTierColumn : AgColDefsI =
{
  displayName: "Comparison Tier",
  field: "comparisonTierName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"comparisonTierId",
  filterValueColumnName:"comparisonTierName",
  cellEditor: {
 
  },
  type: "editableColumn",
  isfilter: true,
  lockPinned: true,
}
const RollupLevelColumn : AgColDefsI =
{
  displayName: "Rollup Level",
  field: "rollupLevelName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellDisplayFormat:"textFilter",
  filterIdColumnName: "rollupLevelId",
  filterValueColumnName: "rollupLevelName",
  cellEditor: {},
  isfilter: true,
  type: "editableColumn",
  lockPinned: true,
}

const ApplyForColumn : AgColDefsI =
{
  displayName: "Apply For",
  field: "applyForName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellDisplayFormat:"textFilter",
  filterIdColumnName: "applyForId",
  filterValueColumnName: "applyForName",
  cellEditor: {
   
  },
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}

const ExpAllocationTierColumn : AgColDefsI =
{
  displayName: "Update Proportional %",
  field: "expAllocationTierName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellDisplayFormat:"textFilter",
  filterIdColumnName: "expAllocationTierId",
  filterValueColumnName: "expAllocationTierName",
  cellEditor: {
   
  },
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}

const NumeratorDenominatorColumn : AgColDefsI =
{
  displayName: "Numerator / Denominator",
  field: "numDenom",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"numDenom",
  filterValueColumnName:"numDenom",
  cellEditor: {
  },
  isfilter: true,
  lockPinned: true,
}

const IncludeinBalanceColumn : AgColDefsI =
{
  displayName: "Include in Calc Balance",
  field: "includeinBalanceName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  cellDisplayFormat:"textFilter",
  filterIdColumnName: "includeinBalanceId",
  filterValueColumnName: "includeinBalanceName",
  cellEditor: {},
  isfilter: true,
  type: "editableColumn",
  lockPinned: true,
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
}
const SystemTierColumn : AgColDefsI =
{
  displayName: "Customized Tier",
  field: "systemTier",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"systemTier",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  lockPinned: true,
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
}
const PrefRetTierColumn : AgColDefsI =
{
  displayName: "Pref. Ret. Tier",
  field: "prefRetTier",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"prefRetTier",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}

const CheckBalAmtColumn : AgColDefsI =
{
  displayName: "Check CF Amt",
  field: "checkBalAmt",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"checkBalAmt",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  lockPinned: true,
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
}

const DistProceedAmtColumn : AgColDefsI =
{
  displayName: "Gross Distribution Proceeds",
  field: "distProceedAmt",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"distProceedAmt",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}

const AllocateCarryPromoteAmtColumn : AgColDefsI =
{
  displayName: "Include in Total Carry / Promote",
  field: "allocateCarryPromoteAmt",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"allocateCarryPromoteAmt",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}

const TierCarryPerColumn : AgColDefsI =
{
  displayName: "Carried Interest %  / Promote %",
  field: "tierCarryPer",
  width: 100,
  minWidth: 30,
  cellType: "number",
  valueFormatter: "percentFormat",
  cellRenderer: "numericRenderer",
  filterValueColumnName:"tierCarryPer",
  cellDisplayFormat:"textFilter",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: true, //(params) =>( params.data.applyTierCarryPer == true),
  lockPinned: true,
}
const HurdleRateColumn : AgColDefsI =
{
  displayName: "Pref Ret / Hurdle Rate",
  field: "hurdleRate",
  width: 100,
  minWidth: 30,
  cellType: "number",
  valueFormatter: "percentFormatWith4Dec",
  cellRenderer: "numericRenderer",
  filterValueColumnName:"hurdleRate",
  cellDisplayFormat:"textFilter",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: true,
  lockPinned: true,
}

const TerminationTierColumn : AgColDefsI =
{
  displayName: "Termination Tier",
  field: "terminationTier",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"terminationTier",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}
const CatchupFeatureColumn : AgColDefsI =
{
  displayName: "Catch up Feature",
  field: "catchUpFeature",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  filterValueColumnName:"catchUpFeature",
  cellDisplayFormat:"textFilter",
  cellRenderer: "checkboxRenderer",
  floatingDropDown: true,
  editable: (params) => params.data.prefRetTier == true,
  lockPinned: true,
}
const CatchUpPercentColumn : AgColDefsI =
{
  displayName: "Catch-up %",
  field: "catchUpPercent",
  width: 100,
  minWidth: 30,
  cellType: "number",
  valueFormatter: "percentFormat",
  cellRenderer: "numericRenderer",
  filterValueColumnName:"catchUpPercent",
  cellDisplayFormat:"textFilter",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: true,
  lockPinned: true,
}
const SwitchSignTierAmtColumn : AgColDefsI =
{
  displayName: "Switch Sign-Tier Amt",
  field: "switchSignTierAmt",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"switchSignTierAmt",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}
const SwitchSignTierCompAmtColumn : AgColDefsI =
{
  displayName: "Switch Sign-Tier Comp.Amt",
  field: "switchSignTierCompAmt",
  width: 100,
  minWidth: 30,
  cellType: "checkbox",
  cellStyle: "text-align-center",
  cellRenderer: "checkboxRenderer",
  filterValueColumnName:"switchSignTierCompAmt",
  cellDisplayFormat:"textFilter",
  floatingDropDown: true,
  editable: true,
  lockPinned: true,
}
const LPASubActColumn : AgColDefsI =
{
  displayName: "LPA Sub Act",
  field: "lpaSubActName",
  width: 100,
  minWidth: 30,
  cellType: "dropdown",
  cellRenderer: "dropdownRenderer",
  cellClass: "leftAlignment",
  filterIdColumnName:"lpaSubActID",
  filterValueColumnName:"lpaSubActName",
  cellEditor: {
 
  },
  isRequired: true,
  isfilter: true,
  editable: true,
  lockPinned: true,
}

// const OperatorColumn : AgColDefsI =
// {
//   displayName: "Operator",
//   field: "operator",
//   width: 100,
//   minWidth: 30,
//   cellType: "dropdown",
//   filterValueColumnName:"operator",
//   cellRenderer: "dropdownRenderer",
//   cellClass: "leftAlignment",
//   cellEditor: {
  
//   },
//   isfilter: true,
//   type: "editableColumn",
//   lockPinned: true,
//   cellClassRules: {
//     blueBackground: (params) => {
//       return params.data.isSumRow == true;
//     }
//   },
// }

export const colDefsTier: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  TierOrderColumn,
  TierColumn,
  TransSetForUploadColumn,
  ComparisonTierColumn,
  RollupLevelColumn,

  ExpAllocationTierColumn,
  IncludeinBalanceColumn,
  PrefRetTierColumn,
  HurdleRateColumn,
  // ApplyTierCarryPerColumn,
  TierCarryPerColumn,
  TerminationTierColumn,
  CatchupFeatureColumn,
  CatchUpPercentColumn,
  SwitchSignTierAmtColumn,
  SwitchSignTierCompAmtColumn,
  LPASubActColumn,
  CommentsColumn,
  // OperatorColumn,
  PrefReturnRuleColumn,
  CheckBalAmtColumn,
  DistProceedAmtColumn,
  AllocateCarryPromoteAmtColumn,
  AllocateColumn
];
export const colDefsTierFilter: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Tier",
    field: "tier",
    width: 100,
    minWidth: 30,
    cellType: "dropdown",
    cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    filterIdColumnName:"tierId",
    filterValueColumnName:"tier",
    cellEditor: {
     
    },
    isfilter: true,
    type: "editableColumn",
    lockPinned: true,
  },
  ApplyForColumn,
  {
    displayName: "Filter Field",
    field: "filterField",
    width: 100,
    minWidth: 30,
    cellType: "dropdown",
    filterValueColumnName:"filterField",
    filterIdColumnName:"filterFieldId",
    cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    cellEditor: {
    
    },
    isfilter: true,
    type: "editableColumn",
    lockPinned: true,
  },
  {
    displayName: "Filter Criteria",
    field: "filterCriteria",
    width: 100,
    minWidth: 30,
    cellType: "dropdown",
    filterValueColumnName:"filterCriteria",
    cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    cellEditor: {
    
    },
    isfilter: true,
    type: "editableColumn",
    lockPinned: true,
  },
  {
    displayName: "Value",
    field: "value",
    width: 100,
    filterValueColumnName:"value",
    cellDisplayFormat:"textFilter",
    minWidth: 30,
    floatingFilter: true,
    lockPinned: true,
  },
];


export const colDefsPrefRetStructure: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    
    displayName: "Preferred Return Information",
    field: "prefRetInfo",
    width: 100,
    filterValueColumnName:"prefRetInfo",
    cellDisplayFormat:"textFilter",
    minWidth: 30,
    floatingFilter: true,
    lockPinned: true,
  },
  
  {
    displayName: "Level",
    field: "prefRetLevel",
    width: 100,
    minWidth: 30,
    // cellType: "number",
    filterValueColumnName:"prefRetLevel",
    cellDisplayFormat:"textFilter",
    cellRenderer: "numericRenderer",
    cellClass: "rightAlignment",
    cellStyle: "text-align-right",
    floatingFilter: true,
    lockPinned: true,
  },
  {
    displayName: "Begin Hurdle Rate",
    field: "beginHurdleRate",
    width: 100,
    minWidth: 30,
    cellType: "number",
    valueFormatter: "percentFormatWith4Dec",
    cellRenderer: "numericRenderer",
    filterValueColumnName:"hurdleRate",
    cellDisplayFormat:"textFilter",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: true,
    lockPinned: true,
  },
  {
    displayName: "End Hurdle Rate",
    field: "endHurdleRate",
    width: 100,
    minWidth: 30,
    cellType: "number",
    valueFormatter: "percentFormatWith4Dec",
    cellRenderer: "numericRenderer",
    filterValueColumnName:"hurdleRate",
    cellDisplayFormat:"textFilter",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: true,
    lockPinned: true,
  },
  {
    displayName: "Promote / Carry %",
    field: "promoteCarryPer",
    width: 100,
    minWidth: 30,
    cellType: "number",
    valueFormatter: "percentFormatWith4Dec",
    cellRenderer: "numericRenderer",
    filterValueColumnName:"promoteCarryPer",
    cellDisplayFormat:"textFilter",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: true,
    lockPinned: true,
  }
];

//#endregion calculation rules only
//#region  tier only
export const colDefsTierComponents: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Tier ID",
    field: "tierId",
    floatingFilter: true,
    filterValueColumnName: "tierId",
    cellDisplayFormat:"textFilter",
    width: 150,
    hide : true
  },
  {
    displayName: "Tier Sub Act ID",
    field: "id",
    width: 150,
    filterValueColumnName: 'id',
    cellDisplayFormat:"textFilter",
    floatingFilter: true,
    lockPinned: true,
    hide : true
  },
  {
    displayName: "Sub Act",
    field: "subActName",
    width: 150,
    cellType: "dropdown",
    cellRenderer: "dropdownRenderer",
    filterValueColumnName: "subActName",
    filterIdColumnName: "subActId",
    cellEditor: {
    
    },
    cellClass: "leftAlignment",
    isfilter: true,
    lockPinned: true,
  },
];
//#endregion
//#region performance rules only
export const colDefsTransactionSet: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  TierColumn,
  RollupLevelColumn,
  NumeratorDenominatorColumn,
  {
    displayName: "Switch Pos / Neg Sign",
    field: "switchSign",
    width: 150,
    cellClass: "leftAlignment",
    filterValueColumnName: "switchSign",
    cellDisplayFormat:"textFilter",
    cellType: "checkbox",
    floatingDropDown: true,
    lockPinned: true,
  },
 
  CommentsColumn
];

export const colDefsTransactionSetFilter: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  TierColumn,
  {
    displayName: "Filter Field",
    field: "filterField",
    cellType: "dropdown",
    cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    filterValueColumnName: "filterField",
    filterIdColumnName: "filterFieldId",
    cellEditor: {
    
    },
    width: 150,
    isfilter: true,
    lockPinned: true,
  },
  {
    displayName: "Filter Criteria",
    field: "filterCriteria",
    width: 150,
    cellType: "dropdown",
    cellRenderer: "dropdownRenderer",
    filterValueColumnName: "filterCriteria",
    filterIdColumnName: "",
    cellEditor: {
     
    },
    cellClass: "leftAlignment",
    isfilter: true,
    lockPinned: true,
  },
  {
    displayName: "Value",
    field: "filterValue",
    width: 150,
    cellClass: "leftAlignment",
    filterValueColumnName: "filterValue",
    cellDisplayFormat:"textFilter",
    floatingFilter: true,
    lockPinned: true,
  },
];
//#endregion 
//#region lookups
export const colDefsLookupDayBasisSetting: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Day Basic Setting",
    field: "dayBasisSettingName",
    floatingFilter: true,
    filterValueColumnName: "dayBasisSettingName",
    cellDisplayFormat:"textFilter",
    width: 150,
  },
];
export const colDefsLookupFundCurrency: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "ISOCode",
    field: "isoCode",
    floatingFilter: true,
    filterValueColumnName: "isoCode",
    cellDisplayFormat:"textFilter",
    width: 150,
  },
  {
    displayName: "Currency Name",
    field: "currencyName",
    floatingFilter: true,
    filterValueColumnName: "currencyName",
    cellDisplayFormat:"textFilter",
    width: 150,
  },
];
export const colDefsLookupFundGroup: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Fund Group",
    field: "fundGroupName",
    floatingFilter: true,
    filterValueColumnName: "fundGroupName",
    cellDisplayFormat:"textFilter",
    width: 150,
  },
];
export const colDefsLookupFundType: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Fund Type",
    field: "fundTypeName",
    floatingFilter: true,
    filterValueColumnName: "fundTypeName",
    cellDisplayFormat:"textFilter",
    width: 150,
  },
];
export const colDefsLookupInvestmentClassification: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Investment Classification",
    field: "investmentClassificationName",
    floatingFilter: true,
    filterValueColumnName: "investmentClassificationName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
];
export const colDefsLookupInvestmentGroup: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Investment Group",
    field: "investmentGroupName",
    floatingFilter: true,
    filterValueColumnName: "investmentGroupName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
];
export const colDefsLookupInvestorClassification: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Investor Classification",
    field: "investorClassificationName",
    floatingFilter: true,
    filterValueColumnName: "investorClassificationName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
];
export const colDefsLookupInvestorGroup: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Investor Group",
    field: "investorGroupName",
    floatingFilter: true,
    filterValueColumnName: "investorGroupName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
];
export const colDefsLookupInvestorVehicle: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Fund Investor Group",
    field: "vehicleInvestorName",
    floatingFilter: true,
    filterValueColumnName: "vehicleInvestorName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
];
export const colDefsLookupSubAccount: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Sub Act Name",
    field: "subActName",
    floatingFilter: true,
    filterValueColumnName: "subActName",
    cellDisplayFormat:"textFilter",
    width: 150,
  },
  {
    displayName: "IsLPA",
    field: "isLPA",
    floatingFilter: true,
    cellType: "checkbox",
    filterValueColumnName: "isLPA",
    // cellDisplayFormat:"textFilter",
    width: 150,
  },
  {
    displayName: "GLAct",
    field: "glAct",
    floatingFilter: true,
    filterValueColumnName: "glAct",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
];
export const colDefsLookupTierComponent: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "TierComponent",
    field: "tierComponentName",
    floatingFilter: true,
    filterValueColumnName: "tierComponentName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
  
];
export const colDefsLookupDoamin: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Domain",
    field: "domainName",
    floatingFilter: true,
    filterValueColumnName: "domainName",
    cellDisplayFormat:"textFilter",
    width: 150,
  }
  
];
//#region Transactions defs
const TransIdColumn : AgColDefsI =
{
  displayName: "TransID",
  field: "id",
  width: 120,
  minWidth: 30,
  type: "editableColumn",
  floatingFilter: true,
  filterValueColumnName: "id",
  cellDisplayFormat: "textFilter"
}
const JournalIdColumn : AgColDefsI =
{
  displayName: "Journal ID",
  field: "journalId",
  width: 120,
  minWidth: 30,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "leftAlignment",
  filterValueColumnName: "journalId",
  cellDisplayFormat: "textFilter"
}
const SubJournalIdColumn : AgColDefsI =
{
  displayName: "Sub Journal ID",
  field: "subJournalId",
  width: 120,
  minWidth: 30,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "leftAlignment",
  filterValueColumnName: "subJournalId",
  cellDisplayFormat: "textFilter"
}
const VehicleInvestorNameColumn : AgColDefsI =
{
  displayName: "Investor Group",
  field: "vehicleInvestorName",
  width: 120,
  minWidth: 30,
  isfilter: true,
  isRequired: true,
  type: "editableColumn",
  cellType: "dropdown",
  cellRenderer: 'transactionDropdownRenderer',
  cellClass: 'leftAlignment',
  filterValueColumnName: "vehicleInvestorName",
  filterIdColumnName: "vehicleInvestorId",
  cellEditor: {
    // // cellEditor: "dropdownRenderer",
    // cellEditorData: this.agvehicalData,
    // cellEditorId: this.agvehicalId,
  },
}
const TransactionInvestorColumn : AgColDefsI =
{
  displayName: "Investor",
  field: "investorName",
  width: 120,
  minWidth: 30,
  isfilter: true,
  isRequired: true,
  type: "editableColumn",
  cellType: "dropdown",
  cellRenderer: 'transactionDropdownRenderer',
  cellClass: 'leftAlignment',
  filterValueColumnName: "investorName",
  filterIdColumnName: "investorId",
  cellEditor: { },
}
const CalculationNameColumn : AgColDefsI =
{
  displayName: "Calculation Name",
  field: "calculationName",
  width: 120,
  minWidth: 30,
  cellClass: "leftAlignment",
  //floatingFilter: true,
  filterValueColumnName: "calculationName",
  filterIdColumnName: "calcId",
  cellDisplayFormat: "textFilter",
  editable: false,
  lockPinned: true,
  hide : false,
  isfilter: true
}
const TransactionInvestmentColumn : AgColDefsI =
{
  displayName: "Investment",
  field: "investmentName",
  width: 120,
  minWidth: 30,
  isfilter: true,
  isRequired: true,
  type: "editableColumn",
  cellType: "dropdown",
  cellRenderer: 'transactionDropdownRenderer',
  cellClass: 'leftAlignment',
  filterValueColumnName: "investmentName",
  filterIdColumnName: "investmentId",
  cellEditor: {},
}
const TransactionPositionColumn : AgColDefsI =
{
  displayName: "Position",
  field: "positionName",
  width: 120,
  minWidth: 30,
  isfilter: true,
  isRequired: true,
  type: "editableColumn",
  cellType: "dropdown",
  cellRenderer: 'transactionDropdownRenderer',
  cellClass: 'leftAlignment',
  filterValueColumnName: "positionName",
  filterIdColumnName: "positionId",
  cellEditor: {
  }
}
const TransactionDateColumn : AgColDefsI =
{
  displayName: "Transaction Date",
  field: "transactionDate",
  width: 120,
  minWidth: 30,
  cellType: "datePickerEditor",
  cellRenderer: 'datePickerRenderer',
  type: "editableColumn",
  isfilter: true,
  floatingFilter: true,
  // cellStyle: "text-align-center",
  // cellClass: "ExcelDate",
  filterValueColumnName: "transactionDate",
  cellDisplayFormat: "textFilter",
  isDateFilterShow: true
}

const TransactionDistIncomeNumColumn : AgColDefsI =
{
  displayName: "Dist Income Num",
  field: "distIncomeNum",
  valueFormatter: "currencyFormat",
  width: 120,
  minWidth: 30,
  cellType: "number",
  cellRenderer: 'numericRenderer',
  cellClass: "rightAlignment",
  type: "editableColumn",
  floatingFilter: true,
  filterValueColumnName: "distIncomeNum",
  cellDisplayFormat: "textFilter",
}
const TransactionAmountColumn : AgColDefsI =
{
  displayName: "Amount",
  field: "amt",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: 'negativeInBrackets',
  filterValueColumnName: "amt",
  cellDisplayFormat: "textFilter"
}

const TransactionImportAmountColumn : AgColDefsI =
{
displayName: "Amount",
field: "amt",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "rightAlignment",
filterValueColumnName: "amt",
cellDisplayFormat: "textFilter"
}

const LocalAmtImportColumn : AgColDefsI =
{
displayName: "Local Amount",
field: "localAmt",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "rightAlignment",
filterValueColumnName: "localAmt",
cellDisplayFormat: "textFilter"
}
const LocalAmtColumn : AgColDefsI =
{
  displayName: "Local Amount",
  field: "localAmt",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "localAmt",
  cellDisplayFormat: "textFilter",
}
const FXRateImportColumn : AgColDefsI =
{
displayName: "FX Rate",
field: "fxRate",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "rightAlignment",
filterValueColumnName: "fxRate",
cellDisplayFormat: "textFilter"
}
const FXRateColumn : AgColDefsI =
{
  displayName: "FX Rate",
  field: "fxRate",
  valueFormatter: "currencyFormatWith6Dec",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "fxRate",
  cellDisplayFormat: "textFilter",
}
const ImportuserIdColumn : AgColDefsI =
{
  displayName: "Created By",
  field: "importUserId",
  width: 120,
  minWidth: 30,
  floatingFilter: true,
  editable: false,
  type: "editableColumn",
  cellClass: "leftAlignment",
  filterValueColumnName: "importUserId",
  cellDisplayFormat: "textFilter",
}
const ImportDateColumn : AgColDefsI =
{
  displayName: "Created Date",
    field: "importDate",
    width: 120,
    minWidth: 30,
    cellType: "datePickerEditor",
    cellRenderer: 'datePickerRenderer',
    type: "editableColumn",
    isfilter: true,
    floatingFilter: true,
    // cellStyle: "text-align-center",
    // cellClass: "ExcelDate",
    filterValueColumnName: "importDate",
    cellDisplayFormat: "dateFilter",
    isDateFilterShow: true,
  
  }
const LotColumn : AgColDefsI =
{
displayName: "Lot",
field: "lot",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "lot",
cellDisplayFormat: "textFilter"
}
const BookingDateColumn : AgColDefsI =
{
displayName: "GL Date",
  field: "glDate",
  width: 120,
  minWidth: 30,
  cellType: "datePickerEditor",
  cellRenderer: 'datePickerRenderer',
  type: "editableColumn",
  isfilter: true,
  floatingFilter: true,
  // cellStyle: "text-align-center",
  // cellClass: "ExcelDate",
  filterValueColumnName: "glDate",
  cellDisplayFormat: "dateFilter",
  isDateFilterShow: true,

}
const PtrTransColumn : AgColDefsI =
{
displayName: "Ptr Trans",
field: "ptrTrans",
cellType: "checkbox",
width: 120,
minWidth: 30,
floatingDropDown: true,
cellClass: "leftAlignment",
type: "editableColumn",
cellRenderer: 'checkboxRenderer',
filterValueColumnName: "ptrTrans",
cellDisplayFormat: "textFilter",
}
const TransTxt1Column : AgColDefsI =
{
displayName: "transTxt1",
field: "transTxt1",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "transTxt1",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransTxt2Column : AgColDefsI =
{
displayName: "transTxt2",
field: "transTxt2",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "transTxt2",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransTxt3Column : AgColDefsI =
{
displayName: "transTxt3",
field: "transTxt3",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "transTxt3",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransTxt4Column : AgColDefsI =
{
displayName: "transTxt4",
field: "transTxt4",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "transTxt4",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransTxt5Column : AgColDefsI =
{
displayName: "transTxt5",
field: "transTxt5",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "transTxt5",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransTxt6Column : AgColDefsI =
{
displayName: "transTxt6",
field: "transTxt6",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "transTxt6",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransNum1Column : AgColDefsI =
{
displayName: "transNum1",
field: "transNum1",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
cellRenderer: 'numericRenderer',
valueFormatter: "currencyFormat",
filterValueColumnName: "transNum1",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransNum2Column : AgColDefsI =
{
displayName: "transNum2",
field: "transNum2",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
cellRenderer: 'numericRenderer',
valueFormatter: "currencyFormat",
filterValueColumnName: "transNum2",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransNum3Column : AgColDefsI =
{
displayName: "transNum3",
field: "transNum3",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
cellRenderer: 'numericRenderer',
valueFormatter: "currencyFormat",
filterValueColumnName: "transNum3",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransNum4Column : AgColDefsI =
{
displayName: "transNum4",
field: "transNum4",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
cellRenderer: 'numericRenderer',
valueFormatter: "currencyFormat",
filterValueColumnName: "transNum4",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransNum5Column : AgColDefsI =
{
displayName: "transNum5",
field: "transNum5",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
cellRenderer: 'numericRenderer',
valueFormatter: "currencyFormat",
filterValueColumnName: "transNum5",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransNum6Column : AgColDefsI =
{
displayName: "transNum6",
field: "transNum6",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
cellRenderer: 'numericRenderer',
valueFormatter: "currencyFormat",
filterValueColumnName: "transNum6",
cellDisplayFormat: "textFilter",
isUDFField: true
}
const TransDate1Column : AgColDefsI =
{
displayName: "transDate1",
  field: "transDate1",
  width: 120,
  minWidth: 30,
  cellType: "datePickerEditor",
  cellRenderer: 'datePickerRenderer',
  type: "editableColumn",
  isfilter: true,
  floatingFilter: true,
  // cellStyle: "text-align-center",
  // cellClass: "ExcelDate",
  filterValueColumnName: "transDate1",
  cellDisplayFormat: "dateFilter",
  isDateFilterShow: true,
  isUDFField: true,
}
const TransDate2Column : AgColDefsI =
{
  displayName: "transDate2",
  field: "transDate2",
  width: 120,
  minWidth: 30,
  cellType: "datePickerEditor",
  cellRenderer: 'datePickerRenderer',
  type: "editableColumn",
  isfilter: true,
  floatingFilter: true,
  // cellStyle: "text-align-center",
  // cellClass: "ExcelDate",
  filterValueColumnName: "transDate2",
  cellDisplayFormat: "dateFilter",
  isDateFilterShow: true,
  isUDFField: true,
}
const TransDate3Column : AgColDefsI =
{
  displayName: "transDate3",
  field: "transDate3",
  width: 120,
  minWidth: 30,
  cellType: "datePickerEditor",
  cellRenderer: 'datePickerRenderer',
  type: "editableColumn",
  isfilter: true,
  floatingFilter: true,
  // cellStyle: "text-align-center",
  // cellClass: "ExcelDate",
  filterValueColumnName: "transDate3",
  cellDisplayFormat: "dateFilter",
  isDateFilterShow: true,
  isUDFField: true,
}
const TransDate4Column : AgColDefsI =
{
  displayName: "transDate4",
  field: "transDate4",
  width: 120,
  minWidth: 30,
  cellType: "datePickerEditor",
  cellRenderer: 'datePickerRenderer',
  type: "editableColumn",
  isfilter: true,
  floatingFilter: true,
  // cellStyle: "text-align-center",
  // cellClass: "ExcelDate",
  filterValueColumnName: "transDate4",
  cellDisplayFormat: "dateFilter",
  isDateFilterShow: true,
  isUDFField: true,
}
const ErrorMessageColumn : AgColDefsI =
{
  displayName: "Error Message",
  field: "errorMessage",
  cellClass: "colorRed",
  width: 400,
  editable: false,
  sortable: false,
  floatingFilter: true,
  filterValueColumnName:"errorMessage",
  cellDisplayFormat:"textFilter"
}
const TransUploadIdColumn : AgColDefsI =
{
  displayName: "TransUploadID",
  field: "id",
  width: 120,
  editable: false,
  // cellStyle: "text-align-right",
  floatingFilter: true,
  filterValueColumnName:"id",
  cellDisplayFormat:"textFilter"
}

const DataSetColumn : AgColDefsI =
{
displayName: "Dataset",
field: "dataSet",
width: 120,
minWidth: 30,
floatingFilter: true,
type: "editableColumn",
cellClass: "leftAlignment",
filterValueColumnName: "dataSet",
cellDisplayFormat: "textFilter",
isUDFField: true
}


//#endregion

export const ColDefsTransactionLevel: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  TransIdColumn,
  CalculationNameColumn,
  JournalIdColumn,
  SubJournalIdColumn,
  FundNameColumn,
  VehicleInvestorNameColumn,
  TransactionInvestorColumn,
  TransactionInvestmentColumn,
  TransactionPositionColumn,
  TransactionSetColumn,
  TransactionDateColumn,
  TransactionDistIncomeNumColumn,
  TransactionAmountColumn,
  DataSetColumn,
  LocalAmtColumn,
  FXRateColumn,
  StatusColumn,
  ImportuserIdColumn,
  ImportDateColumn,
  CommentsColumn,
  LotColumn,
  BookingDateColumn,
  PtrTransColumn,
  TransTxt1Column,
  TransTxt2Column,
  TransTxt3Column,
  TransTxt4Column,
  TransTxt5Column,
  TransTxt6Column,
  TransNum1Column,
  TransNum2Column,
  TransNum3Column, 
  TransNum4Column,
  TransNum5Column,
  TransNum6Column,
  TransDate1Column,
  TransDate2Column,
  TransDate3Column,
  TransDate4Column,
  {
    field: "isFromCalcJE",
    displayName: "From Calculation JE",
    width: 120,
    minWidth:30,
    cellType: "checkbox",
    cellStyle: "text-align-center",
    filterValueColumnName:"isFromCalcJE",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    editable:false,
  },
];
export const ColDefsFundLevel: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  JournalIdColumn,
  FundNameColumnFundLevel,
  InvestmentColumnFundLevel,
  PositionColumnFundLevel,
  TransactionSetColumnFundLevel,
  TransactionDateColumn,
  DistIncomeNumColumn,
  TransactionAmountColumn,
  LocalAmtColumn,
  FXRateColumn,
  StatusColumn,
  ImportuserIdColumn,
  ImportDateColumn,
  CommentsColumn,
  LotColumn,
  BookingDateColumn,
  PtrTransColumn,
  TransTxt1Column,
  TransTxt2Column,
  TransTxt3Column,
  TransTxt4Column,
  TransTxt5Column,
  TransTxt6Column,
  TransNum1Column,
  TransNum2Column,
  TransNum3Column, 
  TransNum4Column,
  TransNum5Column,
  TransNum6Column,
  TransDate1Column,
  TransDate2Column,
  TransDate3Column,
  TransDate4Column
];
//#endregion
//#region fund module impoorts
export const colDefsInvestorImport: AgColDefsI[] = [
  {
    displayName: "Error Message",
    field: "errorMessage",
    cellClass: "colorRed",
    filterValueColumnName:"errorMessage",
    cellDisplayFormat:"textFilter",
    width: 120,
    minWidth:30,
    editable: false,
    sortable: false,
    floatingFilter: true,
  },
  {
    displayName: "Fund InvestorID",
    field: "fundInvestorExtId",
    width: 120,
    filterValueColumnName:"fundInvestorExtId",
    minWidth:30,
    editable: false,
    sortable: false,
    floatingFilter: true,
    cellDisplayFormat:"textFilter",
    cellClass: "leftAlignment",
    hide : true
  },
  {
    displayName: "Fund",
    field: "fundName",
    width: 120,
    minWidth:30,
    cellClass: "leftAlignment",
    filterValueColumnName:'fundName',
    filterIdColumnName:"fundName",
    editable: false,
    sortable: false,
    isfilter: true,
  },
  {
    field: "investorName",
    displayName: "Investor",
    width: 120,
    minWidth:30,
    cellClass: "leftAlignment",
    filterValueColumnName:"investorName",
    filterIdColumnName:"investorName",
    editable: false,
    sortable: false,
    isfilter: true,
  },
  {
    field: "investorGroup",
    displayName: "Investor Group",
    width: 120,
    minWidth:30,
    cellClass: "leftAlignment",
    filterValueColumnName:'investorGroup',
    filterIdColumnName:"investorGroup",
    editable: false,
    sortable: false,
    isfilter: true,
  },
  {
    field: "commitment",
    displayName: "Commitment",
    cellClass: "leftAlignment",
    valueFormatter: "currencyFormat",
    width: 120,
    filterValueColumnName:"commitment",
    cellDisplayFormat:"textFilter",
    minWidth:30,
    cellStyle: "text-align-center",
    floatingFilter: true,
  },
  {
    field: "recCarry",
    displayName: "Receives Carry",
    width: 120,
    minWidth:30,
    cellType: "checkbox",
    cellStyle: "text-align-center",
    filterValueColumnName:"recCarry",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    editable:false,
  },
  {
    field: "feeEligible",
    displayName: "Fee Eligible",
    width: 120,
    minWidth:30,
    cellType: "checkbox",
    cellStyle: "text-align-center",
    filterValueColumnName:"feeEligible",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    editable:false,
  },
  {
    field: "sideLetter",
    displayName: "Special Alloc",
    width: 120,
    minWidth:30,
    cellType: "checkbox",
    cellStyle: "text-align-center",
    filterValueColumnName:"sideLetter",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    editable: false,
  },
  {
    field: "sideLetterPromote1",
    displayName: "Side Letter Promote for 1st Hurdle",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    valueFormatter:"currencyFormat",
    floatingFilter: true,
    filterValueColumnName:"sideLetterPromote1",
    cellDisplayFormat:"percentFilter",
  },
  {
    field: "sideLetterPromote2",
    displayName: "Side Letter Promote for 2nd Hurdle",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    valueFormatter:"currencyFormat",
    floatingFilter: true,
    filterValueColumnName:"sideLetterPromote2",
    cellDisplayFormat:"percentFilter",
  },
  {
    field: "sideLetterPromote3",
    displayName: "Side Letter Promote for 3rd Hurdle",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    valueFormatter:"currencyFormat",
    floatingFilter: true,
    filterValueColumnName:"sideLetterPromote3",
    cellDisplayFormat:"percentFilter",
  },
  {
    field: "sideLetterPref1",
    displayName: "Side Letter Pref Ret for 1st Hurdle",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    valueFormatter:"currencyFormat",
    filterValueColumnName:"sideLetterPref1",
    cellDisplayFormat:"percentFilter",
    floatingFilter: true,
    editable: false,
  },
  {
    field: "sideLetterPref2",
    displayName: "Side Letter Pref Ret for 2nd Hurdle",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    valueFormatter:"currencyFormat",
    filterValueColumnName:"sideLetterPref2",
    cellDisplayFormat:"percentFilter",
    floatingFilter: true,
    editable: false,
  },
  {
    field: "sideLetterPref3",
    displayName: "Side Letter Pref Ret for 3rd Hurdle",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    valueFormatter:"currencyFormat",
    filterValueColumnName:"sideLetterPref3",
    cellDisplayFormat:"percentFilter",
    floatingFilter: true,
    editable: false,
  },
  {
    field: "active",
    displayName: "Active",
    width: 120,
    minWidth:30,
    cellType: "checkbox",
    cellStyle: "text-align-center",
    filterValueColumnName:"active",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    editable: false,
  },
  {
    field: "fundInvestorExtId",
    displayName: "Fund Investor Ext. ID",
    valueFormatter:"currencyFormat",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    floatingFilter: true,
    filterValueColumnName:"fundInvestorExtId",
    cellDisplayFormat:"textFilter",
    editable: false,
  },
  {
    field: "comments",
    displayName: "Comments",
    width: 120,
    minWidth:30,
    floatingFilter: true,
    filterValueColumnName:"comments",
    cellDisplayFormat:"textFilter",
    editable: false,
  },
  {
    field: "importDate",
    displayName: "Created Date",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    cellRenderer: "datePickerRenderer",
    floatingFilter: true,
    filterValueColumnName:"importDate",
    cellDisplayFormat:"textFilter",
    editable: false,
  },
];

export const colDefsInvestmentImport: AgColDefsI[] = [
  {
    displayName: "Error Message",
    field: "errorMessage",
    cellClass: "colorRed",
    width: 120,
    filterValueColumnName:"errorMessage",
    cellDisplayFormat:"textFilter",
    minWidth:30,
    editable: false,
    sortable: false,
    floatingFilter: true,
  },
  {
    displayName: "fundInvestmentId",
    field: "fundInvestmentId",
    filterValueColumnName:"fundInvestmentId",
    cellDisplayFormat:"textFilter",
    width: 120,
    minWidth:30,
    editable: false,
    sortable: false,
    floatingFilter: true,
    hide : true
  },
  {
    displayName: "Fund",
    field: "fundName",
    width: 120,
    minWidth:30,
    cellClass: "leftAlignment",
    filterValueColumnName:"fundName",
    filterIdColumnName:"fundName",
    editable:false,
    isfilter: true,
  },
  {
    field: "investmentName",
    displayName: "Investment",
    width: 120,
    minWidth:30,
    filterValueColumnName:"investmentName",
    filterIdColumnName:"investmentName",
    cellClass: "leftAlignment",
    editable:false,
    isfilter: true,
  },
  {
    field: "positionName",
    displayName: "Position",
    property:"",
    width: 120,
    filterValueColumnName:"positionName",
    filterIdColumnName:"positionName",
    minWidth:30,
    cellClass: "leftAlignment",
    editable:false,
    isfilter: true,
  },
 
  {
    field: "fundInvestmentExtId",
    displayName: "External ID",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    editable:false,
    valueFormatter:"currencyFormat",
    filterValueColumnName:"fundInvestmentExtId",
    cellDisplayFormat:"textFilter",
    floatingFilter: true,
  },
  {
    field: "fundInvestmentAmt",
    displayName: "Amount",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    editable:false,
    valueFormatter:"currencyFormat",
    filterValueColumnName:"fundInvestmentAmt",
    cellDisplayFormat:"textFilter",
    floatingFilter: true,
  },
  {
    field: "active",
    displayName: "Active",
    width: 120,
    minWidth:30,
    cellType: "checkbox",
    cellStyle: "text-align-center",
    filterValueColumnName:"active",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    editable:false,
  },
  {
    field: "investmentVintageDate",
    displayName: "Vintage Date",
    width: 120,
    minWidth:30,
    cellStyle: "text-align-right",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName:"investmentVintageDate",
    cellDisplayFormat:"textFilter",
    isfilter: true,
    editable:false,
  },
  {
    field: "comments",
    displayName: "Comments",
    filterValueColumnName:"comments",
    cellDisplayFormat:"textFilter",
    width: 120,
    minWidth:30,
    floatingFilter: true,
    editable:false,
  },
  {
    field: "importDate",
    displayName: "Created Date",
    width: 120,
    cellRenderer: "datePickerRenderer",
    filterValueColumnName:"importDate",
    cellDisplayFormat:"textFilter",
    minWidth:30,
    editable:false,
  },
];
//#endregion
//#region  Import Transactions
export const ColDefsTransactionImport: AgColDefsI[] = [
  ErrorMessageColumn,
  TransUploadIdColumn,
  TransIdColumn,
  JournalIdColumn,
  SubJournalIdColumn,
  {
    displayName: "Fund",
    field: "fund",
    width: 120,
    isfilter: true,
    isRequired: true,
    editable: false,
    filterValueColumnName:"fund",
    filterIdColumnName:"fundId"
  },
  {
    displayName: "Fund Investor",
    field: "fundInvestor",
    width: 120,
    isfilter: true,
    isRequired: true,
    editable: false,
    floatingFilter: true,
    filterValueColumnName:"fundInvestor",
    cellDisplayFormat:"textFilter"
  },
  {
    displayName: "Investor Group",
    field: "vehicleInvestor",
    width: 120,
    isfilter: true,
    isRequired: true,
    editable: false,
    floatingFilter: true,
    filterValueColumnName:"vehicleInvestor",
    cellDisplayFormat:"textFilter"
  },
  {
    displayName: "Fund Investment",
    field: "fundInvestment",
    width: 120,
    isfilter: true,
    isRequired: true,
    editable: false,
    floatingFilter: true,
    filterValueColumnName:"fundInvestment",
    cellDisplayFormat:"textFilter"
  },
  {
    displayName: "Position",
    field: "position",
    width: 120,
    isfilter: true,
    isRequired: true,
    editable: false,
    floatingFilter: true,
    filterValueColumnName:"position",
    cellDisplayFormat:"textFilter"
  },
  {
    displayName: "Transaction Set",
    field: "tier",
    width: 120,
    isfilter: true,
    isRequired: true,
    editable: false,
    floatingFilter: true,
    filterValueColumnName:"tier",
    cellDisplayFormat:"textFilter"
  },
  {
    displayName: "Transaction Date",
    field: "transactionDate",
    width: 120,
    minWidth: 30,
    isfilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "transactionDate",
    valueFormatter : "dateFormat"
  },
  TransactionDistIncomeNumColumn,
  TransactionImportAmountColumn,
  LocalAmtImportColumn,
  FXRateImportColumn,
  StatusColumn,
  ImportuserIdColumn,
  {
    displayName: "Created Date",
    field: "importDate",
    width: 120,
    minWidth: 30,
    isfilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "importDate",
  },
  CommentsColumn,
  LotColumn,
  {
    displayName: "GL Date",
    field: "glDate",
    width: 120,
    minWidth: 30,
    isfilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "glDate",
  },
  PtrTransColumn,
  TransTxt1Column,
  TransTxt2Column,
  TransTxt3Column,
  TransTxt4Column,
  TransTxt5Column,
  TransTxt6Column,
  TransNum1Column,
  TransNum2Column,
  TransNum3Column, 
  TransNum4Column,
  TransNum5Column,
  TransNum6Column,
  {
    displayName: "transDate1",
    field: "transDate1",
    width: 120,
    minWidth: 30,
    floatingFilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "transDate1",
    cellDisplayFormat: "dateFilter",
    isDateFilterShow: true,
    isUDFField: true
  },
  {
    displayName: "transDate2",
    field: "transDate2",
    width: 120,
    minWidth: 30,
    floatingFilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "transDate2",
    cellDisplayFormat: "dateFilter",
    isDateFilterShow: true,
    isUDFField: true
  },
  {
    displayName: "transDate3",
    field: "transDate3",
    width: 120,
    minWidth: 30,
    floatingFilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "transDate3",
    cellDisplayFormat: "dateFilter",
    isDateFilterShow: true,
    isUDFField: true
  },
  {
    displayName: "transDate4",
    field: "transDate4",
    width: 120,
    minWidth: 30,
    floatingFilter: true,
    type: "editableColumn",
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "transDate4",
    cellDisplayFormat: "dateFilter",
    isDateFilterShow: true,
    isUDFField: true
  },
  {
    displayName: "Dataset",
    field: "dataSet",
    cellClass: "leftAlignment",
    filterValueColumnName: "dataSet",
    cellDisplayFormat: "textFilter",
    width: 100,
    minWidth: 30,
    floatingFilter: true,
    lockPinned: true,
  },
];
//#region lookups
export const colDefsPermissionFundGroup: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Domain - RoleName",
    field: "fundDomainName",
    width: 245,
    minWidth: 30,
    cellClass: "leftAlignment",
    isfilter: true,
    filterValueColumnName:"fundDomainName",
    filterIdColumnName: "fundDomainName",
    cellDisplayFormat:"textFilter",
    editable: false,
    lockPinned: true,
    sortable:true
  },
  {
    displayName: "Fund Group",
    field: "fundGroupName",
    width: 245,
    minWidth: 30,
    cellClass: "leftAlignment",
    isfilter: true,
    filterValueColumnName:"fundGroupName",
    filterIdColumnName: "groupId",
    cellDisplayFormat:"textFilter",
    editable: false,
    lockPinned: true,
    sortable:true
  },
  {
    displayName: "Role Name",
    field: "roleName",
    width: 245,
    minWidth: 30,
    isfilter: true,
    cellClass: "leftAlignment",
    filterValueColumnName:"roleName",
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
    hide: true,
  },
  {
    field: "viewPermission",
    displayName: "View",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"viewPermission",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    lockPinned: true,
    editable: true,
  },
  {
    field: "modifyPermission",
    displayName: "Modify",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"modifyPermission",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    lockPinned: true,
    editable:true,
  },
  {
    field: "deletePermission",
    displayName: "Delete",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"deletePermission",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    lockPinned: true,
    editable:true,
  }
];
export const colDefsPermissionForm: AgColDefsI[] = [
  SelectColumn,
  ActionColumn,
  {
    displayName: "Role Name",
    field: "roleName",
    width: 155,
    minWidth: 30,
    isfilter: true,
    cellClass: "leftAlignment",
    filterValueColumnName:"roleName",
    filterIdColumnName: "roleId",
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
  },
  {
    displayName: "Module Name",
    field: "moduleName",
    width: 180,
    minWidth: 30,
    cellClass: "leftAlignment",
    isfilter: true,
    filterValueColumnName:"moduleName",
    filterIdColumnName: "moduleId",
    cellDisplayFormat:"textFilter",
    editable: false,
    lockPinned: true,
    sortable:true
  },
  {
    displayName: "Form Name",
    field: "formName",
    width: 155,
    minWidth: 30,
    cellClass: "leftAlignment",
    isfilter: true,
    filterValueColumnName:"formName",
    filterIdColumnName: "menuId",
    // cellDisplayFormat:"textFilter",
    editable: false,
    lockPinned: true,
    sortable:true
  },
  {
    field: "viewPermission",
    displayName: "View",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"viewPermission",
    cellDisplayFormat:"textFilter",
    editable: (params) => params.data.viewPermissionId > 0,
    floatingDropDown: true,
    lockPinned: true,
  },
  {
    field: "modifyPermission",
    displayName: "Modify",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"modifyPermission",
    cellDisplayFormat:"textFilter",
    //editable: false,
    editable: (params) => params.data.modifyPermissionId > 0,
    //editable: (params) => params.data.specialPrefRetRate == true,
    // editable: (params) => {
    //   console.log(params.data.modifyPermissionId);
    //   return params.data.modifyPermissionId == 0
    // },
    floatingDropDown: true,
    lockPinned: true,
  },
  {
    field: "deletePermission",
    displayName: "Delete",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"deletePermission",
    cellDisplayFormat:"textFilter",
    editable: (params) => params.data.deletePermissionId > 0,
    floatingDropDown: true,
    lockPinned: true,
  }
];
export const colDefsPermissionColumn: AgColDefsI[] = [
  SelectColumn,
  // ActionColumn,
  {
    displayName: "Role Name",
    field: "roleName",
    width: 180,
    minWidth: 30,
    isfilter: true,
    cellClass: "leftAlignment",
    filterValueColumnName:"roleName",
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
  },
  {
    displayName: "Module Name",
    field: "formName",
    width: 230,
    minWidth: 30,
    cellClass: "leftAlignment",
    isfilter: true,
    filterValueColumnName:"formName",
    filterIdColumnName: "menuId",
    cellDisplayFormat:"textFilter",
    editable: false,
    lockPinned: true,
    sortable:true
  },
  {
    displayName: "Column Name",
    field: "columnName",
    width: 180,
    minWidth: 30,
    cellClass: "leftAlignment",
    isfilter: true,
    filterValueColumnName:"columnName",
    cellDisplayFormat:"textFilter",
    editable: false,
    lockPinned: true,
    sortable:true
  },
  {
    field: "permissions",
    displayName: "Modify Permission",
    width: 130,
    minWidth: 110,
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    cellStyle: "text-align-center",
    filterValueColumnName:"permissions",
    cellDisplayFormat:"textFilter",
    floatingDropDown: true,
    lockPinned: true,
    editable:true,
  }
];
//#endregion

//#region User

export const colDefsUsers: AgColDefsI[] = [
  {
    field: "id",
    width: 40,
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
    sortable:true,
    hide : true
  },
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    sortable:true
  },
  {
    displayName: "Actions",
    field: "actions",
    width: 180,
    minWidth: 30,
    pinned: "left",
    cellType: "icons",
   
    cellDisplayFormat:"textFilter",
    editable: false,
    sortable: false,
    lockPinned: true,
    lockPosition: true,
  },

  {
    displayName: "Username",
    field: "userName",
    width: 180,
    
    minWidth: 30,
    //cellClass: "leftAlignment",
    //cellClass: 'ag-header-cell-comp-wrapper-user',
    //cellStyle: { 'width': '90% !important' },
    floatingFilter: true,
    isfilter: true,
    //isRequired: true,
    //floatingFilter: true,
    filterValueColumnName:"userName",
    filterIdColumnName: "id",
    //editable: (params) => params.data.isAdded == true,
    editable: (params) => !params.data.userName || params.data.userName.toLocaleLowerCase() != 'superadminuser',
    cellDisplayFormat:"textFilter",
    //editable: true,
    lockPinned: true,
    sortable:true
  },
  
  {
    field: "passwordHash",
    displayName: "Password",
    width: 180,
    minWidth: 30,
    floatingFilter: true,
    // cellType: "dropdown",
    // cellRenderer: "dropdownRenderer",
     cellClass: "leftAlignment",
    // cellEditor: {
    //   cellEditorData: this.agvehicalData,
    //   cellEditorId: this.agvehicalId,
    // },
    //isRequired: true,
    //isfilter: true,
    filterValueColumnName:"passwordHash",
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
  },
  {
    field: "confirmPassword",
    displayName:"Confirm Password",
    width: 180,
    minWidth: 30,
    floatingFilter: true,
    // cellType: "dropdown",
    // cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    // cellEditor: {
    //   cellEditorData: this.agInvestorName,
    //   cellEditorId: this.agInvestorId,
    // },
    //isRequired: true,
    //isfilter: true,
    filterValueColumnName:"confirmPassword",
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
  },
  {
    field: "email",
    displayName: "Email",
    width: 180,
    minWidth: 30,
    floatingFilter: true,
    isfilter: true,
    // valueFormatter: "currencyFormat",
    // cellType: "number",
    // cellRenderer: "numericRenderer",
    cellClass: "leftAlignment",
    // valueFormatter: params => this.agGridService.currencyFormatter(params.data.investorCommitment, ''),
    // filterParams: {
    //   buttons: ["apply"],
    // },
    //cellStyle: "text-align-left",
    //floatingFilter: true,
    editable: (params) => !params.data.userName || params.data.userName.toLocaleLowerCase() != 'superadminuser',
    filterValueColumnName:"email",
    filterIdColumnName: "id",
    cellDisplayFormat:"textFilter",
    lockPinned: true,
  }
];

export const colDefsUserRole: AgColDefsI[] = [


  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    sortable:true
  },
  {
    field: "id",
    width: 40,
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
    sortable:true,
    hide : true
  },
  {
    displayName: "Actions",
    field: "actions",
    width: 100,
    minWidth: 30,
    cellType: "icons",
    editable: false,
    sortable: false,
    lockPinned: true,
    lockPosition: true
  },
  {
    field: "roleName",
    displayName: "Role Name",
    width: 150,
    minWidth: 80,
    cellType: "dropdown",
    cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    floatingFilter: true,
    filterValueColumnName: "roleName",
    filterIdColumnName: "roleId",
    cellEditor: {

    },
    //isRequired: true,
    //isfilter: true,
    isfilter: true,
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
  },
 {
    displayName: "User Name",
    field: "userName",
    width: 150,
    minWidth: 80,
    cellType: "dropdown",
    floatingFilter: true,
    cellRenderer: "dropdownRenderer",
    cellClass: "leftAlignment",
    filterValueColumnName: "userName",
    filterIdColumnName: "userId",
    cellEditor: {

    },
    //isRequired: true,
    //isfilter: true,
    isfilter: true,
    cellDisplayFormat:"textFilter",
    type: "editableColumn",
    lockPinned: true,
  }]
//#endregion

//#region  Roles
export const colDefsRole: AgColDefsI[] = [
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    sortable:true
  },
  {
    displayName: "Actions",
    field: "actions",
    width: 100,
    minWidth: 30,
    cellType: "icons",
    editable: false,
    sortable: false,
    lockPinned: true,
  },
  {
    displayName: "Role",
    field: "name",
    width: 180,
    minWidth: 30,
    filterValueColumnName:"name",
    cellDisplayFormat:"textFilter",
    filterIdColumnName: "id",
    isfilter: true,
    //isRequired: true,
    floatingFilter: true,
   // editable: (params) => params.data.isAdded == true,
    editable:true,
    lockPinned: true,
  },
  {
    displayName: "Description",
    field: "description",
    width: 180,
    minWidth: 30,
    filterValueColumnName:"description",
    cellDisplayFormat:"textFilter",
    filterIdColumnName: "id",
    isfilter: true,
    //isRequired: true,
    floatingFilter: true,
    type: "editableColumn",
    lockPinned: true,
  },
 
];
//#endregion

//#region  calculations

const CalculationDateColumn : AgColDefsI ={
  field: "",
  width: 40,
  pinned: "left",
  headerCheckboxSelection: true,
  checkboxSelection: true,
  lockPosition: true,
};


const PRRateColumn : AgColDefsI ={
  field: "prRate",
  displayName: "PR Rate",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormat",
  filterValueColumnName: "prRate",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: false,
  lockPinned: true
};
const PRRateColumnPrefReturnCal : AgColDefsI ={
  field: "prRate",
  displayName: "PR Rate",
  width: 100,
  minWidth: 30,
  valueFormatter: "percentFormat",
  filterValueColumnName: "prRate",
  cellDisplayFormat: "textFilter",
  cellType: "number",
  cellRenderer: "numericRenderer",
  cellStyle: "text-align-right",
  floatingFilter: true,
  editable: false,
  lockPinned: true,
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  }
};
const CashFlowAmountColumnPrefReturnCal : AgColDefsI ={
  displayName: "Cash Flow Amt",
  field: "cashFlowAmt",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  editable : false,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "amt",
  cellDisplayFormat: "textFilter",
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  }
};

const CashFlowAmountColumn : AgColDefsI ={
  displayName: "Cash Flow Amt",
  field: "cashFlowAmt",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  editable : false,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "amt",
  cellDisplayFormat: "textFilter"
};
const CashFlowDateColumn : AgColDefsI =
{
  displayName: "Cash Flow Date",
  field: "cashFlowDate",
  width: 150,
  minWidth: 30,
  cellRenderer: "datePickerRenderer",
  // cellClass: "ExcelDate",
  filterValueColumnName: "cashFlowDate",
  filterIdColumnName: "textFilter",
  editable: false,
  lockPinned: true,
  valueFormatter : "dateFormat"
   
};
const CashFlowDateColumnPrfReturnCal : AgColDefsI =
{
  displayName: "Cash Flow Date",
  field: "cashFlowDate",
  width: 150,
  minWidth: 30,
  cellRenderer: "datePickerRenderer",
  // cellClass: "ExcelDate",
  filterValueColumnName: "cashFlowDate",
  filterIdColumnName: "textFilter",
  editable: false,
  lockPinned: true,
  valueFormatter : "dateFormat",
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  }
   
};
const RunDateColumn : AgColDefsI =
{
  displayName: "Run Date",
  field: "runDate",
  width: 150,
  minWidth: 30,
  // cellClass: "ExcelDate",
  cellRenderer: "datePickerRenderer",
  filterValueColumnName: "runDate",
  filterIdColumnName: "textFilter",
  editable: false,
  lockPinned: true,
};
const RunDateColumnPrefReturnCal : AgColDefsI =
{
  displayName: "Run Date",
  field: "runDate",
  width: 150,
  minWidth: 30,
  // cellClass: "ExcelDate",
  cellRenderer: "datePickerRenderer",
  filterValueColumnName: "runDate",
  filterIdColumnName: "textFilter",
  editable: false,
  lockPinned: true,
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  }
};
const TierAmountColumn : AgColDefsI =
{
  displayName: "Tier Amt",
  field: "tierAmt",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  editable : false,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "tierAmt",
  cellDisplayFormat: "textFilter",
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
}
const ComparisonTierAmountColumn : AgColDefsI =
{
  displayName: "Comparison Amt",
  field: "comparisionAmt",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  editable : false,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "comparisionAmt",
  cellDisplayFormat: "textFilter",
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
}

const TierCalcColumn : AgColDefsI =
{
  displayName: "Tier Calc",
  field: "tierCalc",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  editable : false,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "tierCalc",
  cellDisplayFormat: "textFilter",
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
  tooltipComponent: 'CustomTooltip',
  tooltipField : 'tierCalc'
}
const BalanceColumn : AgColDefsI =
{
  displayName: "Balance",
  field: "balance",
  valueFormatter: "currencyFormat",
  cellType: "number",
  cellRenderer: 'numericRenderer',
  width: 120,
  minWidth: 30,
  editable : false,
  type: "editableColumn",
  floatingFilter: true,
  cellClass: "currencyFormat",
  filterValueColumnName: "balance",
  cellDisplayFormat: "textFilter",
  cellClassRules: {
    blueBackground: (params) => {
      return params.data.isSumRow == true;
    }
  },
  tooltipComponent: 'CustomTooltip',
  tooltipField : 'balance'
}

export const colDefsPrefRetCalc: AgColDefsI[] = [
  {
    displayName: "Fund",
    field: "fund",
    width: 150,
    minWidth: 30,
    cellClass: "leftAlignment",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    editable: false,
    isfilter: true,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    field: "calculationName",
    displayName: "Calculation Name",
    width: 150,
    minWidth: 30,
    cellClass: "leftAlignment",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  CalculationDateColumn,
  {
    field: "prRuleName",
    displayName: "PR Rule Name",
    filterValueColumnName: "prRuleName",
    filterIdColumnName: "prRuleName",
    isfilter: true,
    width: 150,
    minWidth: 30,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "calculationMethod",
    displayName: "Calculation Method",
    filterValueColumnName: "calculationMethod",
    filterIdColumnName: "calculationMethod",
    isfilter: true,
    width: 150,
    minWidth: 30,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "investor",
    displayName: "Investor",
    filterValueColumnName: "investor",
    filterIdColumnName: "investor",
    width: 150,
    minWidth: 30,
    editable: false,
    isfilter: true,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "investorGroup",
    displayName: "Investor Group",
    filterValueColumnName: "investorGroup",
    filterIdColumnName: "investorGroup",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "tier",
    displayName: "Tier",
    filterValueColumnName: "tier",
    filterIdColumnName: "tier",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    type: "editableColumn",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  PRRateColumnPrefReturnCal,
  CashFlowAmountColumnPrefReturnCal,
  CashFlowDateColumnPrfReturnCal,
  {
    field: "coumpoundInterestPeriod",
    displayName: "Compound Interest Period",
    filterValueColumnName: "coumpoundInterestPeriod",
    cellDisplayFormat: "textFilter",
    width: 170,
    minWidth: 30,
    editable: false,
    floatingFilter: true,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "daysCapital",
    displayName: "Days Capital",
    filterValueColumnName: "daysCapital",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    editable: false,
    floatingFilter: true,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "frequency",
    displayName: "Frequency",
    filterValueColumnName: "frequency",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    editable: false,
    floatingFilter: true,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "daysCalc",
    displayName: "Days Calc",
    filterValueColumnName: "daysCalc",
    cellDisplayFormat: "textFilter",
    width: 140,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    displayName: "Pref. Ret. Amount",
    field: "prefRetAmt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "prefRetAmt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  RunDateColumnPrefReturnCal,
  {
    field: "ptrTrans",
    displayName: "Ptr Trans",
    filterValueColumnName: "ptrTrans",
    cellDisplayFormat: "textFilter",
    width: 100,
    minWidth: 30,
    cellType: "checkbox",
    floatingDropDown: true,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
  {
    field: "termValueRow",
    displayName: "Term Values Row",
    filterValueColumnName: "termValueRow",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    cellType: "checkbox",
    floatingDropDown: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    }
  },
];

/* Start of Pref Summary */
export const  colDefsPrefRetCalcSummary: AgColDefsI[] = [
  {
    displayName: "Fund",
    field: "fund",
    width: 150,
    minWidth: 30,
    cellClass: "leftAlignment",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    editable: false,
    isfilter: true,
    lockPinned: true,
  },
  {
    field: "calculationName",
    displayName: "Calculation Name",
    width: 150,
    minWidth: 30,
    cellClass: "leftAlignment",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  CalculationDateColumn,
  {
    field: "prRuleName",
    displayName: "PR Rule Name",
    filterValueColumnName: "prRuleName",
    filterIdColumnName: "prRuleName",
    isfilter: true,
    width: 150,
    minWidth: 30,
    editable: false,
    lockPinned: true,
  },
  {
    field: "calculationMethod",
    displayName: "Calculation Method",
    filterValueColumnName: "calculationMethod",
    filterIdColumnName: "calculationMethod",
    isfilter: true,
    width: 150,
    minWidth: 30,
    editable: false,
    lockPinned: true,
  },
  {
    field: "tier",
    displayName: "Tier",
    filterValueColumnName: "tier",
    filterIdColumnName: "tier",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    type: "editableColumn",
  },
  PRRateColumn,
  CashFlowAmountColumn,
  CashFlowDateColumn,
  {
    field: "coumpoundInterestPeriod",
    displayName: "Compound Interest Period",
    filterValueColumnName: "coumpoundInterestPeriod",
    cellDisplayFormat: "textFilter",
    width: 170,
    minWidth: 30,
    editable: false,
    floatingFilter: true,
    lockPinned: true,
  },
  {
    field: "daysCapital",
    displayName: "Days Capital",
    filterValueColumnName: "daysCapital",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    editable: false,
    floatingFilter: true,
    lockPinned: true,
  },
  {
    field: "frequency",
    displayName: "Frequency",
    filterValueColumnName: "frequency",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    editable: false,
    floatingFilter: true,
    lockPinned: true,
  },
  {
    field: "daysCalc",
    displayName: "Days Calc",
    filterValueColumnName: "daysCalc",
    cellDisplayFormat: "textFilter",
    width: 140,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Pref. Ret. Amount",
    field: "prefRetAmt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "prefRetAmt",
    cellDisplayFormat: "textFilter"
  },
  RunDateColumn,
  {
    field: "ptrTrans",
    displayName: "Ptr Trans",
    filterValueColumnName: "ptrTrans",
    cellDisplayFormat: "textFilter",
    width: 100,
    minWidth: 30,
    cellType: "checkbox",
    floatingDropDown: true,
    lockPinned: true,
  },
  {
    field: "termValueRow",
    displayName: "Term Values Row",
    filterValueColumnName: "termValueRow",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    cellType: "checkbox",
    floatingDropDown: true,
    editable: false,
    lockPinned: true,
  },
];

export const  colDefsTierCalc: AgColDefsI[] = [
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
    tooltipComponent: 'CustomTooltip',
   
  },
  IncludeinBalanceColumn,
  SystemTierColumn,
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Fund",
    field: "fund",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
    
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Calculation Name",
    field: "calculationName",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },

  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Investor",
    field: "investor",
    filterValueColumnName: "investor",
    filterIdColumnName: "investor",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Investor Group",
    field: "investorGroup",
    filterValueColumnName: "investorGroup",
    filterIdColumnName: "investorGroup",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Investment",
    field: "investment",
    filterValueColumnName: "investment",
    filterIdColumnName: "investment",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Position",
    field: "position",
    filterValueColumnName: "position",
    filterIdColumnName: "position",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    type : 'editableColumn',
    displayName: "Tier",
    field: "tier",
    filterValueColumnName: "tier",
    filterIdColumnName: "tier",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    displayName: "Tier Order",
    field: "tierOrder",
    filterValueColumnName: "tierOrder",
    cellDisplayFormat: "textFilter",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    type : 'editableColumn',
    displayName: "Comparison Tier",
    field: "comparisonTier",
    filterValueColumnName: "comparisonTier",
    filterIdColumnName: "comparisonTier",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  TierAmountColumn,
  ComparisonTierAmountColumn,
  {
    field: "allocPercent",
    displayName: "Proportional %",
    width: 100,
    minWidth: 30,
    valueFormatter: "percentFormat",
    filterValueColumnName: "allocPercent",
    cellDisplayFormat: "textFilter",
    cellType: "number",
    cellRenderer: "numericRenderer",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
    tooltipComponent: 'CustomTooltip',
    tooltipField : 'tierCalc'
  },
  TierCalcColumn,
  // OperatorColumn,
  BalanceColumn,
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Calculation Rule",
    field: "calculationRule",
    filterValueColumnName: "calculationRule",
    filterIdColumnName: "calculationRule",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    displayName: "Pref. Ret. Tier",
    field: "prefRetTier",
    filterValueColumnName: "prefRetTier",
    cellDisplayFormat: "textFilter",
    cellType: "checkbox",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    displayName: "Termination Tier",
    field: "terminationTier",
    filterValueColumnName: "terminationTier",
    cellDisplayFormat: "textFilter",
    cellType: "checkbox",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Calculation Type",
    field: "calculationType",
    filterValueColumnName: "calculationType",

    filterIdColumnName: ".",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },

  },
  {
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    displayName: "Ptr Trans",
    field: "ptrTrans",
    cellType: "checkbox",
    filterValueColumnName: "ptrTrans",
    cellDisplayFormat: "textFilter",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    field: "tierCarryPer",
    displayName: "Carried Interest %  / Promote %",
    width: 100,
    minWidth: 30,
    valueFormatter: "percentFormat",
    filterValueColumnName: "tierCarryPer",
    cellDisplayFormat: "textFilter",
    cellType: "number",
    cellRenderer: "numericRenderer",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Allocate",
    field: "allocate",
    filterValueColumnName: "allocate",
    filterIdColumnName: "allocate",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    displayName: "Carried Interest",
    field: "carriedInterest",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "carriedInterest",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    displayName: "Participant allocation",
    field: "participantAlloc",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "participantAlloc",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    field: "hurdleRate",
    displayName: "Pref Ret / Hurdle Rate",
    width: 100,
    minWidth: 30,
    valueFormatter: "percentFormat",
    filterValueColumnName: "hurdleRate",
    cellDisplayFormat: "textFilter",
    cellType: "number",
    cellRenderer: "numericRenderer",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  CheckBalAmtColumn
  
];

export const colDefsTierCalcSummary: AgColDefsI[] = [
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
    tooltipComponent: 'CustomTooltip',
   
  },
  IncludeinBalanceColumn,
  SystemTierColumn,
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Fund",
    field: "fund",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
    
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Calculation Name",
    field: "calculationName",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },

  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Investment",
    field: "investment",
    filterValueColumnName: "investment",
    filterIdColumnName: "investment",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Position",
    field: "position",
    filterValueColumnName: "position",
    filterIdColumnName: "position",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    type : 'editableColumn',
    displayName: "Tier",
    field: "tier",
    filterValueColumnName: "tier",
    filterIdColumnName: "tier",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    displayName: "Tier Order",
    field: "tierOrder",
    filterValueColumnName: "tierOrder",
    cellDisplayFormat: "textFilter",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    type : 'editableColumn',
    displayName: "Comparison Tier",
    field: "comparisonTier",
    filterValueColumnName: "comparisonTier",
    filterIdColumnName: "comparisonTier",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  TierAmountColumn,
  ComparisonTierAmountColumn,
  {
    field: "allocPercent",
    displayName: "Proportional %",
    width: 100,
    minWidth: 30,
    valueFormatter: "percentFormat",
    filterValueColumnName: "allocPercent",
    cellDisplayFormat: "textFilter",
    cellType: "number",
    cellRenderer: "numericRenderer",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  TierCalcColumn,
  // OperatorColumn,
  BalanceColumn,
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Calculation Rule",
    field: "calculationRule",
    filterValueColumnName: "calculationRule",
    filterIdColumnName: "calculationRule",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    displayName: "Pref. Ret. Tier",
    field: "prefRetTier",
    filterValueColumnName: "prefRetTier",
    cellDisplayFormat: "textFilter",
    cellType: "checkbox",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    displayName: "Termination Tier",
    field: "terminationTier",
    filterValueColumnName: "terminationTier",
    cellDisplayFormat: "textFilter",
    cellType: "checkbox",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Calculation Type",
    field: "calculationType",
    filterValueColumnName: "calculationType",

    filterIdColumnName: ".",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },

  },
  {
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    displayName: "Ptr Trans",
    field: "ptrTrans",
    cellType: "checkbox",
    filterValueColumnName: "ptrTrans",
    cellDisplayFormat: "textFilter",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    field: "tierCarryPer",
    displayName: "Carried Interest %  / Promote %",
    width: 100,
    minWidth: 30,
    valueFormatter: "percentFormat",
    filterValueColumnName: "tierCarryPer",
    cellDisplayFormat: "textFilter",
    cellType: "number",
    cellRenderer: "numericRenderer",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    displayName: "Allocate",
    field: "allocate",
    filterValueColumnName: "allocate",
    filterIdColumnName: "allocate",
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    displayName: "Carried Interest",
    field: "carriedInterest",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "carriedInterest",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    displayName: "Participant allocation",
    field: "participantAlloc",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "participantAlloc",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  {
    field: "hurdleRate",
    displayName: "Pref Ret / Hurdle Rate",
    width: 100,
    minWidth: 30,
    valueFormatter: "percentFormat",
    filterValueColumnName: "hurdleRate",
    cellDisplayFormat: "textFilter",
    cellType: "number",
    cellRenderer: "numericRenderer",
    cellStyle: "text-align-right",
    floatingFilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      blueBackground: (params) => {
        return params.data.isSumRow == true;
      }
    },
  },
  CheckBalAmtColumn
  
];

export const colDefsAllocation: AgColDefsI[] = [
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Calculation Name",
    field: "calculationName",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Fund",
    field: "fund",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Investor",
    field: "investor",
    filterValueColumnName: "investor",
    filterIdColumnName: "investor",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Investor Group",
    field: "investorGroup",
    filterValueColumnName: "investorGroup",
    filterIdColumnName: "investorGroup",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Gross Proceeds Amt",
    field: "amt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "amt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Carry / Promote Amt",
    field: "carryPromoteAmt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "carryPromoteAmt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Proceeds Net of Carry",
    field: "allocAmt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "allocAmt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Calc Type",
    field: "calcType",
    filterValueColumnName: "calcType",
    filterIdColumnName: "calcType",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Max LTD Carry Amt",
    field: "maxLTDCarry",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "maxLTDCarry",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Catchup",
    field: "catchUp",
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    filterValueColumnName: "catchUp",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Receives Carry",
    field: "recCarry",
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    filterValueColumnName: "recCarry",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Ptr Trans",
    field: "ptrTrans",
    filterValueColumnName: "ptrTrans",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    cellType: "checkbox",
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
];

export const colDefsAllocationsSummary: AgColDefsI[] = [
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Calculation Name",
    field: "calculationName",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Fund",
    field: "fund",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Gross Proceeds Amt",
    field: "amt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "amt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Carry / Promote Amt",
    field: "carryPromoteAmt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "carryPromoteAmt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Proceeds Net of Carry",
    field: "allocAmt",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "allocAmt",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Calc Type",
    field: "calcType",
    filterValueColumnName: "calcType",
    filterIdColumnName: "calcType",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Max LTD Carry Amt",
    field: "maxLTDCarry",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "maxLTDCarry",
    cellDisplayFormat: "textFilter",
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Catchup",
    field: "catchUp",
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    filterValueColumnName: "catchUp",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Receives Carry",
    field: "recCarry",
    cellType: "checkbox",
    cellRenderer: "checkboxRenderer",
    filterValueColumnName: "recCarry",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
  {
    displayName: "Ptr Trans",
    field: "ptrTrans",
    filterValueColumnName: "ptrTrans",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingDropDown: true,
    editable: false,
    cellType: "checkbox",
    lockPinned: true,
    cellClassRules: {
      purpleRow: (params) => {
        return params.data.finalRow == true;
      }
    },
  },
];


export const colDefsJournalEntries: AgColDefsI[] = [
  {
    field: "",
    width: 40,
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: true,
  },
  {
    displayName: "Actions",
    field: "",
    cellType: "icons",
    width: 150,
    minWidth: 30,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Calculation Name",
    field: "calculationName",
    filterValueColumnName: "calculationName",
    filterIdColumnName: "calculationName",
    width: 150,
    editable: false,
    lockPinned: true,
    isfilter: true,
  },
  {
    displayName: "Calc Data Set",
    field: "calcDataSet",
    filterValueColumnName: "calcDataSet",
    filterIdColumnName: "calcDataSet",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Transaction Set",
    field: "transactionSet",
    filterValueColumnName: "transactionSet",
    filterIdColumnName: "transactionSet",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Category",
    field: "category",
    filterValueColumnName: "category",
    cellDisplayFormat: "textFilter",
    width: 150,
    editable: false,
    lockPinned: true,
    floatingFilter: true,
  },
  {
    displayName: "Fund",
    field: "fund",
    filterValueColumnName: "fund",
    filterIdColumnName: "fund",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Investment",
    field: "investment",
    filterValueColumnName: "investment",
    filterIdColumnName: "investment",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Position",
    field: "position",
    filterValueColumnName: "position",
    filterIdColumnName: "position",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Investor",
    field: "investor",
    filterValueColumnName: "investor",
    filterIdColumnName: "investor",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Investor Group",
    field: "investorGroup",
    filterValueColumnName: "investorGroup",
    filterIdColumnName: "investorGroup",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "JE ID",
    field: "jeid",
    filterValueColumnName: "jeid",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Trans ID",
    field: "transID",
    filterValueColumnName: "transID",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "JE Comments",
    field: "jeComments",
    filterValueColumnName: "jeComments",
    filterIdColumnName: "jeComments",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "SubAct",
    field: "subAct",
    filterValueColumnName: "subAct",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    isfilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Date",
    field: "date",
    width: 150,
    minWidth: 30,
    cellClass: "leftAlignment",
    cellRenderer: "datePickerRenderer",
    filterValueColumnName: "date",
    filterIdColumnName: "textFilter",
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "ISO",
    field: "iso",
    filterValueColumnName: "iso",
    cellDisplayFormat: "textFilter",
    width: 150,
    minWidth: 30,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "IS Debit",
    field: "isDebit",
    filterValueColumnName: "isDebit",
    cellDisplayFormat: "textFilter",
    minWidth: 30,
    width: 150,
    floatingFilter: true,
    editable: false,
    lockPinned: true,
  },
  {
    displayName: "Amount",
    field: "amount",
    valueFormatter: "currencyFormat",
    cellType: "number",
    cellRenderer: 'numericRenderer',
    width: 120,
    minWidth: 30,
    editable : false,
    type: "editableColumn",
    floatingFilter: true,
    cellClass: "currencyFormat",
    filterValueColumnName: "amount",
    cellDisplayFormat: "textFilter"
  },
];
//#endregion