import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { CommonTabService } from '../services/common-tab.service';
import { NotificationService } from '../services/notification.service';
import { StorageService } from '../services/storage.service';
import { StorageName } from '../common/enum';
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class DiretyCheckGuard implements CanDeactivate<ComponentCanDeactivate> {

  private $destroy: Subject<void> = new Subject();
  private redirectTo: string = '';
  private selfCall: boolean = false;

  constructor(
    private router: Router,
    public commonTabService: CommonTabService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.$destroy.next();
      }
    });
  }

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var confirmationSaveClicked = this.router.getCurrentNavigation().extras?.state?.confirmationSaveClicked;
    if (confirmationSaveClicked) {
      return true;
    }
    if (this.selfCall) {
      this.selfCall = false;
      return true;
    }
    else {
      this.redirectTo = decodeURI(nextState ? nextState.url : currentState.url);
      return component.canDeactivate ? (component.canDeactivate() ? true : this.openConfirmDialog()) : true;
    }
  }

  openConfirmDialog() {
    var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    //check if user don't have modify rihgts then no need to open open confirm dialog
    if (formPermission && formPermission.modifyPermission ) {
  
    this.notificationService.openConfirmDialog().subscribe((result) => {
      if (result.isDenied) {
        this.selfCall = true;
        this.router.navigate([this.redirectTo]);
      }
      else if (result.isConfirmed) {
        this.commonTabService.saveBeforeClose(); 
        this.$destroy.next();
        this.commonTabService.setredirectUrlAfterSave(this.redirectTo);
      }
    })
  }
    return false;
  }

}
