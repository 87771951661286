import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { TierTransactionService } from "src/app/pages/transactions/tier-transactions/tier-transactions.service";
import { AllInvestorVehicle, DropdownModel, InvestmentList, InvestorList, PositionList } from "../../model/common.model";
@Component({
  selector: "app-cell-editor-transaction",
  templateUrl: "./cell-editor-transaction.component.html",
  styleUrls: ["./cell-editor-transaction.component.scss"],
})
export class CellEditorTransactionComponent
  implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild("container", { read: ViewContainerRef }) public container;
  agInvestorName: any[] = [];
  agInvestorId: any[] = [];
  agvehicalData: DropdownModel[] = [];
  agvehicalId: any[] = [];
  agPositionName: DropdownModel[] = [];
  agpositionId: any[] = [];
  agInvestmentName: DropdownModel[] = [];
  agInvestmentId: any[] = [];
  constructor(private tierTransactionsService: TierTransactionService) { }
  values = [];
  params;
  value;
  public happy: boolean = false;
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }
  agInit(params: ICellEditorParams<any, any>): void {
    var columnName = "";
    columnName = params.colDef.field;
    switch (columnName) {
      case "vehicleInvestorName":
        var investorId = params.data.investorId == undefined ? (params.data.investorId ? params.data.investorId : 0) : params.data.investorId;
        var fundId = params.data.fundId;
        investorId = params.data.isInvestorValueChanged == true ? investorId : 0;
        this.getInvestorVehicle(fundId, investorId);
        break;
      case "investorName":
        var vehicleInvestorId = params.data.vehicleInvestorId == undefined ? (params.data.vehicleInvestorId ? params.data.vehicleInvestorId : 0) : params.data.vehicleInvestorId;
        var fundId = params.data.fundId;
        this.getInvestors(fundId, vehicleInvestorId);
        break;
      case "investmentName":
        var positionId = params.data.positionId == undefined ? (params.data.positionId ? params.data.positionId : 0) : params.data.positionId;
        var fundId = params.data.fundId;
        positionId = params.data.isPositionValueChanged == true ? positionId : 0;
        this.getInvestment(fundId, positionId);
        break;
      case "positionName":
        var investmentId = params.data.investmentId == undefined ? (params.data.investmentId ? params.data.investmentId : 0) : params.data.investmentId;
        var fundId = params.data.fundId;
        this.getPosition(fundId, investmentId);
        break;
    }

    this.params = params;
    this.value = params.value;
  }
  getInvestorVehicle(fundId, investorId)  {
    this.tierTransactionsService.GetInvestorVehicle(fundId, investorId).subscribe((data) => {
      if (data.response) {
        let investorVehicle = data.response as AllInvestorVehicle[];
        this.values = investorVehicle?.map( (item: AllInvestorVehicle) => ({name: item.vehicleInvestorName,id: item.id.toString()}));
      }
    })
  }

  getInvestors(fundId, vehicleInvestorId)  {
    this.tierTransactionsService.GetInvestors(fundId, vehicleInvestorId).subscribe((data) => {
      if (data.response) {
        let investors = data.response as InvestorList[];
        this.values =  investors?.map( (item: InvestorList) => ({name: item.investorName,id: item.id.toString()}));
      }
    })
  }
  getPosition(fundId, investmentId)  {
    this.tierTransactionsService.GetPosition(fundId, investmentId).subscribe((data) => {
      if (data.response) {
        let position = data.response as PositionList[];
        this.values = position?.map( (item: PositionList) => ({name: item.positionName,id: item.id.toString()}));
      }
    })
  }
  getInvestment(fundId, positionId)  {
    this.tierTransactionsService.GetInvestment(fundId, positionId).subscribe((data) => {
      if (data.response) {
        let investments = data.response as InvestmentList[];
        this.values = investments?.map( (item: InvestmentList) => ({name: item.investmentName,id: item.id.toString()}));
      }
    })
  }


  getValue() {
    return this.value;
  }
  isPopup?(): boolean {
    return false;
  }
  getPopupPosition?(): string {
    return 'top'
  }
  isCancelBeforeStart?(): boolean {
    return false;
  }
  isCancelAfterEnd?(): boolean {
    return false
  }
  focusIn?(): void {
    throw new Error("Method not implemented.");
  }
  focusOut?(): void {
    throw new Error("Method not implemented.");
  }
  afterGuiAttached?(): void {
    this.getValue()
  }
  ngOnInit(): void {
  }
  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (  key == 37 ||    key == 39) { // 37 left 39 right
      event.stopPropagation();
    }
  }
  onClick(happy: boolean) {
    this.params.api.stopEditing();
  }
  selected(item){
    if(this.params.colDef.field == "positionName")
    this.params.node.data["isPositionValueChanged"] = true;
    if(this.params.colDef.field == "investorName")
    this.params.node.data["isInvestorValueChanged"] = true;
    var idColumn = this.params.colDef.headerComponentParams.filterIdColumnName;
    var selectedItem =  this.values.filter(a=>a.name==item)[0];
    this.params.node.data[idColumn] = selectedItem.id;
    this.value = selectedItem.name;
    var columnName = this.params.colDef.field;
    switch (columnName) {
      case "vehicleInvestorName":
        var vehicleInvestorId =  this.params.data.vehicleInvestorId == undefined ? ( this.params.data.vehicleInvestorId ?  this.params.data.vehicleInvestorId : 0) :  this.params.data.vehicleInvestorId;
        var fundId =  this.params.data.fundId;
        var investorId =  this.params.data.investorId;
        this.tierTransactionsService.GetInvestors(fundId, vehicleInvestorId).subscribe((data) => {
          if (data.response) {
            let investors = data.response as InvestorList[];
            var list =  investors?.map( (item: InvestorList) => ({name: item.investorName,id: item.id.toString()}));
            var exist = list.some(e => e.id.toString() === investorId?.toString());
            if(exist == false){
              this.params.data.investorId = 0;
              this.params.data.investorName = "";
            }
          }
        })

        break;
      case "investorName":
        var investorId = this.params.data.investorId == undefined ? ( this.params.data.investorId ?  this.params.data.investorId : 0) :  this.params.data.investorId;
        var fundId =  this.params.data.fundId;
        var vehicleInvestorId =  this.params.data.vehicleInvestorId;
        this.tierTransactionsService.GetInvestorVehicle(fundId, investorId).subscribe((data) => {
          if (data.response) {
            let investorVehicle = data.response as AllInvestorVehicle[];
            var list = investorVehicle?.map( (item: AllInvestorVehicle) => ({name: item.vehicleInvestorName,id: item.id.toString()}));
            var exist = list.some(e => e.id.toString() === vehicleInvestorId?.toString());
            if(exist == false){
              this.params.data.investorId = 0;
              this.params.data.investorName = "";
            }
          }
        })
        break;
      case "investmentName":
        var investmentId =  this.params.data.investmentId == undefined ? ( this.params.data.investmentId ?  this.params.data.investmentId : 0) :  this.params.data.investmentId;

      var fundId =  this.params.data.fundId;
      var positionId =  this.params.data.positionId;
      this.tierTransactionsService.GetPosition(fundId, investmentId).subscribe((data) => {
        if (data.response) {
          let position = data.response as PositionList[];
          var list = position?.map( (item: PositionList) => ({name: item.positionName,id: item.id.toString()}));
          var exist = list.some(e => e.id.toString() === positionId?.toString());
          if(exist == false){
            this.params.data.positionId = 0;
            this.params.data.positionName = "";
          }
        }
      })
        break;
      case "positionName":
      var positionId =  this.params.data.positionId == undefined ? ( this.params.data.positionId ?  this.params.data.positionId : 0) :  this.params.data.positionId;
        var fundId =  this.params.data.fundId;
        var investmentId =  this.params.data.investmentId;
        this.tierTransactionsService.GetInvestment(fundId, positionId).subscribe((data) => {
          if (data.response) {
            let investments = data.response as InvestmentList[];
            var list = investments?.map( (item: InvestmentList) => ({name: item.investmentName,id: item.id.toString()}));
            var exist = list.some(e => e.id.toString() === investmentId?.toString());
            if(exist == false){
              this.params.data.investmentId = 0;
              this.params.data.investmentName = "";
            }
          }
        })

        break;
    }
  }
}