import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AgGridService } from "../ag-grid/ag-grid.service";
import { CustomeHeaderService } from "../custome-header/custome-header.service";
import { Select2Data, Select2UpdateEvent } from "ng-select2-component";
import { PageHeaderModel } from "./page-header.model";
import Swal from "sweetalert2";
import { NotificationService } from "../../services/notification.service";
import { DropdownModel } from "../../model/common.model";
import { CommonTabService } from "../../services/common-tab.service";
import { StorageService } from "../../services/storage.service";
import { GlobalService } from "../../services/global.service";
import { ToastrService } from "ngx-toastr";
import { TierTransactionService } from "src/app/pages/transactions/tier-transactions/tier-transactions.service";
import { StorageName } from "../../common/enum";
import { Result } from "../../model/response.model";
@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"]
})
export class PageHeaderComponent implements OnInit {
  @ViewChild('headerDiv') myDiv: ElementRef;
  params;
  public filteredOptions: Select2Data;
  public fundfilteredOptions : Select2Data;
  public transactionFilterOptions: Select2Data;
  isDisabled = false;
  filterList: DropdownModel[];
  inputBoxWidth: string = "w-400";
  transactionfilterList: DropdownModel[];
  Id: string = "";
  fundId : string = "";
  transactionFilterId: string = "";
  @Input() pageHeaderModel: PageHeaderModel;
  //filteredOptions : DropdownModel[];
  @Output() onFilterDropdownChange: EventEmitter<any> = new EventEmitter();
  @Output() onFundFilterDropdownChange: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onOpenLockUnlock: EventEmitter<any> = new EventEmitter();
  @Output() onApplyFilter: EventEmitter<any> = new EventEmitter();
  @Output() onEditFilter: EventEmitter<any> = new EventEmitter();
  @Output() onAddFilter: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteFilter: EventEmitter<any> = new EventEmitter();
  @Output() onFundDropdownChange: EventEmitter<any> = new EventEmitter();
  @Output() onFundDropdownChangeOnLoad: EventEmitter<any> = new EventEmitter();
  @Output() onReset: EventEmitter<boolean> = new EventEmitter();
  @Output() onDropdownChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onUndo: EventEmitter<any> = new EventEmitter();
  @Output() onAddNewForm: EventEmitter<any> = new EventEmitter();
  @Output() fillTopDropdown: EventEmitter<any> = new EventEmitter();
  @Output() onPostSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onOpenCalcFilterPopup: EventEmitter<any> = new EventEmitter();
  @Output() onCopyCalc: EventEmitter<any> = new EventEmitter();
  @Output() onOpenJEPopup: EventEmitter<any> = new EventEmitter();
  @Output() onOpenSMPopup: EventEmitter<any> = new EventEmitter();
  @Output() onOpenLockPopup: EventEmitter<any> = new EventEmitter();
  @Output() onOpenGraphPopup: EventEmitter<any> = new EventEmitter();
  @Output() onRunCalc: EventEmitter<any> = new EventEmitter();

  overlay: boolean = true;
  constructor(
    public agGridService: AgGridService,
    public notificationService: NotificationService,
    public storageService: StorageService,
    public commonService: CommonTabService,
    public globalService: GlobalService,
    public toasterService: ToastrService,
    public transactionService: TierTransactionService,
    public customHeaderService: CustomeHeaderService) { }
  ngOnInit(): void {
    this.commonService.getAllDropdowns(this.pageHeaderModel.controllerName + "s").subscribe((val: any) => {
      if (this.pageHeaderModel.controllerName == "Role")
        this.getById("0", false, this.pageHeaderModel.showDeleted);
      else
        this.fillTopDropdown.emit();
      var mainDropdown = this.pageHeaderModel.controllerName == "Transaction" ? "Fund" : this.pageHeaderModel.controllerName;
      this.pageHeaderModel.filteredOptions = this.storageService.retrieve(mainDropdown.toUpperCase() + "_DROPDOWN");
      if(this.pageHeaderModel.controllerName == "Calculation"){
        this.inputBoxWidth = "w-240";
        var fundDropdown = this.storageService.retrieve("FUND_DROPDOWN");
        this.fundfilteredOptions = fundDropdown?.map((item: DropdownModel) => ({ label: item.name, value: item.id }));
        var savedFundId = this.storageService.retrieve("CALCULATION_FUNDID");
        if (savedFundId && fundDropdown.findIndex(x => x.id == savedFundId.toString()) > -1)
            this.fundId = savedFundId.toString();
        else
            this.fundId = "0";
        this.fundId = this.fundId == undefined || this.fundId == '' ? "0" : this.fundId;
        this.setPermissionFundWise(this.fundId);
        this.onFundDropdownChange.emit(this.fundId);
      }
      this.filteredOptions = this.pageHeaderModel.filteredOptions?.map((item: DropdownModel,index) => ({ label: item.name, value: item.id ,data: {
        color: item.color,
        name: item.name,
        hoverColor: item.hoverColor,
      },
      templateId : "template1",
      id: 'option-'+item.id.toString()}));

      //this.data25  = JSON.parse(JSON.stringify( this.filteredOptions));
      if (this.pageHeaderModel.controllerName == "Transaction") {
        this.pageHeaderModel.transactionFilterOptions = this.storageService.retrieve(StorageName.TRANSACTION_FILTER_DROPDOWN);
        this.transactionFilterOptions = this.pageHeaderModel.transactionFilterOptions?.map((item: DropdownModel) => ({ label: item.name, value: item.id }));
      }
      var savedId = this.storageService.retrieve(this.pageHeaderModel.IdStorageName);
      if (savedId && this.pageHeaderModel.filteredOptions.findIndex(x => x.id == savedId.toString()) > -1)
        this.Id = savedId.toString();
      else
        this.Id = "0";
      
      this.pageHeaderModel.id = parseInt(this.Id);
      if (this.pageHeaderModel.controllerName == StorageName.TRANSACTION_CONTROLLER) {
        var savedFilterId = this.storageService.retrieve(StorageName.TRANSACTION_FILTERID);
        if (savedFilterId) {
          this.transactionFilterId = savedFilterId.toString();
          this.pageHeaderModel.transactionFilterId = parseInt(savedFilterId.toString());
          if (savedFilterId != "0")
            this.applyFilter();
        }
        else
          this.transactionFilterId = "0";
        this.pageHeaderModel.transactionFilterId = parseInt(this.transactionFilterId);
        this.setPermissionFundWise(this.Id);
        this.onFundDropdownChangeOnLoad.emit(this.Id);
      }
      if (this.pageHeaderModel.controllerName != StorageName.TRANSACTION_CONTROLLER && this.pageHeaderModel.controllerName != "Calculation") {
        this.setPermissionFundWise(this.Id);
        }

    })

  }

  save() { this.onSave.emit(); }
  addNewForm() {  
    this.pageHeaderModel.isAddNew = true; 
    this.setFilterDropdown("");
    if (this.pageHeaderModel.controllerName != "Transaction")  this.onAddNewForm.emit(); else this.onAddFilter.emit(); }
  undo() { 
    if(this.pageHeaderModel.controllerName != "Calculation")
      this.getById(this.Id, false, this.pageHeaderModel.showDeleted); 
      else
      this.onUndo.emit(this.pageHeaderModel.id);
    }
  undoTransaction() {
    this.transactionFilterId = "0";
    this.storageService.store(StorageName.TRANSACTION_FILTERID, "0");
    this.onUndo.emit(this.Id);
  }
  //#region transactions
  openLockUnlock() { this.onOpenLockUnlock.emit(); }
  applyFilter() {
    this.storageService.store(StorageName.TRANSACTION_FILTERID, this.transactionFilterId.toString());
    this.onApplyFilter.emit(this.transactionFilterId.toString());
  }
  editFilter() {
    if (parseInt(this.transactionFilterId) != 0)
      this.onEditFilter.emit(this.transactionFilterId);
    else
      this.notificationService.warningpopup("Please select filter")
  }
  addRow() {
    this.agGridService.addNewRow({ api: this.pageHeaderModel.gridApi, newRow: new Object() });
    var currentRows = this.pageHeaderModel.agGridI.gridApi.api.getModel().rowsToDisplay.map(a => a.data);
    this.storageService.store(this.pageHeaderModel.agGridI.initialRows, currentRows);
  }
  exportAllItems() {
    var currentRows = this.pageHeaderModel.agGridI.gridApi.api.getModel().rowsToDisplay.map(a => a.data);
    if ((this.pageHeaderModel.agGridI.controllerName == "Reports" && this.Id == "0") ||
      (this.pageHeaderModel.agGridI.controllerName != "Reports" && currentRows.length == 0)
    ) {
      this.notificationService.warningpopup("No records to export!!");
    }
    else
      this.agGridService.exportAll(this.pageHeaderModel.gridApi, this.pageHeaderModel.agGridI.controllerName, false);
  }

  resetFilter() {
    if (this.pageHeaderModel.agGridI.serversidepagination == true)
      this.onReset.emit(true);
    else {
      this.agGridService.
        resetAllFilters(this.pageHeaderModel.agGridI.initialRows,
          this.pageHeaderModel.agGridI.filterStorageName,
          this.pageHeaderModel.agGridI.gridName, this.pageHeaderModel.gridApi);
    }
    this.customHeaderService.isResetFilterIcon.emit(true);
  }

  clearSort() {
    this.storageService.store(this.pageHeaderModel.agGridI.gridName, []);
    this.pageHeaderModel.agGridI.gridApi.columnApi.applyColumnState({
      defaultState: { sort: null },
    });
  }

  editReport() {
    this.onEditFilter.emit(this.Id);
  }

  addReport() {
    this.onAddFilter.emit();
  }
  runReport() {
    if (this.Id != "" && parseInt(this.Id) != 0)
      this.onApplyFilter.emit(this.Id);
    else
      this.notificationService.warningpopup("Please select Report to Run")
  }
  deleteReport() {
    if (this.Id != "" && parseInt(this.Id) != 0)
      this.onDeleteFilter.emit(this.Id);
    else
      this.notificationService.warningpopup("Please select Report to Delete")
  }
  //#endregion

  post(data: any, showDeleteConfirmation: boolean, isPermanentDelete: boolean = false) {
    if (showDeleteConfirmation == true && isPermanentDelete == true) {
      let result = this.notificationService.deleteOrReset(isPermanentDelete == true ? 1 : 2);
      result.subscribe(res => {
        if (res.isConfirmed)
          this.postRecords(data);
      });
    }
    else this.postRecords(data);
  }

  postRecords(data) {
    this.commonService.post(this.pageHeaderModel.controllerName, data).subscribe((res) => {
      //hide loader
      this.globalService.deactivateLoader();
      if (res.isSuccess) {
        this.commonService.checkRedirectUrlAfterSave();
        this.toasterService.success(res.message, "", {
          timeOut: 1000
        });

        if (this.pageHeaderModel.controllerName != "Report" &&  this.pageHeaderModel.controllerName != "Calculation") {
          if (this.pageHeaderModel.isServersidePagination != true) {
            var isDeleted = (this.pageHeaderModel.controllerName == "Lookup") ? false : true;
            this.getById(res.response.id.toString(), isDeleted, this.pageHeaderModel.showDeleted);
          }
          else this.onReset.emit(false);
        } else {
          //start- for calculation page only
          if(this.pageHeaderModel.controllerName == "Calculation")
            this.setFilterDropdown(res.response.id.toString());
          //end- for calculation page only
          this.onPostSuccess.emit(res.response);
        }
      } else {
        this.commonService.setredirectUrlAfterSave(null);
        this.notificationService.warningpopup(res.errors); // show error if success false
      }
    });
  }

  onDelete() {
    var idTodelete = this.pageHeaderModel.id.toString();

    switch(this.pageHeaderModel.controllerName)
    {
      case StorageName.TRANSACTION_CONTROLLER:
        this.onDeleteFilter.emit()
        break;
      case StorageName.CALCULATION_CONTROLLER:
        if(this.pageHeaderModel.isCalcLocked==false)
          if (parseInt(idTodelete) != 0)
            this.delete(idTodelete);
          else
            this.notificationService.warningpopup("select item to delete");
        else
          this.notificationService.warningpopup("Calculation is locked. Please unlock to delete");
          break;
      default:
          if (parseInt(idTodelete) != 0)
            this.delete(idTodelete);
          else
            this.notificationService.warningpopup("select item to delete");
        
    }
  }
  delete(idTodelete) {
    var controllerName = this.pageHeaderModel.controllerName;
    if (this.pageHeaderModel.controllerName == StorageName.TRANSACTION_CONTROLLER)
      controllerName = StorageName.TRANSACTION_FILTER_CRITERIA;
    if (this.pageHeaderModel.controllerName == StorageName.REPORT_HEADER_CONTROLLER) {
      controllerName = "ReportCriterias";
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "",
      html: "<h3 style='color:red'>You won't be able to revert this!</h3>",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      width: 350
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService
          .delete(controllerName, 1, [parseInt(idTodelete)])
          .subscribe((res) => {
            if (res.isSuccess) {
              this.toasterService.success("Deleted Successfully!!", "", {
                timeOut: 1000
              });
              if (this.pageHeaderModel.isServersidePagination != true ) 
                  this.getById("0", true, this.pageHeaderModel.showDeleted);
              else 
                this.onFundDropdownChange.emit("0");
            } else this.notificationService.errorpopup(res.errors);
          });
      }
    });
  }

  update(key: string, event: Select2UpdateEvent<any>) {
    if (event.options && event.options.length > 0) {
      this.pageHeaderModel.id = event.value;
      this.Id = event.value.toString();
      if (this.pageHeaderModel.controllerName == "Lookup" || this.pageHeaderModel.controllerName == "Calculation" )
        this.onDropdownChange.emit(event.value);
      else
      {
        if(this.pageHeaderModel.controllerName == "Fund")
          this.setPermissionFundWise(event.value);
        this.getById(event.value, false, this.pageHeaderModel.showDeleted);
      }
    }
  }

  close(key: string, event: Event) {
    console.log(key, event);
  }

  updateFilterFromFund(key: string, event: any) {
    if (event.hoveringValue != null || event.hoveringValue != undefined) {
      this.pageHeaderModel.fundId = event.hoveringValue;
      this.pageHeaderModel.id = parseInt(this.Id);
      this.fundId = event.hoveringValue;
      this.setPermissionFundWise(this.fundId);
      this.onFundDropdownChange.emit(this.fundId);
    }
  }

  updateFundForTransaction(key: string, event: any) {
    if (event.hoveringValue != null || event.hoveringValue != undefined) {
      this.pageHeaderModel.id = event.hoveringValue;
      this.Id = event.hoveringValue.toString();
      this.pageHeaderModel.transactionFilterId = 0;
      this.transactionFilterId = "0";
      if(this.pageHeaderModel.name == undefined || this.pageHeaderModel.name == ""){
        var filteredItem = this.pageHeaderModel.filteredOptions.filter((obj) => {return obj.id === this.Id;});
        this.pageHeaderModel.name = filteredItem[0].name;
      }
     this.setPermissionFundWise(event.hoveringValue);
      this.onFundDropdownChange.emit(event.hoveringValue);
    }
  }

  setPermissionFundWise(fundId)
  {
        //set from permission
        var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
        if (formPermission) {
          this.pageHeaderModel.canModify = formPermission.modifyPermission;
          this.pageHeaderModel.canDelete = formPermission.deletePermission;
        }
        if(formPermission.formName == "Funds" || formPermission.formName == "Tier Transactions" || formPermission.formName == "Calculations")
        {
          var fundDropdown = this.storageService.retrieve("FUND_DROPDOWN");
          var filteredFund = fundDropdown.filter(a=>a.id == fundId)[0];
          var canModify = filteredFund.color;
          var canDelete = filteredFund.hoverColor;
          this.pageHeaderModel.canModify =  canModify == 'true' ? true : false;
          this.pageHeaderModel.canDelete =  canDelete == 'true' ? true : false;
        } 
        // const inputElements = this.myDiv.nativeElement.querySelectorAll('input');
        // inputElements.forEach(input => {
        //     input.disabled = !this.pageHeaderModel.canModify;
        //   });


         // this.isDisabled = !this.pageHeaderModel.canModify;;
  }



  updateFilterForTransaction(key: string, event: any) {
      this.pageHeaderModel.transactionFilterId = event.hoveringValue;
      this.transactionFilterId = event.hoveringValue != null ? event.hoveringValue.toString() : "";
      this.onFundFilterDropdownChange.emit(event.hoveringValue);
  }

  getById(id: string, isDeleted: boolean = false, showDeleted: boolean) {
    var userid = this.commonService.getUserid();
    this.storageService.store(this.pageHeaderModel.IdStorageName, id);
    this.commonService.getById(this.pageHeaderModel.controllerName, parseInt(id), showDeleted).subscribe((res) => {
      if (res.isSuccess) {
        this.pageHeaderModel.isAddNew = false;
        if (isDeleted == true) this.setFilterDropdown(id);
        this.onFilterDropdownChange.emit(res);
      } else this.notificationService.errorpopup(res.errors);
    });
  }

  setFilterDropdown(id: string) {
    this.commonService.getfilterDropdown(this.pageHeaderModel.controllerName).subscribe((res) => {
      if (res.isSuccess) {
        this.filterList = [];
        this.filterList = res.response;
        this.storageService.store(this.pageHeaderModel.controllerName.toUpperCase() + "_DROPDOWN", res.response);
        this.filteredOptions = this.filterList?.map(
          (item: DropdownModel) => ({
            label: item.name,
            value: item.id,

          })
        );
        this.Id = id;
        //set filter text after change in dropdown
        this.setFilterText(res,id,"selec2-1");
      }
    });
  }

  setFilterDropdownRunReport(id: string,reportName:string) {
    this.commonService.getfilterDropdown(this.pageHeaderModel.controllerName).subscribe((res) => {
      if (res.isSuccess) {
        this.filterList = [];
        this.filterList = res.response;
        this.storageService.store(this.pageHeaderModel.controllerName.toUpperCase() + "_DROPDOWN", res.response);
        this.filteredOptions = this.filterList?.map(
          (item: DropdownModel) => ({
            label: item.name,
            value: item.id,
          })
        );
        //this.Id = id;
        //set filter text after change in dropdown
        const select = document.getElementById("selec2-1");
        const filterText = select.querySelector(".select2-selection__rendered span");
        filterText.innerHTML = reportName;
      }
    });
  }

  setCalcFilterDropdown(id: string, res : any) {
        this.filterList = [];
        this.filterList = res.response;
        this.storageService.store(this.pageHeaderModel.controllerName.toUpperCase() + "_DROPDOWN", res.response);
        this.filteredOptions = this.filterList?.map(
          (item: DropdownModel) => ({
            label: item.name,
            value: item.id,
          })
        );
        this.Id = id;
        this.pageHeaderModel.id = parseInt(id);
        this.storageService.store(this.pageHeaderModel.IdStorageName , id);
        //set filter text after change in dropdown
        this.setFilterText(res,id,"selec2-1");
  }

  changeColor(obj, color) {          
    obj.style.background = color;
  }

  changeColorOnHover(obj, color) {
    obj.style.background = color;
  }

  //#region Transaction items

  setTransactionFilterDropdown(savedId) {
    this.commonService.getfilterDropdown("TransactionFilterCriteria").subscribe((res) => {
      if (res.isSuccess) {
        this.transactionfilterList = [];
        this.transactionfilterList = res.response;
        this.transactionFilterOptions = this.transactionfilterList?.map(
          (item: DropdownModel) => ({
            label: item.name,
            value: item.id,
          })
        );
        this.transactionFilterId = savedId.toString();
        //set filter text after change in dropdown
        this.setFilterText(res,savedId,"selec2-2");
      }
    });
  }

  //#endregion

  setFilterText(res : Result, id : string, dropdownId : string)
  {
    const select = document.getElementById(dropdownId);
    const filterText = select.querySelector(".select2-selection__rendered span");
    if(res != undefined && res != null){
      var filteredItem = res.response.filter((obj) => {
        return obj.id === id;
      });
      filterText.innerHTML = filteredItem != undefined && filteredItem.length > 0 ? filteredItem[0].name : "";
    }
    else filterText.innerHTML = "";
  }

  openCalcFilterPopup() { this.onOpenCalcFilterPopup.emit(); }
  copyCalc() { 
    this.pageHeaderModel.id = 0;
    this.pageHeaderModel.name = "";
    this.Id = undefined;
    this.setFilterText(undefined,"0","selec2-1")
   this.onCopyCalc.emit(); 
  }

  getPermissionByGroupAndDomain(fundGroupId, domainId) {
    this.commonService.getPermissionByGroupAndDomain(fundGroupId, domainId).subscribe((res) => {
      if (res != null && res.isSuccess) {
          var filteredFund = res.response[0];
          if(filteredFund){
            var canModify = filteredFund.color;
            var canDelete = filteredFund.hoverColor;
            this.pageHeaderModel.canModify =  canModify == 'true' ? true : false;
            this.pageHeaderModel.canDelete =  canDelete == 'true' ? true : false;
            var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
            formPermission.modifyPermission =   this.pageHeaderModel.canModify;
            formPermission.deletePermission =   this.pageHeaderModel.canDelete ;
            this.storageService.store(StorageName.CURRENT_FORM_PERMISSION,formPermission);
            if(this.pageHeaderModel.canModify == false && this.pageHeaderModel.canDelete == true)
              this.notificationService.warningpopup("you don't have Modify Permission for the selected group or domain");
            if(this.pageHeaderModel.canModify == true && this.pageHeaderModel.canDelete == false)
              this.notificationService.warningpopup("you don't have Delete Permission for the selected group or domain");
            if(this.pageHeaderModel.canModify == false && this.pageHeaderModel.canDelete == false)
              this.notificationService.warningpopup("you don't have Modify and Delete Permission for the selected group or domain")
          }
      }
    });
  }

  openJEPopup() { this.onOpenJEPopup.emit(); }
  openSMPopup() { this.onOpenSMPopup.emit(); }
  openLockPopup() { this.onOpenLockPopup.emit(); }
  openGraphPopup() { this.onOpenGraphPopup.emit(); }
  runCalc() { this.onRunCalc.emit(); }
  
}
