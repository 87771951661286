import { Menu } from './menu.model';

export const getFundInformationVMenu = [

    new Menu(21, 'Funds', '/funds/fund', 'gesture', null, false, 0),
    new Menu(22, 'Investors', '/funds/investors', 'price_change', null, false, 0),
    new Menu(23, 'Investments', '/funds/investments', 'attach_money', null, false, 0),
    new Menu(24, 'Positions', '/funds/positions', 'hotel_class', null, false, 0),
    new Menu(25, 'Import', '/funds/imports', 'download', null, false, 0),

];

export const getTransactionVMenu = [
    new Menu(31, 'Tier Transactions', '/transactions/tier-transactions', 'paid', null, false, 0),
    new Menu(32, 'Import Transactions', '/transactions/import-transactions', 'undo', null, false, 0),
];

export const getCalculationRuleLibraryVMenu = [
    new Menu(41, 'Calculation Rules', '/calculation-rules-library/calculation-rules', 'rule_folder', null, false, 0),
    new Menu(42, 'Tiers', '/calculation-rules-library/tiers', 'north_east', null, false, 0),
    new Menu(43, 'Performance Rules', '/calculation-rules-library/performance-rules', 'data_exploration', null, false, 0),
]

export const getAdministrationVMenu = [
    new Menu(51, 'Permissions', '/administration/permissions', 'psychology', null, false, 0),
    new Menu(52, 'Roles', '/administration/roles', 'manage_accounts', null, false, 0),
    new Menu(53, 'Users', '/administration/users', 'person', null, false, 0),
    new Menu(54, 'User Defined Fields', '/administration/user-defined-fields', 'recent_actors', null, false, 0),
    new Menu(55, 'Lookup Values', '/administration/lookups', 'pin', null, false, 0),
]



export const getHorizontalMenu = [
    new Menu(1, 'Home', '/dashboard', 'home', null, false, 0),

    new Menu(2, 'Fund Information', '/funds', 'comment', null, false, 0, true),

    new Menu(3, 'Transactions', '/transactions', 'drive_file_move_outline', null, false, 0, true),

    new Menu(4, 'Calculations', '/calculation', 'calculate', null, false, 0),

    new Menu(5, 'Reporting', '/reporting', 'trending_up', null, false, 0),

    new Menu(6, 'Calculation Rule Library', '/calculation-rules-library', 'menu_book', null, false, 0, true),

    new Menu(7, 'Administration', '/administration', 'settings_suggest', null, false, 0, true),
    
    new Menu(8, 'Dashboard', '/new-dashboard', 'dashboard', null, false, 0),
]