import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StorageName } from '../../common/enum';
import { StorageService } from '../../services/storage.service';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { IconsService } from './icons.service';
import { NotificationService } from '../../services/notification.service';
export interface MyParams {
  eventOccurs?: any;
}
@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit, ICellRendererAngularComp {
  params;
  currentPageName: any;
  isVisible: boolean = true;
  canModify: boolean = false;
  canDelete: boolean = false;
  constructor(public agGridService: AgGridService,
    public iconService:IconsService,
    public notificationService: NotificationService,
    public storageService: StorageService) { }
  agInit(params: ICellRendererParams & MyParams): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }
  ngOnInit(): void {
    switch(this.params.colDef.headerComponentParams.gridName)
    {
      case StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_GRID:
      case StorageName.FUNDADMIN_FUNDS_CALCULATIONS_GRID:
      case StorageName.GRID_ADMINISTRATION_UDFS_UDF:
      case StorageName.FUND_LEVEL_GRID:      
      case StorageName.PERMISSION_FUNDGROUP_GRID:
      case StorageName.PERMISSION_FORM_GRID:
       this.isVisible = false;
        break;
      case StorageName.USERS_GRID:
        this.isVisible = !this.params.data.userName || this.params.data.userName.toLocaleLowerCase() != 'superadminuser';
          break;  
      default:
        this.isVisible = true;
    }
     //set from permission
     var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
     if (formPermission) {
       this.canModify = formPermission.modifyPermission;
       this.canDelete = formPermission.deletePermission;
     }
 
  }
  onCopy() {
    this.params.node.setSelected(true);
    //this.params.node.data.id = 0;
    this.params.node.data.isEdited = false;
    this.params.node.data.isAdded = false;
    this.params.node.data.isDeleted = false;
    this.params.node.data.isPasteRow = false;
    this.storageService.store(this.params.colDef.headerComponentParams.initialRows,this.params.api.getModel().rowsToDisplay.map(a => a.data));
  }
  onPaste() {
   var rowIndex = this.params.rowIndex+1;
    this.params.api.getSelectedRows().forEach((item) => {
      let newSelectedRow = {...Object.assign({},item)};
      newSelectedRow.id = 0;
      newSelectedRow.isPasteRow = true;
      newSelectedRow.isAdded = true;
      newSelectedRow.isEdited = false;
      newSelectedRow.isDeleted = false;
      this.params.api.updateRowData({ add: [newSelectedRow], addIndex: rowIndex })
      rowIndex ++;
    });
    this.params.api.deselectAll();
    this.storageService.store(this.params.colDef.headerComponentParams.initialRows,this.params.api.getModel().rowsToDisplay.map(a => a.data));
  }
  onExport() {
        this.params.node.setSelected(true);
        this.agGridService.exportAll(this.params,this.params.colDef.headerComponentParams.controllerName,true);
        this.params.api.deselectAll();
  }
  onDelete($event) {
    if ($event.target.innerHTML?.trim() == "delete_forever")
    {
      this.setNodeOnDelete(true);
  }
  else
  {
    this.setNodeOnDelete(false);
  }
  }

  setNodeOnDelete(isTrue : boolean)
  {
    if(this.params.node.data.status == "Locked" && this.params.colDef.headerComponentParams.gridName == StorageName.TRANSACTION_LEVEL_GRID)
    {
      this.notificationService.warningpopup("Please unlock record to delete");
    }
    else
    {
    this.params.node.setSelected(isTrue);
    this.params.node.data.isEdited = isTrue;
    this.params.node.data.isAdded = false;
    this.params.node.data.isDeleted = isTrue;
    this.params.node.data.isPasteRow = false;
    this.storageService.store(this.params.colDef.headerComponentParams.initialRows,this.params.api.getModel().rowsToDisplay.map(a => a.data));
    }
  }
}
