import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/shared/services/global.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CalculationModel, ChartDropdownModel } from '../calculation.model';
import { CalculationService } from '../calculation.service';
@Component({
  selector: 'app-chart-modal',
  templateUrl: './chart-modal.component.html',
  styleUrls: ['./chart-modal.component.scss']
})
export class ChartModalComponent implements OnInit {
  calculationData:CalculationModel;
  chartType : string;
  tabsId: number;
  chartCategories: ChartDropdownModel[] = [];
  chartCategoryValues: ChartDropdownModel[] = [];
  public single: any[];
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Country';
  public showYAxisLabel = true;
  public yAxisLabel = 'Population';
  public colorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#378D3B', '#0096A6', '#F47B00', '#606060']
  };  
  constructor( 
    public calculationService:CalculationService,
    public notificationService: NotificationService,
    public globalService: GlobalService,
    public dialogRef: MatDialogRef<ChartModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
    this.multi = JSON.parse(this.data.data);
    this.xAxisLabel = JSON.parse(this.data.data)[0].name;
    this.yAxisLabel = 'Amt';
    this.single = JSON.parse(this.data.data)[0].series;
  }

  swapChartType(chartType) {
    this.chartType = chartType;
    // BindChart();
    };
}
