import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() { }
  sucesspopup(message) {
    Swal.fire({
      position: 'top-right',
      width: 350,
      heightAuto: false,
      icon: 'success',
      text: message,
      showConfirmButton: false,
      timer: 1500
    })
  }
  //error with confirmbutton.
  errorpopup(message, title = null) {
    Swal.fire({
      title: '',
      html: message,
      icon: 'error',
      width: 350
    }).then((result) => { });
    // Swal.fire({
    //   title: title,
    //   position: 'center',
    //   text: "",
    //   html: message,
    //   width: 350,
    //   heightAuto: false,
    //   // timer: 1500,
    //   // showConfirmButton: false,
    //   icon: 'error'
    // });
  }
  //
  warningpopup(message) {
    Swal.fire({
      title: '',
      html: message,
      position: 'center',
      width: 350,
      heightAuto: false,
      // timer: 1500,
      // showConfirmButton: false,
      icon: 'warning'
    });
  }
  deletepopup(): Observable<SweetAlertResult> {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      position: 'center',
      text: 'Are you sure you want to delete this item?',
      showDenyButton: true,
      width: 350,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      subject.next(result);
    })
    return subject.asObservable();
  }
  deleteOrReset(type) {
    var subject = new Subject<SweetAlertResult>();
    var deleteTypeString = "";
    var confirmButtonString = "Yes, delete it!";
    switch (type) {
      case 1:
        deleteTypeString = "<h3 style='color:red'>You won't be able to revert this!</h3>";
        break;
      case 2:
        deleteTypeString = "This item will be temporarily deleted"
        break;
      case 3:
        deleteTypeString = "You are going to RESET temporary deleted items"
        confirmButtonString = "Yes, reset it!"
        break;
    }
    Swal.fire({
      title: 'Are you sure?',
      html: deleteTypeString,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonString,
      cancelButtonText: 'No, cancel!',
      width: 350
    }).then((result) => {
      subject.next(result);
      // if (result.isConfirmed) {
      // } 
      // else if (result.isDenied) 
      //   this.notificationService.warningpopup("Changes are not saved");
    });
    return subject.asObservable();
  }

  openConfirmDialog() {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      title: 'Proceed without Saving?',
      text: "You have unsaved changes",
      icon: 'question',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel!',
      width: 350
    }).then((result) => {
      subject.next(result);
    });
    return subject.asObservable();
  }

  openImportSuccessDialog(msg : string) {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      title: 'File upload process',
      text:msg,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel!',
      width: 350
    }).then((result) => {
      subject.next(result);
    });
    return subject.asObservable();
  }
}
