import { Injectable }                       from '@angular/core';
import { Observable,                
         tap }                              from 'rxjs';
import { Result }                           from 'src/app/shared/model/response.model';
import { DataService }                      from 'src/app/shared/services/data.service';
@Injectable({
  providedIn: 'root'
})
export class FundInformationService {
  constructor(private service: DataService) { }

public deleteModel(controllerName : string, data : any):Observable<Result>
{
  let url='/'+controllerName+'/delete';
  return this.service.post(url,data).pipe<Result>(tap((response:any)=>{
    return response;
  }))
}

}