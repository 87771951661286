import { CursorError } from '@angular/compiler/src/ml_parser/lexer';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { LoginService } from 'src/app/pages/login/login.service';
import { StorageName } from '../common/enum';
import { CommonTabService } from '../services/common-tab.service';
import { StorageService } from '../services/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private loginService: LoginService,
        private storageService: StorageService,
        private commonService: CommonTabService,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       //let currentUser = this.storageService.retrieve(StorageName.CURRENTUSER).loginUserDataModel ?? this.storageService.retrieve(StorageName.CURRENTUSER);
       const currentUser = this.loginService.currentUserValue;
        if (currentUser && currentUser.isQueSet == true) {
            if(!this.router.navigated && !route.data.formname)
            {
                if(currentUser.permissions == null || currentUser.permissions == undefined)
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                //get permission on refresh
                const source$ = this.commonService.getById(StorageName.PERMISSION_CONTROLLER, parseInt(currentUser.permissions.role.id), false);
                const res = await firstValueFrom(source$);
                currentUser.permissions = res.response;
                this.storageService.store(StorageName.CURRENTUSER, currentUser);
            }
            // check if route is restricted by role
            if (route.data.formname) {
                
                var formPermission = currentUser.permissions.formPermissions.find(x => x.formName == route.data.formname);
                this.storageService.store(StorageName.CURRENT_FORM_PERMISSION, formPermission);
                if(!formPermission || formPermission.viewPermission == false) {
                    // role not authorised so redirect to home page
                    this.router.navigate(['/access-denied']);
                    return false;
                }
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}