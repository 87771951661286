import { CellClassParams, EditableCallbackParams } from "ag-grid-community";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridI, ColDefsCalculations, ColDefsCalculationSetting, ColDefsInvestment, ColDefsInvestor, ColDefsPtrTransfer } from "src/app/shared/component/ag-grid/ag-grid.interface";
export class FundModel
{
    fund                                : Fund = new Fund();
    fundInvestors                       : FundInvestors[]=[];
    fundInvestments                     : FundInvestments[]=[];
    ptrTransfers                        : FundPtrTransfers[]=[];
    fundCalculationSettings             : FundCalculationSettings[]=[];
    isNew                               : boolean = true;
    fundCalculations                    : FundCalculation[];
}
export class Fund 
{
    fundName            : string = "";
    fundTypeId          : number = undefined;
    domainId            : number = undefined;
    fundFamily          : string= "";
    launchDate          : Date= undefined;
    closeDate           : Date= undefined;
    fundExternalId      : number= undefined;
    comments            : string= "";
    fundGroupId         : number= undefined;
    currencyId          : number= undefined;
    id                  : number= undefined;
    createdAt           : string= "";
    updatedAt           : string= "";
    isDeleted           : boolean = false;
    deletedAt           : string= null;
    isEdit              : boolean = false;
}
export class FundInvestors 
{
    id                      : number;
    fundId                  : number;
    vehicleInvestorId       : number;
    leAssociationId         : number;
    investorId              : number;
    investorName            : string;
    investorCommitment      : number;
    inactiveDate            : Date;
    managementFeeEligible   : boolean;
    recCarry                : boolean;
    feeEligible             : boolean;
    sideLetter              : boolean;
    sideLetterPromote1      : string;
    sideLetterPromote2      : string;
    sideLetterPromote3      : string;
    sideLetterPref1         : string;
    sideLetterPref2         : string;
    sideLetterPref3         : string;
    active                  : boolean;
    fundInvestorExtId       : string;
    comments                : string;
    operation               : string;
    fundName                : string;
    vehicleInvestorName     : string;
    leAssociationName       : string;
    importDate              : Date;
    importUserId            : string;
    commitPer               : string;
    createdAt               : string;
    updatedAt               : string;
    isDeleted               : boolean = false;
    isAdded                 : boolean = false;
    isEdited                : boolean = false;
    deletedAt               : string;
    isRowChecked            : boolean = false;
    isPasteRow              : boolean = false;
    totalRecords            : number;
}
export class FundInvestments 
{
    id                          : number;
    fundsId                     : number;
    investmentId                : number;
    positionId                  : number;
    currencyId                  : number;
    fundInvestmentExtId         : string;
    fundInvestmentAmt           : string;
    investmentVintageDate       : string;
    strInvestmentVintageDate    : string;
    active                      : boolean;
    investmentLiquidationDate   : Date;
    comments                    : string;
    inactiveDate                : Date;
    operation                   : string;
    fundName                    : string;
    investmentName              : string;
    positionName                : string;
    investmentGUID              : string;
    positionGUID                : string;
    fundInvestmentGUID          : string;
    importUserId                : string;
    importDate                  : Date;
    createdAt                   : string;
    updatedAt                   : string;
    isDeleted                   : boolean = false;
    deletedAt                   : string;
    isEdited                    : boolean = false;
    isAdded                     : boolean = false;
    totalRecords                : number;
    isRowChecked                : boolean = false;
    isPasteRow                  : boolean = false;
}
export class  FundCalculationSettings
{
    id                          : number;
    calcRuleFundId              : number;
    fundId                      : number;
    carryPtrDesignation         : string;
    allocCalcLevel              : string;
    lpaEntries                  : boolean;
    distReAllocEntries          : boolean;
    incomeReAllocEntries        : boolean;
    unrealizedEntries           : boolean;
    comments                    : string;
    // operation                : string;
    fundName                    : string;
    // createdAt                : string;
    // updatedAt                : string;
    isDeleted                   : boolean = false;
    // deletedAt                : string;
    totalRecords            : number;
    isEdited                : boolean = false;
    isRowChecked            : boolean = false;
}
export class FundCalculation
{
    id                      : number;
    calcId                  : number;
    fundName                : string;
    calcName                : string;
    fundId                  : number;
    calcRuleId              : number;
    calcTypeId              : number;
    calcDesc                : string;
    calcDataSet             : string;
    calcDate                : string;
    runDate                 : string;
    distIncomeNum           : number;
    pLReallocSubActId       : number;
    calcStatusId            : number;
    fundInvestmentId        : number;
    calcRuleName            : string;
    calcStatus              : string;
    calcStateId             : number;
    isDeleted               : boolean = false;
    totalRecords            : number;
    plReallocSubActId       : number;
    isEdited                : boolean = false;
    isRowChecked            : boolean = false;
}
export class FundPtrTransfers 
{
    id                  : number;
    isAdded             : boolean = false;
    isEdited            : boolean = false;
    isDeleted           : boolean = false;
    fundId              : number;
    ptrTransNameId      : number;
    vehicleInvestorId   : number;
    investorId          : number;
    fundInvestorId      : number;
    transferPer         : number | null;
    status              : string;
    transferTypeId      : number;
    statusId            : number;
    comments            : string;
    operation           : string;
    ptrTransferName     : string;
    fundName            : string;
    investorName        : string;
    vehicleInvestorName : string;
    investorGUID        : string;
    fundInvestorGUID    : string;
    vehicleGUID         : string;
    ptrTransGUID        : string;
    transferDate        : string;
    strTransferDate     : string;
    islocked            : boolean = false;
    isRowChecked        : boolean = false;
    isPasteRow          : boolean = false;
}
//#region  Grid Model Declaration
  // for Investor Col Defination
  export const  agGridDataModelInvestor: AgGridI = {
    colDefs: ColDefsInvestor,
    rowSelection: "multiple",
    gridName: StorageName.FUNDADMIN_FUNDS_INVESTORS_GRID,
    controllerName: StorageName.FUNDADMIN_FUNDS_INVESTORS_CONTROLLER,
    storageName: StorageName.FUNDADMIN_FUNDS_INVESTORS_COLUMNCHANGED,
    paginationStore: StorageName.FUNDADMIN_FUNDS_INVESTORS_PAGINATION,
    initialRows: StorageName.FUNDADMIN_FUNDS_INVESTORS_INITIALROWS,
    filterStorageName: StorageName.FUNDADMIN_FUNDS_INVESTORS_FILTER,
    showDeleteStatusStorageName: StorageName.FUNDADMIN_FUNDS_SHOW_DELETED,
    tabIndex: 0,
    showDeleted : false,
    columnTypes: {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return params.data.canModify == true ;
        }
      }
    },
  };
  // for Investment Col Defination
  // for Investment
  export const agGridDataModelInvestment: AgGridI = {
    colDefs: ColDefsInvestment,
    rowSelection: "multiple",
    gridName: StorageName.FUNDADMIN_FUNDS_INVESTMENT_GRID,
    controllerName: StorageName.FUNDADMIN_FUNDS_INVESTMENT_CONTROLLER,
    storageName: StorageName.FUNDADMIN_FUNDS_INVESTMENT_COLUMNCHANGED,
    paginationStore: StorageName.FUNDADMIN_FUNDS_INVESTMENT_PAGINATION,
    initialRows: StorageName.FUNDADMIN_FUNDS_INVESTMENT_INITIALROWS,
    filterStorageName: StorageName.FUNDADMIN_FUNDS_INVESTMENT_FILTER,
    showDeleteStatusStorageName: StorageName.FUNDADMIN_FUNDS_SHOW_DELETED,
    tabIndex: 1,
    showDeleted : false,
    columnTypes: {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return params.data.canModify == true ;
        }
      }
    },
  };
  // for Calculation Col Defination
  export const agGridDataModelCalculations: AgGridI = {
    colDefs: ColDefsCalculations,
    rowSelection: "multiple",
    gridName: StorageName.FUNDADMIN_FUNDS_CALCULATIONS_GRID,
    controllerName: StorageName.FUNDADMIN_FUNDS_CALCULATIONS_CONTROLLER,
    storageName: StorageName.FUNDADMIN_FUNDS_CALCULATIONS_COLUMNCHANGED,
    paginationStore: StorageName.FUNDADMIN_FUNDS_CALCULATIONS_PAGINATION,
    initialRows: StorageName.FUNDADMIN_FUNDS_CALCULATIONS_INITIALROWS,
    filterStorageName: StorageName.FUNDADMIN_FUNDS_CALCULATIONS_FILTER,
    showDeleteStatusStorageName: StorageName.FUNDADMIN_FUNDS_SHOW_DELETED,
    tabIndex: 3,
    showDeleted : false,
    columnTypes: {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return params.data.canModify == true ;
        }
      }
    },
  };
  // for Calculation Settings  Col Defination
  export const agGridDataModelCalculationSetting: AgGridI = {
    colDefs: ColDefsCalculationSetting,
    rowSelection: "multiple",
    gridName: StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_GRID,
    controllerName: StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_CONTROLLER,
    storageName: StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_COLUMNCHANGED,
    paginationStore: StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_PAGINATION,
    initialRows: StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_INITIALROWS,
    filterStorageName: StorageName.FUNDADMIN_FUNDS_CALCULATION_SETTINGS_FILTER,
    showDeleteStatusStorageName: StorageName.FUNDADMIN_FUNDS_SHOW_DELETED,
    tabIndex: 2,
    showDeleted : false,
    columnTypes: {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return params.data.canModify == true ;
        }
      }
    },
  };
  // for Ptr Transfer Col Defination
  // for Ptr Transfer
  export const agGridDataModelPtrTransfer: AgGridI = {
    colDefs: ColDefsPtrTransfer,
    rowSelection: "multiple",
    gridName: StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_GRID,
    controllerName: StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_CONTROLLER,
    storageName: StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_COLUMNCHANGED,
    paginationStore: StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_PAGINATION,
    initialRows: StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_INITIALROWS,
    filterStorageName: StorageName.FUNDADMIN_FUNDS_PTR_TRANSFER_FILTER,
    showDeleteStatusStorageName: StorageName.FUNDADMIN_FUNDS_SHOW_DELETED,
    tabIndex: 4,
    showDeleted : false,
    columnTypes: {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return params.data.canModify == true ;
        }
      }
    },
  };
  
//#endregion Grid Model Declaration