import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageName } from '../../common/enum';
import { StorageService } from '../../services/storage.service';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { sort, StorageSortModel } from './sort.model';
@Injectable({
  providedIn: 'root'
})
export class SortService {
  emitOnSort = new EventEmitter();
  private readonly _sortList = new BehaviorSubject<sort[]>([]);
  readonly sortList$ = this._sortList.asObservable();
  public _sortedArrayData: any[] = [];
  public gridName = new BehaviorSubject<any>({});
  previousSortListArray: any[] = []
  get sortList(): sort[] {
    return this._sortList.getValue();
  }
  set sortList(data: sort[]) {
    this._sortList.next(data);
  }
  sortOldRows: any[] = [];
  sortData: any = [
    { name: "Sort Ascending", id: 1, display: true },
    { name: "Sort Descending", id: 2, display: true },
    { name: "Remove Sort", icon: "close", id: 3, display: false },
    { name: "Hide Column", icon: "close", id: 4, display: true }
  ]
  constructor(public storageService: StorageService) { }
  ngOnInit(): void {
  }
  performSort(initialRowsStorageName: string, storageName: string) {
    let initialRows = this.storageService.retrieve(initialRowsStorageName);
    this.sortList = this.storageService.retrieve(storageName);
    let tempData: any[] = Object.assign([], initialRows);
    if (this.sortList?.length > 0) {
      this.updateAllLevel(this.sortList);
      tempData?.sort(this.dynamicSortMultiple(this.sortList));
    }
    return tempData;
  }
  updateAllLevel(list: sort[]) {
    let count = 1;
    list.forEach((element: sort) => {
      element.level = count++;
    });
  }
  private dynamicSort(col, direction) {
    return function (a, b) {
      a = a[col];
      b = b[col];
      if (a == b) return 0;
      if (typeof (a) == "number") {
        if (direction == "ASC")
          return a > b ? 1 : -1;
        else return a < b ? 1 : -1;
      } else {
        if (direction == "ASC")
          return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        else return a.toLowerCase() < b.toLowerCase() ? 1 : -1;
      }
    }
  }
  dynamicSortMultiple(props) {
    let that = this;
    // var props = arguments;
    return function (obj1, obj2) {
      var i = 0, result = 0, numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = that.dynamicSort(props[i].name, props[i].direction)(obj1, obj2);
        i++;
      }
      return result;
    }
  }
  setRemoveSortVisibility(columnName) {
    if (this.sortList)
      this.sortData[2].display = this.sortList.findIndex(x => x.name == columnName) > -1
  }
  clearSort() {
    this.sortList = [];
    this.sortOldRows = [];
  }

}
