import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { StorageName } from 'src/app/shared/common/enum';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { LoginRequestModel, LoginUserDataModel, QueAnsRequestModel, ResetPwdRequestModel } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private currentUserSubject: BehaviorSubject<LoginUserDataModel>;
  public currentUser: Observable<LoginUserDataModel>;

  constructor(
      private service: DataService,
      private storageService: StorageService
    ) {
        let initialUser = this.storageService.retrieve(StorageName.CURRENTUSER);
        this.currentUserSubject = new BehaviorSubject<LoginUserDataModel>(initialUser);
        this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginUserDataModel {
      return this.currentUserSubject.value;
  }

  public postLogin(model:LoginRequestModel):Observable<Result> 
  {
    this.storageService.removeItem(StorageName.LASTACTIVITYTIME);
    let url='/Login/login';
    return this.service.post(url,model,true).pipe<Result>(tap((response:any) => {
      // login successful if there's a jwt token in the response
      //if (user && user.token) {
      if (response && response.isSuccess) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.storageService.store(StorageName.CURRENTUSER, response.response.loginUserDataModel);
        var IsAuthenticated =   this.storageService.store(StorageName.Authenticated,true);
        this.currentUserSubject.next(response.response.loginUserDataModel);
      }
      return response;
    }));
  }
  public getQuestionslist(): Observable<Result> {
    let url ='/Login/getQuestionsList';
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }


  public postQueAns(model:QueAnsRequestModel):Observable<Result> 
  {
    let url='/Login/postQuestionsData';
    return this.service.post(url,model,true).pipe<Result>(tap((response:any) => {
      // login successful if there's a jwt token in the response
      //if (user && user.token) {
      if (response && response.isSuccess) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        let userdata = this.storageService.retrieve(StorageName.CURRENTUSER);
        userdata.isQueSet = true;
        this.storageService.store(StorageName.CURRENTUSER, userdata);
        this.currentUserSubject.next(userdata);
      }
      return response;
    }));
  }

  public logout() {
    // remove user from local storage to log user out
    this.storageService.removeItem(StorageName.CURRENTUSER);
    this.storageService.removeItem(StorageName.LASTACTIVITYTIME);
    this.currentUserSubject.next(null);
  }

  public getQuestionsByUserName(userName):Observable<Result> 
  {
    let url = "/Login/getQuestionsByUserName?userName=" + userName;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }

  public postSendLink(model:QueAnsRequestModel):Observable<Result> 
  {
    let url='/Login/postSendLink';
    return this.service.post(url,model,true).pipe<Result>(tap((response:any) => {
      return response;
    }));
  }

  public checkResetLink(link):Observable<Result> 
  {
    let url = "/Login/checkResetLink?link=" + link;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }

  public postResetPassword(model:ResetPwdRequestModel):Observable<Result> 
  {
    let url='/Login/postResetPassword';
    return this.service.post(url,model,true).pipe<Result>(tap((response:any) => {
      return response;
    }));
  }
}
