import { StorageName } from "src/app/shared/common/enum";
import { AgGridI, colDefsUserRole, colDefsUsers } from "src/app/shared/component/ag-grid/ag-grid.interface";
import { DropdownModel } from "src/app/shared/model/common.model";
import { PaginationModelCommon } from "src/app/shared/model/pagination.model";

export class UserModel
{
    user                                : User = new User();
    users                               : User[]=[];
    userRoles                           : userRole[]=[];
    isNew                               : boolean = true;
}

export class User 
{
    userName            : string;
    passwordHash        : string;
    confirmPassword     : string;
    email               : string;
    id                  : number;
    createdAt           : string;
    updatedAt           : string;
    isDeleted           : boolean = false;
    deletedAt           : string;
    isEdited            : boolean = false;
    isAdded             : boolean = false;
}

export class userRole 
{
    userId              : number;
    roleId              : number;
    userName            : number;
    roleName            : number;
    id                  : number;
    createdAt           : string;
    updatedAt           : string;
    isDeleted           : boolean = false;
    deletedAt           : string;
    isEdited            : boolean = false;
    isAdded             : boolean = false;
}

export const agGridDataModelUser: AgGridI = {
    colDefs: colDefsUsers,
    rowSelection: "multiple",
    gridName: StorageName.USERS_GRID,
    storageName: StorageName.USERS_USERCOLUMNCHANGED,
    paginationStore: StorageName.USERS_PAGINATION,
    initialRows: StorageName.USERS_INITIALROWS,
    filterStorageName: StorageName.USERS_FILTER,
    controllerName : StorageName.USERS_CONTROLLER,
    showDeleteStatusStorageName: StorageName.USER_SHOW_DELETED,
    tabIndex: 0,
  };

  export const agGridDataModelUserRole: AgGridI = {
    colDefs: colDefsUserRole,
    rowSelection: "multiple",
    gridName: StorageName.USER_ROLES_GRID,
    storageName: StorageName.USER_ROLES_COLUMNCHANGED,
    paginationStore: StorageName.USER_ROLES_PAGINATION,
    initialRows: StorageName.USER_ROLES_INITIALROWS,
    filterStorageName: StorageName.USER_ROLES_FILTER,
    controllerName : StorageName.USER_ROLES_CONTROLLER,
    showDeleteStatusStorageName : StorageName.USER_SHOW_DELETED,
    tabIndex: 1,
  };

  //for userrole
 