import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgGridService } from '../ag-grid/ag-grid.service';
@Injectable({
  providedIn: 'root'
})
export class FilterService {
  rows: any;
  transactionGridUniqueData = new BehaviorSubject<any>([]);
  emitHeaderInputValue = new EventEmitter();
  emitOnFilter = new EventEmitter();

  constructor(public agGridService:AgGridService) { }
 
}
