import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { NotificationService } from "src/app/shared/services/notification.service";
import { ToastrService } from "ngx-toastr";
import { CalculationService } from '../calculation.service';
import { CalculationModel } from '../calculation.model';
import { DropdownListModel } from '../../fund-information/fund-information.model';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: "app-lock-unlock-records-update",
  templateUrl: "./lock-unlock-records-update.component.html",
  styleUrls: ["./lock-unlock-records-update.component.scss"],
})
export class LockUnlockRecordsUpdateComponent implements OnInit, OnDestroy {
  dropdownListModel = new DropdownListModel();

  private _postCalLockedUnlockedDataSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LockUnlockRecordsUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public calculationService: CalculationService,
    public toasterService: ToastrService,
    public NotificationService: NotificationService,
    public storageService: StorageService

  ) { }

  formModel: CalculationModel;
  fundList: any[] = [];
  ngOnInit() {
    this.formModel = this.data.calculation;
   // this.formModel.calculation.fundId = this.formModel.calculation.fundId.toString();
    this.dropdownListModel = this.data.dropdownModel;
  }

  updateLockStatus() {
    this._postCalLockedUnlockedDataSubscription = this.calculationService.postCalLockedUnlockedData(this.formModel.calculation).subscribe((res) => {
      if (res.isSuccess)
        this.toasterService.success(res.message, "", {
          timeOut: 1000
        });
      else 
        this.NotificationService.warningpopup(res.message);
      this.dialogRef.close({ data: res.response });
    });

  }

  ngOnDestroy(): void {
    if (this._postCalLockedUnlockedDataSubscription) {
      this._postCalLockedUnlockedDataSubscription.unsubscribe();
    }
  }
}
