import { sort } from "src/app/shared/component/sort/sort.model";
import { DropdownModel } from "src/app/shared/model/common.model";
import { PaginationModelCommon } from "src/app/shared/model/pagination.model";
export class TransactionDropdownListModel
{
    fundDropdown                        : DropdownModel[];
    vehicleInvestorDropdown             : DropdownModel[];
    investorDropdown                    : DropdownModel[];
    investmentDropdown                  : DropdownModel[];
    positionDropdown                    : DropdownModel[];
    tierDropdown                        : DropdownModel[];
    transactionFilterDropdown           : DropdownModel[];
}
export class PaginationModel extends PaginationModelCommon
{
  investorId: number;
  selectedIds: number[];
  showColumn : string[]=[];
  sortList: sort[] = [];
}
export class ExportModel
{
    sortColumn          : string;
    sortDirection       : string;
    page                : number=1;
    pageSize            : number=25;
    searchText          : string;
    getAll              : boolean = false;
    fundId              : number =0;
    selectedIds         : number[];
    showColumn          : string[]=[];
    sortList            : sort[] = []
}

export class CalcStatusListModel {
  id: number;
  status: string;
}

export class GetTotalRecordByID {
  FundLevelDataModels: [];
}
