//#region Imports
import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { ComponentCanDeactivate } from "src/app/shared/guard/direty-check.guard";
import { CommonService } from "src/app/shared/services/common.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { PageHeaderModel } from "src/app/shared/component/page-header/page-header.model";
import { TableHeaderModel } from "src/app/shared/component/table-header-buttons/table-header-buttons.model";
import { CommonTabService } from "src/app/shared/services/common-tab.service";
import { PageHeaderComponent } from "src/app/shared/component/page-header/page-header.component";
import { TableHeaderButtonsComponent } from "src/app/shared/component/table-header-buttons/table-header-buttons.component";
import { DropdownListModel } from "../fund-information/fund-information.model";
import { agGridDataModelAllocation, agGridDataModelAllocationSummary, agGridDataModelJournalEntries, agGridDataModelPrefRetCalc, agGridDataModelPrefRetCalcSummary, agGridDataModelTierCalc, agGridDataModelTierCalcSummary, CalcGraphModel, Calculation, CalculationModel } from "./calculation.model";
import { CalculationService } from "./calculation.service";
import { MatDialog } from "@angular/material/dialog";
import { SerchFiltermodalComponent } from "./serch-filtermodal/serch-filtermodal.component";
import { JournalModalComponent } from "./journal-modal/journal-modal.component";
import { ChartModalComponent } from "./chart-modal/chart-modal.component";
import { ScenarioModeModalComponent } from "./scenario-mode-modal/scenario-mode-modal.component";
import { LockUnlockRecordsUpdateComponent } from "./lock-unlock-records-update/lock-unlock-records-update.component";
import { RunModelComponent } from "./run-model/run-model.component";
import { CustomeHeaderService } from "src/app/shared/component/custome-header/custome-header.service";
import { exportMultipleSheetsAsExcel } from 'ag-grid-enterprise';
import { NotificationService } from "src/app/shared/services/notification.service";
import { ExportService } from "src/app/shared/services/export.service";
//#endregion
//#region Component start

@Component({
  selector: "app-calculation",
  templateUrl: "./calculation.component.html",
  styleUrls: ["./calculation.component.scss"],
  providers: [AppComponent],
})
export class CalculationComponent extends CommonService implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;
  @ViewChild(TableHeaderButtonsComponent) tableHeaderComponent: TableHeaderButtonsComponent;
  //#region variable declarations
  // top model which contains filter, save, undo, delete and filter name
  pageHeaderModel: PageHeaderModel;
  // top model for the panel over grid which contains add row, export, reset filter, reset grid, undo , soft delete and permanent delete and view/show hidden records
  tableHeaderModel: TableHeaderModel;

  //current/selected tab
  tabID: string;
  // grid apis which sets on grid ready event from ag-grid
  prefRetCalcGridApi: any;
  prefRetCalcSummaryGridApi: any;
  tierCalcGridApi: any;
  tierCalcSummaryGridApi: any;
  allocationsGridApi: any;
  allocationsSummaryGridApi: any;
  journalEntriesGridApi: any;
  //for changed form data
  formModel = new CalculationModel();
  //for original form data - will be used to reset/clear the form
  loadedFormModel = new CalculationModel();
  // for loading all the dropdowns - form and grid every dropdown
  dropdownListModel = new DropdownListModel();

  public single: any[];
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Country';
  public showYAxisLabel = true;
  public yAxisLabel = 'Population';
  public colorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#378D3B', '#0096A6', '#F47B00', '#606060']
  };  
  showChart: boolean = false;

  // tab group list
  allTabGroupList: any[] = [
    { dataModel: agGridDataModelPrefRetCalc, label: 'Pref Ret Calc', tabIndex: 0 , tabType : "details" , visible : false },
    { dataModel: agGridDataModelPrefRetCalcSummary, label: 'Pref Ret Calc Summary', tabIndex: 1 , tabType : "summary", visible : false},
    { dataModel: agGridDataModelTierCalc, label: 'Tier Calc', tabIndex: 2 , tabType : "details", visible : false},
    { dataModel: agGridDataModelTierCalcSummary, label: 'Tier Calc Summary', tabIndex: 3 , tabType : "summary", visible : false},
    { dataModel: agGridDataModelAllocation, label: 'Allocations', tabIndex: 4 , tabType : "details", visible : false},
    { dataModel: agGridDataModelAllocationSummary, label: 'Allocations Summary', tabIndex: 5 , tabType : "summary", visible : false},
    { dataModel: agGridDataModelJournalEntries, label: 'Journal Entries', tabIndex: 6 , tabType : "details", visible : false}
  ];

  tabGroupList : any[];
  //#endregion variable declarations


  private _onSaveBeforeCloseSubscription!: Subscription;
  
  
  //#region ctor
  constructor(
    public renderer: Renderer2,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public commonTabService: CommonTabService,
    public calculationService : CalculationService,
    public customeHeaderService : CustomeHeaderService,
    private notificationService: NotificationService,
    public exportService : ExportService,
    public dialog: MatDialog
  ) { super(renderer); 
  }


  ngOnDestroy(): void {
    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }
  //#endregion
  //#region Save Confirmation
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
   return  this.dataNotChanged()
  }
  
  //#endregion
  //#region Init
  ngOnInit(): void {
    this.fillPageHeader();
    this.setTableHeader();
    this._onSaveBeforeCloseSubscription = this.commonTabService.onSaveBeforeClose.subscribe((data) => this.onSave());
    this.customeHeaderService.columnCheckboxChecked.subscribe((res) => {
      this.showChart = true;
     this.getGraphData()
    });
  }

  fillPageHeader() {
    this.pageHeaderModel = new PageHeaderModel();
    this.pageHeaderModel.placeHolder = "Enter New Calculation Name";
    this.pageHeaderModel.controllerName = StorageName.CALCULATION_CONTROLLER;
    this.pageHeaderModel.IdStorageName = StorageName.CALCULATION_ID;
    this.pageHeaderModel.fundIdStorageName = StorageName.CALCULATION_FUNDID;
    this.pageHeaderModel.filterOptionStorageName = StorageName.CALCULATION_DROPDOWN;
    this.pageHeaderModel.setVisibilityForForm("Calculation");

  }
  setTableHeader() {
    this.tableHeaderModel = new TableHeaderModel();
    this.tableHeaderModel.setVisibility({
      isAddButtonVisible: false,
      isExportButtonVisible: false,
      isUndoButtonVisible: false,
      isResetFilterButtonVisible: true,
      isSoftDeleteButtonVisible: false,
      isPermanentDeleteButtonVisible: false,
      isExpandButtonVisible: false,
      isCalculationForm : true
    });    
    this.setTabGroup();
  }
  fillTopDropdown() {
    this.dropdownListModel.fundDropdown = this.storageService.retrieve(StorageName.FUND_DROPDOWN);
    this.dropdownListModel.calculationRuleDropdown = this.storageService.retrieve(StorageName.CALCULATIONRULE_DROPDOWN);
    this.dropdownListModel.calcTypeDropdown = this.storageService.retrieve(StorageName.CALCULATION_TYPE_DROPDOWN);
    this.dropdownListModel.statusDropdown = this.storageService.retrieve(StorageName.STATUS_GRID_DROPDOWN);
    this.dropdownListModel.calculationStateDropdown = this.storageService.retrieve(StorageName.CALCULATION_STATE_DROPDOWN);
    this.dropdownListModel.subActDropdown = this.storageService.retrieve(StorageName.SUB_ACT_DROPDOWN);
    this.dropdownListModel.LockUnlockStatusDropdown = this.storageService.retrieve(StorageName.LOCK_UNLOCK_STATUS_DROPDOWN);
    this.dropdownListModel.calculationDropdown = this.storageService.retrieve(StorageName.CALCULATION_DROPDOWN);
    this.dropdownListModel.tierDropdown = this.storageService.retrieve(StorageName.TIER_DROPDOWN);
    this.dropdownListModel.calculationLevelDropdown = this.storageService.retrieve(StorageName.CALCULATION_LEVEL_DROPDOWN);
  }
  //#endregion
  //#region Clear Complete Form
  clearFields(): void {
    this.formModel = new CalculationModel();
    this.pageHeaderModel.id = 0;
    this.pageHeaderModel.name = "";


    //set enpty rows to all grid apis
    this.prefRetCalcGridApi.api.setRowData([]);
    this.prefRetCalcSummaryGridApi.api.setRowData([]);
    this.tierCalcGridApi.api.setRowData([]);
    this.tierCalcSummaryGridApi.api.setRowData([]);
    this.allocationsGridApi.api.setRowData([]);
    this.allocationsSummaryGridApi.api.setRowData([]);
    this.journalEntriesGridApi.api.setRowData([]);

    //fill the form with blank records
    this.fillForm(this.formModel);
  }
  //#endregion Clear Complete Form
  //#region page header events
  onAddNewForm() {
    //clear form fields
    this.clearFields();
    //focus on textbox to add new form name
    document.getElementById("AddNewForm").focus();

    this.tableHeaderModel.isAddNew = true;

    //load first tab by default - 0
    this.loadTabData();
  }
  //calling post api to save/update records
  onSave(): any {
    // get filter dropdown value from child component and assign
    this.formModel.calculation.calcName = this.pageHeaderModel.name;
    this.formModel.calculation.id = this.pageHeaderModel.id;
    //create new mode for form to use for posting updated entries
    var postData = new CalculationModel();
    postData.calculation = this.formModel.calculation;
    this.pageHeaderComponent.post(postData, false);
  }
  //#endregion

  //#region Tab Events
  //

  onChangeTab(event) {
    this.tabID = event.index;
    this.loadTabData(event.tab.textLabel);
  }
  //load the tab records on click of tab
  loadTabData(tabName : string = "Pref Ret Calc") {
    this.tabID = tabName;
    this.tableHeaderModel.setVisibility({
      isExportButtonVisible : false,
      isAddButtonVisible: false,
      isSoftDeleteButtonVisible: false,
      isShowDeletedButtonVisible: false,
      isHideDeletedButtonVisible: false,
      isResetDeletedButtonVisible: false,
      isPermanentDeleteButtonVisible: false,
      isCalculationForm : true
    });
    this.tableHeaderModel.isGraohButtonVisible = false;

    agGridDataModelPrefRetCalc.gridApi = this.prefRetCalcGridApi;
    if(this.prefRetCalcGridApi != undefined)
    this.formModel.prefRetCalcs = this.agGridService.performFilter(agGridDataModelPrefRetCalc);
    agGridDataModelPrefRetCalcSummary.gridApi = this.prefRetCalcSummaryGridApi;
    if(this.prefRetCalcSummaryGridApi != undefined)
    this.formModel.prefRetCalcSummary = this.agGridService.performFilter(agGridDataModelPrefRetCalcSummary);
    agGridDataModelTierCalc.gridApi = this.tierCalcGridApi;
    if(this.tierCalcGridApi != undefined)
    this.formModel.tierCalcs = this.agGridService.performFilter(agGridDataModelTierCalc);
    agGridDataModelTierCalcSummary.gridApi = this.tierCalcSummaryGridApi;
    if(this.tierCalcSummaryGridApi != undefined)
    this.formModel.tierCalcSummary = this.agGridService.performFilter(agGridDataModelTierCalcSummary);
    agGridDataModelAllocation.gridApi = this.allocationsGridApi;
    if(this.allocationsGridApi != undefined)
    this.formModel.allocations = this.agGridService.performFilter(agGridDataModelAllocation);
    agGridDataModelAllocationSummary.gridApi = this.allocationsSummaryGridApi;
    if(this.allocationsSummaryGridApi != undefined)
    this.formModel.allocationsSummary = this.agGridService.performFilter(agGridDataModelAllocationSummary);
    agGridDataModelJournalEntries.gridApi = this.journalEntriesGridApi;
    if(this.journalEntriesGridApi != undefined)
    this.formModel.journalEntries = this.agGridService.performFilter(agGridDataModelJournalEntries);
    switch (this.tabID) {
      case "Pref Ret Calc": {
        // agGridDataModelPrefRetCalc.masterIdStorageName = this.pageHeaderModel.IdStorageName;
    
        this.tableHeaderModel.agGridI = agGridDataModelPrefRetCalc;
        this.tableHeaderModel.gridApi = this.prefRetCalcGridApi;
        break;
      }
      case "Pref Ret Calc Summary": {
      //  agGridDataModelPrefRetCalcSummary.masterIdStorageName = this.pageHeaderModel.IdStorageName;
    
        this.tableHeaderModel.agGridI = agGridDataModelPrefRetCalcSummary;
        this.tableHeaderModel.gridApi = this.prefRetCalcSummaryGridApi;
        break;
      }
      case "Tier Calc": {
       // agGridDataModelTierCalc.masterIdStorageName = this.pageHeaderModel.IdStorageName;
     
        this.tableHeaderModel.agGridI = agGridDataModelTierCalc;
        this.tableHeaderModel.gridApi = this.tierCalcGridApi;
        this.tableHeaderModel.isGraohButtonVisible = true;

        break;
      }
      case "Tier Calc Summary": {
      //  agGridDataModelTierCalcSummary.masterIdStorageName = this.pageHeaderModel.IdStorageName;
      
        this.tableHeaderModel.agGridI = agGridDataModelTierCalcSummary;
        this.tableHeaderModel.gridApi = this.tierCalcSummaryGridApi;
        break;
      }
      case "Allocations": {
       // agGridDataModelAllocation.masterIdStorageName = this.pageHeaderModel.IdStorageName;
      
        this.tableHeaderModel.agGridI = agGridDataModelAllocation;
        this.tableHeaderModel.gridApi = this.allocationsGridApi;
        this.tableHeaderModel.isGraohButtonVisible = true;

        break;
      }
      case "Allocations Summary": {
      //  agGridDataModelAllocationSummary.masterIdStorageName = this.pageHeaderModel.IdStorageName;
     
        this.tableHeaderModel.agGridI = agGridDataModelAllocationSummary;
        this.tableHeaderModel.gridApi = this.allocationsSummaryGridApi;
        break;
      }
      case "Journal Entries": {
      //  agGridDataModelPrefRetCalc.masterIdStorageName = this.pageHeaderModel.IdStorageName;
  
        this.tableHeaderModel.agGridI = agGridDataModelJournalEntries;
        this.tableHeaderModel.gridApi = this.journalEntriesGridApi;
        break;
      }
    }

    var fromHomePageExport = this.storageService.retrieve(StorageName.IS_EXPORT_CALC_FROM_HOMEPAGE);
    var fromHomePageExportWithFormule = this.storageService.retrieve(StorageName.IS_EXPORT_WITH_FORMULA_CALC_FROM_HOMEPAGE);
    if(fromHomePageExport != undefined && fromHomePageExport == true)
    {
      this.storageService.removeItem(StorageName.IS_EXPORT_CALC_FROM_HOMEPAGE);
      this.storageService.removeItem(StorageName.IS_EXPORT_WITH_FORMULA_CALC_FROM_HOMEPAGE);
      if(fromHomePageExportWithFormule == true)
        this.onExcelExport(true);
       else
        this.onExcelExport(false);
    }
  }
  ongridReady(item, gridApi) {
    switch (item.tabIndex) {
      case 0: this.prefRetCalcGridApi = gridApi; break;
      case 1: this.prefRetCalcSummaryGridApi = gridApi; break;
      case 2: this.tierCalcGridApi = gridApi; break;
      case 3: this.tierCalcSummaryGridApi = gridApi; break;
      case 4: this.allocationsGridApi = gridApi; break;
      case 5: this.allocationsSummaryGridApi = gridApi; break;
      case 6: this.journalEntriesGridApi = gridApi; break;
    }
  }

  onPageSizeUpdated(pageSize){
    switch (this.tabID) {
      case "Pref Ret Calc": this.storageService.store(agGridDataModelPrefRetCalc.paginationStore,pageSize);                 break;
      case "Pref Ret Calc Summary": this.storageService.store(agGridDataModelPrefRetCalcSummary.paginationStore,pageSize);  break;
      case "Tier Calc": this.storageService.store(agGridDataModelTierCalc.paginationStore,pageSize);                        break;
      case "Tier Calc Summary": this.storageService.store(agGridDataModelTierCalcSummary.paginationStore,pageSize);         break;
      case "Allocations": this.storageService.store(agGridDataModelAllocation.paginationStore,pageSize);                    break;
      case "Allocations Summary": this.storageService.store(agGridDataModelAllocationSummary.paginationStore,pageSize);     break;
      case "Journal Entries": this.storageService.store(agGridDataModelJournalEntries.paginationStore,pageSize);            break;
    }
    this.loadTabData(this.tabID);
  }

  setGridsOnly(formModel: CalculationModel)
  {
    this.storageService.store(StorageName.CALCULATIONS_PREF_RET_CALC_INITIALROWS, formModel.prefRetCalcs);
    this.storageService.store(StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_INITIALROWS, formModel.prefRetCalcSummary);
    this.storageService.store(StorageName.CALCULATIONS_TIER_CALC_INITIALROWS, formModel.tierCalcs);
    this.storageService.store(StorageName.CALCULATIONS_TIER_CALC_SUMMARY_INITIALROWS, formModel.tierCalcSummary);
    this.storageService.store(StorageName.CALCULATIONS_ALLOCATIONS_INITIALROWS, formModel.allocations);
    this.storageService.store(StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_INITIALROWS, formModel.allocationsSummary);
    this.storageService.store(StorageName.CALCULATIONS_JOURNAL_ENTRIES_INITIALROWS, formModel.journalEntries);
    this.loadTabData(this.tabID);
  }

  //#endregion tab events
  //#region get and fill form records
  getById(res: any) {
   // this.storageService.store(StorageName.CALCULATIONID, parseInt(res.response.calculation.calcId));
    this.setForm(res.response);
  }
  setForm(formModel: CalculationModel) {
    this.fillForm(formModel);
    this.loadTabData(this.tabID);
  }
  fillForm(formModel: CalculationModel) {
    //#region Top Data
    if(this.dropdownListModel.investmentDropdown == undefined)
      this.dropdownListModel.investmentDropdown = formModel.investmentDropdown;
    else if(JSON.stringify(this.dropdownListModel.investmentDropdown).replace("'", "").replace(/"/g, '') !== JSON.stringify(formModel.investmentDropdown).replace("'", "").replace(/"/g, ''))
      this.dropdownListModel.investmentDropdown = formModel.investmentDropdown;
   
   
    if(this.dropdownListModel.positionDropdown == undefined)
      this.dropdownListModel.positionDropdown = formModel.positionDropdown;
    else if(JSON.stringify(this.dropdownListModel.positionDropdown).replace("'", "").replace(/"/g, '') !== JSON.stringify(formModel.positionDropdown).replace("'", "").replace(/"/g, ''))
      this.dropdownListModel.positionDropdown = formModel.positionDropdown;

    if(this.dropdownListModel.investorGroupDropdown == undefined)
      this.dropdownListModel.investorGroupDropdown = formModel.investorGroupDropdown;
    else if(JSON.stringify(this.dropdownListModel.investorGroupDropdown).replace("'", "").replace(/"/g, '') !== JSON.stringify(formModel.investorGroupDropdown).replace("'", "").replace(/"/g, ''))
      this.dropdownListModel.investorGroupDropdown = formModel.investorGroupDropdown;

    this.formModel.calculation = formModel.calculation;
    this.formModel.calculation.proceeds = (formModel.calculation.proceeds=='')? undefined : this.agGridService.currencyFormatter(formModel.calculation.proceeds,"",2);
    this.loadedFormModel.calculation = { ...this.formModel.calculation };
    this.pageHeaderModel.id = formModel.calculation.id;
    this.pageHeaderModel.name = formModel.calculation.calcName;
    this.pageHeaderModel.isCalcLocked = formModel.calculation.isCalcLocked;
    this.pageHeaderModel.fundId = (formModel.calculation.fundId != undefined && formModel.calculation.fundId != null && formModel.calculation.fundId != "") ? 0 : parseInt(formModel.calculation.fundId) ;
    this.tableHeaderModel.isAddNew = false;
    if(this.formModel.calculation.isHypothetical == true)
      this.pageHeaderComponent.pageHeaderModel.isHypotheticalLabelVisible = true;
    else
    this.pageHeaderComponent.pageHeaderModel.isHypotheticalLabelVisible = false;

    if(this.formModel.calculation.isScenarioModeApplied == true)
      this.pageHeaderComponent.pageHeaderModel.isScenarioModeAppliedLabelVisible = true;
    else
    this.pageHeaderComponent.pageHeaderModel.isScenarioModeAppliedLabelVisible = false;
    if(this.formModel.calculation.investmentId != null && this.formModel.calculation.investmentId != undefined)
      this.formModel.calculation.investmentId = this.formModel.calculation.investmentId.toString();
    if(this.formModel.calculation.positionId != null && this.formModel.calculation.positionId != undefined)
      this.formModel.calculation.positionId = this.formModel.calculation.positionId.toString(); 
    if(this.formModel.calculation.investorGroupId != null && this.formModel.calculation.investorGroupId != undefined)
      this.formModel.calculation.investorGroupId = this.formModel.calculation.investorGroupId.toString(); 

    // if(this.formModel.calculation.fundId != undefined || this.formModel.calculation.fundId != null)
    //   this.fundDropdownChange(this.formModel.calculation.fundId);
    //#endregion Top Data
    //#region Set Grid Storage
    this.storageService.store(StorageName.CALCULATIONS_PREF_RET_CALC_INITIALROWS, formModel.prefRetCalcs);
    this.storageService.store(StorageName.CALCULATIONS_PREF_RET_CALC_SUMMARY_INITIALROWS, formModel.prefRetCalcSummary);
    this.storageService.store(StorageName.CALCULATIONS_TIER_CALC_INITIALROWS, formModel.tierCalcs);
    this.storageService.store(StorageName.CALCULATIONS_TIER_CALC_SUMMARY_INITIALROWS, formModel.tierCalcSummary);
    this.storageService.store(StorageName.CALCULATIONS_ALLOCATIONS_INITIALROWS, formModel.allocations);
    this.storageService.store(StorageName.CALCULATIONS_ALLOCATIONS_SUMMARY_INITIALROWS, formModel.allocationsSummary);
    this.storageService.store(StorageName.CALCULATIONS_JOURNAL_ENTRIES_INITIALROWS, formModel.journalEntries);
    
    //#endregion
  }
  //#endregion get and fill form records

  onShowDetails(showDetails:  boolean){
    this.tableHeaderModel.showDetails = showDetails;
    this.setTabGroup();
  }

  onShowSummary(showSummary : boolean){
    this.tableHeaderModel.showSummary = showSummary;
    this.setTabGroup();
  }

  onIncludeDraftStatus(showDetails){
    this.formModel.isDraftCalc = showDetails;
    this.onCalcListDropdownChange(this.pageHeaderModel.id);
  }

  setTabGroup(){
    this.tabGroupList = this.allTabGroupList.filter((obj) => {
      return (obj.tabType === 'details' && this.tableHeaderModel.showDetails== true) || (obj.tabType === 'summary' && this.tableHeaderModel.showSummary== true);
    });
  }

  fundDropdownChange(fundId : string){
     this.formModel.calculation.fundId=fundId;
     this.calculationService.getInvestmentDropDownDataByFundID(this.formModel.calculation.fundId ,this.formModel.calculation.investmentId )
     .subscribe((res) => {
       if (res.isSuccess) {
        this.dropdownListModel.investmentDropdown = res.response;
        this.formModel.calculation.investmentId = (this.formModel.calculation.investmentId!= undefined && this.formModel.calculation.investmentId != null) ? this.formModel.calculation.investmentId.toString() : undefined;
        this.investmentDropdownChange(this.formModel.calculation.investmentId)
       }
     });
  }

  investmentDropdownChange(investmentId : string){
    this.formModel.calculation.investmentId=investmentId;
    this.calculationService.getPositionDropDownDataByFundID(this.formModel.calculation.fundId,this.formModel.calculation.investmentId , this.formModel.calculation.positionId )
    .subscribe((res) => {
      if (res.isSuccess) {
       this.dropdownListModel.positionDropdown = res.response;
      // this.formModel.calculation.positionId = (this.formModel.calculation.positionId!= undefined && this.formModel.calculation.positionId != null) ? this.formModel.calculation.positionId.toString() : undefined;
       this.getInvestorGroupByFundId(this.formModel.calculation.fundId,this.formModel.calculation.investorGroupId)
      }
    });
  }
  getInvestorGroupByFundId(fundId : string, investorGroupId : string){
    this.formModel.calculation.fundId=fundId;
    this.formModel.calculation.investorGroupId=investorGroupId;
    this.calculationService.getInvestorGroupByFundId(this.formModel.calculation.fundId)
    .subscribe((res) => {
      if (res.isSuccess) {
       this.dropdownListModel.investorGroupDropdown = res.response;
      // this.formModel.calculation.investorGroupId = (this.formModel.calculation.investorGroupId!= undefined && this.formModel.calculation.investorGroupId != null) ? this.formModel.calculation.investorGroupId.toString() : undefined;
      }
    });
  }

  




  onPostSuccess()
  {
    this.loadedFormModel.calculation = { ...this.formModel.calculation };
    // this.storageService.store(this.pageHeaderModel.IdStorageName,calcListId);
    //this.pageHeaderModel.id = calcListId;
    //this.getCalculationById();
  }

  onCalcListDropdownChange(calcListId){
    this.storageService.store(this.pageHeaderModel.IdStorageName,calcListId);
    //this.pageHeaderModel.id = calcListId;
    this.getCalculationById();
  }

  getCalculationById(onRun : boolean = false){
    this.pageHeaderModel.fundId= this.storageService.retrieve(this.pageHeaderModel.fundIdStorageName);
    this.calculationService.getCalcByFundIdCalcId(this.pageHeaderModel.fundId,this.pageHeaderModel.id , this.formModel.isDraftCalc)
    .subscribe((res) => {
      if (res.isSuccess)
      {
          this.getById(res);
      }
    });
  }

  onFundDropdownChange(fundId){
    this.storageService.store(this.pageHeaderModel.fundIdStorageName, fundId);
    this.getCalcFilterDropdown(new CalculationModel())
  }

  getCalcFilterDropdown(calculationModel : CalculationModel)
  {
    if(calculationModel == undefined)
      calculationModel = new CalculationModel();
    var searchListFromPopup =  this.storageService.retrieve('searchCalcList');
    if(searchListFromPopup != undefined){
      calculationModel.statusDropdown = searchListFromPopup.statusDropdown;
      calculationModel.calculationStateDropdown = searchListFromPopup.calculationStateDropdown;
      calculationModel.calcTypeDropdown = searchListFromPopup.calcTypeDropdown;
    }
    calculationModel.fundId = this.storageService.retrieve(this.pageHeaderModel.fundIdStorageName);
    calculationModel.calcId = this.storageService.retrieve(this.pageHeaderModel.IdStorageName);
    this.calculationService.getCalcListFromSearch(calculationModel).subscribe((res) => {
      if (res.isSuccess) {
        this.dropdownListModel.calculationDropdown = res.response;
        this.pageHeaderComponent.setCalcFilterDropdown(this.pageHeaderModel.id.toString(),res);
        if(res.response.length ==1 || res.response.length==0)
          this.pageHeaderModel.id = 0;
        this.calculationService.getCalcByFundIdCalcId(calculationModel.fundId,this.pageHeaderModel.id, this.formModel.isDraftCalc )
          .subscribe((res) => {
            if (res.isSuccess)
              this.getById(res);
          });
      }
    });
  }

  //#region  popups
  private onOpenCalcFilterPopup(): void {
    let dialogRef = this.dialog.open(SerchFiltermodalComponent, {
      data: { calculation: this.formModel}, panelClass: 'my-dialog-container'});
    dialogRef.afterClosed().subscribe((loaddata) => {
      if (loaddata)
      this.getCalcFilterDropdown(loaddata.data);
    });
  }


  private onOpenJEPopup(): void {
    let dialogRef = this.dialog.open(JournalModalComponent, {
    data: { calculation: this.formModel , dropdownModel : this.dropdownListModel }, panelClass: 'my-dialog-container'});
    dialogRef.afterClosed().subscribe((loaddata) => {
      if (loaddata.data != undefined)
        this.onSave();
    });
  }

  private onOpenSMPopup(): void {
    let dialogRef = this.dialog.open(ScenarioModeModalComponent, {
      data: { calculation: this.formModel, tierDropdown : this.dropdownListModel.tierDropdown}, panelClass: 'my-dialog-container'});
    dialogRef.afterClosed().subscribe((loaddata) => {
      if (loaddata)
      console.log("loaded")
    });
  }

  private onOpenLockPopup(): void {
    let dialogRef = this.dialog.open(LockUnlockRecordsUpdateComponent, {
      data: { calculation: this.formModel , dropdownModel : this.dropdownListModel}, panelClass: 'my-dialog-container'});
    dialogRef.afterClosed().subscribe((loaddata) => {
      if (loaddata)
      console.log("loaded")
    });
  }

  private onOpenGraphPopup(): void {
    let dialogRef = this.dialog.open(ChartModalComponent, {
      data: { calculation: this.formModel}, panelClass: 'my-dialog-container'});
    dialogRef.afterClosed().subscribe((loaddata) => {
      if (loaddata)
      console.log("loaded")
    });
  }
  openConfirmDialog() {
    this.notificationService.openConfirmDialog().subscribe((result) => {
      if (result.isDenied) {
        this.openRunCalcModel();
      }
      else if (result.isConfirmed) {
        this.onSave(); 
        this.openRunCalcModel();
      }
    })
    return false;
  }
  private onRunCalc() : void{
    if(this.dataNotChanged()===false)
    {
      this.openConfirmDialog();
    }
    else
      this.openRunCalcModel();
  }

  openRunCalcModel()
  {
    let dialogRef = this.dialog.open(RunModelComponent, {
      data: { calculation: this.formModel, dropdownModel : this.dropdownListModel}, panelClass: 'my-dialog-container'});
    dialogRef.afterClosed().subscribe((loaddata) => {
      if (loaddata.data != undefined)
      {
        this.getCalculationById(true);
        if(loaddata.data.isExportedExcel)
          this.onExcelExport(loaddata.data.includeFormulasInExport);
      }
    });
  }

  getColumnsToInclude(gridApi) : any
  {
              // Get the column definitions
              const columnDefs = gridApi.api.getColumnDefs();
              // Get the column state
              const columnState = gridApi.columnApi.getColumnState();
              const columnsToInclude = columnState
              .filter(colState => !colState.hide) // Exclude hidden columns
              .map((colState) => {
                const columnDef = columnDefs.find(col => col.colId === colState.colId);
                const displayName = columnDef ? columnDef.headerName : colState.colId; // Use headerName if available
                return {
                  ColumnName: displayName,
                  ColumnOrder: colState.order
                };
            });
              return columnsToInclude;
  }

  onExcelExport(includeFormulasInExport : boolean) {
    if(includeFormulasInExport == true)
    {
      var postData = new CalculationModel();
  
      postData.calculation = this.formModel.calculation;
      postData.prefRetCalcs =  this.storageService.retrieve(agGridDataModelPrefRetCalc.initialRows);
      postData.tierCalcs =  this.storageService.retrieve(agGridDataModelTierCalc.initialRows);
      postData.allocations =  this.storageService.retrieve(agGridDataModelAllocation.initialRows);
      postData.journalEntries = this.storageService.retrieve(agGridDataModelJournalEntries.initialRows);

      const columnKeysPrefRetCalc = this.prefRetCalcGridApi.columnApi.getColumnState();
      postData.columnKeysPrefRetCalc = columnKeysPrefRetCalc.map((colState, index) => ({
        ColumnName: colState.colId,
        ColumnOrder: index + 1
      }));
      postData.columnKeystierCalc = this.getColumnsToInclude(this.tierCalcGridApi);

      const arr: string[] = ['Customized Tier', 'Comparison Amt', 'Include in Calc Balance'];

      var customisedTierExist : boolean = false;
      var comparisonAmtExist : boolean = false;
      var includeInCalcBalanceExist : boolean = false;

     postData.columnKeystierCalc.forEach(item => 
      {
        if(item.ColumnName == "Customized Tier")
          customisedTierExist = true;
        if(item.ColumnName == "Comparison Amt")
          comparisonAmtExist = true;
        if(item.ColumnName == "Include in Calc Balance")
          includeInCalcBalanceExist = true;
      }
      );
      if(customisedTierExist == false || comparisonAmtExist == false || includeInCalcBalanceExist == false )
        this.notificationService.warningpopup("Imported file may not run formula correctly due to missing columns ('Customized Tier', 'Comparison Amt', 'Include in Calc Balance')")

      const columnKeysallocations = this.allocationsGridApi.columnApi.getColumnState();
      postData.columnKeysallocations = columnKeysallocations.map((colState, index) => ({
        ColumnName: colState.colId,
        ColumnOrder: index + 1
      }));
      const columnKeysjournalEntries = this.journalEntriesGridApi.columnApi.getColumnState();
      postData.columnKeysjournalEntries = columnKeysjournalEntries.map((colState, index) => ({
        ColumnName: colState.colId,
        ColumnOrder: index + 1
      }));
    // this.download();
      this.calculationService.ExportAfterRun(postData).subscribe(result => {
        if (result.isSuccess) this.exportService.export(result.response);
        else                  this.notificationService.errorpopup(result.errors)
      })
    }
    else
   {

    var columnKeysPrefRetCalc = this.agGridService.generateColumnsForExcel(this.prefRetCalcGridApi);
    var columnKeystierCalc = this.agGridService.generateColumnsForExcel(this.tierCalcGridApi);
    var columnKeysallocations = this.agGridService.generateColumnsForExcel(this.allocationsGridApi);
    var columnKeysjournalEntries = this.agGridService.generateColumnsForExcel(this.journalEntriesGridApi);


    var tierCalcExcel = this.tierCalcGridApi.api.getSheetDataForExcel({ sheetName: 'Tier Calc', columnKeys : columnKeystierCalc,   processCellCallback: (params) => {
      const colDef = params.column.getColDef()
      // try to reuse valueFormatter from the colDef
      if (colDef.valueFormatter) {
         if (colDef.cellClass.toString().includes('percentFormat')) {
          params.value = params.value == undefined  ? "" : ( params.value / 100);
        }
        return params.value;
      }
      if (params.value == "true" || params.value  ==  true && params.value != 1) {
        params.value = "Yes"
      }
      if (params.value == "false") {
        params.value = ""
      }
      return params.value;
    }});
    var prefRetCalcExcel = this.prefRetCalcGridApi.api.getSheetDataForExcel({ sheetName: 'Pref Ret Calc' , columnKeys : columnKeysPrefRetCalc ,processCellCallback: (params) => {
      const colDef = params.column.getColDef()
      // try to reuse valueFormatter from the colDef
      if (colDef.valueFormatter) {
         if (colDef.cellClass.toString().includes('percentFormat')) {
          params.value = params.value == undefined  ? "" : ( params.value / 100);
        }
        return params.value;
      }
      // if (colDef.cellClass.toString().includes('ExcelDate')) {
      //   params.value = params.value == undefined  ? "" : ""// this.agGridService.dateConvertorByRegion(params.value );
      // }
      if (params.value == "true" || params.value  ==  true && params.value != 1) {
        params.value = "Yes"
      }
      if (params.value == "false") {
        params.value = ""
      }
      return params.value;
    }});
    var allocationsExcel = this.allocationsGridApi.api.getSheetDataForExcel({ sheetName: 'Allocations' , columnKeys : columnKeysallocations, processCellCallback: (params) => {
      const colDef = params.column.getColDef()
      // try to reuse valueFormatter from the colDef
      if (colDef.valueFormatter) {
         if (colDef.cellClass.toString().includes('percentFormat')) {
          params.value = params.value == undefined  ? "" : ( params.value / 100);
        }
        return params.value;
      }
      if (params.value == "true" || params.value  ==  true && params.value != 1) {
        params.value = "Yes"
      }
      if (params.value == "false") {
        params.value = ""
      }
      return params.value;
    }});
    var journalEntriesExcel = this.journalEntriesGridApi.api.getSheetDataForExcel({ sheetName: 'Journal Entries' , columnKeys : columnKeysjournalEntries, processCellCallback: (params) => {
      const colDef = params.column.getColDef()
      // try to reuse valueFormatter from the colDef
      if (colDef.valueFormatter) {
         if (colDef.cellClass.toString().includes('percentFormat')) {
          params.value = params.value == undefined  ? "" : ( params.value / 100);
        }
        return params.value;
      }
      if (params.value == "true" || params.value  ==  true && params.value != 1) {
        params.value = "Yes"
      }
      if (params.value == "false") {
        params.value = ""
      }
      return params.value;
    }});
    var spreadsheets = [];
    spreadsheets.push(
      tierCalcExcel ,prefRetCalcExcel ,allocationsExcel,journalEntriesExcel
    );
    exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: this.pageHeaderModel.name  +'.xlsx',
    });
  }
  }

  getGraphData()
  {
    var calcGraphModel = new CalcGraphModel();
    calcGraphModel.selectedGrid = this.tabID;
    calcGraphModel.fundId = this.storageService.retrieve(this.pageHeaderModel.fundIdStorageName);
    calcGraphModel.calcId = this.formModel.calculation.id;
    if(this.tabID=="Allocations")
    {
      const columnNames = this.allocationsGridApi.columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerComponentParams).filter(a=> a.isSelected != undefined && a.isSelected == true).map(a=>a.displayName);
      calcGraphModel.selectedColumnNames = columnNames.join(',');
    }
    else
    {
      const columnNames = this.tierCalcGridApi.columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerComponentParams).filter(a=> a.isSelected != undefined && a.isSelected == true).map(a=>a.displayName);
      calcGraphModel.selectedColumnNames = columnNames.join(',');
    }
    this.calculationService.GetChartData(calcGraphModel)
    .subscribe((res) => {
      if (res.isSuccess)
      {
        calcGraphModel.graphJson = JSON.stringify(res.response);
        this.multi = JSON.parse(calcGraphModel.graphJson);
        this.xAxisLabel = this.formModel.calculation.fundName;
        this.yAxisLabel = 'Amt';
        this.single = JSON.parse(calcGraphModel.graphJson)[0].series;
      }
    });
  }

  public onShowGraph()
  {
    if(this.showChart)
      this.showChart = false;
    else
      this.showChart = true;
    if(this.showChart)
      this.getGraphData();
  }

  private getCalcAllocPer(calcExpeAllocPerc) : void{
    this.formModel.calculation.calcExpeAllocPerc= calcExpeAllocPerc;
    if(calcExpeAllocPerc)
      this.calculationService.GetCalcAllocPerByFundIdCalcID(this.formModel.calculation)
      .subscribe((res) => {
        if (res.isSuccess)
          this.formModel.calculation.allocationPercent =  res.response;
      });
  }

  private changedLockStatus(isCalcLocked){
    this.formModel.calculation.isCalcLocked = isCalcLocked;
    this.pageHeaderModel.isCalcLocked = isCalcLocked;
  }

  private dataNotChanged() : boolean
  {
    var data1 = JSON.stringify(this.formModel.calculation).replace("'", "").replace(/"/g, '');
    var data2 = JSON.stringify(this.loadedFormModel.calculation).replace("'", "").replace(/"/g, '');
    var test =  data1 === data2;
    return (
      data1 === data2
      );
  }

  onChangeCalculationLevel(calculationLevelId)
  {
    this.formModel.calculation.calculationLevelId = calculationLevelId;
    switch(this.formModel.calculation.calculationLevelId)
    {
      case "1":
        this.formModel.calculation.investmentId = null;
        this.formModel.calculation.positionId = null;
        this.formModel.calculation.calcDataSet = '';
        break;
      case "2":
        this.formModel.calculation.investmentId = null;
        this.formModel.calculation.positionId = null;
        break;
      case "3":
        this.formModel.calculation.calcDataSet = '';
        break;
    }
  }



  onChangeProceeds(proceeds : string)
  {
    // alert(proceeds)
    // var numberData = proceeds.replace(",", "");
    // alert(parseFloat(numberData).toFixed(2))
  var proceedsValue = this.agGridService.currencyFormatter(proceeds.split(',').join(''),"",2);
  //alert(proceedsValue)
      this.formModel.calculation.proceeds = proceedsValue.toString();
  }

  // onFocusProceeds(proceeds : any)
  // {
  //     this.formModel.calculation.proceeds = proceeds.split(',').join('');
  // }

  

  //#endregion
}
//#endregion Component End