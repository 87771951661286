import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResetFilterCalcRuleComponent } from 'src/app/pages/reset-filter-calc-rule/reset-filter-calc-rule/reset-filter-calc-rule.component';
import { StorageName } from 'src/app/shared/common/enum';
import { DropdownModel } from 'src/app/shared/model/common.model';
import { FilterModel } from 'src/app/shared/model/response.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AgGridService } from '../../ag-grid/ag-grid.service';
import { Filter } from '../filter.model';
import { FilterService } from '../filter.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() isFilter: boolean;
  @Input() isInputFilter: boolean;
  @Input() isPopUpInputFilter: boolean;
  @Input() isDropDownFilter: boolean;
  @Input() params: any;
  @Input() columnName: string;
  @Input() gridName: string;
  @Input() initialRows: string;
  @Input() filterStorageName: string;
  @Input() idColumnName: string;
  @Input() valueColumnName: string;
  @Input() cellDisplayType: string;
  @Input() isDateFilterShow: boolean;
  @Input() isServerSidePagination: boolean;
  @Input() rows: any[];
  columnfilter = DropdownModel[3]=[
    { id: "", name: "" },
    { id: "All", name: "All" },
    { id: "true", name: "Yes" },
    { id: "false", name: "No" }
  ];
  rollUpLevel = DropdownModel[3]=[
    { id: "", name: "" },
    { id: "fund", name: "Fund" },
    { id: "investment", name: "Investment" }
  ];
 
  isFilterPopUpOpen:boolean = false;
  existingRowsData: any;
  model : string;
  modelRollupLevel : string;
  inputValue: any;
  inputPopUpHeaderValue: any;
  filterList: Filter[]=[];
  transactionGridUniqueData: any;
  constructor(public _matDialog: MatDialog,
              public agGridService:AgGridService,
              public storageService:StorageService,
             // public sortService:SortService,
              public filterService:FilterService) {
   }
  ngOnInit(): void {
  //  this.setFilteredText(this.valueColumnName);
    // this.filterService.transactionGridUniqueData.subscribe(res=>{
    //   this.transactionGridUniqueData = res;
    // })
  }
  openFilterModelPopUp(idColumnName:string,valueColumnName:string){
    this.transactionGridUniqueData  = this.storageService.retrieve("filterPopupSource");
    var uniqueData: FilterModel[] = [];
    this.existingRowsData = this.storageService.retrieve(this.initialRows)
    if(this.existingRowsData == undefined){
      this.storageService.store(this.initialRows,this.rows);
    }
    
    this.existingRowsData = this.storageService.retrieve(this.initialRows)
    if(this.isServerSidePagination){

      switch(valueColumnName){
        case 'fundName':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.fundList;
          break;
        case 'investmentName':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.investmentList;
          break;
        case 'vehicleInvestorName':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.investorGroupList;
          break;
        case 'investorName':
          uniqueData = this.transactionGridUniqueData?.investorList;
          break;
        case 'positionName':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.positionList;
          break;
        case 'status':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.statusList;
          break;
        case 'tierName':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.transactionSetList;
          break;
        case 'calculationName':
          uniqueData = [];
          uniqueData = this.transactionGridUniqueData?.calculationNameList;
          break;
      }
    }
    else{
      uniqueData = [];
      for(let i = 0 ; i<this.existingRowsData.length;i++ ){
        if(this.existingRowsData[i][idColumnName]==null)
          this.existingRowsData[i][idColumnName] = ""
          if(this.existingRowsData[i][valueColumnName]==null)
          this.existingRowsData[i][valueColumnName] = ""
        let index = uniqueData.findIndex(x=>(x.name == this.existingRowsData[i][valueColumnName],x.id == this.existingRowsData[i][idColumnName]))
        if(index == -1){
          if(this.existingRowsData[i][idColumnName]==null)
          this.existingRowsData[i][idColumnName] = ""
          if(this.existingRowsData[i][valueColumnName]==null)
          this.existingRowsData[i][valueColumnName] = ""
          uniqueData.push({id:this.existingRowsData[i][idColumnName],name:this.existingRowsData[i][valueColumnName]});
        }
      }
    }
    let dialogRef = this._matDialog.open(ResetFilterCalcRuleComponent,{
      maxWidth: 450,
      minWidth: 450,
      data:{data:uniqueData,colName:this.columnName,valueColumnName:valueColumnName,filterStorageName:this.filterStorageName,isDateFilterShow:this.isDateFilterShow},
    });
    dialogRef.componentInstance.onFilter.subscribe(
      (response: any) => {
        
       this.updateFilter(valueColumnName,response);
       dialogRef.componentInstance.onFilterCompleted.emit(response);
      }
    );
  }
  performFilterColumnwise(valueColumnName,filterData,textInput)
  {    
    
    // for decimal conversion
    let previousFilterList = this.storageService.retrieve(this.filterStorageName);
    
    this.filterList = [];
    let index =  previousFilterList?.findIndex(a=>a.name == valueColumnName);
      if(previousFilterList!=undefined)
      {
          this.filterList = previousFilterList;
          
      }
      if(index != undefined && index != -1){
        this.filterList[index].type = this.cellDisplayType;
        this.filterList[index].textFilter = textInput;
        this.filterList[index].FilterData = filterData?.length == 0 ? undefined :  filterData;
      }
      else
      {
        if(filterData?.length == 0){
          filterData = undefined;
        }
        this.filterList.push({ type: this.cellDisplayType,  name:valueColumnName, textFilter:textInput , FilterData : filterData });
      }
   //   this.inputValue = textInput;
      this.storageService.store(this.filterStorageName,this.filterList);
      // this.existingRowsData = this.storageService.retrieve(this.initialRows);
      //this.existingRowsData = this.sortService.performSort(this.existingRowsData,this.gridName);
      if(this.isServerSidePagination){
        
        this.filterService.emitOnFilter.emit(this.filterStorageName)
      }else{
        this.agGridService.performFilter(this.params)
      }
  }
  emitInputValueForPopUP(value){
    this.inputPopUpHeaderValue = value;
    this.filterService.emitHeaderInputValue.emit(value);
  }
  updateInputFilter(valueColumnName,textInput){
    this.inputValue = textInput;

    if(this.gridName != StorageName.TRANSACTION_LEVEL_GRID  || (this.gridName == StorageName.TRANSACTION_LEVEL_GRID && ((valueColumnName!= "transactionDate") || ( valueColumnName=="transactionDate" && this.inputValue.length==10))))
      this.performFilterColumnwise( valueColumnName, undefined,textInput);
  }
  updateDropdownFilter(valueColumnName,dropdownInput){
    let filterText = this.model.toLowerCase();
    switch(this.model.toLowerCase())
    {
      case "yes":
        filterText= "true"
        break;
      case "no":
        filterText= "false"
        break;
      case "all":
        filterText= ""
        break;
      case "fund":
        filterText= "fund"
        break;
      case "investment":
        filterText= "investment"
        break;
      case "":
        filterText= ""
        break;
    }
    this.performFilterColumnwise( valueColumnName, undefined,filterText);
  }
  updateRollupLevelDropdown(valueColumnName,dropdownInput){
    this.performFilterColumnwise( valueColumnName, undefined,this.modelRollupLevel);
  }
 
  updateFilter(valueColumnName,filterData) {
    this.performFilterColumnwise( valueColumnName, filterData.tableFilterValues,filterData.textFilterValue);
  }
  onClearFilter(name: string)
  {
    this.performFilterColumnwise( name, undefined,undefined);
  }
  setFilteredText(colName)
  {
    if(this.isFilterApplied(colName)== true)
    {
    var data = this.storageService.retrieve(this.filterStorageName);
    let previousFilterList = data?.filter(col => col.name == colName);
    if(previousFilterList!=undefined)
    {
      this.inputValue = previousFilterList[0]?.textFilter;
      this.inputPopUpHeaderValue = "";
      this.model = previousFilterList[0]?.textFilter;
    }
  }
  else
  {
    this.inputValue ="";
    this.model ="";
  }
  }
    
  setDropdown(colName) {
    if(this.isFilterApplied(colName)== true)
    {
    var data = this.storageService.retrieve(this.filterStorageName);
    let previousFilterList = data?.filter(col => col.name == colName);
    if(previousFilterList!=undefined)
    {
      this.inputValue = previousFilterList[0]?.textFilter;
      this.inputPopUpHeaderValue = "";
      this.model = previousFilterList[0]?.textFilter;
    }
  }
  else
  {
    this.inputValue ="";
    this.model ="";
  }
  return this.model;
  }
  
  isFilterApplied(colName) {
   var isFilterAppliedToColumn: boolean = false;
   var data = this.storageService.retrieve(this.filterStorageName);
   if(data!= undefined)
   {
    let previousFilterList = data?.filter(col => col.name == colName);
   if(previousFilterList!=undefined)
   {
    
          if(previousFilterList[0]?.FilterData != undefined)
          if(previousFilterList[0]?.FilterData.length > 0)
            isFilterAppliedToColumn = true;
          if(previousFilterList[0]?.type == 'dateFilter' && (previousFilterList[0]?.textFilter != '' && previousFilterList[0]?.textFilter != undefined && previousFilterList[0]?.textFilter != null))
            isFilterAppliedToColumn = true;
          if(previousFilterList[0]?.type == 'textFilter' && (previousFilterList[0]?.textFilter != '' && previousFilterList[0]?.textFilter != undefined && previousFilterList[0]?.textFilter != null))
            isFilterAppliedToColumn = true;
          if(previousFilterList[0]?.type == 'textFilter' && (previousFilterList[0]?.textFilter != '' && previousFilterList[0]?.textFilter != undefined && previousFilterList[0]?.textFilter != null))
            isFilterAppliedToColumn = true;
    }
  }
    return isFilterAppliedToColumn;
  }
  
}
