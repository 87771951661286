import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ViewChildren,
  QueryList,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd, RouterEvent } from "@angular/router";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { filter, Subject, Subscription, takeUntil } from "rxjs";
import { AppSettings } from "../app.settings";
import { Settings } from "../app.settings.model";
import { GlobalService } from "../shared/services/global.service";
import { StorageService } from "../shared/services/storage.service";
import { Menu } from "../theme/components/menu/menu.model";
import { MenuService } from "../theme/components/menu/menu.service";
import { SidenavComponent } from "../theme/components/sidenav/sidenav.component";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [MenuService],
})
export class PagesComponent implements OnInit, OnDestroy {
  @ViewChild("sidenav") sidenav: any;
  @ViewChild("backToTop") backToTop: any;
  @ViewChild(SidenavComponent) childSidenav: SidenavComponent;
  @ViewChildren(PerfectScrollbarDirective)
  pss: QueryList<PerfectScrollbarDirective>;

  public settings: Settings;
  public menuOption: string;
  public menuTypeOption: string;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized

  public menus = ["vertical", "horizontal"];
  public menuTypes = ["default", "compact", "mini"];

  public destroy$: Subject<void> = new Subject<void>();
  private currentUrl: string = '';
  private clickedMenu: boolean = false;
  private menuData: Menu;

  private _unsubscribeOnDestroy$: Subject<void> = new Subject();
  isLoading: boolean = false;

  private _routereventpipeSubscription!: Subscription;
  private _routereventSubscription!: Subscription;
  private _isActivatedLoaderSubscription!: Subscription;

  constructor(
    public appSettings: AppSettings,
    @Inject(Router) public router: Router,
    public storageservice: StorageService,
    private globalService: GlobalService,
    private crf: ChangeDetectorRef
  ) {
    this.settings = this.appSettings.settings;
    this._routereventpipeSubscription = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe((ev) => {
      this.currentUrl = decodeURI(ev.url);
      if (this.clickedMenu) {
        this.clickedMenu = false;
        if (ev.url.toLowerCase().includes(this.menuData.routerLink.toLowerCase())) {
          this.onClickMenuItemfn(this.menuData);
        }
      }

      // Put settimeout because we need to call function to appear vertical menu after initialization of view
      setTimeout(() => {
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 300);
        if (this.currentUrl) {
          let menu = this.childSidenav.getMenuByRouteUrl(this.currentUrl);
          if (menu) {
            this.onClickMenuItemfn(menu);
          }
        }
        this._routereventSubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            if (!this.settings.sidenavIsPinned) {
              this.sidenav.close();
            }
            if (window.innerWidth <= 768) {
              this.sidenav.close();
            }
          }
        });
      }, 800);
    });
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.settings.menu = "vertical";
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.defaultMenu = this.settings.menu;

    this._isActivatedLoaderSubscription = this.globalService.isActivatedLoader$.pipe(takeUntil(this._unsubscribeOnDestroy$)).subscribe((active) => {
      this.isLoading = active;
      this.crf.detectChanges();
    });
  }

  onClickMenuItem(ev) {
    this.clickedMenu = true;
    this.menuData = ev;
  }

  onClickMenuItemfn(ev) {
    this.childSidenav?.openVerticalMenu(ev.id);
    this.settings.allowSidenav = ev.allowSideBar;
    this.settings.menu = ev.title;
    // if (!this.childSidenav?.openVerticalMenu(menuId)) {
    //   this.sidenav.close();
    //   this.toggleSideMenuIsClickable = false;
    // } else {
    //   this.sidenav.open();
    //   this.toggleSideMenuIsClickable = true;
    // }
  }


  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(["/"]);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    if (this.backToTop) {
      event.target.scrollTop > 300
        ? (this.backToTop.nativeElement.style.display = "flex")
        : (this.backToTop.nativeElement.style.display = "none");
    }
    if (this.settings.menu == "horizontal") {
      if (this.settings.fixedHeader) {
        var currentScrollTop =
          event.target.scrollTop > 56 ? event.target.scrollTop : 0;
        if (currentScrollTop > this.lastScrollTop) {
          document.querySelector("#horizontal-menu").classList.add("sticky");
          event.target.classList.add("horizontal-menu-hidden");
        } else {
          document.querySelector("#horizontal-menu").classList.remove("sticky");
          event.target.classList.remove("horizontal-menu-hidden");
        }
        this.lastScrollTop = currentScrollTop;
      } else {
        if (event.target.scrollTop > 56) {
          document.querySelector("#horizontal-menu").classList.add("sticky");
          event.target.classList.add("horizontal-menu-hidden");
        } else {
          document.querySelector("#horizontal-menu").classList.remove("sticky");
          event.target.classList.remove("horizontal-menu-hidden");
        }
      }
    }
  }

  public scrollToTop() {
    this.pss.forEach((ps) => {
      if (
        ps.elementRef.nativeElement.id == "main" ||
        ps.elementRef.nativeElement.id == "main-content"
      ) {
        ps.scrollToTop(0, 250);
      }
    });
  }

  @HostListener("window:resize")
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = "vertical";
    } else {
      this.defaultMenu == "horizontal"
        ? (this.settings.menu = "horizontal")
        : (this.settings.menu = "vertical");
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this._routereventpipeSubscription) {
      this._routereventpipeSubscription.unsubscribe();
    }

    if (this._routereventSubscription) {
      this._routereventSubscription.unsubscribe();
    }

    if (this._isActivatedLoaderSubscription) {
      this._isActivatedLoaderSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }
}
