import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridService } from '../ag-grid/ag-grid.service';
export interface MyParams {
  // label?: string;
  eventOccurs?: any;
}
@Component({
  selector: 'app-run',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit,ICellRendererAngularComp {
  params;
  constructor(public agGridService: AgGridService) { }
  agInit(params: ICellRendererParams & MyParams): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }
  ngOnInit(): void {
 
  }
  onRun(){
    this.params.eventOccurs.emit({ eventName: 'run', payload: this.params });
  }
  onRresetTransfer(){
    this.params.eventOccurs.emit({ eventName: 'reset-transfer', payload: this.params });
  }
}
