import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
@Component({
  selector: 'app-lock-unlock-records',
  templateUrl: './lock-unlock-records.component.html',
  styleUrls: ['./lock-unlock-records.component.scss']
})
export class LockUnlockRecordsComponent implements OnInit {
  lockFromDate: Date;
  @Output() lockProcess = new EventEmitter<any>();
  constructor(private notificationService: NotificationService) { }
  ngOnInit(): void {
    // MatFormFieldModule
  }
  islocked: boolean = true;
  lockRecords(value) {
    this.islocked = value;
  }
  
  onProcess() {
    // console.log({
    //   isLocked: this.islocked, lockedFrom: this.lockFromDate
    // })
    if (!this.lockFromDate) {
      this.notificationService.warningpopup("Please enter locked from date to procced!")
      return false;
    }
    this.lockProcess.emit({
      isLocked: this.islocked, lockedFrom: this.lockFromDate
    })
  }
}
