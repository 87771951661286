import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DropdownModel } from "src/app/shared/model/common.model";
import { NotificationService } from "src/app/shared/services/notification.service";
import { ScenarioMode } from "../calculation.model";
import { CalculationService } from "../calculation.service";

@Component({
  selector: "app-scenario-mode-modal",
  templateUrl: "./scenario-mode-modal.component.html",
  styleUrls: ["./scenario-mode-modal.component.scss"],
})
export class ScenarioModeModalComponent implements OnInit {
  tierId:number;
  percent:any;
  realdata:any;
  tierName:string;
  isUpdate = false;
  arrayData = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toasterService:ToastrService,
    public calculationService: CalculationService,
    public notificationService: NotificationService
  ) {}

  tierDropdown:DropdownModel[]
  scenarioData = new ScenarioMode();
  ngOnInit(): void {
    console.log("the data is: ", this.data);
    this.tierDropdown = this.data.tierDropdown;
    this.getScenario()
  }
  getScenario(){
    this.calculationService.GetScenarioMode().subscribe(result =>{
      console.log('the result is: ', result);
      this.arrayData = result.response
    })
  }
  onOk() {}

  onAdd() {
    for(let i = 0; i < this.tierDropdown.length; i++){
      if(this.tierDropdown[i]?.id == this.tierId?.toString()){
        this.tierName = this.tierDropdown[i].name
      }
    }
    
    if(!this.tierName) { 
      this.notificationService.errorpopup('Please select Tier');
      return;
    }
    if(!this.realdata) { 
      this.notificationService.errorpopup('Please enter Percent');
      return;
    }
    if (this.isUpdate) {
      this.isUpdate = false;
      this.scenarioData.tierName = this.tierName;
      this.scenarioData.percentage = parseFloat(this.realdata);
      this.scenarioData.tierId = this.tierId;
      this.calculationService.AddScenarioMode(this.scenarioData).subscribe(result =>{
        if(result.isSuccess){
          this.toasterService.success("Recorde Updated Successfully.","", {
            timeOut: 1000
          });
          // this.notificationService.sucesspopup("Recorde Updated Successfully.")
          this.arrayData = result.response
        }else{
          this.notificationService.errorpopup('An Error Occurred')
        }
      })
      this.onClear()
     console.log('the update is: :', this.isUpdate);
     this.scenarioData = new  ScenarioMode();
    } else {
      this.scenarioData.tierId=this.tierId;
      this.scenarioData.percentage = parseFloat(this.realdata);
      this.scenarioData.tierName = this.tierName;
      this.calculationService.AddScenarioMode(this.scenarioData).subscribe(event=>{
        if(event.isSuccess){
          this.toasterService.success("Recorde Updated Successfully.","", {
            timeOut: 1000
          });
          // this.notificationService.sucesspopup("Recorde Updated Successfully.")
        }else{
          this.notificationService.errorpopup('An Error Occurred')
        }
        this.arrayData = event.response;
        this.onClear()
      })

    }
  }

  loadUpdate(item) {
    this.isUpdate = true;
    this.scenarioData.id = item.id
    this.tierId = item.tierId.toString();
    //this.percent = item.percentage;
   // this.percent =  parseFloat(item.percentage).toFixed(2) + '%';
    this.percent =item.percentage;
  }
  onDelete(id) {
    let result = this.notificationService.deletepopup();
    result.subscribe(res =>{
      console.log('the result is: ', res);
     if(res.isConfirmed){
      this.calculationService.DeleteScenarioMode(id).subscribe(event => {
        if(event.isSuccess){
          this.arrayData = event.response
        }
      })
     }
    })

    
  }

  Onchange(event: any) {
    this.realdata=parseFloat(this.percent).toFixed(4);
    this.percent = parseFloat(this.percent).toFixed(4) + "%";
  }
  onkeypress(event:any)
  {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onClear() {
    this.tierId = null;
    this.percent =null;
    this.realdata=null;
  }
}
