export class DropdownModel {
   name: string;
   id: string;
   color? : string= "";
   hoverColor?: string= "";
}
 
export class FundList {
   id: number;
   fundName: string;
}

export class AllInvestorVehicle {
   vehicleInvestorName: string;
   id: number;
 }

export class PositionList {
   id: number;
   positionName: string;
   positionGUID: string;
}

export class InvestorList {
   investorName: string;
   id: number;
}

export class InvestmentList {
   investmentName: string;
   investmentGUID: number;
   id: number;
}

export class TierListModel {
   id: number;
   tierName: string;
}

export class CalcRuleList {
   id: number;
   calcRuleName: string;
}