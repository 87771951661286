import { Component, HostListener, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { Observable, Subscription } from 'rxjs';
import { sort } from 'src/app/shared/component/sort/sort.model';
import { ComponentCanDeactivate } from 'src/app/shared/guard/direty-check.guard';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  AgColDefsI,
  AgGridI,
} from "src/app/shared/component/ag-grid/ag-grid.interface";
import { GetTotalRecordByID, udf, udfDataModel, udfPostModel } from '../user-defined-fields.model';
import { StorageName } from 'src/app/shared/common/enum';
import { UserDefinedFieldsService } from '../user-defined-fields.service';
import { StorageService } from "src/app/shared/services/storage.service";
import { GlobalService } from "src/app/shared/services/global.service";
import { SortService } from "src/app/shared/component/sort/sort.service";
import { IconsService } from "src/app/shared/component/actions/icons.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { ExportService } from "src/app/shared/services/export.service";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { FilterService } from "src/app/shared/component/filter/filter.service";
import { PaginationModelCommon } from 'src/app/shared/model/pagination.model';
import { ToastrService } from "ngx-toastr";
import { TierTransactionService } from 'src/app/pages/transactions/tier-transactions/tier-transactions.service';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';

@Component({
  selector: 'app-user-defined-fields',
  templateUrl: './user-defined-fields.component.html',
  styleUrls: ['./user-defined-fields.component.scss']
})
export class UserDefinedFieldsComponent extends CommonService implements OnInit, ComponentCanDeactivate, OnDestroy {

  UDFRows: udfDataModel[] = [];
  restedUDFRows = [];

  // list decleartion
  collapse: boolean = true;
  mydata: Select2Data;
  overlay = true;
  ishidden: boolean = true;
  tabID: number;

  //UserFilterList                : GetUserFilterList[];
  filteredUserOptions: Select2Data;
  TransactionFilterCriteria = [];
  flexibleHeight: string;
  // list decleartion

  agUserName = [];
  agUserId = [];

  agUserRoleName = [];
  agUserRoleId = [];
  //grid

  oldudfPostModel = new udfPostModel();
  udfPostModel = new udfPostModel();
  udfPaginationModel = new PaginationModelCommon();

  // user = new user();
  postudf = new udf();
  udfId: number;
  value2: number;
  value2updated: boolean = false;
  isAddRowVisible: boolean = false;
  isSaveBtnClicked: boolean = false;
  canModify: boolean = false;

  // UserN: GetUserFilterList[];
  // UName: string[];

  udfGridApi: any;

  colDefsUDFs: AgColDefsI[] = [
    // {
    //   field: "id",
    //   width: 40,
    //   pinned: "left",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   lockPosition: true,
    // },
    {
      field: "",
      width: 40,
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      lockPosition: true,
      sortable: true
    },
    {
      displayName: "Actions",
      field: "actions",
      width: 100,
      minWidth: 30,
      pinned: "left",
      cellType: "icons",
      editable: false,
      sortable: false,
      lockPinned: true,
      lockPosition: true,
    },
    {
      displayName: "North Star Field",
      field: "nStarUDFName",
      width: 150,
      minWidth: 30,
      cellClass: "leftAlignment",
      floatingFilter: true,

      //isRequired: true,
      //floatingFilter: true,
      filterValueColumnName: "nStarUDFName",
      cellDisplayFormat: "textFilter",
      editable: false,
      lockPinned: true,
      sortable: true
    },
    {
      displayName: "Caption",
      field: "udfFieldName",
      width: 180,
      minWidth: 30,
      floatingFilter: true,
      // cellType: "dropdown",
      // cellRenderer: "dropdownRenderer",
      cellClass: "leftAlignment",
      // cellEditor: {
      //   cellEditorData: this.agvehicalData,
      //   cellEditorId: this.agvehicalId,
      // },
      //isRequired: true,
      //isfilter: true,
      filterValueColumnName: "udfFieldName",
      cellDisplayFormat: "textFilter",
      editable: true,
      lockPinned: true,
    },
    {
      field: "active",
      displayName: "Active",
      width: 130,
      minWidth: 110,
      cellType: "checkbox",
      cellRenderer: "checkboxRenderer",
      cellStyle: "text-align-center",
      filterValueColumnName: "active",
      cellDisplayFormat: "textFilter",

      floatingDropDown: true,
      lockPinned: true,
    }
  ];

  agGridDataModelUDF: AgGridI = {
    colDefs: this.colDefsUDFs,
    rowSelection: "multiple",
    //formName: "User",
    gridName: StorageName.GRID_ADMINISTRATION_UDFS_UDF,
    storageName: StorageName.UDFS_UDFCOLUMNCHANGED,
    paginationStore: StorageName.UDFS_UDF_PAGINATION,
    initialRows: StorageName.ADMINISTRATION_UDFS_UDF_INITIALROWS,
    filterStorageName: StorageName.FILTER_ADMINISTRATION_UDFS_UDF_SELECTED,
    tabIndex: 0,
  };

  sortList: sort[] = [];
  private _sortListSubscription!: Subscription;
  private _entireDataSubscription!: Subscription;
  private _getExtraColumnSubscription!: Subscription;
  private _onSaveBeforeCloseSubscription!: Subscription;

  constructor(
    public udfService: UserDefinedFieldsService,
    public StorageService: StorageService,
    private globalService: GlobalService,
    public agGridService: AgGridService,
    public sortService: SortService,
    public iconsService: IconsService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public renderer: Renderer2,
    public filterService: FilterService,
    public ExportService: ExportService,
    public toasterService: ToastrService,
    private tierTransactionsService: TierTransactionService,
    public commonService: CommonTabService
  ) {
    super(renderer);
    this.agGridService.clearRows();
    // for sorting
    this._sortListSubscription = this.sortService.sortList$.subscribe(
      (data) => {
        this.sortList = data;
        // this.StorageService.store("sortStoredList",this.sortList);
      }
    );
  }

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
   var UDF =  this.StorageService.retrieve(this.agGridDataModelUDF.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    return (UDF?.length == 0);
  }

  ngOnInit(): void {
    //set from permission
    var formPermission = this.StorageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    if (formPermission) {
      this.canModify = formPermission.modifyPermission;
    }
    this._onSaveBeforeCloseSubscription = this.commonService.onSaveBeforeClose.subscribe((data) => this.onSave());
    this.ishidden = true;
    this.tabID = 0;
    this.flexibleHeight = "min-height: calc(94vh - 114px) !important;max-height: calc(94vh - 114px) !important;";
    this.isAddRowVisible = true;
    this.allGetApis();
    this.onTabDataLoad(0);
  }

  allGetApis() {
    this.getAllRecordByID();
  }

  getAllRecordByID() {
    // if (this.userId == undefined || this.userId == null) return;
    // this.userPaginationModel.userId = this.userId;
    if (this.udfPostModel.isNew) {
      this.udfPaginationModel.getAll = false;
    } else {
      this.udfPaginationModel.getAll = true;
    }
    //  this.userPaginationModel.getAll = this.fundId == 0;
    this._entireDataSubscription = this.udfService
      .postUDFRecordByID(this.udfPaginationModel)
      .subscribe((res) => {

        if (res.response) {
          this.globalService.deactivateLoader();
        }
        if (res.isSuccess) {
          this.getUDFRecord(res.response);
          //this.totalRecords = res.response?.fundInvestorTotalRecords;
          this.oldudfPostModel = res.response;
          //this.passResetGird = res.response;
          this.isSaveBtnClicked = false;
          //this.fundData = new fundDataModel();
        }
      });
  }

  getUDFRecord(getTotalRecordByID: GetTotalRecordByID) {

    this.UDFRows = [];
    this.UDFRows = getTotalRecordByID?.udfDataModel;
    this.restedUDFRows = getTotalRecordByID?.udfDataModel;
    this.StorageService.store(StorageName.ADMINISTRATION_UDFS_UDF_INITIALROWS, this.restedUDFRows)

    this.onTabDataLoad(this.tabID);
  }

  onTabDataLoad(index) {
    this.tabID = index;
    switch (this.tabID) {
      case 0: {
        this.agGridDataModelUDF.masterIdStorageName = "",
          this.agGridDataModelUDF.gridApi = this.udfGridApi;
        this.UDFRows = this.agGridService.performFilter(this.agGridDataModelUDF);
        this.isAddRowVisible = true;
        this.sortService.gridName.next({ gridName: StorageName.GRID_ADMINISTRATION_UDFS_UDF, rows: this.UDFRows });
        this.iconsService.tabName.next("udf_udf");
        break;
      }
    }
  }

  getChangedDataUDF(event) {
    console.log('the data is changed...', event);

    if (this.tabID == 0) {
      this.UDFRows = event;
      this.restedUDFRows = event;
    }
  }

  addNewRow(event) {
    console.log("adding new row...: ", event);
    if (event.data?.isAdded) {
      event.data.isEdited = false;
      event.data.isDeleted = false;
      event.data.isAdded = true;
    }
    if (event?.data.isEdited) {
      console.log("on edited: ", event);
    }
  }

  ongridReady(item, event) {
    console.log(item.label);
    if (item == "UDF") {
      this.udfGridApi = event;
    }
  }

  exportAllItems(tabID) {
    tabID = this.tabID;
    var filename = "";
    var gridapi: any;
    switch (tabID) {
      case 0: {
        var fileName = "UDF Export";
        gridapi = this.udfGridApi;
        break;
      }
      // case 1: {
      //   var fileName = "UserRole Export";
      //   gridapi = this.userRoleGridApi;
      //   break;
      // }
    }
    this.agGridService.exportAll(gridapi, fileName);
  }

  resetTableData() {
    this.globalService.activateLoader();
    //if (!this.udfPostModel.isNew) {
    this.getAllRecordByID();
    //}
  }

  onResetFilter(tabID) {

    switch (tabID) {
      case 0: {
        this.UDFRows = this.agGridService.
          resetAllFilters(StorageName.ADMINISTRATION_UDFS_UDF_INITIALROWS,
            StorageName.FILTER_ADMINISTRATION_UDFS_UDF_SELECTED,
            StorageName.GRID_ADMINISTRATION_UDFS_UDF, this.udfGridApi)
        break;
      }
    }
  }

  onSelectAll() {
    if (this.UDFRows != undefined && this.UDFRows?.length > 0) {
      var val = !this.UDFRows[0].active;
      this.UDFRows = this.UDFRows.map(obj => ({ ...obj, isEdited: true, active: val }));
      this.restedUDFRows = this.UDFRows;
      this.udfGridApi.api.setRowData(this.UDFRows);

    }
  }

  onSave() {
    switch (this.tabID) {
      case 0: {
        var postUDFRows =  this.StorageService.retrieve(this.agGridDataModelUDF.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
        // var postUDFRows =  this.UDFRows?.filter(
        //   (row) => row.isEdited == true || row.isAdded == true
        // );
        // console.log('the udfs: ', postUDFRows);

        break;
      }

    }

    this.udfPostModel = new udfPostModel();

    if (postUDFRows?.length != 0) {
      if (postUDFRows == undefined) {
        postUDFRows = [];
      }

      postUDFRows.forEach((data) => {
        this.postudf = new udf();
        this.postudf.nStarTable = data.nStarTable;
        this.postudf.nStarUDFName = data.nStarUDFName;
        this.postudf.udfFieldName = data.udfFieldName;
        this.postudf.active = data.active;
        this.postudf.id = data.id;
        this.postudf.isAdded = data.isAdded;
        this.postudf.isEdited = data.isEdited;
        this.postudf.isDeleted = data.isDeleted;

        this.udfPostModel.uDFs.push(this.postudf);
      });
    }

    var checkRequiredDataForUDF =
      this.udfPostModel.uDFs.find(
        (a) =>
          (a.nStarTable == undefined || a.nStarUDFName == undefined || a.udfFieldName == undefined) &&
          a.isDeleted == false
      );

    if (
      this.udfPostModel.uDFs.map((a) => a.isAdded == true) &&
      checkRequiredDataForUDF != undefined
    ) {
      //this.notificationService.warningpopup("Error");
      return false;
    }


    {
      console.log('the post data.... ', this.udfPostModel);

      this.globalService.activateLoader();
      this._entireDataSubscription = this.udfService
        .postUDFData(this.udfPostModel)
        .subscribe((res) => {
          this.globalService.deactivateLoader();
          if (res.isSuccess) {
            this.commonService.checkRedirectUrlAfterSave();
            this.isSaveBtnClicked = true;
            if (res.message == "Ptr Data is not Saved.") {
              this.notificationService.warningpopup(res.message);
            } else {
              this.toasterService.success(res.message, "", {
                timeOut: 1000
              });
            }
            this.udfPostModel.isNew = false;
            this.udfId = res.response == null ? 0 : res.response.id;
            // this.value2 = this.fundId;
            //this.getFilteredOptions();
            this.getAllRecordByID();
            this.getUDFLIST();
          } else {
            this.commonService.setredirectUrlAfterSave(null);
            this.notificationService.warningpopup(res.message);
          }
        });
    }

  }

  getUDFLIST() {
    this._getExtraColumnSubscription = this.tierTransactionsService.getExtraColumn().subscribe(data => {
      this.StorageService.store("UDFLIST", data.response);
    })
  }

  ngOnDestroy(): void {
    if (this._entireDataSubscription) {
      this._entireDataSubscription.unsubscribe();
    }

    if (this._sortListSubscription) {
      this._sortListSubscription.unsubscribe();
    }

    if (this._getExtraColumnSubscription) {
      this._getExtraColumnSubscription.unsubscribe();
    }

    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }
}