import { StorageName } from "src/app/shared/common/enum";
import { AgGridI, colDefsRole } from "src/app/shared/component/ag-grid/ag-grid.interface";
import { sort } from "src/app/shared/component/sort/sort.model";
import { DropdownModel } from "src/app/shared/model/common.model";
import { PaginationModelCommon } from "src/app/shared/model/pagination.model";

export class RolesModel
{
    roles                       : Roles[]=[];
}

export class Roles
{
    id                  : number;
    name                : string;
    description         : string;
    createdAt           : string;
    updatedAt           : string;
    isDeleted           : boolean = false;
    deletedAt           : string;
    isEdited            : boolean = false;
    isAdded             : boolean = false;
}

export const agGridDataModelRoles: AgGridI = {
    colDefs: colDefsRole,
    rowSelection: "multiple",
    gridName: StorageName. ROLES_GRID,
    storageName: StorageName.ROLES_COLUMNCHANGED,
    paginationStore: StorageName.FROLES_PAGINATION,
    initialRows: StorageName.ROLES_INITIALROWS,
    filterStorageName: StorageName.ROLES_FILTER,
    controllerName : StorageName. ROLES_CONTROLLER,
    showDeleteStatusStorageName : StorageName.ROLES_SHOW_DELETED,
    tabIndex: 1,
  };


