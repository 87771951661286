import { DropdownModel } from "src/app/shared/model/common.model";
import { PermissionsModel } from "../Administration/permissions/permissions.model";
export class LoginRequestModel 
{
    userName            : string;
    password            : string;
}

export class LoginUserDataModel 
{
    id                  : number;
    userName            : string;
    email               : string;
    isQueSet            : boolean;
    roleId              : number;
    permissions         : PermissionsModel;
}

export class QueAnsRequestModel 
{
    id                  : number;
    question1Id         : number;
    answer1             : string;
    question2Id         : number;
    answer2             : string;
    question3Id         : number;
    answer3             : string;
}

export class ResetPwdRequestModel 
{
    resetlink           : string;
    newpassword         : string;
}