import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { ComponentCanDeactivate } from "src/app/shared/guard/direty-check.guard";
import { NotificationService } from "src/app/shared/services/notification.service";
import { CommonService } from "src/app/shared/services/common.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/common/enum";
import { AgGridService } from "src/app/shared/component/ag-grid/ag-grid.service";
import { FilterService } from "src/app/shared/component/filter/filter.service";
import { ToastrService } from "ngx-toastr";
import { DropdownListModel } from "../../fund-information/fund-information.model";
import { PageHeaderModel } from "src/app/shared/component/page-header/page-header.model";
import { FundInformationService } from "../../fund-information/fund-information.service";
import { agGridDataModelPermissionColumn, agGridDataModelPermissionForm, agGridDataModelPermissionFundGroup, FundGroupPermission, PermissionsModel } from './permissions.model';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { TableHeaderModel } from 'src/app/shared/component/table-header-buttons/table-header-buttons.model';
import { PageHeaderComponent } from 'src/app/shared/component/page-header/page-header.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  providers: [AppComponent],
})
export class PermissionsComponent extends CommonService implements OnInit, OnDestroy, ComponentCanDeactivate {
  //#region variable declarations
  @ViewChild(PageHeaderComponent) pageHeaderComponent: PageHeaderComponent;
  // top model which contains filter, save, undo, delete and filter name
  pageHeaderModel: PageHeaderModel;

  id: number;
  //fundgrouprows: agGridDataModelPermissionFundGroup[] = [];
  grouprows = new PermissionsModel();
  restedfundGroupRows = [];

  //current/selected tab
  tabID: number;

  // grid apis which sets on grid ready event from ag-grid
  fundGroupPermissionGridApi: any;
  formPermissionGridApi: any;
  columnPermissionGridApi: any;

  //for changed form data
  formModel = new PermissionsModel();

  //for original form data - will be used to reset/clear the form
  loadedFormModel = new PermissionsModel();

  // for loading all the dropdowns - form and grid every dropdown
  dropdownListModel = new DropdownListModel();

  //for storing original row data
  restedInvestorRows = [];

  overlay = true;
  // tab group list
  tabGroupList: any[] = [
    { dataModel: agGridDataModelPermissionForm, label: 'Form Level', tabIndex: 0 },
    { dataModel: agGridDataModelPermissionFundGroup, label: 'Domain Level', tabIndex: 1 },
    { dataModel: agGridDataModelPermissionColumn, label: 'Column Level', tabIndex: 2 },
  ];

  private _onSaveBeforeCloseSubscription!: Subscription;
  //#endregion variable declarations

  //#region ctor
  constructor(
    public renderer: Renderer2,
    public notificationService: NotificationService,
    public storageService: StorageService,
    public agGridService: AgGridService,
    public filterService: FilterService,
    public fundInformationService: FundInformationService,
    public toasterService: ToastrService,
    public commonService: CommonTabService,
  ) { super(renderer); }


  //#endregion

  //#region Save Confirmation
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
   var fundGroup =  this.storageService.retrieve(agGridDataModelPermissionFundGroup.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
   var form =  this.storageService.retrieve(agGridDataModelPermissionForm.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
   var column =  this.storageService.retrieve(agGridDataModelPermissionColumn.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    return (
      JSON.stringify(this.formModel.role) === JSON.stringify(this.loadedFormModel.role) &&
      fundGroup?.length == 0 &&
      form?.length == 0 &&
      column?.length == 0
    );
  }
  //#endregion

  //#region Init
  ngOnInit(): void {
    this.fillPageHeader();
    this._onSaveBeforeCloseSubscription = this.commonService.onSaveBeforeClose.subscribe((data) => this.onSave());
  }

  fillPageHeader() {
    this.pageHeaderModel = new PageHeaderModel();
    this.pageHeaderModel.controllerName = StorageName.PERMISSION_CONTROLLER;
    this.pageHeaderModel.IdStorageName = StorageName.PERMISSION_ROLEID;
    this.pageHeaderModel.filterOptionStorageName = StorageName.PERMISSION_DROPDOWN;
    this.pageHeaderModel.setVisibilityForForm("PermissionRole");
  }

  fillTopDropdown() {
    this.dropdownListModel.permissionRoleDropdown = this.storageService.retrieve(StorageName.PERMISSION_DROPDOWN);
  }

  //#endregion

  //#region Clear Complete Form
  clearFields(): void {
    this.formModel = new PermissionsModel();

    //fill the form with blank records
    this.fillForm(this.formModel);
  }
  //#endregion Clear Complete Form

  //#region page header events
  //calling post api to save/update records
  onSave() {
    // get filter dropdown value from child component and assign
    this.formModel.role.name = this.pageHeaderModel.name;
    this.formModel.role.id = this.pageHeaderModel.id.toString();

    //create new mode for form to use for posting updated entries
    var postData = new PermissionsModel();
    postData.role = this.formModel.role;

    var fundGroup =  this.storageService.retrieve(agGridDataModelPermissionFundGroup.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    var form =  this.storageService.retrieve(agGridDataModelPermissionForm.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);
    var column =  this.storageService.retrieve(agGridDataModelPermissionColumn.initialRows).filter(a => a.isEdited == true  || a.isAdded == true);

    postData.fundGroupPermissions = fundGroup;// this.fundGroupPermissionGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.formPermissions =form;// this.formPermissionGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);
    postData.columnPermissions = column;// this.columnPermissionGridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true || row.isAdded == true);

    var deletedRecordsfundGroupPermissions = postData.fundGroupPermissions?.filter((row) => row.isEdited == true && row.isDeleted == true);
    var deletedRecordsformPermissions = postData.formPermissions?.filter((row) => row.isEdited == true && row.isDeleted == true);
    var deletedRecordscolumnPermissions = postData.columnPermissions?.filter((row) => row.isEdited == true && row.isDeleted == true);

    this.pageHeaderComponent.post(postData, deletedRecordsfundGroupPermissions.length != 0 || deletedRecordsformPermissions.length != 0 || deletedRecordscolumnPermissions.length != 0);
  }
  //get the records by id again to reset/undo the changed records
  onUndo() {
    this.getById(this.formModel.role.id);
  }
  //#endregion

  //#region Tab Events

  onChangeTab(event) {
    this.tabID = event.index;
    this.loadTabData(this.tabID);
  }
  //load the tab records on click of tab
  loadTabData(index = 0) {
    this.tabID = index;
    switch (this.tabID) {
      case 0:
        agGridDataModelPermissionForm.masterIdStorageName = this.pageHeaderModel.IdStorageName,
          agGridDataModelPermissionForm.gridApi = this.formPermissionGridApi;
        this.pageHeaderModel.agGridI = agGridDataModelPermissionForm;
        this.pageHeaderModel.gridApi = this.formPermissionGridApi;
        this.formModel.formPermissions = this.agGridService.performFilter(agGridDataModelPermissionForm);
        break;
      case 1:
        agGridDataModelPermissionFundGroup.masterIdStorageName = this.pageHeaderModel.IdStorageName,
          agGridDataModelPermissionFundGroup.gridApi = this.fundGroupPermissionGridApi;
        this.pageHeaderModel.agGridI = agGridDataModelPermissionFundGroup;
        this.pageHeaderModel.gridApi = this.fundGroupPermissionGridApi;
        this.formModel.fundGroupPermissions = this.agGridService.performFilter(agGridDataModelPermissionFundGroup);
        break;
      case 2:
        agGridDataModelPermissionColumn.masterIdStorageName = this.pageHeaderModel.IdStorageName,
          agGridDataModelPermissionColumn.gridApi = this.columnPermissionGridApi;
        this.pageHeaderModel.agGridI = agGridDataModelPermissionColumn;
        this.pageHeaderModel.gridApi = this.columnPermissionGridApi;
        this.formModel.columnPermissions = this.agGridService.performFilter(agGridDataModelPermissionColumn);
        break;
    }
    // this.tableHeaderComponent.getTabData();
  }

  onCellChange(event) {
    switch (this.tabID) {
      case 0:
        this.formPermissionGridApi.api.setRowData(this.formModel.formPermissions);
        break;
      case 1:
        this.fundGroupPermissionGridApi.api.setRowData(this.formModel.fundGroupPermissions);
        break;
      case 2:
        this.columnPermissionGridApi.api.setRowData(this.formModel.columnPermissions);
        break;
    }
  }

  ongridReady(item, gridApi) {
    switch (item.tabIndex) {
      case 0: this.formPermissionGridApi      = gridApi; break;
      case 1: this.fundGroupPermissionGridApi = gridApi; break;
      case 2: this.columnPermissionGridApi    = gridApi; break;
    }
  }
  onPageSizeUpdated(pageSize){
    switch (this.tabID) {
      case 0: this.storageService.store(agGridDataModelPermissionForm.paginationStore,pageSize);      break;
      case 1: this.storageService.store(agGridDataModelPermissionFundGroup.paginationStore,pageSize); break;
      case 2: this.storageService.store(agGridDataModelPermissionColumn.paginationStore,pageSize);    break;
    }
    this.loadTabData(this.tabID);
  }
  //#endregion tab events

  getById(res: any) {
    this.storageService.store(StorageName.PERMISSION_ROLEID, parseInt(res.response.role.id));
    this.setForm(res.response);
  }

  setForm(formModel: PermissionsModel) {
    this.fillForm(formModel);
    this.loadTabData(this.tabID);
  }

  fillForm(formModel: PermissionsModel) {
    //#region Top Data
    this.formModel.role = formModel.role;
    this.loadedFormModel.role = { ...this.formModel.role };
    this.pageHeaderModel.id = parseInt(formModel.role.id);
    this.pageHeaderModel.name = formModel.role.name;
    //#region Set Grid Storage
    this.storageService.store(StorageName.PERMISSION_FUNDGROUP_INITIALROWS, formModel.fundGroupPermissions);
    this.storageService.store(StorageName.PERMISSION_FORM_INITIALROWS, formModel.formPermissions);
    this.storageService.store(StorageName.PERMISSION_COLUMN_INITIALROWS, formModel.columnPermissions);
    //#endregion
  }
  //#endregion get and fill form records

  ngOnDestroy(): void { 
    if (this._onSaveBeforeCloseSubscription) {
      this._onSaveBeforeCloseSubscription.unsubscribe();
    }
  }
}
  //#endregion Component End