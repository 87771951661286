//import { PaginationModelCommon } from "src/app/shared/model/pagination.model";

export class UserDefinedFields {
}

// export class userPaginationModel extends PaginationModelCommon{
//     userId      : number;
// }

export class udfDataModel 
{
    id                      : number;
    nStarTable              : string;
    nStarUDFName            : string;
    udfFieldName            : string;
    active                  : boolean;
    isDeleted               : boolean = false;
    totalRecords            : number;
    isEdited                : boolean = false;
    isRowChecked            : boolean = false;
    isPasteRow              : boolean = false;
    isAdded                 : boolean = false;
}

export class udfPostModel
{
    isNew                       : boolean = true;
    uDFs                        : udf[]=[];
}

export class udf 
{
    nStarTable          : string;
    nStarUDFName        : string;
    udfFieldName        : string;
    active              : boolean;
    id                  : number;
    createdAt           : string;
    updatedAt           : string;
    isDeleted           : boolean = false;
    deletedAt           : string;
    isEdited            : boolean = false;
    isAdded             : boolean = false;
}

export class GetTotalRecordByID
{
    // userId: number;
    udfDataModel                       : udfDataModel[];
}
