import { BrowserModule } from "@angular/platform-browser";
import { NgModule,Injector } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { AgmCoreModule } from "@agm/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
};
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { SharedModule } from "./shared/shared.module";
import { PipesModule } from "./theme/pipes/pipes.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { AppSettings } from "./app.settings";
import { SidenavComponent } from "./theme/components/sidenav/sidenav.component";
import { VerticalMenuComponent } from "./theme/components/menu/vertical-menu/vertical-menu.component";
import { HorizontalMenuComponent } from "./theme/components/menu/horizontal-menu/horizontal-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";
import { FlagsMenuComponent } from "./theme/components/flags-menu/flags-menu.component";
import { FullScreenComponent } from "./theme/components/fullscreen/fullscreen.component";
import { ApplicationsComponent } from "./theme/components/applications/applications.component";
import { MessagesComponent } from "./theme/components/messages/messages.component";
import { UserMenuComponent } from "./theme/components/user-menu/user-menu.component";
import { CalculationComponent } from "./pages/calculation/calculation.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AdministrationComponent } from "./pages/Administration/administration.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ResetFilterCalcRuleComponent } from "./pages/reset-filter-calc-rule/reset-filter-calc-rule/reset-filter-calc-rule.component";
import { ToastrModule } from "ngx-toastr";
import { Select2Module } from "ng-select2-component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ErrorHandlerInterceptor } from "./shared/interceptors/error-handler.interceptor";
import { PermissionsModule } from "./pages/Administration/permissions/permissions.module";
import { RolesModule } from "./pages/Administration/roles/roles/roles.module";
import { UsersModule } from "./pages/Administration/users/users.module";
import { UserDefinedFieldsModule } from "./pages/Administration/user-defined-fields/user-defined-fields/user-defined-fields.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ResetFilterCalcRuleRoutingModule } from "./pages/reset-filter-calc-rule/reset-filter-calc-rule/reset-filter-calc-rule-routing.module";
import { LockUnlockRecordsModule } from "./pages/lock-unlock-records/lock-unlock-records/lock-unlock-records.module";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SearchFilterModule } from './pages/search-filter/search-filter/search-filter.module';
import { MatInputModule } from "@angular/material/input";
import { SpinnerModule } from "./shared/component/spinner/spinner.module";
import { AgGridModule } from "ag-grid-angular";
import { LoginComponent } from "./pages/login/login.component";
import { ImportPopupComponent } from "./pages/import-popups/import-popup.component";
import { LockUnlockRecordsUpdateModule } from "./pages/calculation/lock-unlock-records-update/lock-unlock-records-update.module";
export let AppInjector: Injector;
@NgModule({
  imports: [
    BrowserModule,
    Select2Module,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I",
    }),
    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    HttpClientModule,
    PipesModule,
    AppRoutingModule,
    NgxDatatableModule,
    NgxChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxPaginationModule,
    MatCheckboxModule,
    PermissionsModule,
    RolesModule,
    UsersModule,
    UserDefinedFieldsModule,
    ResetFilterCalcRuleRoutingModule,
    FlexLayoutModule,
    LockUnlockRecordsModule,
    LockUnlockRecordsUpdateModule,
    SearchFilterModule,
    MatInputModule,
    SpinnerModule,
    FormsModule,
    AgGridModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,  
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    CalculationComponent,
    AdministrationComponent,
    ResetFilterCalcRuleComponent,
    
    ImportPopupComponent,
    LoginComponent
  ],

  
  providers: [
    AppSettings,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private injector: Injector)
  {
    AppInjector = this.injector;
  }
}
