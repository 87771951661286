import { InvestorComponent } from './investors.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DiretyCheckGuard } from 'src/app/shared/guard/direty-check.guard';
const routes: Routes = [
   { path: '', component: InvestorComponent, canDeactivate: [DiretyCheckGuard] }
];
@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule]
})
export class InvestorRoutingModule { }