export enum GroupOp {
   AND = 0,
   OR = 1
}

export enum Operations {
   eq, // "equal"
   ne, // "not equal"
   lt, // "less"
   le, // "less or equal"
   gt, // "greater"
   ge, // "greater or equal"
   bw, // "between"
   bn, // "does not begin with"
   ew, // "ends with"
   en, // "does not end with"
   cn, // "contains"
   nc  // "does not contain"
}

export class Rule {
   field: string;
   op: Operations
   data: string
}

export class Group {
   groupOp: string;
   rules: Rule[];
}

export class Filters {
   constructor() {
      this.rules = [];
      this.groups = [];
   }
   rules: Rule[];
   groups: Group[];
}