import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesRoutingModule } from './roles-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Select2Module } from 'ng-select2-component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RolesComponent } from './roles.component';

@NgModule({
  declarations: [
    RolesComponent
  ],
  imports: [
    CommonModule,
    RolesRoutingModule,
    SharedModule,
    Select2Module,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule, 
    MatSelectModule
  ]
})
export class RolesModule { }
