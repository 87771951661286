import { PaginationModel } from "src/app/pages/transactions/transactions.model";
import { DropdownModel } from "../../model/common.model";
import { AgGridI } from "../ag-grid/ag-grid.interface";
export class PageHeaderModel {
    public setVisibilityForForm(formName: string) {
        switch (formName) {
            case "Transaction":
                this.isAddButtonVisible = true;
                this.isLockUnlockButtonVisible = true;
                this.isFundLabelVisible = true;
                this.isSaveButtonVisible = true;
                this.isUndoTransactionButtonVisible = true;
                this.isTransactionFilterDropdownVisible = true;
                this.isApplyFilterButtonVisible = true;
                this.isEditFilterButtonVisible = true;
                this.isDeleteButtonVisible = false;
                this.isFundFilterDropdownVisible = false;  
                this.isFilterDropdownVisible = false;
                this.isTransactionDeleteButtonVisible = true;
                break;
            case "Fund Information":
            case "Calculation Rule Library":
                this.isAddButtonVisible = true;
                this.isFilterLabelVisible = true;
                this.isFilterDropdownVisible = true;
                this.isSaveButtonVisible = true;
                this.isUndoButtonVisible = true;
                this.isDeleteButtonVisible = true;
                this.isAddNewFieldVisible = true;
                this.isCopyVisible = true;
                break;
            case "Position":
                this.isFilterLabelVisible = true;
                this.isFilterDropdownVisible = true;
                this.isSaveButtonVisible = true;
                break;
            case "Lookup":
                this.isLookupFilterDropdownVisible = true;
                this.isAdminAddButtonVisible = true;
                this.isSaveButtonVisible = true;
                this.isExportButtonVisible = true;
                this.isUndoButtonVisible = true;
                this.isResetFilterButtonVisible = true;
                this.isResetSortButtonVisible = true;
                break;
            case "PermissionRole":
                this.isFilterLabelVisible = true;
                this.isFilterDropdownVisible = true;
                this.isSaveButtonVisible = true;
                this.isUndoButtonVisible = true;
                this.isDeleteButtonVisible = false;
                this.isAddNewFieldVisible = false;
                this.isResetSortButtonVisible = true;
                this.isExportButtonVisible = true;
                this.isAdminAddButtonVisible = false;
                this.isResetFilterButtonVisible = true;
                break;
            case "Role":
                this.isSaveButtonVisible = true;
                this.isResetSortButtonVisible = true;
                this.isExportButtonVisible = true;
                this.isAdminAddButtonVisible = true;
                this.isResetFilterButtonVisible = true;
                this.isUndoButtonVisible = true;
                break;
            case "User":
                this.isFilterLabelVisible = true;
                this.isFilterDropdownVisible = true;
                this.isSaveButtonVisible = true;
                this.isResetSortButtonVisible = true;
                this.isExportButtonVisible = true;
                this.isAdminAddButtonVisible = true;
                this.isResetFilterButtonVisible = true;
                this.isUndoButtonVisible = true;
                break;
            case "Reporting":
                // this.isApplyFilterButtonVisible = true;
                this.isReportFilterVisible = true;
                this.isSaveButtonVisible = true;
                break;
            case "Calculation":
                this.isAddButtonVisible = true;
                this.isFilterLabelVisible = false;
                this.isFilterDropdownVisible = true;
                this.isSaveButtonVisible = true;
                this.isUndoButtonVisible = true;
                this.isDeleteButtonVisible = true;
                this.isAddNewFieldVisible = true;
                this.isFundLabelVisible = true;
                this.isTransactionFilterDropdownVisible = false;
                this.isFundFilterDropdownVisible = true;
                this.isCalListLabelVisible = true;
                this.isRunCalcVisible = true;
                this.isCalculationForm = true;
                this.isCopyVisible = true;
        }
    }
    id: number;
    fundId: number;
    transactionFilterId: number;
    name: string;
    placeHolder: string = "";
    filterOptionStorageName: string;
    isCalcLocked: boolean = false;
    filteredOptions: DropdownModel[];
    transactionFilterOptions: DropdownModel[];
    controllerName: string;
    agGridI: AgGridI;
    gridApi: any;
    IdStorageName: string;
    fundIdStorageName: string;
    isServersidePagination: boolean = false;
    paginationModel: PaginationModel = new PaginationModel();
    isAddButtonVisible: boolean = false;
    isFilterLabelVisible: boolean = false;
    isFundLabelVisible: boolean = false;
    isFilterDropdownVisible: boolean = false;
    isSaveButtonVisible: boolean = false;
    isUndoTransactionButtonVisible: boolean = false;
    isFundFilterDropdownVisible: boolean = false;
    isTransactionFilterDropdownVisible: boolean = false;
    isApplyFilterButtonVisible: boolean = false;
    isEditFilterButtonVisible: boolean = false;
    isDeleteButtonVisible: boolean = false;
    isAddNewFieldVisible: boolean = false;
    isLockUnlockButtonVisible: boolean = false;
    isUndoButtonVisible: boolean = false;
    isLookupFilterDropdownVisible: boolean = false;
    isAdminAddButtonVisible: boolean = false;
    isExportButtonVisible: boolean = false;
    isResetFilterButtonVisible: boolean = false;
    isResetSortButtonVisible: boolean = false;
    canModify: boolean = false;
    canDelete: boolean = false;
    filter: string;
    showDeleted: boolean = false;
    isReportFilterVisible: boolean = false;
    isAddNew: boolean = false;
    isCalListLabelVisible : boolean = false;
    isRunCalcVisible : boolean = false;
    isCalculationForm : boolean = false;
    isTransactionDeleteButtonVisible :boolean = false;
    isHypotheticalLabelVisible:boolean = false;

    isScenarioModeAppliedLabelVisible : boolean = false;
    isCopyVisible : boolean = false;
}
