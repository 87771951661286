import { Injectable, Renderer2 } from '@angular/core';
import { PaginationModel } from 'src/app/pages/fund-information/fund-information.model';
import {
  defaultPageLimit, pageLimitOptions,
  Pagination
} from '../model/pagination.model';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  public readonly pageLimitOptions = pageLimitOptions;
  selectedPageLimit: number = defaultPageLimit;
  maxTotalPage: number = 0;
  goToPageNumber: number;
  pagination: Pagination = new Pagination(0, 0, this.selectedPageLimit);

  paginationModel = new PaginationModel();
  totalRecords: number = 0;

  // ngxDatatableSSRConfig         = new NgxDatatableSSRConfig();

  constructor(
    public renderer2: Renderer2
  ) {
    this.paginationModel.page = 0;
    this.paginationModel.pageSize = 25;
  }

  //#region Delete Data

  // delete(controllerName : string,model : any)
  // {

  // }



  //#endregion

  //#region Pagination

  handlePageChange(event: any): void {
    this.pagination.page = event.offset;
    this.paginationModel.page = this.pagination.page

    this.updatePaginationObject();
    //  this.PaginationGetById();
  }

  onLimitChange(event: any): void {
    this.pagination.pageSize = parseInt(event.toString());
    this.paginationModel.pageSize = this.pagination.pageSize

    this.updatePaginationObject();
    // this.PaginationGetById();
  }

  goToPage(event: any): void {
    if (this.goToPageNumber >= 0) {
      if (this.goToPageNumber > this.maxTotalPage) {
        //todo: show message 
        this.pagination.page = this.maxTotalPage - 1;
        this.paginationModel.page = this.pagination.page;
      }
      else {
        this.pagination.page = this.goToPageNumber - 1;
        this.paginationModel.page = this.pagination.page
      }
      this.updatePaginationObject();
    }
    // this.PaginationGetById();
  }

  updatePaginationData(rows: any) {
    this.totalRecords = rows?.length;
    this.updatePaginationObject()
  }

  private updatePaginationObject() {
    this.pagination.count = this.totalRecords
    this.maxTotalPage = Math.ceil(this.pagination.count / this.pagination.pageSize);
    this.pagination.fromRecordNumber = (this.pagination.page * this.pagination.pageSize) + 1
    this.pagination.toRecordNumber = ((this.pagination.page * this.pagination.pageSize) + this.pagination.pageSize > this.pagination.count) ? this.pagination.count
      : (this.pagination.page * this.pagination.pageSize) + this.pagination.pageSize
    this.goToPageNumber = this.pagination.page + 1;
  }

  //#endregion


  onUserEvent(e, rows, editing) {
    if (e.type == "click" || e.type == "keydown") {
      let that = this;
      const keyupenter$ = this.renderer2.listen(e.cellElement, "keyup.enter", event => {
        event.stopImmediatePropagation();
        if (keyupenter$) {
          keyupenter$();
        }
        let ele = event.target.nextElementSibling
        console.log("ele: ", event);
        if (ele) {
          ele.focus();
          ele.click();
        }
      });
      const keyupspace$ = this.renderer2.listen(e.cellElement, "keydown.space", event => {
        event.stopImmediatePropagation();
        if (keyupspace$) {
          keyupspace$();
        }
        console.log("data:", e);
        let rowIndex = rows.findIndex(x => x.id == e.row.id);
        if (rowIndex > -1)
          editing[rowIndex + '-' + e.column.name] = true;
      });
    }
  }

  isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }
  
    if (str.trim() === '') {
      return false;
    }
  
    return !Number.isNaN(Number(str));
  }
}