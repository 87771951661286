import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Result } from 'src/app/shared/model/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { PaginationModelCommon } from 'src/app/shared/model/pagination.model';
import { udfPostModel } from './user-defined-fields.model';

@Injectable({
  providedIn: 'root'
})
export class UserDefinedFieldsService {

  constructor(private service: DataService) { }

  public postUDFRecordByID(getRecordByID:PaginationModelCommon):Observable<Result> 
  {
    let url='/UDF/getUDFRecordById';
    return this.service.post(url,getRecordByID,true).pipe<Result>(tap((response:any) => {
      return response;
    }));
  }

  public postUDFData(udfPostModel:udfPostModel):Observable<Result> 
  {
    let url='/UDF/postUDFData'; 
    var a = JSON.stringify(udfPostModel);
    console.log('the json is: ', a);
    
    return this.service.post(url,udfPostModel,true).pipe<Result>(tap((response:any) => {
    return response;
    }));
  }

}
