import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { PagesComponent } from "./pages/pages.component";
import { AuthGuard } from "./shared/guard/auth.guard";

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: PagesComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: "legacy",
      // useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
