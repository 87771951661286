export const MY_DATE_FORMATS = {
   parse: {
      dateInput: 'MM/DD/YYYY',
   },
   display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
   },
}; 
export const MOMENT_DATE_FORMATE = "MM/DD/yyyy";