import { AgGridI } from "../ag-grid/ag-grid.interface";
export interface ITableHeaderItemVisibilityFields
{
        isAddButtonVisible?                     : boolean ;
        isExportButtonVisible?                  : boolean ;       
        isUndoButtonVisible?                    : boolean ;
        isResetFilterButtonVisible?             : boolean ;
        isSoftDeleteButtonVisible?              : boolean ;
        isPermanentDeleteButtonVisible?         : boolean ;
        isShowDeletedButtonVisible?             : boolean ;
        isHideDeletedButtonVisible?             : boolean ;
        isResetDeletedButtonVisible?            : boolean ;
        isExpandButtonVisible?                  : boolean ;
        isUnlockButtonVisible?                  : boolean ;
        isLockButtonVisible?                    : boolean ;
        isCalculationForm?                      : boolean ;
        isTransactionStatusButtonVisible?       : boolean ;
        isTransactionFilterCheckboxVisible?     : boolean ;
        isActiveFilter?                         : boolean ;
        isDeletedFilter?                        : boolean ;
        isLockedFilter?                         : boolean ;
        isDraftFilter?                         : boolean ;
        masterDataColumnName?                   : string  ;
        masterDataValueName?                    : string  ;
}
export class TableHeaderModel {
    setVisibility(tableHeaderItemVisibilityFields : ITableHeaderItemVisibilityFields)
    {
        this.isExportButtonVisible                  =   tableHeaderItemVisibilityFields.isExportButtonVisible           ?? true  ;       
        this.isAddButtonVisible                     =   tableHeaderItemVisibilityFields.isAddButtonVisible              ?? true  ;
        this.isUndoButtonVisible                    =   tableHeaderItemVisibilityFields.isUndoButtonVisible             ?? true  ;
        this.isResetFilterButtonVisible             =   tableHeaderItemVisibilityFields.isResetFilterButtonVisible      ?? true  ;
        this.isSoftDeleteButtonVisible              =   tableHeaderItemVisibilityFields.isSoftDeleteButtonVisible       ?? true  ;
        this.isPermanentDeleteButtonVisible         =   tableHeaderItemVisibilityFields.isPermanentDeleteButtonVisible  ?? true  ;
        this.isShowDeletedButtonVisible             =   tableHeaderItemVisibilityFields.isShowDeletedButtonVisible      ?? true  ;
        this.isExpandButtonVisible                  =   tableHeaderItemVisibilityFields.isExpandButtonVisible           ?? true  ;
        this.isUnlockButtonVisible                  =   tableHeaderItemVisibilityFields.isUnlockButtonVisible           ?? false ;
        this.isLockButtonVisible                    =   tableHeaderItemVisibilityFields.isLockButtonVisible             ?? false ;
        this.isHideDeletedButtonVisible             =   tableHeaderItemVisibilityFields.isHideDeletedButtonVisible      ?? false ;
        this.isResetDeletedButtonVisible            =   tableHeaderItemVisibilityFields.isResetDeletedButtonVisible     ?? false ;
        this.isCalculationForm                      =   tableHeaderItemVisibilityFields.isCalculationForm               ?? false ;
        this.isTransactionStatusButtonVisible       =   tableHeaderItemVisibilityFields.isTransactionStatusButtonVisible ?? false ;
        this.isTransactionFilterCheckboxVisible     =   tableHeaderItemVisibilityFields.isTransactionFilterCheckboxVisible ?? false ;
        this.isActiveFilter                         =   tableHeaderItemVisibilityFields.isActiveFilter ?? false ;
        this.isDeletedFilter                        =   tableHeaderItemVisibilityFields.isDeletedFilter ?? false ;
        this.isDraftFilter                          =   tableHeaderItemVisibilityFields.isDraftFilter ?? false ;
        this.isLockedFilter                         =   tableHeaderItemVisibilityFields.isLockedFilter ?? false ;
    }

    isAddButtonVisible              : boolean ;
    isExportButtonVisible           : boolean ;
   // isExportButtonVisible1           : boolean ;
    isUndoButtonVisible             : boolean ;
    isResetFilterButtonVisible      : boolean ;
    isSoftDeleteButtonVisible       : boolean ;
    isPermanentDeleteButtonVisible  : boolean ;
    isShowDeletedButtonVisible      : boolean ;
    isHideDeletedButtonVisible      : boolean ;
    isResetDeletedButtonVisible     : boolean ;
    isExpandButtonVisible           : boolean ;
    isUnlockButtonVisible           : boolean ;
    isLockButtonVisible             : boolean ;
    agGridI                         : AgGridI ;
    gridApi                         : any     ;
    canModify                       : boolean = false;
    canDelete                       : boolean = false;
    isAddNew                        : boolean = false;

//for calculation form
    showDetails                             : boolean = true;
    showSummary                             : boolean = false;
    includeDraftStatus                      : boolean = false;
    isCalculationForm                       : boolean = false;
    isTransactionStatusButtonVisible        : boolean = false;
    isTransactionFilterCheckboxVisible      : boolean = false;
    isActiveFilter                          : boolean = false;    
    isDeletedFilter                         : boolean = false;    
    isLockedFilter                          : boolean = false;    
    isDraftFilter                           : boolean = false;    
    isGraohButtonVisible                    : boolean = false;
    masterDataColumnName                    : string     ;
    masterDataValueName                     : string     ;
}
