import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataService } from './services/data.service';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MY_DATE_FORMATS } from './common/my-date-formats';
import { SortComponent } from './component/sort/sort.component';
import { MydatePipe } from './pipes/mydate.pipe';
import { EntercellDirective } from './directives/entercell.directive';
import { CustomeFilterComponent } from './component/custome-filter/custome-filter.component';
import { CustomeHeaderComponent } from './component/custome-header/custome-header.component';
import { AgGridComponent } from './component/ag-grid/ag-grid.component';
import { TableHeaderButtonsComponent } from './component/table-header-buttons/table-header-buttons.component';
import { TableHeaderButtonModule } from './component/table-header-buttons/table-header-buttons.module';
import { AgGridModule } from 'ag-grid-angular';
import { IconModule } from './component/actions/icon.module';
import { FormsModule } from '@angular/forms';
import { CellEditorComponent } from './component/cell-editor/cell-editor.component';
import { MultiselectDropdownModule } from 'src/app/shared/component/multiselect-dropdown/multiselect-dropdown.module';
import { DatepickerComponent } from './component/datepicker/datepicker.component';
import { DatepickerEditorComponent } from './component/datepicker-editor/datepicker-editor.component';
import { RunComponent } from './component/run/run.component';
import { CheckboxRendererComponent } from './component/checkbox-renderer/checkbox-renderer.component';
import { NumericeditorComponent } from './component/numericeditor/numericeditor.component';
import { NumericValueEditorComponent } from './component/numeric-value-editor/numeric-value-editor.component';
import { FilterComponent } from './component/filter/filter/filter.component';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { CustomInputControlComponent } from './component/custom-input-control/custom-input-control.component';
import { Select2Module } from 'ng-select2-component';
import { CellEditorTransactionComponent } from './component/cell-editor-transaction/cell-editor-transaction.component';
import { PageHeaderImportFormComponent } from './component/page-header-import-form/page-header-import-form.component';

@NgModule({
  imports: [
    AgGridModule,
    Select2Module,
    TableHeaderButtonModule,
    FormsModule,
    CommonModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatMomentDateModule,
    IconModule,
    FormsModule,
    MultiselectDropdownModule
  ],

  providers: [
    DataService, { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    DatePipe,
    MydatePipe
  ],

  exports: [
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    OnlyNumbersDirective,
    AutofocusDirective,
    EntercellDirective,
    SortComponent,
    MydatePipe,
    AgGridComponent,
    TableHeaderButtonsComponent,
    PageHeaderComponent,
    PageHeaderImportFormComponent,
    CustomInputControlComponent
  ],

  declarations: [
    OnlyNumbersDirective,
    AutofocusDirective,
    SortComponent,
    MydatePipe,
    EntercellDirective,
    CustomeFilterComponent,
    CustomeHeaderComponent,
    AgGridComponent,
    TableHeaderButtonsComponent,
    CellEditorComponent,
    CellEditorTransactionComponent,
    DatepickerComponent,
    DatepickerEditorComponent,
    RunComponent,
    CheckboxRendererComponent,
    NumericeditorComponent,
    NumericValueEditorComponent,
    FilterComponent,
    PageHeaderComponent,
    PageHeaderImportFormComponent,
    CustomInputControlComponent
  ]
})
export class SharedModule { }
