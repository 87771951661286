import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Menu } from "./menu.model";
import {
  getAdministrationVMenu,
  getCalculationRuleLibraryVMenu,
  getFundInformationVMenu,
  getHorizontalMenu,
  getTransactionVMenu,
} from "./menu";
import { LoginService } from "src/app/pages/login/login.service";

@Injectable()
export class MenuService {
  constructor(private location: Location, private router: Router, public loginService: LoginService) { }

  public HorizontalMenu(): Array<Menu> {
    return getHorizontalMenu;
  }
  public FundInformationVMenu(): Array<Menu> {
    return getFundInformationVMenu;
  }
  public TransactionVMenu(): Array<Menu> {
    return getTransactionVMenu;
  }
  public CalculationRuleLibraryVMenu(): Array<Menu> {
    return getCalculationRuleLibraryVMenu;
  }
  public AdministrationVMenu(): Array<Menu> {
    return getAdministrationVMenu;
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter((item) => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(
          (item) => item.id == menuItem.parentId
        )[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById("menu-item-" + menuId);
    let subMenu = document.getElementById("sub-menu-" + menuId);
    if (subMenu) {
      if (subMenu.classList.contains("show")) {
        subMenu.classList.remove("show");
        menuItem.classList.remove("expanded");
      } else {
        subMenu.classList.add("show");
        menuItem.classList.add("expanded");
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter((item) => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach((item) => {
        if (item.id != menuId) {
          let subMenu = document.getElementById("sub-menu-" + item.id);
          let menuItem = document.getElementById("menu-item-" + item.id);
          if (subMenu) {
            if (subMenu.classList.contains("show")) {
              subMenu.classList.remove("show");
              menuItem.classList.remove("expanded");
            }
          }
        }
      });
    }
  }

  public getMenuByRouteUrl(url) {
    if (url) {
      if (url === "/dashboard") {
        return this.HorizontalMenu()[0];
      }
      
      if (
        url === "/funds" ||
        url === "/funds/fund" ||
        url === "/funds/investors" ||
        url === "/funds/investments" ||
        url === "/funds/positions" ||
        url === "/funds/imports"
      ) {
        return this.HorizontalMenu()[1];
      }

      if (
        url === "/transactions" ||
        url === "/transactions/tier-transactions" ||
        url === "/transactions/import-transactions"
      ) {
        return this.HorizontalMenu()[2];
      }

      if (url === "/calculation") {
        return this.HorizontalMenu()[3];
      }

      if (url === "/reporting") {
        return this.HorizontalMenu()[4];
      }
      if (
        url === "/calculation-rules-library" ||
        url === "/calculation-rules-library/calculation-rules" ||
        url === "/calculation-rules-library/performance-rules" ||
        url === "/calculation-rules-library/tiers"
      ) {
        return this.HorizontalMenu()[5];
      }
      if (
        url === "/administration" ||
        url === "/administration/permissions" ||
        url === "/administration/roles" ||
        url === "/administration/users" ||
        url === "/administration/user-defined-fields" ||
        url === "/administration/lookups"
      ) {
        return this.HorizontalMenu()[6];
      }
      // return this.HorizontalMenu().find(x => url.includes(x.routerLink))
    }
    return null;
  }

  setActiveMenu(menuName)
  {
    this.HorizontalMenu()[3].routerLink = "\reports"

  }

  public getHorizontalMenuById(menuId) {
    if (menuId > 0) {
      return this.HorizontalMenu().find((x) => x.id == menuId);
    }
    return null;
  }

  public getVerticalMenuById(id) {
    var menuItems = [];
    var headerTitle = '';
    let menu = this.getHorizontalMenuById(id);
    switch (id) {
      case 2:
        {
          menuItems = this.FundInformationVMenu();
          headerTitle = 'Fund Admin';
          break;
        }
      case 3:
        {
          menuItems = this.TransactionVMenu();
          headerTitle = menu.title;
          break;
        }
      case 6:
        {
          menuItems = this.CalculationRuleLibraryVMenu();
          headerTitle = 'Calc. Rules Library';
          break;
        }
      case 7:
        {
          menuItems = this.AdministrationVMenu();
          headerTitle = menu.title;
          break;
        }
      default:
        {
          //this.parentMenu = [];
          menuItems = [];
        }
    }

    //filter menu based on permission
    const currentUser = this.loginService.currentUserValue;
    if (currentUser && currentUser.permissions) {
      menuItems = menuItems.filter(function (element) {
        return currentUser.permissions.formPermissions.findIndex(x =>
          x.formName == element.title && x.viewPermission == true) >= 0;
      });
    }

    return { menuItems, headerTitle };
  }

}
