import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AgGridService } from "../ag-grid/ag-grid.service";
import { CustomeHeaderService } from "../custome-header/custome-header.service";
import { TableHeaderModel } from "./table-header-buttons.model";
import { StorageService } from "../../services/storage.service";
import { CommonTabService } from "../../services/common-tab.service";
import { NotificationService } from "../../services/notification.service";
import { ToastrService } from "ngx-toastr";
import { StorageName } from "../../common/enum";
@Component({
  selector: "app-table-header-buttons",
  templateUrl: "./table-header-buttons.component.html",
  styleUrls: ["./table-header-buttons.component.scss"]
})
export class TableHeaderButtonsComponent implements OnInit {
  params;
  tabID = 1;
  collapse: boolean = false;
  @Input() tableHeaderModel!: TableHeaderModel;
  @Output() showDeleted: EventEmitter<any> = new EventEmitter();
  @Output() onAddRow: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteRows: EventEmitter<any> = new EventEmitter();
  @Output() onReset: EventEmitter<boolean> = new EventEmitter();
  @Output() onExportAllTransactions: EventEmitter<boolean> = new EventEmitter();
  
  @Output() onShowDetails: EventEmitter<boolean> = new EventEmitter();
  @Output() onShowSummary: EventEmitter<boolean> = new EventEmitter();
  @Output() onIncludeDraftStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() onShowGraph: EventEmitter<boolean> = new EventEmitter();
  @Output() onRemoveSort: EventEmitter<string> = new EventEmitter();
  @Output() onTransactionStatusFilter: EventEmitter<number> = new EventEmitter();
  @Output() onActiveFilter: EventEmitter<string> = new EventEmitter();
  @Output() onDeleteFilter: EventEmitter<string> = new EventEmitter();
  @Output() onDraftFilter: EventEmitter<string> = new EventEmitter();
  @Output() onLockedFilter: EventEmitter<string> = new EventEmitter();

  constructor(public agGridService: AgGridService,
    public storageService: StorageService,
    public commonTabService: CommonTabService,
    public notificationService: NotificationService,
    public toasterService: ToastrService,
    public customHeaderService: CustomeHeaderService) { }

  ngOnInit(): void {
    //set from permission
    var formPermission = this.storageService.retrieve(StorageName.CURRENT_FORM_PERMISSION);
    if (formPermission) {
      this.tableHeaderModel.canModify = formPermission.modifyPermission;
      this.tableHeaderModel.canDelete = formPermission.deletePermission;
    }
  }
  onActiveFilterClick(event){
    if(event.checked){      
      this.onActiveFilter.emit('2');
    }
    else{
      this.onActiveFilter.emit('');
    }
  }

  onDeleteFilterClick(event){
    if(event.checked){
      this.onDeleteFilter.emit('1');
    }
    else{
      this.onDeleteFilter.emit('');
    }
  }
  
  onDraftFilterClick(event){
    if(event.checked){
      this.onDraftFilter.emit('1');
    }
    else{
      this.onDraftFilter.emit('');
    }
  }

  onLockedFilterClick(event){
    if(event.checked){
      this.onLockedFilter.emit('4');
    }
    else{
      this.onLockedFilter.emit('');
    }
  }
  addRow() {
    var masterFormDataFieldName = (this.tableHeaderModel.masterDataColumnName == "" || this.tableHeaderModel.masterDataColumnName == undefined) ?  this.tableHeaderModel.agGridI.masterIdStorageName.replace("_","").replace("ID","").toLocaleLowerCase()+ "Name" : this.tableHeaderModel.masterDataColumnName;
    const obj = {
      feeEligible: true,
      active: true,
      masterFormDataFieldName : this.tableHeaderModel.masterDataValueName      
    }    
    this.tableHeaderModel.agGridI.colDefs.forEach(agColumn=>{
      if(agColumn.cellType == "number"){        
        obj[agColumn.field] = 0        
      }
    });

    obj[masterFormDataFieldName] = this.tableHeaderModel.masterDataValueName;
    if (this.tableHeaderModel.agGridI.serversidepagination == true)
      this.onAddRow.emit();
    else {      
      this.agGridService.addNewRow({ api: this.tableHeaderModel.gridApi, newRow: obj });
      var currentRows = this.tableHeaderModel.agGridI.gridApi.api.getModel().rowsToDisplay.map(a => a.data);
      this.storageService.store(this.tableHeaderModel.agGridI.initialRows, currentRows);
    }
  }
  exportAllItems() {
    if (this.tableHeaderModel.agGridI.serversidepagination == true)
    this.onExportAllTransactions.emit();
    else
    this.agGridService.exportAll(this.tableHeaderModel.gridApi, this.tableHeaderModel.agGridI.controllerName, false);
  }
  undo() {
    if (this.tableHeaderModel.agGridI.serversidepagination == true)
      this.onReset.emit(false);
    else {
      // this.tableHeaderModel.isShowDeletedButtonVisible = true;
      // this.tableHeaderModel.isHideDeletedButtonVisible = false;
      // this.tableHeaderModel.isResetDeletedButtonVisible = false;
      this.getTabData(undefined,true);
    }
  }
  resetFilter() {
    if(this.tableHeaderModel.agGridI != undefined)
    {
    if (this.tableHeaderModel.agGridI.serversidepagination == true)
      this.onReset.emit(true);
    else {
      this.agGridService.
        resetAllFilters(this.tableHeaderModel.agGridI.initialRows,
          this.tableHeaderModel.agGridI.filterStorageName,
          this.tableHeaderModel.agGridI.gridName, this.tableHeaderModel.gridApi);
    }
  }
    this.customHeaderService.isResetFilterIcon.emit(true);
  }

  clearSort() {
    this.storageService.store(this.tableHeaderModel.agGridI.gridName, []);
    this.tableHeaderModel.agGridI.gridApi.columnApi.applyColumnState({
      defaultState: { sort: null },
    });
    if(this.tableHeaderModel.agGridI.gridName==StorageName.TRANSACTION_LEVEL_GRID || this.tableHeaderModel.agGridI.gridName==StorageName.FUND_LEVEL_GRID )
      this.onRemoveSort.emit(this.tableHeaderModel.agGridI.gridName);
  }

  onDeleteOrReset(type: number) {
    var selectedRows = this.tableHeaderModel.gridApi.api.getSelectedRows();
    if (selectedRows.length > 0)
      this.deleteOrReset(type, selectedRows);
    else
      this.notificationService.warningpopup("select atleast one item");
  }

  onlockUnlockSelectedItem(isLocked: boolean) {
    var selectedRows = this.tableHeaderModel.gridApi.api.getSelectedRows();
    if (selectedRows.length > 0) {
      const selectedIds = selectedRows.map(({ id }) => id);
      this.commonTabService.lockUnlockRecords(isLocked, selectedIds)
        .subscribe((res) => {
          if (res.isSuccess) {
            this.toasterService.success(res.message, "", {
              timeOut: 1000
            });
          }
          else {
            this.notificationService.errorpopup(res.errors);
          }
          this.onReset.emit(false);
        });
    }
    else
      this.notificationService.warningpopup("select atleast one item");
  }

  deleteOrReset(type: number, selectedRows: any) {
    if (type == 1) {
      let result = this.notificationService.deleteOrReset(type);
      result.subscribe(res => {
        if (res.isConfirmed) {
          this.processDelete(type, selectedRows);
        }
      });
    } else this.processDelete(type, selectedRows);
  }

  processDelete(type: number, selectedRows: any) {
    const selectedIds = selectedRows.map(({ id }) => id);
    this.commonTabService.delete(this.tableHeaderModel.agGridI.controllerName, type, selectedIds)
      .subscribe((res) => {
        if (res.isSuccess) 
          this.toasterService.success(res.message, "", {timeOut: 1000});
        else 
          this.notificationService.errorpopup(res.errors);
        if (this.tableHeaderModel.agGridI.serversidepagination == true)
          this.onDeleteRows.emit(type== 1 ? selectedRows.length : 0);
        else
          this.getTabData(undefined,false,true);
      });
  }

  processAfterDeleted(selectedRows : any) {
       this.tableHeaderModel.agGridI.gridApi.api.forEachNode((rowNode, index) => {
        var temp = rowNode.data;
        var newData =  selectedRows.filter(a=>a.id ==temp.id) ;
        if(newData != undefined && newData.length >0)
        rowNode.setData(newData[0]);
       });
       var currentRows = this.tableHeaderModel.agGridI.gridApi.api.getModel().rowsToDisplay.map(a => a.data);
       this.storageService.store(this.tableHeaderModel.agGridI.initialRows, currentRows);
      }

  onShowDeleted() {
    this.storageService.store(this.tableHeaderModel.agGridI.showDeleteStatusStorageName, true);
    this.tableHeaderModel.isShowDeletedButtonVisible = false;
    this.tableHeaderModel.isHideDeletedButtonVisible = true;
    this.tableHeaderModel.isResetDeletedButtonVisible = true;
    if (this.tableHeaderModel.agGridI.serversidepagination === true || this.tableHeaderModel.agGridI.serversidepagination != undefined)
      this.showDeleted.emit(true);
    else
      this.getTabData(undefined,false,true);
  }
  onHideDeleted() {
    this.storageService.store(this.tableHeaderModel.agGridI.showDeleteStatusStorageName, false);
    this.tableHeaderModel.isShowDeletedButtonVisible = true;
    this.tableHeaderModel.isHideDeletedButtonVisible = false;
    this.tableHeaderModel.isResetDeletedButtonVisible = false;
    if (this.tableHeaderModel.agGridI.serversidepagination === true)
      this.showDeleted.emit(false);
    else
    this.getTabData(undefined,false,true);
  }

  getTabData(gridApi = undefined, undo : boolean = false, resetAfterDelete : boolean = false) {
    var ochangedData =  this.storageService.retrieve(this.tableHeaderModel.agGridI.initialRows).filter(a => a.isEdited == true || a.isAdded == true);
    if((ochangedData!= undefined && ochangedData.length == 0) || resetAfterDelete == true || undo ==true)
    {
    if(this.tableHeaderModel.isAddNew==false){
      if (gridApi != undefined)
        gridApi.api.showLoadingOverlay();
      var showDeleted = this.storageService.retrieve(this.tableHeaderModel.agGridI.showDeleteStatusStorageName);
      if(showDeleted == undefined){
        showDeleted = true;
        this.storageService.store(this.tableHeaderModel.agGridI.showDeleteStatusStorageName,showDeleted);
      }
      this.setDeleteButtonVisibility(showDeleted);
      var masterId = this.storageService.retrieve(this.tableHeaderModel.agGridI.masterIdStorageName);
      this.commonTabService.getTabData(this.tableHeaderModel.agGridI.controllerName, masterId == undefined ? 0 : masterId, showDeleted)
        .subscribe((res) => {
          if (res.isSuccess) {
            var dataEdited =   this.tableHeaderModel.agGridI.gridApi.api.getModel().rowsToDisplay.map(a => a.data)?.filter((row) => row.isEdited == true );
            this.storageService.store(this.tableHeaderModel.agGridI.initialRows, res.response);
            var filteredRows = this.agGridService.performFilter(this.tableHeaderModel.agGridI);
            this.tableHeaderModel.gridApi.api.setRowData(filteredRows);
            if(resetAfterDelete == true)
              this.processAfterDeleted(dataEdited);
            if(this.tableHeaderModel.agGridI.controllerName == "Calculation Settings" && filteredRows.length == 0)
              this.addRow();
          }
        });
    }
  }
  }

  setDeleteButtonVisibility(showDeleted) {
    if (this.tableHeaderModel.agGridI.controllerName != "Permission") {
      this.tableHeaderModel.isShowDeletedButtonVisible = !showDeleted;
      this.tableHeaderModel.isHideDeletedButtonVisible = showDeleted;
      this.tableHeaderModel.isResetDeletedButtonVisible = showDeleted;
    }
    else {
      this.tableHeaderModel.isShowDeletedButtonVisible = false;
      this.tableHeaderModel.isHideDeletedButtonVisible = false;
      this.tableHeaderModel.isResetDeletedButtonVisible = false;
    }
  }
  flexibleHeight: string =
    "min-height: calc(100vh - 228px) !important;max-height: calc(100vh - 350px) !important;";
  showHideInputs() {
    var e = document.getElementById("ContentElement")
    e?.classList?.toggle("hide");
    if (e?.classList?.contains("hide")) {
      this.flexibleHeight =
        "min-height: calc(100vh - 141px) !important;max-height: calc(100vh - 141px) !important;";
    } else {
      this.flexibleHeight =
        "min-height: calc(100vh - 228px) !important;max-height: calc(100vh - 350px) !important;";
    }
    this.collapse = !this.collapse;
  }

  showDetails(){this.onShowDetails.emit(this.tableHeaderModel.showDetails);}
  showSummary(){this.onShowSummary.emit(this.tableHeaderModel.showSummary);}
  includeDraftStatus(){this.onIncludeDraftStatus.emit(this.tableHeaderModel.includeDraftStatus);}
  showGraph(){this.onShowGraph.emit();}
  TransactionStatusFilter(status: number) {
    this.onTransactionStatusFilter.emit(status);
  }
}
