import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ImportFileModel } from 'src/app/shared/component/page-header-import-form/page-header-import-form.model';
import { Result } from 'src/app/shared/model/response.model';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(private service: DataService,private commonTabService: CommonTabService) { }
  importType : number; 
  public Import(importFileModel:ImportFileModel,formName : string):Observable<Result> 
    {
      let userid = this.commonTabService.getUserid();
      importFileModel.importUserID = userid;
      let url='/'+formName.replace(" ","")+'Import/Import'; 
      return this.service.post(url,importFileModel,true).pipe<Result>(tap((response:any) => {
      return response;
      }));
    }

    public ImportTransaction(importFileModel:any,formName : string):Observable<Result> 
    {
      let url = '/' + formName.replace(" ", "") + `Import/Import`;
      return this.service.postWithFormData(url,importFileModel,true).pipe<Result>(tap((response:any) => {
      return response;
      }));
    }
  public validate(formName : string,isAddNew : boolean, isAppend : boolean, isOverride : boolean= false):Observable<Result>
  {
    var roleId = this.commonTabService.getRoleid();
    let url='/'+formName.replace(" ","")+'/Validate?isAddNew='+ (isAddNew == undefined ?  false : isAddNew) + '&isAppend='+(isAppend == undefined ? false : isAppend)  + '&isOverride='+isOverride + '&roleId='+roleId;
    return this.service.get(url).pipe<Result>(tap((response:any)=>{
      return response;
    }));
  }
  public commit(formName : string,isAddNew : boolean, isAppend : boolean, isOverride : boolean= false):Observable<Result>
  {
    var roleId = this.commonTabService.getRoleid();
    let userid = this.commonTabService.getUserid();
    let url='/'+formName.replace(" ","")+'/Commit?isAddNew='+ (isAddNew == undefined ?  false : isAddNew) + '&isAppend='+(isAppend == undefined ? false : isAppend) + '&isOverride='+isOverride+'&importUserIdPost='+ userid+ '&roleId='+roleId;
    return this.service.get(url).pipe<Result>(tap((response:any)=>{
      return response;
    }));
  }

  public delete(formName : string):Observable<Result>
  {
    let userid = this.commonTabService.getUserid();
    let url='/'+formName.replace(" ","")+'/delete';
    return this.service.get(url).pipe<Result>(tap((response:any)=>{
      return response;
    }));
  }
}
 