import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit {
  @Input() textField: string = "";
  @Input() data: any;
  @Input() selectedItems: any[] = [];
  @Input() disabled: boolean = false;
  @Output() selectedItemsChange = new EventEmitter<any>();
  // @Input() set selectedItems(value) {
  //   this.selectedItem = value;
  // }
  // selectedItem: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  constructor() { }
  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'id',
      textField: "name",
      allowSearchFilter: true,
      itemsShowLimit: 3,
      showSelectedItemsAtTop: true,
      enableCheckAll: true,
      placeholder: "Select",
      disabled: this.disabled
    } as IDropdownSettings;
  }
  get getItems() {
    return this.data.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }
  onItemSelect(item: any) {
    this.selectedItemsChange.emit(this.selectedItems);
  }
  onItemDeSelect(item: any) {
    this.selectedItemsChange.emit(this.selectedItems);
  }
  onItemSelectAll(item: any) {
    // this.selectedItems = item;
    //not working properly, not setting value of parent property
    this.selectedItemsChange.emit(item);
  }
  onItemDeSelectAll(item: any) {
    // this.selectedItems = item;
    //not working properly
    this.selectedItemsChange.emit(item);
  }
}
