import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { ValueFormatterParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { __param } from 'tslib';
import { StorageName } from '../../common/enum';
import { defaultPageLimit } from '../../model/pagination.model';
import { CommonFilterModel } from '../../model/response.model';
import { StorageService } from '../../services/storage.service';
import { SortService } from '../sort/sort.service';
import { AgColDefsI } from './ag-grid.interface';
import { sheetNameList } from '../page-header-import-form/page-header-import-form.model';
import { parse, format } from 'date-fns';
import { enUS } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class AgGridService {
  eventType = '';
  allRows = new EventEmitter();
  allColumns = new EventEmitter();
  addRows = new EventEmitter();
  resetFilterData = new EventEmitter();
  onSoftDelete = new EventEmitter();
  onPermanentDelete = new EventEmitter();
  private readonly _filterData = new BehaviorSubject<CommonFilterModel[]>([]);
  readonly filterData$ = this._filterData.asObservable();
  private readonly _colDefs = new BehaviorSubject<AgColDefsI[]>([]);
  readonly colDefs$ = this._colDefs.asObservable();
  //rowsChanged = new BehaviorSubject<any[]>([]);
  setPageSize = new BehaviorSubject<any>([]);
  isEdited = new EventEmitter();
  disabledCheckBox = false;
  filterList: any[];
  UDFList: any[];
  sheetNameList: sheetNameList[] = [];
  // public _SortingArray: any[] = [];
  constructor(public storageService: StorageService,
    public sortService: SortService
  ) { }
  get ColDefs(): AgColDefsI[] {
    return this._colDefs.getValue();
  }
  set ColDefs(data: AgColDefsI[]) {
    this._colDefs.next(data);
  }
  get filterData(): CommonFilterModel[] {
    return this._filterData.getValue();
  }
  set filterData(data: CommonFilterModel[]) {
    this._filterData.next(data);
  }
  public clearRows() {
    const a: any[] = [];
    this.allRows.next(a);
  }
  colDefss = new BehaviorSubject<AgColDefsI[]>([]);
  public setColDefss(data: AgColDefsI[]) {
    this.colDefss.next(data);
  }
  public addRow(data: AgColDefsI[]) {
    this.colDefss.next(data);
  }
  public addNewRow(rowData: any) {    
    rowData.newRow.id = 0;
    rowData.newRow.isAdded = true;
    rowData.newRow.isDeleted = false;
    rowData.newRow.isEdited = false;
    rowData.api.api.updateRowData({ add: [rowData.newRow], addIndex: 0 })
  }
  public addGridRows(rowData: any) {
    rowData.api.api.applyTransaction({ add: rowData.newRow, addIndex: 0 })
  }
  public exportAll(gridapi: any, filename: string, onlySelected: boolean = false): void {

    filename = filename + " " + new Date().toLocaleString();
    gridapi.api.exportDataAsExcel({
      columnKeys: this.generateColumnsForExcel(gridapi),
      fileName: filename,
      sheetName: 'Sheet',
      onlySelected: onlySelected,
      processCellCallback: (params) => {
        const colDef = params.column.getColDef();
        // try to reuse valueFormatter from the colDef
        if (colDef.valueFormatter) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params.value,
            node: params.node!,
            colDef: params.column.getColDef()
          };

          if (colDef.cellClass.toString().includes('percentFormat') ||
            colDef.cellClass.toString().includes('percentFormatWith4Dec')) {
            params.value = params.value == undefined ? "" : (params.value / 100);
          }
          return params.value;
        }

        if (colDef.cellClass.toString().includes('ExcelDate')) {
          params.value = params.value == undefined ? "": this.dateConvertorByRegion(params.value);
        }
        if (params.value == "true" || params.value == true && params.value != 1) {
          params.value = "Yes"
        }
        if (params.value == "false") {
          params.value = ""
        }
        return params.value;
      },
    })
  }

  public exportAllTransactions(gridapi: any, filename: string, index: number, onlySelected: boolean = false): void {
    filename = filename + " " + new Date().toLocaleString();
    gridapi.api.exportDataAsExcel({
      columnKeys: this.generateColumnsForExcel(gridapi),
      fileName: filename,
      sheetName: 'Sheet',
      onlySelected: onlySelected,
      processCellCallback: (params) => {
        const colDef = params.column.getColDef()

        // try to reuse valueFormatter from the colDef
        if (colDef.valueFormatter) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params.value,
            node: params.node!,
            colDef: params.column.getColDef()
          };
          if (colDef.cellClass.toString().includes('ExportDate')) {
            params.value = this.dateConvertorByRegion(valueFormatterParams.data);
          }
          if (colDef.valueFormatter.toString().includes('percentColumnFormatter')) {
            params.value = this.percentColumnFormatter(valueFormatterParams.data, '')
          }
          return params.value;
        }
        if (params.value == "true" || params.value == true) {
          params.value = "Yes"
        }
        if (params.value == "false" || params.value == false) {
          params.value = "No"
        }

        return params.value;
      },
    })
  }

  generateColumnsForExcel(gridapi: any): string[] {
    const keys = gridapi.columnApi.getAllDisplayedColumns()
      .map((column) => {
        // Exclude columns with 'Actions' colId or blank header names
        const colId = column.getColId();
        const headerName = column.getColDef().headerName;
        if (headerName === 'Actions' || headerName === '' || headerName === 'Run' || headerName === undefined || colId === 'actions' || headerName === '' || colId === 'run' || colId === undefined) {
          return null;
        } else {
          return colId;
        }
      })
      .filter((key) => key !== null);
    // if (keys.includes("run")) {
    //   var slicedata = keys.slice(3)
    // }
    // else {
    //   var slicedata = keys.slice(2)
    // }
    return keys;
  }

  onColumnMoved(e) {
  }
  currencyFormatter(currency, sign, digits: number) {
    if (currency != "" && currency != undefined && currency != null) {
      currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', minimumFractionDigits: digits, maximumFractionDigits: digits, }).format(currency).replace(/([$])/g, '');
      return currency;
    }
    else {
      return currency;
    }
  }


  // dateValueFormatter(params) {
  //   var date = new Date(params);
  //   var day = date.getDate().toString().padStart(2, '0');
  //   var month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   var year = date.getFullYear().toString();
  //   var newDate = year + '-' + month + '-' + day;
  //   var formattedDate= this.dateConvertorByRegion(newDate);
  //  return formattedDate; 
  // }

  dateConvertorByRegion(paramDate) {
    if(paramDate == '' || paramDate == undefined)
      return '';
    const datePipe = new DatePipe(navigator.language);
    var newDateReturn = datePipe.transform(paramDate, 'shortDate'); 
    const formattedDate = new Date(newDateReturn).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' });
    return formattedDate; 
  }

  percentColumnFormatterWith4dec(percent, sign) {
    if (percent != "" && percent != undefined && percent != null) {
      percent = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(percent / 100);
      return percent;
    }
    else {
      return percent;
    }
  }

  percentColumnFormatter(percent, sign) {
    if (percent != "" && percent != undefined && percent != null) {
      percent = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(percent / 100);
      return percent;
    }
    else {
      return percent;
    }
  }

  dateColumnFormatter(params) {
    //const dateParts = percent.value.match(/(\w+) (\d+) (\d+) (\d+):(\d+)(\w+)/);
     // Try parsing the date in different formats
     if(params.column.colId == "importDate")
     {
        params.value = new Date();
     } 
     var formattedDate = this.dateConvertorByRegion(params.value );
 
       return formattedDate; // 'shortDate' format for date only
     
  }

 // Custom function to try parsing the date in different formats using date-fns
 private tryParseDate(dateStr: string): Date | null {
  // Define the formats you want to try parsing
  const formats = ['MMM d yyyy h:mma', 'yyyy-MM-dd'];

  for (const format of formats) {
    const parsedDate = parse(dateStr, format, new Date(), { locale : enUS });
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  }

  return null; // Parsing failed for all formats
}

  
  performFilter(agGridDataModel: any) {    
    let rows = this.storageService.retrieve(agGridDataModel.initialRows);
    let sortList = this.storageService.retrieve(agGridDataModel.gridName);
    // agGridDataModel.columnApi.applyColumnState({
    //   state: sortList,
    //   defaultState: { sort: null },
    // });
    try {
      if (agGridDataModel.gridApi != undefined)
        agGridDataModel.gridApi.columnApi.applyColumnState({
          state: sortList,
          defaultState: { sort: null },
        });
      else
        agGridDataModel.columnApi.applyColumnState({
          state: sortList,
          defaultState: { sort: null },
        });;
    }
    catch (ex) {
      console.log(ex)
    }

    this.filterList = [];
    this.filterList = this.storageService.retrieve(agGridDataModel.filterStorageName);
    
    var newFilterRows: any[] = rows;
    this.filterList?.forEach(element => {
      if (element?.FilterData != undefined) {
        const value = element?.FilterData.map(a => a.name);
        
        if (value != undefined) {
          if (value.length == 0) {
            newFilterRows = rows;
          }
          else {            
            newFilterRows = newFilterRows.filter(function (d) {
              var check: boolean = false;            
              value.forEach(val => {               
                if (d[element.name]?.toString().toLowerCase() == (val?.toLocaleLowerCase()) || 
                    val?.toLocaleLowerCase() == "null/(empty)" ||
                    !val) {
                  check = d[element.name]?.toString().toLowerCase() == (val?.toLocaleLowerCase()) || 
                          (val?.toLocaleLowerCase() == "null/(empty)" && (d[element.name] == null || d[element.name].toString().toLowerCase() == '')) ||
                          !val;
                }
              })
              return check;
            });
          }
        }
      }
      
      const txtInputValue = element?.textFilter?.toLowerCase();
      const type = element?.type;
      if (txtInputValue != undefined && txtInputValue != "" && txtInputValue != null) {
        newFilterRows = newFilterRows?.filter(function (d) {
          var checkInput: boolean = false;
          checkInput =
            ((element.type == "textFilter"
              && d[element.name]?.toString().toLowerCase().startsWith(txtInputValue))
              || (element.type == "percentFilter"
                && ((d[element.name] * 100).toString().toLowerCase().startsWith(txtInputValue)))
              // || (element.type == "dateFilter"
              //   && ((new DatePipe('en-US').transform(d[element.name], 'MM/dd/yyyy')).toString().toLowerCase().startsWith(txtInputValue)))
            )
            if (element.type == "textFilter" || element.name.toLowerCase().indexOf("date") != -1) {

                var checkInput: boolean = false;
                // Format the date in the desired locale-specific format
                var date = d[element.name];
                const formattedDate = this.dateConvertorByRegion(date);
            
                // Check if the formatted date starts with txtInputValue
                checkInput = formattedDate.toLowerCase().startsWith(txtInputValue);
               // return checkInput;

            // return checkInput;
            }
         return checkInput;
         
        });
      }
    });

    var gridApi: any;
    if (agGridDataModel.gridApi != undefined)
      gridApi = agGridDataModel.gridApi.api;
    else
      gridApi = agGridDataModel.api;
    var storedPageSize = this.storageService.retrieve(agGridDataModel.paginationStore);
    gridApi.paginationSetPageSize(storedPageSize == undefined ? defaultPageLimit : storedPageSize);
    gridApi.setRowData(newFilterRows);
    if (agGridDataModel.gridName == StorageName.CALCULATIONS_TIER_CALC_GRID) {
      let obj = null;
      if (newFilterRows.length > 0) {
        let sumOfCarriedInterest: number = newFilterRows.map(a => a.carriedInterest)?.reduce(function (a, b) { return a + b; });
        let sumOfParticipantAlloc: number = newFilterRows.map(a => a.participantAlloc)?.reduce(function (a, b) { return a + b; });
        let sumOfTierAmt: number = newFilterRows.map(a => a.tierAmt)?.reduce(function (a, b) { return a + b; });
        let sumOfComparisonTierAmt: number = newFilterRows.map(a => a.comparisionAmt)?.reduce(function (a, b) { return a + b; });
        obj = {
          investor: "Totals ",
          carriedInterest: sumOfCarriedInterest,
          participantAlloc: sumOfParticipantAlloc,
          tierAmt: sumOfTierAmt,
          comparisionAmt: sumOfComparisonTierAmt,
          isSumRow: true
        }
      }
      else {
        obj = {
          investor: "Totals ",
          carriedInterest: 0,
          participantAlloc: 0,
          tierAmt: 0,
          comparisionAmt: 0,
          isSumRow: true
        }
      }
        if (agGridDataModel.gridApi != undefined)
          gridApi = agGridDataModel.gridApi;
        else
          gridApi = agGridDataModel;

        gridApi.api.setPinnedBottomRowData([obj]);

        //this.addNewRow({ api: gridApi, newRow: obj });

      }

      if (agGridDataModel.gridName == StorageName.CALCULATIONS_PREF_RET_CALC_GRID) {
        let obj = null;
        if (newFilterRows.length > 0) {
          let sumOfcashFlowAmt: number = newFilterRows.map(a => a.cashFlowAmt)?.reduce(function (a, b) { return a + b; });
          let sumOfprefRetAmt: number = newFilterRows.map(a => a.prefRetAmt).reduce(function (a, b) { return a + b; });

          obj = {
            investor: "Totals ",
            cashFlowAmt: sumOfcashFlowAmt,
            cashFlowDate: "",
            prefRetAmt: sumOfprefRetAmt,
            isSumRow: true
          }
        }
        else {
          obj = {
            investor: "Totals ",
            cashFlowAmt: 0,
            cashFlowDate: "",
            prefRetAmt: 0,
            isSumRow: true
          }
        }
        if (agGridDataModel.gridApi != undefined)
          gridApi = agGridDataModel.gridApi;
        else
          gridApi = agGridDataModel;

        gridApi.api.setPinnedBottomRowData([obj]);
      }

      // this.setPageSize.next({pageSize : data, rows : newFilterRows });
      return newFilterRows;
    }

    resetAllFilters(initialRowsStorageName, filterStorageName, storageNameSorting, gridApi = null) {
      this.storageService.removeItem(filterStorageName);
      let initialRows = this.sortService.performSort(initialRowsStorageName, storageNameSorting);
      if (gridApi != null && gridApi != undefined)
        gridApi.api.setRowData(initialRows);


      if (initialRowsStorageName == StorageName.CALCULATIONS_TIER_CALC_INITIALROWS && initialRows.length > 0) {
        let sumOfCarriedInterest: number = initialRows.map(a => a.carriedInterest).reduce(function (a, b) { return a + b; });
        let sumOfParticipantAlloc: number = initialRows.map(a => a.participantAlloc).reduce(function (a, b) { return a + b; });
        let sumOfTierAmt: number = initialRows.map(a => a.tierAmt).reduce(function (a, b) { return a + b; });
        let sumOfComparisonTierAmt: number = initialRows.map(a => a.comparisionAmt).reduce(function (a, b) { return a + b; });
        const obj = {
          investor: "Totals ",
          carriedInterest: sumOfCarriedInterest,
          participantAlloc: sumOfParticipantAlloc,
          tierAmt: sumOfTierAmt,
          comparisionAmt: sumOfComparisonTierAmt,
          isSumRow: true
        }
        gridApi.api.setPinnedBottomRowData([obj]);

      }
      
      if (initialRowsStorageName == StorageName.CALCULATIONS_PREF_RET_CALC_INITIALROWS) {
        let obj = null;
        
        if (initialRows.length > 0) {
          let sumOfcashFlowAmt: number = initialRows.map(a => a.cashFlowAmt)?.reduce(function (a, b) { return a + b; });
          let sumOfprefRetAmt: number = initialRows.map(a => a.prefRetAmt).reduce(function (a, b) { return a + b; });
          obj = {
            investor: "Totals ",
            cashFlowAmt: sumOfcashFlowAmt,
            cashFlowDate: "",
            prefRetAmt: sumOfprefRetAmt,
            isSumRow: true
          }
        } else {
          obj = {
            investor: "Totals ",
            cashFlowAmt: 0,
            cashFlowDate: "",
            prefRetAmt: 0,
            isSumRow: true
          }
        }
        gridApi.api.setPinnedBottomRowData([obj]);

      }
      return initialRows;
    }
  }