import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import { Subject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FundInformationService } from 'src/app/pages/fund-information/fund-information.service';
import { FundsComponent } from 'src/app/pages/fund-information/funds/funds.component';
import { HeaderTitleService } from 'src/app/shared/services/header-title.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import Swal from 'sweetalert2';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';

@Component
  (
    {
      selector: 'app-vertical-menu',
      templateUrl: './vertical-menu.component.html',
      styleUrls: ['./vertical-menu.component.scss'],
      encapsulation: ViewEncapsulation.None,
      providers: [MenuService, FundsComponent, AppComponent]
    }
  )
export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  onClose: Subject<boolean> = new Subject();
  // isOpenSideMenu    : boolean = true;
  parentMenu: Array<any>;
  public settings: Settings;
  constructor
    (
      public appSettings: AppSettings,
      public menuService: MenuService,
      public fundInformationService: FundInformationService,
      public router: Router,
      private Funds: FundsComponent,
      public NotificationService: NotificationService,
      public headerTitleService: HeaderTitleService,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }

  changeSideNavContent(id): void {
    var vmenu = this.menuService.getVerticalMenuById(id); 

    this.headerTitleService.title = vmenu.headerTitle;
    this.menuItems = vmenu.menuItems;

    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }


  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        }
        else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  onClick(menuId) {
    document.getElementById("Fund Information").classList.remove("ActiveMenu");
    document.getElementById("Transactions").classList.remove("ActiveMenu")
    document.getElementById("Calculation Rule Library").classList.remove("ActiveMenu");
    document.getElementById("Administration").classList.remove("ActiveMenu")
    
    switch(menuId)
    {
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
        document.getElementById("Fund Information").classList.add("ActiveMenu");
        break;
      case 31:
      case 32:
        document.getElementById("Transactions").classList.add("ActiveMenu");
        break;
      case 41:
      case 42:
      case 43:
        document.getElementById("Calculation Rule Library").classList.add("ActiveMenu");
        break;
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
        document.getElementById("Administration").classList.add("ActiveMenu");
        break;
    }
 
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }
}
