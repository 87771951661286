import { Injectable }                       from '@angular/core';
import { Observable,                
         tap }                              from 'rxjs';
import { Operations } from 'src/app/shared/model/filter.model';
import { Result }                           from 'src/app/shared/model/response.model';
import { CommonTabService } from 'src/app/shared/services/common-tab.service';
import { DataService }                      from 'src/app/shared/services/data.service';
import { PostTransactionLockEntry, TransactionFilterCriteriaModel, TransactionPaginationModel } from './tier-transactions.model';

@Injectable({
  providedIn: 'root'
})
export class TierTransactionService {
  rows                            : any;
  constructor(private service: DataService,private commonTabService: CommonTabService) { }
  DeleteTransactionByFilter(data): Observable<Result> {
    let url = "/Transaction/DeleteTransactionByFilter";
    return this.service.post(url, data, true).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  postFilterCriteria(transactionFilterCriteria: TransactionFilterCriteriaModel): Observable<Result> {
    let url = '/Transaction/PostTransactionFilterCriteria';
    return this.service.post(url, transactionFilterCriteria, true).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }
  GetInvestors(fundId, vehicleInvestorId): Observable<Result> {
    let url = '/Transaction/getInvestor?FundId=' + fundId + "&vehicleInvestorId=" + vehicleInvestorId;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      }));
  }
  GetInvestorVehicle(fundId, investorId): Observable<Result> {
    //getGridInvestorGroupByIdListFilter
    let url = '/Transaction/getInvestorGroup?FundId=' + fundId + "&investorId=" + investorId;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      }));
  }
  GetPosition(fundId, investmentId): Observable<Result> {
    let url = "/Transaction/getPosition?FundId=" + fundId + "&investmentId=" + investmentId;
    return this.service.get(url).pipe<Result>(
      tap((response: any) => {
        return response;
      })
    )
  }
  GetInvestment(fundId, positionId): Observable<Result> {
    let url = '/Transaction/getInvestment?FundId=' + fundId + "&positionId=" + positionId;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  getExtraColumn(): Observable<Result> {
    let url = "/Transaction/GetExtraColumns?tableName=Transactions";
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  //for pulling the filter records by fund id
  getGridFilterRecords(fundId) {
    let url = "/Transaction/GetGridFilterRecords?FundId=" + fundId;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  public postTransactionLock(postTransactionLockEntry: PostTransactionLockEntry): Observable<Result> {
    
    let url = "/Transaction/PostTransactionLockEntry"
    return this.service.post(url, postTransactionLockEntry, true).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  transactionFilterCriteria(filterId): Observable<Result> {
    let roleid = this.commonTabService.getRoleid();
    let url = '/Transaction/GetTransactionFilterCriteria?FilterId=' + filterId + '&roleid='+ roleid;
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  public getDataWithPagination(transactionList : TransactionPaginationModel,controllerName : string):Observable<Result>
  {
    let url='/Transaction/Get'+controllerName.replace(' ','');
    return this.service.post(url,transactionList).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  }

  public getTransactionImportDataWithPagination(transactionList : TransactionPaginationModel,controllerName : string):Observable<Result>
  {
    let roleid = this.commonTabService.getRoleid();
    transactionList.roleId = roleid;
    let url='/TransactionImport/Get';
    return this.service.post(url,transactionList).pipe<Result>(tap((response:any)=>{
      return response;
    }))
  }
  public modifyFilter(filters, data, field, operation, fromPopup : boolean) {
    if (!data) {
      return filters;
    }
    var group, values;
    if (operation === null || operation === undefined || operation === "") {
      operation = Operations.eq;
    }
    if (field == "PtrTrans") {
      if (data !== null && data !== undefined && data !== "") {
        filters.rules.push({
          data: data,
          op: Operations.eq,
          field: field
        });
      }
    }
    else {
      if (field === "TransactionDate" || field === "FromDate" || field === "ToDate" || field === "DistIncomeNum"
        || field === "journalId" || field === "subJournalId") {
        if (data !== null && data !== undefined && data !== "") {
          filters.rules.push({
            data: data,
            op: operation,
            field: field
          });
        }
      } else {
        values = data.split(",");
        if (values.length <= 1 && fromPopup == false)  {
          if (values[0] !== "") {
            data = values[0];
            if (data !== null && data !== undefined) {
              filters.rules.push({
                data: data,
                op: operation,
                field: field
              });
            }
          }
        } else {
          group = {
            groupOp: "OR",
            rules: []
          };
          filters.groups.push(group);
          for (var i = 0; i < values.length; i++) {
            var fund = values[i];
            if (fund !== null && fund !== undefined && fund !== "") {
              group.rules.push({
                data: fund,
                op: operation,
                field: field
              });
            }
          }
        }
      }
    }
    return filters;
  }
  GetAllInvestors(): Observable<Result> {
    let url = '/Investor/getInvestorList';
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  GetAllInvestorVehicle(): Observable<Result> {
    let url = '/VehicleInvestor/getAllInvestorVehicle';
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  GetAllInvestment(): Observable<Result> {
    let url = '/Transaction/GetInvestmentList';
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  GetAllPosition(): Observable<Result> {
    let url = "/Position/getPositionList"
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  GetAllTier(): Observable<Result> {
    let url = "/Transaction/getTierList"
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }
  GetAllCalcStatus(): Observable<Result> {
    let url = "/CalcStatus/getAllCalcStatus"
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }));
  }

  deleteTransactionImportRecords(): Observable<Result> {
    let url = "/TransactionImport/Delete"
    return this.service.get(url).pipe<Result>(tap((response: any) => {
      return response;
    }))
  }
  
}

