import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownModel } from 'src/app/shared/model/common.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { DropdownListModel } from '../../fund-information/fund-information.model';
import { CalculationModel } from '../calculation.model';
import { CalculationService } from '../calculation.service';

@Component({
  selector: 'app-journal-modal',
  templateUrl: './journal-modal.component.html',
  styleUrls: ['./journal-modal.component.scss']
})
export class JournalModalComponent implements OnInit {
  dropdownListModel = new DropdownListModel();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialogRef: MatDialogRef<JournalModalComponent>,
    public calculationService:CalculationService,
    public storageService: StorageService,
  ) { }
  formModel: CalculationModel;
  ngOnInit(): void {
    this.formModel = this.data.calculation;
    this.dropdownListModel = this.data.dropdownModel;
    this.formModel.calculation.investmentJEId = this.formModel.calculation.investmentJEId.toString();
  }
  investmentDropdownChange(investmentId : string){
    this.formModel.calculation.investmentJEId = investmentId;
    this.formModel.calculation.positionJEId = (this.formModel.calculation.positionJEId == undefined || this.formModel.calculation.positionJEId == null) ?  "0" : this.formModel.calculation.positionJEId;
    this.calculationService.getPositionDropDownDataByFundID(this.formModel.calculation.fundId,this.formModel.calculation.investmentJEId , this.formModel.calculation.positionJEId )
    .subscribe((res) => {
      if (res.isSuccess) {
       this.dropdownListModel.positionDropdown = res.response;
       this.formModel.calculation.positionJEId =  this.formModel.calculation.positionJEId.toString();
      }
    });
  }

  postionJeRecords(positions: DropdownModel[]){
    this.dialogRef.close({ data: this.formModel });
  }
  onOk(){
    this.dialogRef.close({ data: this.formModel })
  }
}
