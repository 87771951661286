import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Select2Module } from 'ng-select2-component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InvestorRoutingModule } from '../../fund-information/investors/investors.routing';

@NgModule({
  declarations: [
    UsersComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    Select2Module,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatSelectModule,
    InvestorRoutingModule,
  ]
})
export class UsersModule { }
