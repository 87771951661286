import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerRoutingModule } from './spinner-routing.module';
import { SpinnerComponent } from './spinner.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { RunModelComponent } from 'src/app/pages/calculation/run-model/run-model.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    RunModelComponent,
  ],
 
  imports: [
    CommonModule,
    SpinnerRoutingModule,
    FormsModule,
    CommonModule,
    SharedModule,
  ],
  exports: [SpinnerComponent],
})
export class SpinnerModule { }
